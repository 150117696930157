import styled from "styled-components";

export const SessionContent = styled.div`
  display: flex;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: flex-start !important;
  flex-grow: 0;
  border-right: 1px solid var(--gray5);
  margin-right: 15px;
`;

export interface TimeContainerProps {
  $showDay?: boolean;
}

export const TimeContainer = styled.div<TimeContainerProps>`
  margin-right: 20px;
  margin-top: 5px;
  min-width: ${props => props.$showDay ? "9rem" : "4rem"};
`;

export const DurationContainer = styled(TimeContainer)`
  width: 56px;
`;

export const RightContent = styled.div`
  display: flex;
`;

export const SessionLabel = styled.div`
  color: ${props => props.theme.font.muted};
`;

export const Units = styled.div`
  margin-top: 16px;
`;

export const UnitsLabel = styled.div`
  color: ${props => props.theme.font.muted};
`;
