import { Ref, forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import * as Core from "@blueprintjs/core";
import classnames from "classnames";
import { uniqueId } from "lodash-es";
import { useCallbackRef } from "../hooks";
import { getComponentId, useControlLabel } from "./formScope";
import { useColorScheme } from "./theme";

export type Placement = Core.Placement;
type CoreRenderTargetProps = Parameters<NonNullable<Core.PopoverProps["renderTarget"]>>[0];
export type RenderTargetProps = Omit<CoreRenderTargetProps, "isOpen">;

export interface PopoverProps extends Omit<Core.PopoverProps, "content" | "position" | "transitionDuration"> {
  id?: string;

  // Force content prop to at least be explicitly listed
  content: NonNullable<Core.PopoverProps["content"]> | undefined;
  /**
   * If true, will render with `display: inline`.
   * @default false
   */
  inline?: boolean;

  renderTarget?: (props: RenderTargetProps) => JSX.Element;
}

export interface Popover {
  readonly popoverElement: HTMLElement | null;
  reposition(): void;
}

export const Popover = forwardRef((props: PopoverProps, ref: Ref<Popover>) => {
  const {
    className,
    minimal = false,
    inline,
    modifiers: controlledModifiers,
    modifiersCustom: controlledModifiersCustom,
    content,
    disabled,
    inheritDarkTheme = true,
    renderTarget,
    onClosed,
    targetProps: controlledTargetProps,
    id = controlledTargetProps?.id,
    ...restProps
  } = props;

  const { colorScheme } = useColorScheme();

  const popoverRef = useRef<Core.Popover | null>(null);
  const label = useControlLabel();
  const popoverId = getComponentId(id, "popover");
  const [modifiers, customModifiers, targetProps] = usePopperModifiers({
    label,
    popoverId,
    flowTo: id,
    modifiers: controlledModifiers,
    modifiersCustom: controlledModifiersCustom,
  });

  // Ensure latest onClosed is fired, due to TransitionGroup holding onto stale callback
  const handleClosed = useCallbackRef(onClosed);

  const classNames = classnames(className, {
    inline,
    [Core.Classes.DARK]: inheritDarkTheme && colorScheme.dark,
  });

  useImperativeHandle(ref, () => ({
    get popoverElement(): HTMLElement | null {
      return popoverRef.current?.popoverElement ?? null;
    },
    reposition,
  }));

  return (
    <Core.Popover
      {...restProps}
      ref={popoverRef}
      className={classNames}
      content={content}
      disabled={disabled || !content}
      inheritDarkTheme={inheritDarkTheme}
      minimal={minimal}
      modifiers={modifiers}
      modifiersCustom={customModifiers}
      renderTarget={renderTarget ? (props) => renderTarget(getRenderTargetProps(props)) : undefined}
      targetProps={renderTarget ? undefined : { ...controlledTargetProps, ...targetProps }}
      onClosed={handleClosed}
    />
  );

  function getRenderTargetProps(props: CoreRenderTargetProps) {
    const renderTargetProps = { ...targetProps, ...props, isOpen: undefined };
    delete renderTargetProps.isOpen;
    return renderTargetProps;
  }

  function reposition() {
    popoverRef.current?.reposition();
  }
});

export interface PopperTargetProps extends Core.DefaultPopoverTargetHTMLProps {
  id: string;
}

export interface PopperModifierOptions {
  label: string | undefined;
  popoverId: string | undefined;
  flowTo: string | undefined;
  modifiers: Core.PopoverProps["modifiers"] | undefined;
  modifiersCustom: ReadonlyArray<Core.PopperCustomModifier> | undefined;
}

export function usePopperModifiers(options: PopperModifierOptions): [modifiers: Core.PopoverProps["modifiers"], modifiersCustom: Core.PopperCustomModifier[], targetProps: PopperTargetProps] {
  const {
    label,
    popoverId: controlledPopoverId,
    flowTo: controlledFlowTo,
    modifiers: controlledModifiers,
    modifiersCustom: controlledModifiersCustom = [],
  } = options;

  const { fontAppearance } = useColorScheme();
  const zoom = fontAppearance === "Large" ? 1.25 : undefined;
  const popoverId = useMemo(() => controlledPopoverId ?? uniqueId("popover-"), [controlledPopoverId]);
  const flowTo = useMemo(() => controlledFlowTo ?? uniqueId("popover-target-"), [controlledFlowTo]);

  const targetProps = useMemo<PopperTargetProps>(() => ({
    "id": flowTo,
    "data-random-id": controlledFlowTo ? undefined : "true",
    "aria-label": label,
  }), [flowTo]);

  const zoomFixModifiers = useMemo<Core.PopoverProps["modifiers"]>(() => {
    if (!zoom) {
      return undefined;
    }

    return {
      preventOverflow: {
        options: {
          padding: {
            bottom: 1 + (document.body.clientHeight * zoom) - document.body.clientHeight,
            right: 1 + (document.body.clientWidth * zoom) - document.body.clientWidth,
          },
        },
      },
      flip: {
        options: {
          padding: {
            bottom: 1 + (document.body.clientHeight * zoom) - document.body.clientHeight,
            right: 1 + (document.body.clientWidth * zoom) - document.body.clientWidth,
          },
        },
      },
    };
  }, [zoom]);

  const modifiers = useMemo<Core.PopoverProps["modifiers"]>(() => ({
    ...controlledModifiers,
    preventOverflow: {
      ...controlledModifiers?.preventOverflow,
      options: {
        ...zoomFixModifiers?.preventOverflow?.options,
      },
    },
    flip: {
      ...controlledModifiers?.flip,
      options: {
        ...zoomFixModifiers?.flip?.options,
      },
    },
  }), [zoomFixModifiers, controlledModifiers]);

  const modifiersCustom = useMemo<Core.PopperCustomModifier[]>(() => [
    {
      name: "aria",
      enabled: true,
      phase: "beforeWrite",
      requires: [],
      fn({ state }) {
        state.elements.popper.setAttribute("aria-flowto", flowTo);
        state.elements.popper.setAttribute("id", popoverId);

        if (controlledPopoverId) {
          state.elements.popper.removeAttribute("data-random-id");
        } else {
          state.elements.popper.setAttribute("data-random-id", "true");
        }

        if (label) {
          state.elements.popper.setAttribute("aria-label", `${label} Popover`);
        }
      },
    },
    ...controlledModifiersCustom,
  ], [popoverId, flowTo, controlledPopoverId, label, controlledModifiersCustom]);

  return [modifiers, modifiersCustom, targetProps];
}
