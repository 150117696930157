// eslint-disable-next-line no-restricted-imports
import * as Yup from "yup";

declare module "yup" {
  export interface MixedSchema<T = any> {
    requiredWhen(when: boolean | ((this: MixedSchema<T>) => boolean), message?: TestOptionsMessage): MixedSchema<Exclude<T, undefined>>;
  }
  export interface StringSchema<T extends string | null | undefined = string> {
    requiredWhen(when: boolean | ((this: StringSchema<T>) => boolean), message?: TestOptionsMessage): StringSchema<Exclude<T, undefined>>;
  }
  export interface NumberSchema<T extends number | null | undefined = number> {
    requiredWhen(when: boolean | ((this: NumberSchema<T>) => boolean), message?: TestOptionsMessage): NumberSchema<Exclude<T, undefined>>;
  }
  export interface BooleanSchema<T extends boolean | null | undefined = boolean> {
    requiredWhen(when: boolean | ((this: BooleanSchema<T>) => boolean), message?: TestOptionsMessage): BooleanSchema<Exclude<T, undefined>>;
  }
  export interface DateSchema<T extends Date | null | undefined = Date> {
    requiredWhen(when: boolean | ((this: DateSchema<T>) => boolean), message?: TestOptionsMessage): DateSchema<Exclude<T, undefined>>;
  }
  export interface ArraySchema<T> {
    requiredWhen(when: boolean | ((this: ArraySchema<T>) => boolean), message?: TestOptionsMessage): ArraySchema<Exclude<T, undefined>>;
  }
  export interface ObjectSchema<T extends object | null | undefined = object> {
    requiredWhen(when: boolean | ((this: ObjectSchema<T>) => boolean), message?: TestOptionsMessage): ObjectSchema<Exclude<T, undefined>>;
  }
}

function requiredWhen<T>(this: T & { required(message?: Yup.TestOptionsMessage): T }, when: boolean | ((this: T) => boolean), message?: Yup.TestOptionsMessage): T {
  const required = typeof when === "function" ? when.call(this) : when;
  return required ? this.required(message) : this;
}

Yup.addMethod<Yup.MixedSchema>(Yup.mixed, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.StringSchema<any>>(Yup.string, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.NumberSchema<any>>(Yup.number, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.BooleanSchema<any>>(Yup.boolean, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.DateSchema<any>>(Yup.date, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.ArraySchema<any>>(Yup.array, "requiredWhen", requiredWhen);
Yup.addMethod<Yup.ObjectSchema<any>>(Yup.object, "requiredWhen", requiredWhen);
