import { PropsWithChildren, ReactElement, useEffect, useLayoutEffect, useRef } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import styled from "styled-components";
import { SubscriptionInfo, createSubscription } from "~/utils";
import { useSubscriptionDispatch, useSubscriptionState } from "~/hooks";

const Host = styled.div`
  .bp5-portal {
    position: initial;
  }

  &.fill,
  &.fill > .portal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export interface PortalTargetProps {
  fill?: boolean;
}

export type PortalTarget = (props: PropsWithChildren<PortalTargetProps>) => (ReactElement<any, any> | null);
export type PortalComponent = (props: PropsWithChildren<{}>) => (ReactElement<any, any> | null);

export interface PortalInstance {
  /**
   * Where all portaled elements will be rendered.  Portaled elements take precedence over children of this component.
   */
  Target: PortalTarget;
  /**
   * The portal to the target.
   */
  Portal: PortalComponent;
}

export interface PortalComponentProps {
  targetClassName?: string;
  portalClassName?: string;
}

export function createPortal(portalProps?: PortalComponentProps): PortalInstance {
  const { portalClassName, targetClassName } = portalProps ?? {};
  const portal = document.createElement("div");

  const mountedSubscription = createSubscription(0);

  portal.classList.add("portal");

  if (portalClassName) {
    portal.classList.add(portalClassName);
  }

  const Target = (props: PropsWithChildren<PortalTargetProps>) => {
    return <TargetRenderer {...props} mountedSubscription={mountedSubscription} portal={portal} targetClassName={targetClassName} />;
  };

  const Portal = (props: PropsWithChildren<{}>) => {
    return <PortalRenderer {...props} mountedSubscription={mountedSubscription} portal={portal} />;
  };

  return {
    Portal,
    Target,
  };
}

interface TargetRendererProps extends PortalTargetProps {
  targetClassName?: string;
  portal: HTMLElement;
  mountedSubscription: SubscriptionInfo<number>;
}

function TargetRenderer(props: PropsWithChildren<TargetRendererProps>) {
  const { children, fill, portal, targetClassName, mountedSubscription } = props;
  const hostRef = useRef<HTMLDivElement>(null);

  const { context: mountedContext, Provider: SubscriptionProvider } = mountedSubscription;

  const mounted = useSubscriptionState(mountedContext);

  useEffect(() => {
    if (hostRef.current) {
      portal.remove();
      hostRef.current.append(portal);
    }
  }, [mounted > 0, portal]);

  const className = classnames(targetClassName, { fill });

  return (
    <SubscriptionProvider>
      {mounted > 0 && <Host ref={hostRef} className={className}>{children}</Host>}
    </SubscriptionProvider>
  );
}

interface PortalRendererProps {
  portal: HTMLElement;
  mountedSubscription: SubscriptionInfo<number>;
}

function PortalRenderer(props: PropsWithChildren<PortalRendererProps>) {
  const { children, portal, mountedSubscription } = props;
  const { context: mountedContext } = mountedSubscription;
  const dispatch = useSubscriptionDispatch(mountedContext);

  useLayoutEffect(() => {
    dispatch(m => m + 1);
    return () => dispatch(m => m - 1);
  }, []);

  return ReactDOM.createPortal(children, portal);
}
