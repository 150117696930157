import styled from "styled-components";
import { Menu } from "@remhealth/ui";
import { ProductToggle } from "@remhealth/core";

export const ResultMenu = styled(Menu)`
  > li:not(:last-child) {
    margin-bottom: 2px;
  }

  .bp5-non-ideal-state {
    margin-bottom: 10px;

    .bp5-spinner {
      margin: 10px;
    }

    .bp5-non-ideal-state-visual {
      margin-bottom: 0;
    }
  }
`;

export const PatientItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  min-height: 36px;

  .name {
    flex-basis: 50%;
    flex-grow: 0.5;
  }
  .gender-and-birth-date {
    flex-basis: 30%;
    text-align: left;
  }
  .patient-id {
    flex-basis: 20%;
  }
  .rightEl {
    margin-left: auto;
    white-space: nowrap;
  }

  &.active {
    .bp5-button,
    .bp5-icon {
      color: inherit !important;
    }
  }
`;

export const SourceBar = styled(ProductToggle)`
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.border.default};
`;

export const Mrn = styled.span`
  font-size: var(--font-size-small);
  opacity: 0.7;
  word-break: break-word;
`;

export const DetailsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1em;
   .disabled {
     cursor: not-allowed;
     background-color: ${props => props.theme.background.muted};
     color: ${props => props.theme.font.muted};
   }
`;

export const DobInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gray2);
  font-size: var(--font-size-small);
  margin-top: 2px;
`;

export const ClientData = styled.div`
  > span:not(:last-child) {
    margin-right: 5px;
  }
`;

export const LabelInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px 0px 7px;
  font-size: var(--font-size-small);
`;

export const NoResultContainer = styled.div`
  white-space: normal;
`;
