import React, { PropsWithChildren, ReactElement, Ref, forwardRef, useState } from "react";
import { Filter, FilterKeep } from "@remhealth/icons";
import { IconButton } from "./button";
import { ColumnSuggestPopover, ColumnSuggestPopoverProps } from "./columnSuggestPopover";

export interface ColumnSuggestFilterProps<T> extends ColumnSuggestPopoverProps<T> {
  "aria-label": string;
  disabled?: boolean;
}

export interface ColumnSuggestFilter<T> {
  reset(): void;
}

function ColumnSuggestFilterComponent<T>(props: ColumnSuggestFilterProps<T>, ref: Ref<ColumnSuggestFilter<T>>) {
  const {
    "aria-label": ariaLabel,
    disabled = false,
    defaultSelectedItems = [],
    onSelectedItemsChange,
    ...restProps
  } = props;

  const [selectedItems, setSelectedItems] = useState<T[]>(defaultSelectedItems);
  const hasFiltered = selectedItems.length > 0;

  return (
    <ColumnSuggestPopover
      ref={ref}
      {...restProps}
      aria-label={ariaLabel}
      defaultSelectedItems={defaultSelectedItems}
      onSelectedItemsChange={handleChange}
    >
      <IconButton
        data-column-filter
        minimal
        tooltip
        aria-label={ariaLabel}
        disabled={disabled ?? false}
        icon={hasFiltered ? <FilterKeep /> : <Filter />}
        intent={hasFiltered ? "primary" : "none"}
      />
    </ColumnSuggestPopover>
  );

  function handleChange(selectedItems: T[]) {
    setSelectedItems(selectedItems);
    onSelectedItemsChange(selectedItems);
  }
}

export const ColumnSuggestFilter = forwardRef(ColumnSuggestFilterComponent) as <T>(props: PropsWithChildren<ColumnSuggestFilterProps<T>> & { ref?: React.Ref<ColumnSuggestFilter<T>> }) => ReactElement;
