import { Node } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import { type ElementNode, type TextNode, isBlockNode, isElementNode, isTextNode } from "@remhealth/compose";
import { PlaceholderContext, placeholders } from "~/placeholders";
import { htmlToNode } from "./parsers";

export interface PlaceholderifyParserOptions {
  placeholderContext?: PlaceholderContext;
  answerPlaceholder?: string;
  retainUnknown: boolean;
}

export function placeholderify(this: Processor, config: PlaceholderifyParserOptions): Transformer {
  return function transformer(tree: Node | ElementNode): Node {
    const { placeholderContext, answerPlaceholder, retainUnknown } = config;

    const replacements: (TextNode | ElementNode)[] = [];
    if (answerPlaceholder) {
      const answerNode = htmlToNode(answerPlaceholder);

      for (const child of answerNode.children) {
        if (isElementNode(child) || isTextNode(child)) {
          replacements.push(child);
        }
      }
    } else {
      replacements.push({ type: "text", value: "answers" });
    }

    if (placeholderContext) {
      visit(tree, isPlaceholderNode, (element, nodeIndex, parent) => {
        const placeholderName = element.properties?.name;
        if (!parent || nodeIndex === undefined || !placeholderName) {
          return;
        }

        const placeholder = placeholders.find(p => p.name === placeholderName);
        if (!placeholder) {
          return;
        }

        const resolved = placeholder.resolver(placeholderContext)
          ?? (retainUnknown ? undefined : placeholder.display);

        if (resolved) {
          parent.children.splice(
            nodeIndex,
            1,
            {
              type: "text",
              value: resolved,
            }
          );
        }
      });
    }

    if (answerPlaceholder || !retainUnknown) {
      visit(tree, isBlockNode, (blockNode, blockNodeIndex, blockNodesParent) => {
        if (!blockNodesParent || blockNodeIndex === undefined) {
          return;
        }

        const newChildren: (TextNode | ElementNode)[] = [];

        visit(blockNode, isAnswerPlaceholderNode, (placeholderNode, nodeIndex, parent) => {
          const placeholderName = placeholderNode.properties?.name;
          if (!parent || nodeIndex === undefined || !placeholderName) {
            return;
          }

          // If text or inline nodes, just inject them as normal
          if (replacements.every(r => isTextNode(r) || !isBlockNode(r))) {
            parent.children.splice(nodeIndex, 1, ...replacements);
          } else if (replacements.length === 1 && replacements.every(r => isBlockNode(r) && isElementNode(parent) && r.tagName.toLowerCase() === parent.tagName.toLowerCase())) {
            // If a block node, but the same kind as parent, just merge its contents into parent
            parent.children.splice(nodeIndex, 1, ...replacements.flatMap(r => isBlockNode(r) ? r.children : r));
          } else {
            // Otherwise, its multiple block nodes so we can't merge it in place.
            // Shift contents to next block
            parent.children.splice(nodeIndex, 1);
            newChildren.push(...replacements);
          }
        });

        if (newChildren.length > 0) {
          const beforeNodes = blockNodesParent.children.slice(0, blockNodeIndex + 1);
          const afterNodes = blockNodesParent.children.slice(blockNodeIndex + 1);
          blockNodesParent.children = [...beforeNodes, ...newChildren, ...afterNodes];
        }
      });
    }

    return tree;
  };
}

function isPlaceholderNode(node: any): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "rh-placeholder";
}

function isAnswerPlaceholderNode(node: any): node is ElementNode {
  return isPlaceholderNode(node) && !!node.properties && node.properties.name === "answers";
}
