import styled from "styled-components";
import { Button, TextArea } from "@remhealth/ui";

export const ModalAction = styled(Button)`
  margin-left: 20px;
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 30px 10px 0px;
`;

export const CancelReasonTextArea = styled(TextArea)`
  min-height: 100px;
  resize: none;
  line-height: 1.5 !important;
  width: 100%;
`;

export const CancelStatusWrapper = styled.div`
  margin: 20px 0px;
`;

export const Contents = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
