import { useMemo } from "react";
import { startsWithAllWords } from "@remhealth/ui";
import {
  AdministrativeGender,
  IItemView,
  LocalDate,
  Patient,
  PatientFilterSet,
  PatientSortField,
  SortField,
  systems
} from "@remhealth/apollo";

import { EmptyView, UserSession, isDateRangeEffective, useUserSession } from "@remhealth/host";
import { useStore } from "./useStore";
import { lastModifiedAscending } from "./filters";
import { ParsedPatientQuery, parsePatientQuery } from "./parsePatientQuery";

export interface PatientFilterOptions {
  ids?: string[];
  excludePatientIds?: string[];
  activeStatus?: "ActiveOnly" | "InactiveOnly";
  gender?: AdministrativeGender[];
  genderIdentity?: GenderIdentityFilter[];
  birthDate?: LocalDate;
  query?: string;
}

export type PatientViewFilter = "All" | "MyPatients" | "GroupMemberships";

export interface PatientsViewOptions extends PatientFilterOptions {
  filter?: PatientViewFilter;
}

export interface GenderIdentityFilter {
  system?: string;
  anyCodes: string[];
}

export function usePatientsView(sort: SortField<PatientSortField>, options: PatientsViewOptions): IItemView<Patient> {
  const store = useStore();
  const user = useUserSession();

  const { field, direction } = sort;

  return useMemo(() => {
    const careTeamIds = getCareTeamIds(user, options.filter ?? "All");
    const parsedQuery: ParsedPatientQuery = !options.query ? {} : parsePatientQuery(options.query);

    if (careTeamIds && careTeamIds.length === 0) {
      return new EmptyView<Patient>();
    }

    return store.patients.view({
      filters: {
        online: createPatientFiltersInternal(options, careTeamIds, parsedQuery),
        offline: s => offlineFilter(s, options, careTeamIds, parsedQuery),
      },
      orderBy: {
        online: sort,
        offline: sortOffline(sort),
      },
    });
  }, [JSON.stringify(options), field, direction, user.person.id]);
}

export function createPatientFilters(options: PatientFilterOptions): PatientFilterSet[] {
  const parsedQuery: ParsedPatientQuery = !options.query ? {} : parsePatientQuery(options.query);
  return createPatientFiltersInternal(options, [], parsedQuery);
}

function createPatientFiltersInternal(options: PatientFilterOptions, careTeamIds: string[] | undefined, parsedQuery: ParsedPatientQuery): PatientFilterSet[] {
  const idsFilter: PatientFilterSet = (!options.ids || options.ids.length === 0) && (!options.excludePatientIds || options.excludePatientIds.length === 0) ? {} : {
    id: {
      in: options.ids,
      notIn: options.excludePatientIds,
    },
  };

  const careTeamFilter: PatientFilterSet = careTeamIds ? {
    careTeam: {
      in: careTeamIds,
      presence: "MustBePresent",
      effective: {
        wraps: LocalDate.today(),
      },
    },
  } : {};

  const statusFilter: PatientFilterSet = !options.activeStatus ? {} : {
    active: {
      equalTo: options.activeStatus === "ActiveOnly",
    },
  };

  const birthDateFilter: PatientFilterSet = options.birthDate
    ? {
      birthDate: {
        equalTo: options.birthDate,
      },
    }
    : parsedQuery.birthDate
      ? {
        birthDate: {
          onOrAfter: parsedQuery.birthDate.start,
          onOrBefore: parsedQuery.birthDate.end,
        },
      }
      : {};

  const genderFilters: PatientFilterSet = !options.gender || options.gender.length === 0 ? {} : {
    gender: {
      in: options.gender,
    },
  };

  const genderIdentityFilters: PatientFilterSet[] = !options.genderIdentity || options.genderIdentity.length === 0
    ? []
    : options.genderIdentity.map(genderIdentity => ({
      genderIdentity: {
        system: genderIdentity.system,
        code: { equalsAny: genderIdentity.anyCodes },
      },
    }));

  const filters: PatientFilterSet[] = mergeFilters(genderIdentityFilters, [{
    ...idsFilter,
    ...careTeamFilter,
    ...statusFilter,
    ...birthDateFilter,
    ...genderFilters,
  }]);

  return createQueryFilters(parsedQuery, filters);
}

function createQueryFilters(parsedQuery: ParsedPatientQuery, filters: PatientFilterSet[]): PatientFilterSet[] {
  const { query, telecom, patientId } = parsedQuery;

  if (telecom) {
    filters = mergeFilters(filters, getTelecomQueries(telecom));
  }

  if (patientId) {
    filters = mergeFilters(filters, getPatientIdFilters(patientId));
  }

  if (query) {
    filters = mergeFilters(filters, getTextFilters(query));
  }

  return filters;
}

function getCareTeamIds(user: Readonly<UserSession>, filter: PatientViewFilter): string[] | undefined {
  if (user.person.resourceType !== "Practitioner") {
    return undefined;
  }

  if (filter === "MyPatients") {
    return [user.person.id];
  }

  if (filter === "GroupMemberships") {
    return user.person.groupMemberships.filter(g => isDateRangeEffective(g.effective)).map(g => g.listing.id);
  }

  return undefined;
}

function mergeFilters(left: PatientFilterSet[], right: PatientFilterSet[]): PatientFilterSet[] {
  if (right.length === 0) {
    return left;
  }
  if (left.length === 0) {
    return right;
  }
  return left.flatMap(l => right.map(r => ({ ...l, ...r })));
}

function getTextFilters(query: string): PatientFilterSet[] {
  return [
    {
      display: { startsWithAllWords: query },
    },
    {
      otherNames: { startsWithAllWords: query },
    },
    {
      identifier: {
        system: systems.patientId,
        startsWith: query,
        ignoreCase: true,
      },
    },
  ];
}

function getPatientIdFilters(patientId: string): PatientFilterSet[] {
  return [
    {
      // Some customers put IDs in the nickname field...
      nickName: {
        display: { startsWithAllWords: patientId },
      },
    },
    {
      identifier: {
        system: systems.patientId,
        startsWith: patientId,
        ignoreCase: true,
      },
    },
  ];
}

function getTelecomQueries(number: string): PatientFilterSet[] {
  const digits = number.replace(/\D+/g, "");

  const filters: PatientFilterSet[] = [{
    telecoms: {
      value: {
        startsWith: `tel:${digits}`,
      },
    },
  }];

  if (digits.length > 3 && digits.length <= 10) {
    filters.push({
      telecoms: {
        value: {
          startsWith: `tel:+1-${digits.slice(0, 3)}${prefixSeparator(digits.slice(3, 6))}${prefixSeparator(digits.slice(6))}`,
        },
      },
    });
  }

  return filters;
}

function prefixSeparator(value: string, separator = "-") {
  if (value !== "") {
    return `${separator}${value}`;
  }

  return value;
}

function offlineFilter(patient: Patient, options: PatientFilterOptions, careTeamIds: string[] | undefined, parsedQuery: ParsedPatientQuery) {
  if (options.ids && options.ids.length > 0 && !options.ids.includes(patient.id)) {
    return false;
  }

  if (options.excludePatientIds && options.excludePatientIds.length > 0 && options.excludePatientIds.includes(patient.id)) {
    return false;
  }

  if (careTeamIds && !patient.careTeam.some(c => careTeamIds.includes(c.member.id) && isDateRangeEffective(c.effective))) {
    return false;
  }

  if (options.activeStatus && patient.active !== (options.activeStatus === "ActiveOnly")) {
    return false;
  }

  const birthDateFilter = options.birthDate ?? parsedQuery.birthDate;
  if (birthDateFilter && patient.birthDate !== birthDateFilter) {
    return false;
  }

  if (options.genderIdentity && options.genderIdentity.length > 0) {
    const patientGenderIdentity = patient.genderIdentity;
    if (!patientGenderIdentity) {
      return false;
    }

    const matchingGenderIdentity = options.genderIdentity.some(genderIdentity => {
      const isMatch = patientGenderIdentity.codings.some(c => {
        if (c.system !== genderIdentity.system) {
          return false;
        }

        return genderIdentity.anyCodes.includes(c.code);
      });
      return isMatch;
    });

    if (!matchingGenderIdentity) {
      return false;
    }
  }

  const { query } = parsedQuery;

  if (query) {
    const nameMatch = startsWithAllWords(patient.display, query);
    const akaMatch = patient.otherNames.some(otherName => otherName.use === "Nickname" && startsWithAllWords(otherName.display, query));
    const patientIdMatch = !!patient.identifiers?.some(i => i.system === systems.patientId && i.value.toLowerCase().startsWith(query.toLowerCase()));

    if (!nameMatch && !akaMatch && !patientIdMatch) {
      return false;
    }
  }

  return true;
}

function sortOffline(sort: SortField<PatientSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Patient, b: Patient) => {
    switch (sort.field) {
      case "BirthDate":
        return dir * a.birthDate.localeCompare(b.birthDate);
      case "FamilyName":
        return dir * (a.name.family ?? "").localeCompare(b.name.family ?? "");
      case "Gender":
        return dir * a.gender.localeCompare(b.gender);
      case "GenderIdentity":
        return dir * (a.genderIdentity?.text ?? "").localeCompare(b.genderIdentity?.text ?? "");
      case "Mrn": {
        const mrnA = a.identifiers?.find(t => t.system === systems.patientId)?.value ?? "";
        const mrnB = b.identifiers?.find(t => t.system === systems.patientId)?.value ?? "";
        return dir * mrnA.localeCompare(mrnB);
      }
      case "Name":
        return dir * a.display.localeCompare(b.display);
      default:
        return dir * lastModifiedAscending(a, b);
    }
  };
}
