import { Requestor } from "@openid/appauth";

export class FetchRequestor extends Requestor {
  private readonly abort?: AbortSignal;

  constructor(abort?: AbortSignal) {
    super();
    this.abort = abort;
  }

  /*
   * Run the request and return OAuth errors as objects
   */
  public async xhr<T>(settings: JQueryAjaxSettings): Promise<T> {
    const url: URL = new URL(<string>settings.url);
    const requestInit: RequestInit = {
      signal: this.abort,
    };
    requestInit.method = settings.method;
    requestInit.mode = "cors";

    if (settings.data) {
      if (settings.method && settings.method.toUpperCase() === "POST") {
        requestInit.body = <string>settings.data;
      } else {
        const searchParams = new URLSearchParams(settings.data);
        searchParams.forEach((value, key) => {
          url.searchParams.append(key, value);
        });
      }
    }

    // Set the request headers
    requestInit.headers = {};
    if (settings.headers) {
      for (const i in settings.headers) {
        if (Object.prototype.hasOwnProperty.call(settings.headers, i)) {
          requestInit.headers[i] = <string>settings.headers[i];
        }
      }
    }

    const isJsonDataType = settings.dataType && settings.dataType.toLowerCase() === "json";

    // Set 'Accept' header value for json requests (Taken from
    // https://github.com/jquery/jquery/blob/e0d941156900a6bff7c098c8ea7290528e468cf8/src/ajax.js#L644
    // )
    if (isJsonDataType) {
      requestInit.headers.Accept = "application/json, text/javascript, */*; q=0.01";
    }

    const response = await fetch(url.toString(), requestInit);

    const contentType = response.headers.get("content-type");
    const isJsonResponse = isJsonDataType || (contentType && contentType.includes("application/json"));
    const json = isJsonResponse ? await response.json() : undefined;

    if (response.status < 200 || response.status >= 300) {
      const text = isJsonResponse ? undefined : await response.text();
      throw new FetchError(response.statusText, response.status, text, json);
    }

    if (isJsonResponse) {
      return json;
    }

    return await response.text() as any; // Can't know T, so return any
  }
}

export class FetchError extends Error {
  public status: number;
  public text: string | undefined;
  public json: unknown | undefined;

  constructor(message: string, status: number, text: string | undefined, json: unknown | undefined) {
    super(message);
    this.status = status;
    this.text = text;
    this.json = json;
  }
}
