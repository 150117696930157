import { Context, Dispatch, SetStateAction, useContext, useLayoutEffect, useMemo } from "react";
import { SubscriptionContext } from "../utils";
import { useDebouncedState } from "./useDebouncedState";
import { StatefulRefObject, useStateRef } from "./useStateRef";

/**
 * Used like a `useState` for a subscription.
 */
export function useSubscription<TState>(context: Context<SubscriptionContext<TState>>): [TState, Dispatch<SetStateAction<TState>>] {
  const { subscription } = useContext(context);
  const state = useStateRef<TState>(subscription.state); // Used to trigger state changes to mounted component

  // UseLayoutEffect to subscribe early in case first render causes update
  useLayoutEffect(() => {
    state.set(subscription.state);
    return subscription.subscribe(state.set);
  }, []);

  return [state.current, dispatch];

  function dispatch(newState: SetStateAction<TState>) {
    state.set(newState); // Trigger state changes to mounted component
    subscription.update(newState);
  }
}

/**
 * Used like a `useStateRef` for a subscription.
 */
export function useSubscriptionRef<TState>(context: Context<SubscriptionContext<TState>>): StatefulRefObject<TState> {
  const { subscription } = useContext(context);
  const state = useStateRef<TState>(subscription.state);

  // UseLayoutEffect to subscribe early in case first render causes update
  useLayoutEffect(() => {
    state.set(subscription.state);
    return subscription.subscribe(state.set);
  }, []);

  return useMemo(() => ({
    get current() {
      return state.current;
    },
    set: dispatch,
  }), [state]);

  function dispatch(newState: SetStateAction<TState>) {
    state.set(newState); // Trigger state changes to mounted component
    subscription.update(newState);
  }
}

/**
 * Returns the updater dispatch for a subscription.
 * Alias for `[, setState] = useSubscription(context)`.
 */
export function useSubscriptionDispatch<TState>(context: Context<SubscriptionContext<TState>>): Dispatch<SetStateAction<TState>> {
  const { subscription } = useContext(context);
  return dispatch;

  function dispatch(newState: SetStateAction<TState>) {
    subscription.update(newState);
  }
}

/**
 * Returns the current value of a subscription.
 * Alias for `[state] = useSubscription(context)`.
 */
export function useSubscriptionState<TState>(context: Context<SubscriptionContext<TState>>): TState {
  const { subscription } = useContext(context);
  const state = useStateRef<TState>(subscription.state); // Used to trigger state changes to mounted component

  // UseLayoutEffect to subscribe early in case first render causes update
  useLayoutEffect(() => {
    state.set(subscription.state);
    return subscription.subscribe(state.set);
  }, []);

  return state.current;
}

/**
 * Returns the current value of a subscription.
 */
export function useDebouncedSubscriptionState<TState>(context: Context<SubscriptionContext<TState>>, debounceMs: number): TState {
  const { subscription } = useContext(context);
  const [state, setState] = useDebouncedState<TState>(subscription.state, debounceMs);

  // UseLayoutEffect to subscribe early in case first render causes update
  useLayoutEffect(() => {
    return subscription.subscribe(setState);
  }, []);

  return state;
}
