import { Coding } from "@remhealth/apollo";

export const myEvolvCancelledStatuses = {
  cancelledByProvider: {
    system: "urn:oid:2.16.840.1.113883.6.96",
    display: "Appointment cancelled by provider",
    code: "185333000",
  },
  cancelledByPatient: {
    system: "urn:oid:2.16.840.1.113883.6.96",
    display: "Appointment cancelled by patient",
    code: "185332005",
  },
  cancelled: {
    system: "urn:oid:2.16.840.1.113883.6.96",
    display: "Cancelled",
    code: "89925002",
  },
} satisfies Record<string, Coding>;
