export type RgbValues = { r: number; g: number; b: number };
export type HslValues = { h: number; s: number; l: number };

export type HslColor = `hsl(${number}, ${number}%, ${number}%)`;
export type RgbColor = `rgb(${number}, ${number}, ${number})`;
export type RgbaColor = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HexColor = `#${string}`;
export type Color = HslColor | RgbColor | RgbaColor | HexColor;

const rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
const hslRegex = /^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/;

/**
 * Converts a color into distinct rgb values.
 * Accepts "rgb(0,0,0)", "rgba(0,0,0,0)", "#000000"
 */
export function toRgb(color: RgbValues | HslValues | Color): RgbValues {
  if (isRgbValues(color)) {
    return color;
  }

  if (isHslValues(color)) {
    return hslToRgb(color);
  }

  const rgbMatch = rgbRegex.exec(color);
  if (rgbMatch) {
    return {
      r: Number.parseInt(rgbMatch[1], 10),
      g: Number.parseInt(rgbMatch[2], 10),
      b: Number.parseInt(rgbMatch[3], 10),
    };
  }

  const hslMatch = hslRegex.exec(color);
  if (hslMatch) {
    return hslToRgb({
      h: Number.parseInt(hslMatch[1], 10),
      s: Number.parseInt(hslMatch[2], 10),
      l: Number.parseInt(hslMatch[3], 10),
    });
  }

  // If RGB --> Convert it to HEX: http://gist.github.com/983661
  const hex = +`0x${color.slice(1).replace(color.length < 5 ? /./g : "", "$&$&")}`;
  return {
    r: hex >> 16,
    g: (hex >> 8) & 255,
    b: hex & 255,
  };
}

/**
 * Creates a string in the format "rgba(r,g,b,a)".
 * @param color Accepts "rgb(0, 0, 0)", "rgba(0, 0, 0, 0)", "#000000"
 * @param alpha The alpha percent in decimal form from 0 to 1.
 */
export function rgba(color: Color, alpha: number): RgbaColor {
  const { r, g, b } = toRgb(color);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function isDarkColor(color: RgbValues | HslValues | Color): boolean {
  const { r, g, b } = toRgb(color);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    (0.299 * (r * r))
    + (0.587 * (g * g))
    + (0.114 * (b * b))
  );

  // Using the HSP value, determine whether the color is light or dark
  return hsp < 160;
}

export interface HslOptions {
  /** Hue value or range.  Between 0 and 360. */
  hue?: number | Readonly<[number, number]>;
  /** Saturation percentage value or range.  Between 0 and 130. */
  saturation?: number | Readonly<[number, number]>;
  /** Lightness percentage value or range.  Between 0 and 100. */
  lightness?: number | Readonly<[number, number]>;
}

/**
 * Converts a string of any length into a LCH color.  The same string and LCH options will always return the same color.
 * @returns A number array consisting of the Hue, Saturation, and Lightness values respectively.
 */
export function stringToHsl(value: string, options: HslOptions): HslColor;

/**
 * Converts a string of any length into a HSL color.  The same string and HSL options will always return the same color.
 * @returns An object consisting of the Hue, Saturation, and Lightness values respectively.
 */
export function stringToHsl(value: string, options: HslOptions, format: "values"): HslValues;

/**
 * Converts a string of any length into a HSL color.  The same string and HSL options will always return the same color.
 * @returns A css string value in the format `hsl(#, #%, #%)`
 */
export function stringToHsl(value: string, options: HslOptions, format: "color"): HslColor;

export function stringToHsl(value: string, options: HslOptions, format: "values" | "color" = "color"): HslColor | HslValues {
  const opt: Required<HslOptions> = {
    lightness: [0, 100],
    saturation: [0, 100],
    hue: [0, 360],
    ...options,
  };

  const hashesNeeded = (typeof opt.hue === "number" ? 0 : 1)
    + (typeof opt.saturation === "number" ? 0 : 1)
    + (typeof opt.lightness === "number" ? 0 : 1);

  const hashes: number[] = hashString(value, hashesNeeded);

  const hue: number = typeof opt.hue === "number"
    ? opt.hue
    : numberToRange(hashes.pop()!, opt.hue, 360);

  const saturation: number = typeof opt.saturation === "number"
    ? opt.saturation
    : numberToRange(hashes.pop()!, opt.saturation, 100);

  const lightness: number = typeof opt.lightness === "number"
    ? opt.lightness
    : numberToRange(hashes.pop()!, opt.lightness, 100);

  const hsl: HslValues = { h: hue, s: saturation, l: lightness };

  if (format === "color") {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)` satisfies HslColor;
  }

  return hsl;
}

export type ColorRange = Readonly<[start: Color, end: Color]>;

export function colorBetween(range: ColorRange, ratio: number, output: "hex"): HexColor;
export function colorBetween(range: ColorRange, ratio: number, output: "hsl"): HslColor;
export function colorBetween(range: ColorRange, ratio: number, output: "rgb"): RgbColor;
export function colorBetween(range: ColorRange, ratio: number, output: "rgba"): RgbaColor;
export function colorBetween(range: ColorRange, ratio: number, output: "hex" | "hsl" | "rgb" | "rgba"): HexColor | HslColor | RgbColor | RgbaColor {
  const startRgb = toRgb(range[0]);
  const endRgb = toRgb(range[1]);
  const r = Math.ceil((endRgb.r * ratio) + (startRgb.r * (1 - ratio)));
  const g = Math.ceil((endRgb.g * ratio) + (startRgb.g * (1 - ratio)));
  const b = Math.ceil((endRgb.b * ratio) + (startRgb.b * (1 - ratio)));

  return rgbTo({ r, g, b }, output);
}

// Creates N number of hashes of a string by splitting it into parts
function hashString(value: string, count: number): number[] {
  const hashes: number[] = [];

  for (let i = 0; i < count; ++i) {
    let hash = 0;

    // Skip characters to avoid giving bias to segments of string
    for (let j = 0 + i; j < value.length; j += count) {
      hash += value.charCodeAt(j) * 97; // Use a prime number to give more volatility
    }

    hashes.push(hash);
  }

  return hashes;
}

// Converts a number into a value that falls between the given `range`
// `range` values can be reversed to indicate a middle hollow range
function numberToRange(value: number, range: Readonly<[number, number]>, boundary: number): number {
  // If reverse range was chosen (e.g. hue of 340-15 is all reds)
  const reverse = range[0] > range[1];

  // Available values range from 0 to `scale`
  const scale = reverse
    ? (boundary - range[0]) + range[1]
    : range[1] - range[0];

  // `position` is the selected value in the 0 to `scale` range
  const position = scale === 0 ? 0 : value % scale;

  if (reverse) {
    return (range[0] + position) % boundary;
  }

  return range[0] + position;
}

function rgbTo(rgb: RgbValues, output: "hex"): HexColor;
function rgbTo(rgb: RgbValues, output: "hsl"): HslColor;
function rgbTo(rgb: RgbValues, output: "rgb"): RgbColor;
function rgbTo(rgb: RgbValues, output: "rgba"): RgbaColor;
function rgbTo(rgb: RgbValues, output: "hex" | "hsl" | "rgb" | "rgba"): HexColor | HslColor | RgbColor | RgbaColor;
function rgbTo(rgb: RgbValues, output: "hex" | "hsl" | "rgb" | "rgba"): HexColor | HslColor | RgbColor | RgbaColor {
  switch (output) {
    case "hex": return `#${hex(rgb.r)}${hex(rgb.g)}${hex(rgb.b)}` satisfies HexColor;
    case "rgb": return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` satisfies RgbColor;
    case "rgba": return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)` satisfies RgbaColor;
    case "hsl": {
      const hsl = rgbToHsl(rgb);
      return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)` satisfies HslColor;
    }
  }
}

function rgbToHsl(rgb: RgbValues): HslValues {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
        ? 2 + ((b - r) / s)
        : 4 + ((r - g) / s)
    : 0;
  return {
    h: 60 * h < 0 ? (60 * h) + 360 : 60 * h,
    s: 100 * (s ? l <= 0.5 ? s / ((2 * l) - s) : s / (2 - ((2 * l) - s)) : 0),
    l: (100 * ((2 * l) - s)) / 2,
  };
}

function hslToRgb(hsl: HslValues): RgbValues {
  let { h, s, l } = hsl;
  s /= 100;
  l /= 100;

  // Based on algorithm from http://en.wikipedia.org/wiki/HSL_and_HSV#Converting_to_RGB
  const chroma = (1 - Math.abs((2 * l) - 1)) * s;
  let huePrime = h / 60;
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1));

  huePrime = Math.floor(huePrime);

  let red = 0;
  let green = 0;
  let blue = 0;

  switch (huePrime) {
    case 0:
      red = chroma;
      green = secondComponent;
      break;
    case 1:
      red = secondComponent;
      green = chroma;
      break;
    case 2:
      green = chroma;
      blue = secondComponent;
      break;
    case 3:
      green = secondComponent;
      blue = chroma;
      break;
    case 4:
      red = secondComponent;
      blue = chroma;
      break;
    case 5:
      red = chroma;
      blue = secondComponent;
      break;
  }

  const lightnessAdjustment = l - (chroma / 2);
  red += lightnessAdjustment;
  green += lightnessAdjustment;
  blue += lightnessAdjustment;

  return {
    r: Math.round(red * 255),
    g: Math.round(green * 255),
    b: Math.round(blue * 255),
  };
}

function isHslValues(value: unknown): value is HslValues {
  return !!value
    && typeof value === "object"
    && "h" in value && "s" in value && "l" in value
    && typeof value.h === "number" && typeof value.s === "number" && typeof value.l === "number";
}

function isRgbValues(value: unknown): value is RgbValues {
  return !!value
    && typeof value === "object"
    && "r" in value && "g" in value && "b" in value
    && typeof value.r === "number" && typeof value.g === "number" && typeof value.b === "number";
}

function hex(x: number) {
  const str = x.toString(16);
  return str.length === 1 ? "0" + str : str;
}
