import styled from "styled-components";
import { Text } from "text";
import { Practitioner, securityRoleIds } from "@remhealth/apollo";
import { Classes, Tag, Tooltip } from "@remhealth/ui";
import { getAccessStatusMessage, usePracticePreferences } from "@remhealth/host";
import { getPracticePreferenceLabeling } from "~/labeling";
import { AmbientListeningTag } from "./userTags.styles";

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const InactiveTag = styled.span`
  text-decoration: line-through;
`;

export interface UserTagsProps {
  user: Practitioner;
}

export function UserTags(props: UserTagsProps) {
  const { user } = props;

  const practicePreference = usePracticePreferences();
  const labels = getPracticePreferenceLabeling(practicePreference);

  const inactiveReason = getAccessStatusMessage(user);
  const hasBellsRole = user.profile.roles.some(r => r.id === securityRoleIds.Bells);
  const hasAdminRole = user.profile.roles.some(r => r.id === securityRoleIds.Administrative);
  const hasSupervisorRole = user.profile.roles.some(r => r.id === securityRoleIds.Supervisor);
  const isPatientPopulationAll = practicePreference.patientPopulationLevel !== "All" && user.patientPopulationOverride === "All";
  const isPatientPopulationCareTeam = practicePreference.patientPopulationLevel !== "CareTeam" && user.patientPopulationOverride === "CareTeam";
  const hasAmbientListeningRole = user.profile.roles.some(r => r.id === securityRoleIds.AmbientListening);

  return (
    <Tags>
      {hasBellsRole && !inactiveReason && <Tag minimal intent="primary">Bells Enabled</Tag>}
      {hasBellsRole && inactiveReason && (
        <Tooltip content={inactiveReason}>
          <Tag minimal intent="none"><InactiveTag className={Classes.TEXT_MUTED}>Bells Enabled</InactiveTag></Tag>
        </Tooltip>
      )}
      {hasAdminRole && <Tag minimal intent="danger">Admin</Tag>}
      {hasSupervisorRole && <Tag minimal intent="warning">Supervisor</Tag>}
      {isPatientPopulationAll && <Tag minimal intent="success">All {labels.Patients}</Tag>}
      {isPatientPopulationCareTeam && <Tag minimal intent="success">Care Team</Tag>}
      {hasAmbientListeningRole && <AmbientListeningTag minimal>{Text.AmbientListening}</AmbientListeningTag>}
    </Tags>
  );
}
