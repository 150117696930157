import styled, { css } from "styled-components";
import { NoteSummary } from "./noteSummary";

export const SubformTableStyles = css`
  table.subform {
    margin: 10px 0;
    overflow: hidden;
    border-spacing: 0;
    border-radius: ${props => props.theme.gridSize}px;
    border: 1px solid ${props => props.theme.border.muted};

    th {
      font-weight: bold;
      color: inherit;
      background-color: ${props => props.theme.background.muted};
    }

    tr {
      height: 36px;
    }

    th, td {
      text-align: left;
      vertical-align: middle;
      padding: 6px 11px;
    }

    thead:last-child tr th,
    tbody:not(:first-child) tr td {
      border-top: 1px solid ${props => props.theme.border.muted};
    }
  }
`;

export const StyledNoteSummary = styled(NoteSummary)`
  padding: 2rem;
  overflow-y: auto;

  header {
    font-weight: normal;

    h1 {
      font-size: var(--font-size-header3);
      color: var(--gray4);
      margin: 0 0 10px 0;
    }

    .main-heading {
      font-size: var(--font-size-large);

      p {
        margin: 0;
      }
    }
  }

  section.center {
    text-align: center;
  }

  h2, h3, h4, h5 {
    font-size: var(--font-size-header4);
  }

  dl {
    display: grid;
    grid-template-columns: max-content auto;

    dt, dd {
      font-weight: normal;
    }

    dt {
      grid-column: 1;
    }

    dd {
      grid-column: 2;
    }

    dt:has(+ dd.list) {
      font-weight: bold;

      &:after {
        content: "";
      }
    }

    dd.list {
      grid-column: 1;
    }
  }

  dt {
    color: var(--dark-gray5);

    &:after {
      content: ":";
    }
  }

  table td {
    font-size: var(--font-size-normal);
  }

  dl.info {
    font-size: var(--font-size-small);
    color: var(--dark-gray5);
    border-bottom: 1px solid var(--gray4);
    padding-bottom: 10px;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-column-gap: 10px;

    dl {
      display: block;
    }

    dt, dd {
      margin: 0;
      .authors {
        padding-left: 8px;
      }
    }

    dt, .name {
      font-weight: bold;
      &:after {
        content: "";
      }
    }

    /* Print date is first dt/dd, but we want to just display it without a label */
    dt.print-date {
      display: none;
    }
    dt.print-date + dd {
      color: var(--dark-gray5);
      margin: 0 0 1rem 0;
      grid-row: 1;
      grid-column: span 5;
    }

    .patient-info {
      margin: 0;

      dt {
        display: none;
      }
    }

    /* Let print-date have the entire first row, labels go on 2nd row */
    dt {
      grid-column: auto;
      grid-row: 2;
    }

    /* Values go on 3rd row */
    dd {
      grid-column: auto;
      grid-row: 3;
    }
  }

  section {
    .label {
      color: var(--dark-gray5);
    }

    &.goal-section {
      dl {
        display: flex;
      }
      dt {
        min-width: 100px;
      }

      td, th {
        width: 25%;
      }
      td {
        vertical-align: top;
      }
    }

    &.session-time-section {
      ul li {
        position: relative;
        display: table-row;

        .start {
          display: table-cell;
        }

        .stop {
          display: table-cell;
        }

        .value {
          padding-left: 4px;
          padding-right: 16px;
        }

        /* Displays the list number */
        &:before {
          position: absolute;
          content: "";
          display: list-item;
        }
      }
      .session-length {
        padding: 4px;
      }
      .session-units {
        padding: 4px;
      }
    }

    &.reviewers {
      table, tr {
        width: 100%;
      }

      th, td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }

    &.add-on {
      .service {
        margin-right: 10px;
      }
    }
  }

  section.diagnoses-section {
    ol li {
      position: relative;
      display: table-row;

      > span:not(:last-child) {
        padding-right: 8px;
      }

      .code {
        display: table-cell;
      }

      /* Displays the list number */
      &:before {
        position: absolute;
        content: "";
        display: list-item;
      }

      .billing, .code, .onset {
        color: var(--dark-gray5);
      }
    }
  }

  section.signature-section {
    dd {
        margin-left: 0px;
        padding: 4px 0;
    }
  }

  ${SubformTableStyles};
`;
