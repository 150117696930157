import { Duration } from "luxon";
import { ClockInterval, useIntervalTimer } from "./useIntervalTimer";

export interface StopwatchOptions {
  /**
   * Initial value of the stopwatch.
   * @default 0
   */
  initialValue?: Duration;

  /**
   * Determines how frequent the updates are reported.
   * @default ClockInterval.minute
   */
  updateFrequency?: ClockInterval;
}

/**
 * Returns a Duration that will continue to increment.
 */
export function useStopwatch(options?: StopwatchOptions): Duration {
  return useIntervalTimer({ updateFrequency: "minute", ...options, mode: "stopwatch" });
}
