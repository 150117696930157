import { Node } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import {
  type ElementContent,
  type ElementNode,
  type RootContent,
  type RootNode,
  type TextNode,
  isBlockNode,
  isElementNode,
  isRootNode,
  isTextNode
} from "./utils";

export interface TextifyOptions {
  /** @default false */
  uppercaseHeaders?: boolean;
  /** @default false */
  trim?: boolean;
}

export function textify(this: Processor, options: TextifyOptions = {}): Transformer {
  const { trim = false, uppercaseHeaders = false } = options;

  return function transformer(tree: Node | RootNode): Node {
    // Combine all into a single text node
    visit(tree, isRootNode, (root) => {
      const children = root.children ?? [];
      const element = mergeElements(children.map((c, i) => getText(c, i, root, 0)), true);

      const text = trim ? element.text.trim() : element.text;

      const textNode: TextNode = {
        type: "text",
        value: text,
      };
      root.children = [textNode];
    });

    return tree;
  };

  function getText(node: ElementContent | RootContent, index: number, parent: RootNode | ElementNode, indentLevel: number): TextElement {
    if (isTextNode(node)) {
      const text = node.value ?? "";

      // Remove new lines between block tags
      if (index > 0 && isBlockNode(parent.children?.[index - 1])) {
        return new TextElement(text.trim(), 0, 0, false);
      }

      return new TextElement(text, 0, 0, false);
    }

    if (!isElementNode(node)) {
      return TextElement.empty;
    }

    const tagName = node.tagName.toLowerCase();
    const children = node.children ?? [];

    // Number or Bullet lists
    if (tagName === "ul" || tagName === "ol") {
      if (children.length === 0) {
        return TextElement.empty;
      }

      const nestedList = isElementNode(parent) && parent.tagName.toLowerCase() === "li";

      return TextElement.block(
        mergeElements(
          children
            .filter(isLiElement)
            .map((li, index) => {
              const bullet = tagName === "ol" ? `${index + 1}. ` : "* ";
              const indent = "  ".repeat(indentLevel);
              const block = getText(li, index, node, indentLevel + 1);
              return new TextElement(indent + bullet + block.text, 0, 0, true);
            })
            .concat(nestedList ? [] : TextElement.line)
        )
      );
    }

    // Definition lists
    if (tagName === "dl") {
      if (children.length === 0) {
        return TextElement.empty;
      }

      return TextElement.block(mergeElements(children.map((child, i) => convertDlChild(child, i, node, indentLevel))));
    }

    // Tables
    if (tagName === "table" && isElementNode(node)) {
      return convertTable(node, indentLevel);
    }

    // Ignore last trailing <br> if parent is block node
    // e.g. <p>Testing<br></p>
    if ((isBlockNode(node) || isLiElement(node)) && isBrElement(children[children.length])) {
      children.pop();
    }

    const innerText = mergeElements(children.map((c, i) => getText(c, i, node, indentLevel)));

    switch (tagName) {
      case "br": return TextElement.line;
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6": return TextElement.header(uppercaseHeaders ? innerText.text.toUpperCase() : innerText.text);
      case "p": {
        // Special case for empty paragraph with only <br> tag
        if (children.every(n => isElementNode(n) && n.tagName.toLowerCase() === "br")) {
          return TextElement.line;
        }
        return TextElement.paragraph(innerText.text);
      }
      case "img": return TextElement.text(String(node.properties.alt ?? ""));
      case "rh-select":
      case "rh-mention":
        return node.properties?.display ? TextElement.text(String(node.properties.display ?? "")) : innerText;
      default:
        return isBlockNode(node) ? TextElement.block(innerText) : innerText;
    }
  }

  function convertDlChild(node: ElementContent, index: number, parent: RootNode | ElementNode, indentLevel: number): TextElement {
    if (isDtElement(node)) {
      return new TextElement(getText(node, index, parent, indentLevel).text + " ", 0, 0, false);
    }
    if (isDdElement(node)) {
      return new TextElement(getText(node, index, parent, indentLevel).text, 0, 1, false);
    }

    return getText(node, index, parent, indentLevel);
  }

  function convertTable(node: ElementNode, indentLevel: number): TextElement {
    const cellDivider = "   ";
    const maxCellWidth = 30;
    const widths = new Map<number, number>();

    const rows = node.children.flatMap(getTableRows).flatMap(row => {
      const cells = row.children.filter(isCellElement).map(cell => getText(cell, 0, row, 0).text);
      return { node: row, cells };
    });

    // Find out largest row width for cells in each column
    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];

      let addlRow: { node: ElementNode; cells: string[] } | undefined;

      for (let c = 0; c < row.cells.length; c++) {
        let cell = row.cells[c];
        const current = widths.get(c) ?? 0;

        if (cell.length > maxCellWidth) {
          let cutIndex = lastOccurrenceOf(cell, [" ", ".", "?", "-"]);
          cutIndex = cutIndex === -1 ? maxCellWidth : cutIndex;
          const remainder = cell.slice(cutIndex).trimStart();
          cell = cell.slice(0, cutIndex);
          row.cells[c] = cell;

          if (!addlRow) {
            addlRow = { node: row.node, cells: Array.from({ length: row.cells.length }).map(() => "") };
          }

          addlRow.cells[c] = remainder;
        }

        const contentLength = cell.length + cellDivider.length;
        widths.set(c, Math.max(current, contentLength));
      }

      if (addlRow) {
        rows.splice(r + 1, 0, addlRow);
      }
    }

    const textRows = rows.flatMap(row => {
      const cells = row.cells.map((cell, index) => {
        const width = widths.get(index) ?? 0;

        if (index + 1 < row.cells.length) {
          cell = cell.padEnd(width - cellDivider.length, " ");
          cell += cellDivider;
        }

        return TextElement.text(cell);
      });

      const indent = "  ".repeat(indentLevel);
      const textRow = [new TextElement(indent + mergeElements(cells).text, 0, 0, true)];

      if (row.node.properties.head) {
        const headingBorders = row.cells.map((_, index) => {
          const width = widths.get(index) ?? 0;
          let text = "-".repeat(width - cellDivider.length);

          if (index + 1 < row.cells.length) {
            text += cellDivider;
          }

          return TextElement.text(text);
        });
        textRow.push(new TextElement(indent + mergeElements(headingBorders).text, 0, 0, true));
      }

      return textRow;
    });

    return new TextElement(mergeElements(textRows).text, 1, 1, true);
  }

  function getTableRows(node: ElementContent): ElementNode[] {
    if (!isElementNode(node)) {
      return [];
    }

    if (node.tagName.toLowerCase() === "thead") {
      return node.children.flatMap(getTableRows).map(row => ({ ...row, properties: { ...row.properties, head: true } }));
    }

    if (node.tagName.toLowerCase() === "tbody") {
      return node.children.flatMap(getTableRows);
    }

    if (node.tagName.toLowerCase() === "tr") {
      return [node];
    }

    return [];
  }
}

function isCellElement(node: ElementContent): node is ElementNode {
  return isElementNode(node) && (node.tagName.toLowerCase() === "td" || node.tagName.toLowerCase() === "th");
}

function isBrElement(node: ElementContent): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "br";
}

function isLiElement(node: ElementContent): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "li";
}

function isDtElement(node: ElementContent): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "dt";
}

function isDdElement(node: ElementContent): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "dd";
}

class TextElement {
  public static empty = new TextElement("", 0, 0, false);
  public static line = new TextElement("", 0, 0, true);

  public readonly text: string;
  public readonly topMargin: number;
  public readonly bottomMargin: number;
  public readonly block: boolean;

  constructor(text: string, topMargin: number, bottomMargin: number, block: boolean) {
    this.text = text;
    this.topMargin = topMargin;
    this.bottomMargin = bottomMargin;
    this.block = block;
  }

  public static text(text: string) {
    return new TextElement(text, 0, 0, false);
  }

  public static header(text: string) {
    return new TextElement(text, 1, 1, true);
  }

  public static paragraph(text: string) {
    return new TextElement(text, 0, 1, true);
  }

  public static block(element: TextElement) {
    return new TextElement(element.text, element.topMargin, element.bottomMargin, true);
  }
}

function mergeElements(blocks: TextElement[], root = false): TextElement {
  // Filter empty blocks
  blocks = blocks.filter(b => b.text || b.block);

  if (blocks.length === 0) {
    return TextElement.empty;
  }

  let text = "";

  for (let i = 0; i < blocks.length; ++i) {
    const block = blocks[i];

    if (i > 0 && text) {
      const previous = blocks[i - 1];
      if (previous && (previous.block || previous.bottomMargin > 0)) {
        const topMargin = Math.max(previous.bottomMargin, block.topMargin);
        text += (previous.block || block.block ? "\n" : "") + "\n".repeat(topMargin);
      } else if (block.block) {
        text += "\n" + "\n".repeat(block.topMargin);
      }
    }

    text += block.text;
  }

  // Add new line at end of root level, unless it appears to be a single line result.
  // We prefer a single paragraph to output without any new lines.
  if (root && blocks.length > 1 && blocks[blocks.length - 1].block) {
    if (!text.endsWith("\n")) {
      text += "\n";
    }

    const additionalLines = blocks[blocks.length - 1].bottomMargin;
    if (additionalLines > 0) {
      text += "\n".repeat(additionalLines);
    }
  }

  const anyBlock = blocks.some(b => b.block);
  return new TextElement(text, blocks[0].topMargin, blocks[blocks.length - 1].bottomMargin, anyBlock);
}

function lastOccurrenceOf(str: string, chars: string[]): number {
  return Math.max(...chars.map(char => str.lastIndexOf(char)));
}

export function rehypeTextify(this: Processor) {
  Object.assign(this, { Compiler: compiler });

  function compiler(node: Node) {
    if (isElementNode(node) || isRootNode(node)) {
      return all(node);
    }

    return isTextNode(node) ? node.value : "";
  }
}

function one(node: Node) {
  if (isTextNode(node)) {
    return node.value;
  }

  return isElementNode(node) ? all(node) : "";
}

function all(node: ElementNode | RootNode) {
  let index = -1;
  const result: string[] = [];

  if (node.children) {
    while (++index < node.children.length) {
      const child = node.children[index];
      if (isTextNode(child)) {
        result[index] = one(child) ?? "";
      } else if (isElementNode(child)) {
        result[index] = all(child);
      }
    }
  }

  return result.join("");
}
