import { useEffect } from "react";
import { StatefulRefObject, useAbort, useStateRef } from "@remhealth/ui";
import { ItemSetEventHandler, RequestOptions } from "@remhealth/apollo";

interface Store<T> {
  get(resourceId: string): T | null;
  fetch(resourceId: string, requestOptions?: RequestOptions): Promise<T | null>;
  onItemSet(resourceId: string, handler: ItemSetEventHandler<T>): () => void;
}

interface PartitionedStore<T> {
  get(partition: string, resourceId: string): T | null;
  fetch(partition: string, resourceId: string, requestOptions?: RequestOptions): Promise<T | null>;
  onItemSet(partition: string, resourceId: string, handler: ItemSetEventHandler<T>): () => void;
}

interface UnpartitionedItem {
  id: string;
}

interface PartitionedItem {
  id: string;
  partition: string;
}

/**
 * Returns an item from the store.  Will perform a fetch call if the item is not yet in the store.
 */
export function useStoreItem<T>(store: Store<T>, id: string | null | undefined): T | null;

/**
 * Returns an item from the store.  Will perform a fetch call if the item is not yet in the store.
 */
export function useStoreItem<T>(store: PartitionedStore<T>, partition: string | null | undefined, id: string | null | undefined): T | null;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends UnpartitionedItem, K extends T = T>(store: Store<T>, initialItem: K): K;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends UnpartitionedItem, K extends T = T>(store: Store<T>, initialItem: K | undefined): K | undefined;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends UnpartitionedItem, K extends T = T>(store: Store<T>, initialItem: K | null): K | null;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends PartitionedItem, K extends T = T>(store: PartitionedStore<T>, initialItem: K): K;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends PartitionedItem, K extends T = T>(store: PartitionedStore<T>, initialItem: K | undefined): K | undefined;

/**
 * Returns an item from the store.  Will default to the item passed in.
 */
export function useStoreItem<T extends PartitionedItem, K extends T = T>(store: PartitionedStore<T>, initialItem: K | null): K | null;

export function useStoreItem(
  ...args: Parameters<typeof useUnpartitionedStoreItem>
  | Parameters<typeof usePartitionedStoreItem>
  | Parameters<typeof useUnpartitionedStoreItemWithItem>
  | Parameters<typeof usePartitionedStoreItemWithItem>
): ReturnType<typeof useUnpartitionedStoreItem>
 | ReturnType<typeof usePartitionedStoreItem>
 | ReturnType<typeof useUnpartitionedStoreItemWithItem>
 | ReturnType<typeof usePartitionedStoreItemWithItem> {
  // Called useStoreItem(store, partition, id)
  if (arguments.length === 3) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePartitionedStoreItem(...(args as Parameters<typeof usePartitionedStoreItem>));
  }

  // Called useStoreItem(store, id)
  if (typeof args[1] === "string") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useUnpartitionedStoreItem(...(args as Parameters<typeof useUnpartitionedStoreItem>));
  }

  // Called useStoreItem(partitionedStore, initialItem)
  if (typeof args[1] === "object" && (args[1] as PartitionedItem).partition) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePartitionedStoreItemWithItem(...(args as Parameters<typeof usePartitionedStoreItemWithItem>));
  }

  // Called useStoreItem(unpartitionedStore, initialItem)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useUnpartitionedStoreItemWithItem(...(args as Parameters<typeof useUnpartitionedStoreItemWithItem>));
}

function useUnpartitionedStoreItem<T>(store: Store<T>, id: string | null | undefined): T | null {
  const key = useStateRef(() => Math.random());
  useUnpartitionStoreUpdates(store, id, key);
  return (id ? store.get(id) : null) ?? null;
}

function useUnpartitionedStoreItemWithItem<T extends UnpartitionedItem>(store: Store<T>, initialItem: T | undefined): T | undefined;
function useUnpartitionedStoreItemWithItem<T extends UnpartitionedItem>(store: Store<T>, initialItem: T | null): T | null;
function useUnpartitionedStoreItemWithItem<T extends UnpartitionedItem>(store: Store<T>, initialItem: T): T;
function useUnpartitionedStoreItemWithItem<T extends UnpartitionedItem>(store: Store<T>, initialItem: T | null | undefined): T | null | undefined {
  const key = useStateRef(() => Math.random());
  const { id } = initialItem ?? {};
  useUnpartitionStoreUpdates(store, initialItem?.id, key);
  return (id ? store.get(id) : null) ?? initialItem;
}

function usePartitionedStoreItem<T>(store: PartitionedStore<T>, partition: string | null | undefined, id: string | null | undefined): T | null {
  const key = useStateRef(() => Math.random());
  usePartitionStoreUpdates(store, partition, id, key);
  return (partition && id ? store.get(partition, id) : null) ?? null;
}

function usePartitionedStoreItemWithItem<T extends PartitionedItem>(store: PartitionedStore<T>, initialItem: T | undefined): T | undefined;
function usePartitionedStoreItemWithItem<T extends PartitionedItem>(store: PartitionedStore<T>, initialItem: T | null): T | null;
function usePartitionedStoreItemWithItem<T extends PartitionedItem>(store: PartitionedStore<T>, initialItem: T): T;
function usePartitionedStoreItemWithItem<T extends PartitionedItem>(store: PartitionedStore<T>, initialItem: T | null | undefined): T | null | undefined {
  const key = useStateRef(() => Math.random());
  const { partition, id } = initialItem ?? {};
  usePartitionStoreUpdates(store, initialItem?.partition, initialItem?.id, key);
  return (partition && id ? store.get(partition, id) : null) ?? initialItem;
}

function useUnpartitionStoreUpdates<T>(store: Store<T>, id: string | null | undefined, key: StatefulRefObject<number>): void {
  const abort = useAbort();

  useEffect(() => {
    if (id && !store.get(id)) {
      fetchItem(id);
    }
    return id ? store.onItemSet(id, refresh) : undefined;
  }, [id]);

  function refresh() {
    key.set(Math.random());
  }

  async function fetchItem(id: string) {
    await store.fetch(id, {
      abort: abort.signal,
    });

    refresh();
  }
}

function usePartitionStoreUpdates<T>(store: PartitionedStore<T>, partition: string | null | undefined, id: string | null | undefined, key: StatefulRefObject<number>): void {
  const abort = useAbort();

  useEffect(() => {
    if (partition && id && !store.get(partition, id)) {
      fetchItem(partition, id);
    }
    return partition && id ? store.onItemSet(partition, id, refresh) : undefined;
  }, [partition, id]);

  function refresh() {
    key.set(Math.random());
  }

  async function fetchItem(partition: string, id: string) {
    await store.fetch(partition, id, {
      abort: abort.signal,
    });

    refresh();
  }
}
