import React, { memo } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { useCallbackRef } from "../hooks";
import { useColorScheme } from "./theme";

export const Overlay = memo((props: React.PropsWithChildren<Core.Overlay2Props & React.HTMLAttributes<HTMLDivElement>>) => {
  const { onClosed } = props;
  const { colorScheme } = useColorScheme();

  // Ensure latest onClosed is fired, due to TransitionGroup holding onto stale callback
  const handleClosed = useCallbackRef(onClosed);

  return (
    <Core.Overlay2
      {...props}
      className={classnames(colorScheme.dark ? "bp5-dark" : "", props.className)}
      onClosed={handleClosed}
    >
      {props.children}
    </Core.Overlay2>
  );
});
