import EmbedBlot from "quill/blots/embed";
import TextBlot from "quill/blots/text";

export class BaseEmbedBlot extends EmbedBlot {
  public update(mutations: MutationRecord[], context: { [key: string]: any }) {
    // Handles case when the embed is the first node within the editor
    // and sometimes the browser allows text to be written left of the guard text node but still within the embed wrapper
    mutations.forEach(mutation => {
      if (mutation.type === "childList" && mutation.target === this.domNode && mutation.addedNodes.length > 0) {
        // Added text to the left of the guard
        if (mutation.nextSibling === this.leftGuard) {
          mutation.addedNodes.forEach(addedNode => {
            if (addedNode instanceof Text) {
              if (this.prev instanceof TextBlot && this.next) {
                this.prev.insertAt(this.prev.length(), addedNode.data);
                context.range = {
                  startNode: this.next.domNode,
                  startOffset: addedNode.length,
                };
              } else {
                this.parent.insertBefore(new TextBlot(this.scroll, addedNode), this);
              }
            }
          }, this);
        }
      }
    });

    // Special case where user wrote space next to embed causing rightGuard to get erased.
    // Need to restore range to proper position
    const rightGuardMutatation = mutations.flatMap(mutation => {
      const rightGuardMutated = mutation.type === "characterData"
        && mutation.target === this.rightGuard
        && mutation.target instanceof Text
        && mutation.target.data[0] === "\uFEFF"
        && mutation.target.data.length > 1;

      return rightGuardMutated ? mutation.target.data.length - 1 : [];
    });

    if (this.parent) {
      super.update(mutations, context);
    }

    // If final outcome is missing left/right guard, just remove the blot
    if (this.domNode.childNodes[0] !== this.leftGuard || this.domNode.childNodes[2] !== this.rightGuard) {
      this.remove();
    } else if (rightGuardMutatation.length > 0) {
      context.range.startOffset = rightGuardMutatation[0];
    }
  }
}
