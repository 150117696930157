import { placeholderifyHtml, placeholderifyText } from "~/markup/parsers";
import { PlaceholderContext } from "./contexts";

export function replacePlaceholders(value: string, context: PlaceholderContext): string;
export function replacePlaceholders(value: string, context: PlaceholderContext, format: "text"): string | null;
export function replacePlaceholders(value: string, context: PlaceholderContext, format: "html"): string;
export function replacePlaceholders(value: string, context: PlaceholderContext, format: "html" | "text" = "html"): string | null {
  const options = {
    placeholderContext: context,
    retainUnknown: false,
  };

  if (format === "text") {
    return placeholderifyText(value, options);
  }

  return placeholderifyHtml(value, options);
}

export function replaceAnswerPlaceholders(value: string, answers: string): string;
export function replaceAnswerPlaceholders(value: string, answers: string, format: "text"): string | null;
export function replaceAnswerPlaceholders(value: string, answers: string, format: "html"): string;
export function replaceAnswerPlaceholders(value: string, answers: string, format: "html" | "text" = "html"): string | null {
  const options = {
    answerPlaceholder: answers,
    retainUnknown: false,
  };

  if (format === "text") {
    return placeholderifyText(value, options);
  }

  return placeholderifyHtml(value, options);
}
