import React, { memo } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { Dialog } from "./dialog";
import { Icon, IconName, IconProps } from "./icon";
import { Button } from "./button";
import { useColorScheme } from "./theme";
import { FormArea } from "./formScope";

const { Classes } = Core;

export interface AlertProps extends Omit<Core.AlertProps, "icon"> {
  header?: string | JSX.Element;
  icon?: IconName | React.ReactElement<IconProps>;
}

export const Alert = memo<React.PropsWithChildren<AlertProps>>(function Alert(props: React.PropsWithChildren<AlertProps>) {
  const { colorScheme } = useColorScheme();
  const {
    canEscapeKeyCancel,
    canOutsideClickCancel,
    children,
    className,
    icon,
    intent,
    cancelButtonText,
    confirmButtonText,
    loading,
    header,
    portalContainer,
    onCancel,
    onClose,
    onConfirm,
    ...overlayProps
  } = { ...Core.Alert.defaultProps, icon: undefined, ...props };

  return (
    <Dialog
      {...overlayProps}
      aria-label="Alert Dialog"
      canEscapeKeyClose={canEscapeKeyCancel}
      canOutsideClickClose={canOutsideClickCancel}
      className={classnames(Classes.ALERT, colorScheme.dark ? "bp5-dark" : "", className)}
      id="alert-dialog"
      portalContainer={portalContainer}
      onClose={event => handleCallbacks(false, event)}
    >
      <FormArea className={Classes.ALERT_BODY} form="Alert Dialog">
        {icon && <Icon icon={icon} intent={intent} size={40} />}
        <div aria-label="Alert Content" className={Classes.ALERT_CONTENTS} id="alert-contents">
          {header && <h3>{header}</h3>}
          {children}
        </div>
      </FormArea>
      <FormArea className={Classes.ALERT_FOOTER} form="Alert Dialog">
        {confirmButtonText && <Button disabled={loading} id="confirm-alert" intent={intent} label={confirmButtonText} onClick={event => handleCallbacks(true, event)} />}
        {cancelButtonText && <Button minimal disabled={loading} id="cancel-alert" label={cancelButtonText} onClick={event => handleCallbacks(false, event)} />}
      </FormArea>
    </Dialog>
  );

  function handleCallbacks(confirmed: boolean, event?: React.SyntheticEvent<HTMLElement>) {
    if (confirmed) {
      onConfirm?.(event);
    } else {
      onCancel?.(event);
    }

    onClose?.(confirmed, event);
  }
});
