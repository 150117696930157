export function isAbortError(value: any): boolean {
  if (!value || typeof value !== "object") {
    return false;
  }

  // From AbortController
  if ("name" in value && value.name === "AbortError") {
    return true;
  }

  // From axios
  if ("name" in value && value.name === "CanceledError") {
    return true;
  }

  return false;
}

/**
 * Returns an AbortSignal that will signal abort if any of the signals passed in are aborted.
 */
export function anyAbortSignal(...signals: AbortSignal[]): AbortSignal {
  const controller = new AbortController();

  // In case any of the given signals are already aborted
  if (signals.some(s => s.aborted)) {
    controller.abort();
  } else {
    signals.forEach(signal => {
      signal.addEventListener("abort", () => {
        if (!controller.signal.aborted) {
          controller.abort();
        }
      }, { once: true });
    });
  }

  return controller.signal;
}

// Silence abort errors
window.addEventListener("error", (event) => {
  if (isAbortError(event.error)) {
    event.preventDefault();
    return true;
  }
  return undefined;
});

// Silence abort errors from promise rejections
window.addEventListener("unhandledrejection", (event) => {
  if (isAbortError(event.reason)) {
    event.preventDefault();
    return true;
  }
  return undefined;
});
