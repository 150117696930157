import { Node, Parent } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import { type ElementNode, type TextNode, isElementNode, isTextNode } from "@remhealth/compose";

export type ElementTransformer = (node: ElementNode, index: number | undefined, parent: Parent | undefined) => void;
export type TextTransformer = (node: TextNode, index: number | undefined, parent: Parent | undefined) => void;

export interface TransformOptions {
  transformElement?: ElementTransformer;
  transformText?: TextTransformer;
}

export function transform(this: Processor, options: TransformOptions): Transformer {
  const { transformElement, transformText } = options;
  return function transformer(tree: Node | ElementNode | TextNode): Node {
    if (transformElement) {
      visit(tree, isElementNode, (node, index, parent) => {
        transformElement(node, index, parent);
      });
    }

    if (transformText) {
      visit(tree, isTextNode, (node, index, parent) => {
        transformText(node, index, parent);
      });
    }

    return tree;
  };
}
