import { Dot } from "@remhealth/icons";
import { NoteRule } from "@remhealth/apollo";
import { useLabeling } from "@remhealth/core";
import { getNoteRuleDisplay } from "./utils";
import { DotIcon, RuleContainer, RuleDisplay } from "./ruleAlertItem.styles";

export interface RuleAlertItemProps {
  rule: NoteRule;
}

export function RuleAlertItem(props: RuleAlertItemProps) {
  const { rule } = props;

  const labels = useLabeling();

  return (
    <RuleContainer>
      <DotIcon icon={<Dot />} intent="warning" size={16} />
      <RuleDisplay>
        {getNoteRuleDisplay(rule.criteria.type, labels)}: {rule.instruction}
      </RuleDisplay>
    </RuleContainer>
  );
}
