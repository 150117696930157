import { useMemo } from "react";
import { LabelPreference, Product } from "@remhealth/apollo";
import { PracticePreferencesData, usePracticePreferences, useUserSession } from "@remhealth/host";

export function useLabeling() {
  const product = useUserSession().practice.product;
  const practicePreferences = usePracticePreferences();
  return useMemo(() => getLabeling(product, practicePreferences), [product, practicePreferences]);
}

export function getLabeling(product: Product | undefined, practicePreferences: PracticePreferencesData) {
  return {
    ...getPracticePreferenceLabeling(practicePreferences),
    ...getProductLabeling(product),
    Product: getProductLabel(product),
  };
}

export type Labeling = ReturnType<typeof getLabeling>;

export function getPracticePreferenceLabeling(practicePreferences: PracticePreferencesData) {
  return {
    Location: practicePreferences.locationLabel.singular,
    Locations: practicePreferences.locationLabel.plural,
    location: practicePreferences.locationLabel.singular.toLocaleLowerCase(),
    locations: practicePreferences.locationLabel.plural.toLocaleLowerCase(),

    Patient: practicePreferences.patientLabel.singular,
    Patients: practicePreferences.patientLabel.plural,
    patient: practicePreferences.patientLabel.singular.toLocaleLowerCase(),
    patients: practicePreferences.patientLabel.plural.toLocaleLowerCase(),

    Session: practicePreferences.sessionLabel.singular,
    Sessions: practicePreferences.sessionLabel.plural,
    session: practicePreferences.sessionLabel.singular.toLocaleLowerCase(),
    sessions: practicePreferences.sessionLabel.plural.toLocaleLowerCase(),

    Problem: practicePreferences.problemLabel.singular,
    Problems: practicePreferences.problemLabel.plural,
    problem: practicePreferences.problemLabel.singular.toLocaleLowerCase(),
    problems: practicePreferences.problemLabel.plural.toLocaleLowerCase(),

    Practitioner: practicePreferences.practitionerLabel.singular,
    Practitioners: practicePreferences.practitionerLabel.singular,
    practitioner: practicePreferences.practitionerLabel.singular.toLocaleLowerCase(),
    practitioners: practicePreferences.practitionerLabel.plural.toLocaleLowerCase(),
  };
}

const supportedProducts = ["ClaimTrak", "myAvatar", "myEvolv", "GEHRIMED"] as const;
type SupportedProduct = (typeof supportedProducts)[number];

function isSupportedProduct(product: Product | undefined): product is SupportedProduct {
  return !!product && (supportedProducts as ReadonlyArray<string>).includes(product);
}

const productLabels: Record<SupportedProduct, string> = {
  ClaimTrak: "CT|One",
  myAvatar: "myAvatar",
  myEvolv: "myEvolv",
  GEHRIMED: "GEHRIMED",
} as const;

export function getProductLabel(product: Product | undefined) {
  return isSupportedProduct(product) ? productLabels[product] : "EHR";
}

type ProductLabels = Record<SupportedProduct, LabelPreference>;

const problemLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Diagnosis",
    plural: "Diagnoses",
  },
  myAvatar: {
    singular: "Problem",
    plural: "Problems",
  },
  myEvolv: {
    singular: "Problem",
    plural: "Problems",
  },
  GEHRIMED: {
    singular: "Problem",
    plural: "Problems",
  },
};

const enrollmentLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Enrollment",
    plural: "Enrollments",
  },
  myAvatar: {
    singular: "Episode",
    plural: "Episodes",
  },
  myEvolv: {
    singular: "Enrollment",
    plural: "Enrollments",
  },
  GEHRIMED: {
    singular: "Enrollment",
    plural: "Enrollments",
  },
};

const locationCodeLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Code",
    plural: "Codes",
  },
  myAvatar: {
    singular: "Code",
    plural: "Codes",
  },
  myEvolv: {
    singular: "License #",
    plural: "Licenses #",
  },
  GEHRIMED: {
    singular: "Code",
    plural: "Codes",
  },
};

const noteCategoryLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Note Category",
    plural: "Note Categories",
  },
  myAvatar: {
    singular: "myAvatar Note Type",
    plural: "myAvatar Note Types",
  },
  myEvolv: {
    singular: "Note Category",
    plural: "Note Categories",
  },
  GEHRIMED: {
    singular: "Note Category",
    plural: "Note Categories",
  },
};

const noteFormLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Form",
    plural: "Forms",
  },
  myAvatar: {
    singular: "myAvatar Form",
    plural: "myAvatar Forms",
  },
  myEvolv: {
    singular: "myEvolv Form",
    plural: "myEvolv Forms",
  },
  GEHRIMED: {
    singular: "Template",
    plural: "Templates",
  },
};

const assessmentFormLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Assessment Form",
    plural: "Assessment Forms",
  },
  myAvatar: {
    singular: "myAvatar Assessment Form",
    plural: "myAvatar Forms",
  },
  myEvolv: {
    singular: "myEvolv Assessment Form",
    plural: "myEvolv Assessment Forms",
  },
  GEHRIMED: {
    singular: "Assessment Template",
    plural: "Assessment Templates",
  },
};

const serviceTypeCodeLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Code",
    plural: "Codes",
  },
  myAvatar: {
    singular: "Code",
    plural: "Codes",
  },
  myEvolv: {
    singular: "License #",
    plural: "License #",
  },
  GEHRIMED: {
    singular: "Code",
    plural: "Codes",
  },
};

const serviceTypeLabels: ProductLabels = {
  ClaimTrak: {
    singular: "Service Type",
    plural: "Service Types",
  },
  myAvatar: {
    singular: "Service Code",
    plural: "Service Codes",
  },
  myEvolv: {
    singular: "Service",
    plural: "Services",
  },
  GEHRIMED: {
    singular: "Visit Type",
    plural: "Visit Types",
  },
};

export function getProductLabeling(product: Product | undefined) {
  if (!isSupportedProduct(product)) {
    product = Product.ClaimTrak;
  }

  const labels = {
    Enrollment: enrollmentLabels[product].singular,
    Enrollments: enrollmentLabels[product].plural,
    enrollment: enrollmentLabels[product].singular.toLocaleLowerCase(),
    enrollments: enrollmentLabels[product].plural.toLocaleLowerCase(),

    LocationCode: locationCodeLabels[product].singular,
    LocationCodes: locationCodeLabels[product].plural,
    locationCode: locationCodeLabels[product].singular.toLocaleLowerCase(),
    locationCodes: locationCodeLabels[product].plural.toLocaleLowerCase(),

    NoteCategory: noteCategoryLabels[product].singular,
    NoteCategories: noteCategoryLabels[product].plural,

    NoteForm: noteFormLabels[product].singular,
    NoteForms: noteFormLabels[product].plural,

    AssessmentForm: assessmentFormLabels[product].singular,
    AssessmentForms: assessmentFormLabels[product].plural,

    ServiceTypeCode: serviceTypeCodeLabels[product].singular,
    ServiceTypeCodes: serviceTypeCodeLabels[product].plural,
    serviceTypeCode: serviceTypeCodeLabels[product].singular.toLocaleLowerCase(),
    serviceTypeCodes: serviceTypeCodeLabels[product].plural.toLocaleLowerCase(),

    ServiceType: serviceTypeLabels[product].singular,
    ServiceTypes: serviceTypeLabels[product].plural,
    serviceType: serviceTypeLabels[product].singular.toLocaleLowerCase(),
    serviceTypes: serviceTypeLabels[product].plural.toLocaleLowerCase(),
  };

  const probLabels = {
    Problem: problemLabels[product].singular,
    Problems: problemLabels[product].plural,
    problem: problemLabels[product].singular.toLocaleLowerCase(),
    problems: problemLabels[product].plural.toLocaleLowerCase(),
  };

  if (product === "GEHRIMED" || product === "ClaimTrak") {
    return { ...labels, ...probLabels };
  }
  return labels;
}
