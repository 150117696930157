import { useCallback, useRef } from "react";

/**
 * Creates a function that remains constant, but whose behavior/contents can be redefined in later renders.
 * Useful for situations where a child component will not render must receive a new callback.
 */
export function useCallbackRef<T extends (...args: any[]) => any>(fn: T | undefined): T;
export function useCallbackRef<T extends (...args: any[]) => any>(fn: T | undefined, allowUndefined: true): T | undefined;
export function useCallbackRef<T extends (...args: any[]) => any>(fn: T | undefined, allowUndefined = false): T | undefined {
  const ref = useRef(fn);

  ref.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(((...args) => ref.current?.(...args)) as T, []);
  return fn === undefined && allowUndefined ? undefined : callback;
}
