import { useEffect } from "react";
import { NonIdealState, Spinner } from "@remhealth/ui";
import type { FHIRSmartLogin } from "@remhealth/host";
import { FullCenterScreen } from "~/styles";
import { Text } from "~/text";

export interface LoginRequiredProps {
  onLogin: (smart?: FHIRSmartLogin) => void;
}

export const LoginRequired = (props: LoginRequiredProps) => {
  const { onLogin } = props;

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const issuer = search.get("iss");
    const launch = search.get("launch");
    if (issuer && launch) {
      onLogin({ issuer, launch });
    } else {
      onLogin();
    }
  }, []);

  return (
    <FullCenterScreen>
      <NonIdealState
        icon={<Spinner intent="primary" size={64} />}
        title={Text.SigningInLoading}
      />
    </FullCenterScreen>
  );
};
