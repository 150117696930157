import { PropsWithChildren } from "react";
import { ItemRendererProps, ListOption, ListOptionProps, highlightText } from "@remhealth/ui";
import { Person } from "@remhealth/apollo";
import { Avatar } from "~/avatars/avatar";
import { Details, Name, PersonContainer } from "./personRenderer.styles";

export const personTagRenderer = (person: Person) => <PersonTag person={person} />;

export interface PersonTagProps {
  person: Person;
  scale?: "normal" | "small" | "large";
  highlight?: string;
}

export function PersonTag(props: PropsWithChildren<PersonTagProps>) {
  const { person, scale = "small", highlight = "", children } = props;

  const avatarSize = scale === "large" ? 40 : scale === "small" ? 28 : 36;

  return (
    <PersonContainer>
      <Avatar person={person} size={avatarSize} />
      <Details>
        <Name>{highlightText(person.display, highlight)}</Name>
        {children}
      </Details>
    </PersonContainer>
  );
}

export function personRenderer(person: Person, itemProps: ItemRendererProps, optionProps?: Partial<ListOptionProps>): React.ReactElement<ListOptionProps> {
  return (
    <ListOption
      {...optionProps}
      key={person.id}
      text={person.display}
    >
      <PersonTag highlight={itemProps.query} person={person} scale="small" />
    </ListOption>
  );
}
