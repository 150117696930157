import { useState } from "react";
import * as Core from "@blueprintjs/core";
import { FormField, getIntent } from "../utils/form";
import { useAutomation, useDebouncer, useUpdateEffect } from "../hooks";

export interface TextAreaProps extends Omit<Core.TextAreaProps, "defaultValue" | "value"> {
  defaultValue?: string;
  value?: string;
  field?: FormField<string | undefined>;
}

export const TextArea = (props: TextAreaProps) => {
  const {
    onChange,
    onBlur,
    field,
    name = field?.name,
    intent = getIntent(field),
    readOnly = field?.readOnly,
    disabled = field?.disabled,
    defaultValue,
    value: controlledValue,
    ...textAreaProps
  } = props;

  const changeDebouncer = useDebouncer(100);

  const [uncontrolledValue, setUncontrolledValue] = useState<string>(defaultValue ?? "");
  const [bufferValue, setBufferValue] = useState<string>();

  const value = bufferValue !== undefined
    ? bufferValue
    : controlledValue !== undefined
      ? controlledValue
      : field ? field.value ?? "" : uncontrolledValue;

  useUpdateEffect(() => {
    if (controlledValue) {
      field?.onChange(controlledValue);
      setUncontrolledValue(controlledValue);
    }
  }, [controlledValue]);

  const { label, id, errorId } = useAutomation(props);

  return (
    <Core.TextArea
      {...textAreaProps}
      aria-errormessage={errorId}
      aria-invalid={field?.error ? true : undefined}
      aria-label={label}
      data-errormessage={field?.error ? field.errorText : undefined}
      disabled={disabled}
      id={id}
      intent={intent}
      name={name}
      readOnly={readOnly}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );

  function change(value: string, event?: React.ChangeEvent<HTMLTextAreaElement>) {
    setUncontrolledValue(value);
    setBufferValue(value);

    if (event && onChange) {
      onChange(event);
    }

    changeDebouncer.delay(() => fireBufferedChange(value));
  }

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    change(event.currentTarget.value, event);
  }

  function handleBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
    onBlur?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }
  }

  function fireBufferedChange(value: string) {
    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(value);
      field?.onTouched();
    }

    setBufferValue(undefined);
  }
};
