import { Fragment, memo } from "react";
import styled from "styled-components";
import { Classes, highlightText } from "@remhealth/ui";
import { NameUse, Patient } from "@remhealth/apollo";

export interface PatientNameProps {
  patient: Patient;
  highlight?: string;
}

const Suffix = styled.span`
  font-size: 0.8em;
`;

export const PatientName = memo((props: PatientNameProps) => {
  const { patient, highlight = "" } = props;
  const { name, otherNames } = patient;

  if (name.text?.trim()) {
    return <span>{name.text}</span>;
  }

  const given = name.given.filter(p => !!p?.trim());
  const firstName = highlightText(given[0], highlight);
  const lastName = highlightText(name.family ?? "", highlight);
  const middleInitial = given.length > 1 ? given[1].slice(0, 1) : undefined;

  let nickNameValue: JSX.Element | undefined;
  const nickName = otherNames.find(n => n.use === NameUse.Nickname);
  if (nickName) {
    // If nickname was placed into Text field
    if (nickName.text?.trim()) {
      nickNameValue = <span key="nickname" className={Classes.TEXT_MUTED}>({highlightText(nickName.text, highlight)})</span>;
    } else if (nickName.given.length > 0 && nickName.family) {
      // If nickname was placed into Given fields
      nickNameValue = <span key="nickname">({highlightText(nickName.given.join(" "), highlight)})</span>;
    }
  }

  const prefix = name.prefix?.trim() ? <span key="prefix" className={Classes.TEXT_MUTED}>{highlightText(name.prefix, highlight)}</span> : undefined;
  const suffix = name.suffix?.trim() ? <Suffix key="suffix" className={Classes.TEXT_MUTED}>{highlightText(name.suffix, highlight)}</Suffix> : undefined;
  const tokens = [prefix, firstName, nickNameValue, middleInitial, lastName, suffix].filter(p => !!p);

  return <>{tokens.map((t, i) => <Fragment key={i}>{t}{i === tokens.length - 1 ? "" : " "}</Fragment>)}</>;
}, arePropsEqual);

function arePropsEqual(prevProps: PatientNameProps, nextProps: PatientNameProps): boolean {
  return prevProps.patient.display === nextProps.patient.display;
}
