import styled from "styled-components";
import { Ellipsize } from "@remhealth/ui";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiagnosisContainer = styled.div`
  display: table-row;
  line-height: 24px;
`;

export const IndexValue = styled.span`
  font-weight: 700;
  color: ${props => props.theme.font.muted};
`;

export const PrimaryDiagnosis = styled.span`
  color: var(--pt-intent-primary);
`;

export const DiagnosisCode = styled.span`
  color: ${props => props.theme.font.muted};
  display: table-cell;
  padding-right: 10px;
`;

export const DiagnosisDate = styled.span`
  display: table-cell;
  padding-left: 10px;
`;

export const DiagnosisTitle = styled(Ellipsize)`
  max-width : 450px;
  color: ${props => props.theme.font.default};
  display: table-cell;
  padding-right: 10px;
`;

export const DiagnosisLeftContent = styled.div`
  display: table-cell;
  padding-right: 10px;
`;
