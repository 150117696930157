import { RichText } from "@remhealth/apollo";
import { Yup } from "@remhealth/core";
import { TextNoteSection } from "~/notes/types";

export function sectionSchema(textSection: TextNoteSection, enforceRequired: boolean): Yup.ObjectSchema<TextNoteSection> {
  const textMaxLength = 50000;
  return Yup.object<TextNoteSection>({
    name: Yup.string(),
    required: Yup.boolean(),
    includeInPatientView: Yup.boolean(),
    code: Yup.string().notRequired(),
    text: Yup.object<RichText>()
      .label(textSection.name)
      .test("max-length", `Cannot be more than ${textMaxLength} characters.`, function(value: RichText | undefined) {
        return !value?.plainText || value.plainText.length <= textMaxLength;
      })
      .when("required", {
        is: true,
        then: Yup.object<RichText>()
          .requiredWhen(enforceRequired && textSection.required)
          .test("required", `${textSection.name} is required.`, function(value: RichText | undefined) {
            return !enforceRequired ? true : !!value?.plainText?.trim();
          }),
      }),
  });
}
