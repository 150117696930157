import React, { useContext } from "react";
import { DateTime } from "luxon";
import {
  Appointment,
  Attendee,
  CarePlanSession,
  Encounter,
  EncounterDocumentationType,
  Group,
  type MultiNote,
  Note,
  NoteSectionFormat,
  Patient,
  PatientNote,
  Practitioner,
  Product,
  Reference,
  Unsubscriber,
  VisitStatus
} from "@remhealth/apollo";
import { useProductFlag, useReleaseCheck, useUserSession } from "@remhealth/host";
import { createSubscription } from "@remhealth/ui";
import type { SuggestionReport } from "@remhealth/compose";
import type { DraftSignature, PatientSignature } from "~/notes/types";

export interface OpenPatientsContext {
  readonly openPatientIds: string[];
  readonly recentPatientIds: string[];
  onPatientOpened(patientId: string): void;
  onPatientClosed(patientId: string): void;
}

export const OpenPatientsContext = React.createContext<OpenPatientsContext>({
  openPatientIds: [],
  recentPatientIds: [],
  onPatientOpened(): void {
    throw new Error("OpenPatientsContext is not initialized.");
  },
  onPatientClosed(): void {
    throw new Error("OpenPatientsContext is not initialized.");
  },
});

export const useOpenPatients = () => useContext(OpenPatientsContext);

export interface PatientPanelContext {
  setIsEditing(value: boolean): void;
}

export const PatientPanelContext = React.createContext<PatientPanelContext>({
  setIsEditing: () => {},
});

export const usePatientPanel = () => useContext(PatientPanelContext);

export type NoteComposeSourceType =
  | "TextSection"
  | "QuestionnaireElement"
  | "AddOnServiceComment"
  | "GoalActivityComment"
  | "ProblemComment";

export type NoteComposeSource = `${NoteComposeSourceType}_${string}`;

export interface NoteSuggestionsState {
  rightPaneOpen: boolean;
  suggestions: Map<NoteComposeSource, SuggestionReport>;
}

export type StartNoteEncounter = Partial<Omit<Encounter, "resourceType" | "subject">> & Pick<Encounter, "resourceType" | "subject"> & {
  attendees?: Attendee[];
  signatures?: (DraftSignature | PatientSignature)[];
};

export interface StartNoteDialogOptions {
  /** @default true */
  initializeLocationFromPastVisit?: boolean;
  documentationType?: EncounterDocumentationType;
}

export interface StartNoteDialog {
  (patient: Patient, options?: StartNoteDialogOptions): void;
  (patient: OpenPatient, options?: StartNoteDialogOptions): void;
  (group: Group, options?: StartNoteDialogOptions): void;
  (encounter: StartNoteEncounter, options?: StartNoteDialogOptions): void;
}

export interface OpenNoteDialogOptions {
  /** @default false */
  bulkSignFlow?: boolean;
  isPullForwardContent?: boolean;
}

export type OpenNoteDialog = (note: Note | CarePlanSession | StartNoteEncounter, options?: OpenNoteDialogOptions) => void;

export interface NoteDialogContext {
  noteDialog: {
    readonly multiNote: MultiNote | null;
    readonly patientNote: PatientNote | null;
    readonly carePlanSession: CarePlanSession | null;
    readonly subject: OpenPatient | Group | null;
    readonly focusToSection: NoteSectionFormat | null;
    readonly patientNotes: PatientNote[];
    readonly activePatient: OpenPatient | null;
    readonly activeGroupSessionTab: VisitStatus | null;
    readonly options?: StartNoteDialogOptions;
    readonly isBulkSignFlow: boolean;
    readonly isPullForwardContent: boolean;
    readonly isActivityLogged: boolean;
    readonly isGroupSessionInfoOpen: boolean;
    startNote: StartNoteDialog;
    openNote: OpenNoteDialog;
    setFocusToSection: (section: NoteSectionFormat | null) => void;
    setPatientNote: (note: PatientNote | null) => void;
    setActiveGroupSessionTab: (tab: VisitStatus) => void;
    setIsActivityLogged: (logged: boolean) => void;
    setIsGroupSessionInfoOpen: (isOpen: boolean) => void;
  };
}

export const NoteDialogContext = React.createContext<NoteDialogContext>({
  noteDialog: {
    multiNote: null,
    patientNote: null,
    carePlanSession: null,
    subject: null,
    focusToSection: null,
    patientNotes: [],
    activePatient: null,
    activeGroupSessionTab: null,
    options: { initializeLocationFromPastVisit: true },
    isBulkSignFlow: false,
    isPullForwardContent: false,
    isActivityLogged: false,
    isGroupSessionInfoOpen: false,
    startNote: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    openNote: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    setFocusToSection: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    setPatientNote: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    setActiveGroupSessionTab: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    setIsActivityLogged: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
    setIsGroupSessionInfoOpen: () => {
      throw new Error("NoteDialogContext is not initialized.");
    },
  },
});

export const useNoteDialog = () => useContext(NoteDialogContext).noteDialog;

export const { context: NoteSuggestionsContext, Provider: NoteSuggestionsProvider } = createSubscription<NoteSuggestionsState>({
  rightPaneOpen: false,
  suggestions: new Map(),
});

export type NoteDialogState = "closed" | "minimized" | "opened" | "maximized" | "fullscreen";

export interface NoteDialogLifecycle {
  state: NoteDialogState;
  promptOnClose: boolean;
  closeRequested: boolean;
  pinRequestedState: Exclude<NoteDialogState, "fullscreen"> | null;
}

export const { context: NoteDialogLifecycleContext, Provider: NoteDialogLifecycleProvider } = createSubscription<NoteDialogLifecycle>({
  state: "closed",
  promptOnClose: false,
  closeRequested: false,
  pinRequestedState: null,
});

export interface BulkSignData {
  failedNoteIds: Set<string>;
  reviewedNotes: Map<string, Note>;
  signingNoteIds: Set<string>;
  signedNoteIds: Set<string>;
  signingNotesCount: number;
  signing: boolean;
}

const initialBulkSignData = {
  failedNoteIds: new Set<string>(),
  reviewedNotes: new Map(),
  signingNoteIds: new Set<string>(),
  signedNoteIds: new Set<string>(),
  signingNotesCount: 0,
  signing: false,
};

export const { context: BulkSignContext, Provider: BulkSignSubscriptionProvider } = createSubscription<BulkSignData>(initialBulkSignData);

export interface SelectedAgendaDate {
  date: DateTime;
  // If true, components will autofocus to the selected date on next render
  scrollToDate: boolean;
}

export interface AgendaState {
  selectedDate: SelectedAgendaDate;
  showRightPane: boolean;
  selectedAppointment: Appointment | null;
  filterByPatient: Patient | null;
  filterByAttendeeIds: string[] | null;
}

export const initialAgendaState: AgendaState = {
  selectedDate: {
    date: DateTime.now(),
    scrollToDate: true,
  },
  showRightPane: true,
  selectedAppointment: null,
  filterByPatient: null,
  filterByAttendeeIds: null,
};

export const { context: AgendaContext, Provider: AgendaSubscriptionProvider } = createSubscription<AgendaState>(initialAgendaState);

export type OnOpenPatientPullCompleteEventHandler = () => void;
export type OnOpenPatientPullingEventHandler = (pulling: boolean) => void;

export interface OpenPatient {
  readonly patient: Patient;
  readonly pulling: boolean;
  readonly pullPromise: Promise<void>;
  pullIfNeeded(): Promise<void>;
  forcePull(): Promise<void>;
  onPulling(listener: OnOpenPatientPullingEventHandler): Unsubscriber;
  onPullComplete(uniqueKey: string, listener: OnOpenPatientPullCompleteEventHandler): Unsubscriber;
  onPullComplete(listener: OnOpenPatientPullCompleteEventHandler): Unsubscriber;
  cancel(): void;
}

export interface PatientAccessAssert {
  (patient: Reference<Patient>): Promise<OpenPatient | false>;
  (patient: Patient): Promise<OpenPatient | false>;
  (patient: Patient | Reference<Patient>): Promise<OpenPatient | false>;
}

export interface PatientAccessAssertAll {
  (patients: Reference<Patient>[]): Promise<OpenPatient[] | false>;
  (patients: Patient[]): Promise<OpenPatient[] | false>;
  (patients: (Patient | Reference<Patient>)[]): Promise<OpenPatient[] | false>;
}

export interface PatientAccessTest {
  (patient: Reference<Patient>): Promise<OpenPatient | false>;
  (patient: Patient): OpenPatient | false;
  (patient: Patient | Reference<Patient>): Promise<OpenPatient | false>;
  (user: Practitioner, patient: Reference<Patient>): Promise<boolean>;
  (user: Practitioner, patient: Patient): boolean;
  (user: Practitioner, patient: Patient | Reference<Patient>): Promise<boolean>;
}

export interface PatientAccessTestAll {
  (patients: Reference<Patient>[]): Promise<OpenPatient[] | false>;
  (patients: Patient[]): OpenPatient[] | false;
  (patients: (Patient | Reference<Patient>)[]): Promise<OpenPatient[] | false>;
  (user: Practitioner, patients: Reference<Patient>[]): Promise<boolean>;
  (user: Practitioner, patients: Patient[]): boolean;
  (user: Practitioner, patients: (Patient | Reference<Patient>)[]): Promise<boolean>;
}

export interface PatientAccessMarkOpened {
  (patient: Patient): OpenPatient;
  (patientRef: Reference<Patient>, abort?: AbortSignal): Promise<OpenPatient>;
}

export interface PatientAccessContext {
  readonly patientAccessOpenedPatients: OpenPatient[];
  assertPatientAccess: PatientAccessAssert;
  assertAllPatientAccess: PatientAccessAssertAll;
  testPatientAccess: PatientAccessTest;
  testAllPatientAccess: PatientAccessTestAll;
  markOpenedPatientAccess: PatientAccessMarkOpened;
}

export const PatientAccessContext = React.createContext<PatientAccessContext>({
  patientAccessOpenedPatients: [],
  assertPatientAccess: () => {
    throw new Error("PatientAccessContext is not initialized.");
  },
  assertAllPatientAccess: () => {
    throw new Error("PatientAccessContext is not initialized.");
  },
  testPatientAccess: () => {
    throw new Error("PatientAccessContext is not initialized.");
  },
  testAllPatientAccess: () => {
    throw new Error("PatientAccessContext is not initialized.");
  },
  markOpenedPatientAccess: () => {
    throw new Error("PatientAccessContext is not initialized.");
  },
});

export interface PatientAccessor {
  readonly openedPatients: OpenPatient[];

  /** Checks if user has access to the patient, and issues access prompt to request access if necessary. */
  assert: PatientAccessAssert;

  /** Checks if user has access to the patients, and issues access prompt to request access if necessary. */
  assertAll: PatientAccessAssertAll;

  /** Tests if user has access to the patient.  Does not issue access prompt if user does not have access. */
  test: PatientAccessTest;

  /** Tests if user has access to the patient.  Does not issue access prompt if user does not have access. */
  testAll: PatientAccessTestAll;

  /** Manually marks a patient as being opened. */
  markOpened: PatientAccessMarkOpened;
}

export const usePatientAccessor = (): PatientAccessor => {
  const context = useContext(PatientAccessContext);
  return {
    get openedPatients() {
      return context.patientAccessOpenedPatients;
    },
    assert: context.assertPatientAccess,
    assertAll: context.assertAllPatientAccess,
    test: context.testPatientAccess,
    testAll: context.testAllPatientAccess,
    markOpened: context.markOpenedPatientAccess,
  };
};

export function useAllowGroups(): boolean {
  const session = useUserSession();
  const myEvolvGroupsEnabled = useReleaseCheck("myEvolvGroups");

  switch (session.practice.product) {
    case Product.ClaimTrak: return true;
    case Product.myEvolv: return myEvolvGroupsEnabled;
    case Product.myAvatar: return true;
    case Product.GEHRIMED: return false;
    case Product.myUnity: return false;
    case undefined: return true;
  }
}

export function useAllowGoalGrouping(): boolean {
  const session = useUserSession();
  return session.practice.product === Product.ClaimTrak && !session.practice.features.includes("LongTermGoals");
}

export function useAllowSignatureConfigurations(): boolean {
  const session = useUserSession();
  const allowSignatureConfigurations = useProductFlag("SignatureConfigurations");
  const ctonePatientSignatures = useReleaseCheck("CTOnePatientSignatures");

  switch (session.practice.product) {
    case Product.ClaimTrak: return ctonePatientSignatures && allowSignatureConfigurations;
    case Product.myEvolv: return false;
    case Product.myAvatar: return false;
    case Product.GEHRIMED: return false;
    case Product.myUnity: return false;
    case undefined: return true;
  }
}

export function useAllowPatientSignatures(): boolean {
  const session = useUserSession();
  const ctonePatientSignatures = useReleaseCheck("CTOnePatientSignatures");
  const myAvatarPatientSignatures = useReleaseCheck("myAvatarPatientSignatures");
  const myEvolvPatientSignatures = useReleaseCheck("myEvolvPatientSignatures");

  switch (session.practice.product) {
    case Product.ClaimTrak: return ctonePatientSignatures;
    case Product.myEvolv: return myEvolvPatientSignatures;
    case Product.myAvatar: return myAvatarPatientSignatures;
    case Product.GEHRIMED: return false;
    case Product.myUnity: return false;
    case undefined: return true;
  }
}

export function useAllowAssessmentNotes(): boolean {
  const session = useUserSession();
  const allowAssessmentNotes = useProductFlag("AssessmentNotes");
  const myAvatarAssessmentNotes = useReleaseCheck("myAvatarAssessmentNotes");

  if (!allowAssessmentNotes) {
    return false;
  }

  switch (session.practice.product) {
    case Product.ClaimTrak: return false;
    case Product.myEvolv: return false;
    case Product.myAvatar: return myAvatarAssessmentNotes;
    case Product.GEHRIMED: return false;
    case Product.myUnity: return false;
    case undefined: return false;
  }
}

export function useAllowGroupRegistration(): boolean {
  const session = useUserSession();
  const allowGroupRegistration = useProductFlag("GroupRegistration");
  const myEvolvGroupRegistration = useReleaseCheck("myEvolvGroupRegistration");

  if (!allowGroupRegistration) {
    return false;
  }

  switch (session.practice.product) {
    case Product.ClaimTrak: return false;
    case Product.myEvolv: return myEvolvGroupRegistration;
    case Product.myAvatar: return false;
    case Product.GEHRIMED: return false;
    case Product.myUnity: return false;
    case undefined: return false;
  }
}
