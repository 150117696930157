import { DateTime } from "luxon";
import { CodeableConcept, Coding, HumanName, Instant, Resource } from "@remhealth/apollo";

export function sanitizePhone(value: string) {
  return value.replace(/[\s()_-]|(\+1)/g, "");
}

export function formatPhone(value: string) {
  const match = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))?((\d{3})(\s|-?))(\d{4})(\s?(([Ee|]xt[.:|]?)|x|X)(\s?(\d+)))?/.exec(value);
  if (!match) {
    return value;
  }

  const countryCode = match[1] ? `+${match[1]} ` : "";
  const areaCode = match[4] ? `(${match[4]}) ` : "";
  const exchangeCode = match[7] ? `${match[7]}-` : "";
  const lineNum = match[9] ?? "";
  const ext = match[14] ? `;${match[14]}` : "";
  return countryCode + areaCode + exchangeCode + lineNum + ext;
}

export function getInitials(name: HumanName) {
  const firstName = (name.given.length > 0 ? name.given[0] : null) ?? "";
  const lastName = name.family ?? "";
  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
}

export function singleCoding(coding: Coding): CodeableConcept;
export function singleCoding(coding?: Coding): CodeableConcept | undefined;
export function singleCoding(coding?: Coding): CodeableConcept | undefined {
  return coding ? { codings: [coding], text: coding.display } : undefined;
}

export function needsPulling(resource: Resource, pullMinimumStalenessHours = 3): boolean {
  if (resource.meta?.pullResult) {
    const lastPull = Instant.toDateTime(resource.meta.pullResult.recorded);
    // Skip pull if last attempt was within minimum staleness
    if (DateTime.now().diff(lastPull).as("hours") < pullMinimumStalenessHours) {
      return false;
    }
  }
  return true;
}

/**
 * Like Promise.all, but throttled.
 * @returns Returns results returned from all actions.  Will probably not be in same order as items.
 */
export async function forEachAsync<TOutput, TInput>(concurrencyLimit: number, items: TInput[], action: (input: TInput) => Promise<TOutput>): Promise<TOutput[]> {
  const tasks = [...items];
  const results: TOutput[] = [];

  while (tasks.length) {
    results.push(...await Promise.all(tasks.splice(0, concurrencyLimit).map(action)));
  }

  return results;
}

/**
 * Like Promise.allSettled, but throttled.
 * @returns Returns results returned from all actions.  Will probably not be in same order as items.
 */
export async function forEachSettledAsync<TOutput, TInput>(concurrencyLimit: number, items: TInput[], action: (input: TInput) => Promise<TOutput>): Promise<PromiseSettledResult<TOutput>[]> {
  const tasks = [...items];
  const results: PromiseSettledResult<TOutput>[] = [];

  while (tasks.length) {
    // eslint-disable-next-line bells/no-promise-all-settled
    results.push(...await Promise.allSettled(tasks.splice(0, concurrencyLimit).map(action)));
  }

  return results;
}

export function rangesOverlap(offset1: number, length1: number, offset2: number, length2: number): boolean {
  const a1 = offset1, a2 = offset1 + length1;
  const b1 = offset2, b2 = offset2 + length2;
  return Math.max(a1, b1) < Math.min(a2, b2);
}
