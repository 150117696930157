import { memo, useEffect, useMemo, useRef } from "react";
import { PatientSearchRecord as ExternalPatient } from "@remhealth/mastodon";
import { LocalDate, Patient } from "@remhealth/apollo";
import { Geosearch, Offline } from "@remhealth/icons";
import { useApollo, useProductFlag, useTracking, useUserSession } from "@remhealth/host";
import {
  Dot,
  createPatientFilters,
  useAudit,
  useEhr,
  useErrorHandler,
  useLabeling
} from "@remhealth/core";
import {
  AsyncSuggest,
  Button,
  Classes,
  DateFormats,
  DefaultMoreResultsAvailable,
  DefaultSuggestInitialContent,
  Ellipsize,
  HotkeyConfig,
  ItemAsyncListRendererProps,
  ItemRendererProps,
  ListOption,
  MenuDivider,
  MenuItem,
  NoResults,
  NonIdealIcon,
  NonIdealState,
  PagingResult,
  Spinner,
  highlightText,
  normalizeKeyCombo,
  startsWithAllWords,
  useAbort,
  useHotkeys,
  useStateIfMounted,
  useStateRef
} from "@remhealth/ui";
import { PatientBanner } from "~/avatars/patientBanner";
import { Text } from "~/text";
import { useOpenPatients } from "~/contexts";
import {
  ClientData,
  DetailsContainer,
  DobInfo,
  LabelInfo,
  Mrn,
  NoResultContainer,
  PatientItem,
  ResultMenu,
  SourceBar
} from "./patientSearch.styles";

type SearchItem = Patient | ExternalPatient;

interface ActivePull {
  patient: ExternalPatient;
  state: "pulling" | "failed";
}

export interface PatientSearchProps {
  /** @default false */
  autoFocus?: boolean;
  disabled?: boolean;
  hotKey?: string;
  closeOnSelect?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  fill?: boolean;
  soft?: boolean;
  id?: string;
  selectedItem?: Patient | null;
  rightElement?: JSX.Element;
  itemRightElement?: JSX.Element | ((patient: Patient | ExternalPatient, itemProps: ItemRendererProps) => JSX.Element | null | undefined);
  itemDisabled?: (patient: Patient) => boolean;
  onSelect(patient: Patient | null): void;
}

export const PatientSearch = memo((props: PatientSearchProps) => {
  const labels = useLabeling();
  const {
    autoFocus = false,
    disabled,
    closeOnSelect = true,
    selectOnly = false,
    fill = false,
    soft = false,
    selectedItem,
    id,
    placeholder = Text.SearchPatients(labels) + (props.hotKey ? ` ${normalizeKeyCombo(props.hotKey).join("+")}` : ""),
    rightElement,
    itemRightElement,
    itemDisabled,
    onSelect,
  } = props;

  const apollo = useApollo();
  const ehr = useEhr();
  const audit = useAudit();
  const session = useUserSession();
  const openPatients = useOpenPatients();
  const tracking = useTracking();
  const handleError = useErrorHandler();
  const abort = useAbort();
  const searchByLastName = useProductFlag("SearchPatientByLastName");

  const searchEhr = useStateRef(false);
  const [activePull, setActivePull] = useStateIfMounted<ActivePull>();
  const [openState, setOpenState] = useStateIfMounted<boolean>();

  const suggestRef = useRef<AsyncSuggest>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const hotkeys = useMemo((): HotkeyConfig[] => !props.hotKey ? [] : [
    {
      allowInInput: true,
      combo: props.hotKey,
      global: true,
      group: "Search",
      label: `${labels.Patient} search`,
      preventDefault: true,
      stopPropagation: true,
      onKeyDown: focusOnSearch,
    },
  ], []);

  useHotkeys(hotkeys);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => searchInputRef.current?.focus(), 500);
    }
  }, []);

  const pulling = activePull?.state === "pulling";
  return (
    <AsyncSuggest<SearchItem>
      ref={suggestRef}
      large
      showListOnQuery
      clearable={!selectOnly}
      disabled={disabled || pulling}
      fetchOnBlankQuery={openPatients.recentPatientIds.length > 0 && !searchEhr.current}
      fill={fill}
      id={id}
      inputProps={{
        inputRef: searchInputRef,
      }}
      itemListRenderer={renderList}
      itemsEqual={itemEqual}
      leftIcon="search"
      name="patient-search"
      noResults={<NoResults label={labels.patients} />}
      optionRenderer={patientRenderer}
      placeholder={pulling ? "" : placeholder}
      popoverProps={{
        isOpen: openState,
        width: 500,
        maxHeight: null,
        onOpening: clearPulling,
        onInteraction: handlePopoverInteraction,
      }}
      queryable={queryPatient}
      rightElement={rightElement}
      selectedItem={!selectOnly ? selectedItem : null}
      soft={soft}
      onSelectedItemChange={handleSelect}
    />
  );

  function handlePopoverInteraction() {
    setOpenState(undefined);
  }

  async function handleSelect(item: SearchItem | null) {
    if (item && !isPatient(item)) {
      if (!item?.birthDate) {
        return;
      }
      setActivePull({ patient: item, state: "pulling" });
      setOpenState(true);
      const signal = abort.signal;
      try {
        const result = await ehr.pull(item, signal);

        if (result.reference) {
          const patient = await apollo.patients.fetchById(result.reference.id, { abort: signal });
          onSelect(patient);
        }
      } catch {
        if (!signal.aborted) {
          setActivePull({ patient: item, state: "failed" });
        } else {
          setActivePull(undefined);
          setOpenState(undefined);
        }
        return;
      }
      setActivePull(undefined);
    } else {
      onSelect(item);
    }

    if (closeOnSelect) {
      setOpenState(false);
    }

    suggestRef.current?.clearQuery();
  }

  function focusOnSearch() {
    tracking.track("Keyboard Shortcut - Search Patients");
    searchInputRef.current?.focus();
  }

  function renderList(listProps: ItemAsyncListRendererProps<SearchItem>): JSX.Element | null {
    const showRecentTitle = !activePull && !listProps.query && listProps.filteredItems.length > 0;
    const showMoreResults = !activePull && !listProps.fetching && listProps.hasMore && listProps.filteredItems.length > 0;
    const showSourceBar = activePull || listProps.query;
    const noResults = listProps.fetching
      ? <MenuItem readonly text={renderLoading()} />
      : <MenuItem readonly text={renderNoResults(listProps.query)} />;

    const menuContent = activePull
      ? <MenuItem readonly text={renderPulling(activePull)} />
      : listProps.renderItems(noResults, showRecentTitle ? undefined : DefaultSuggestInitialContent);

    // Recent patients show on blank query
    return (
      <>
        {showSourceBar && renderSourceBar()}
        <ResultMenu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
          {showRecentTitle && (
            <MenuDivider title={`Recent ${labels.patients}`} />
          )}
          {menuContent}
          {showMoreResults && DefaultMoreResultsAvailable}
        </ResultMenu>
      </>
    );
  }

  function renderSourceBar() {
    if (!session.practice.product) {
      return null;
    }

    return (
      <SourceBar small disabled={pulling} ehr={searchEhr.current} productLabel={labels.Product} onChange={handleSourceChange}>
        {searchEhr.current && searchByLastName && <LabelInfo className={Classes.TEXT_MUTED}>{Text.SearchByLastName}</LabelInfo>}
      </SourceBar>
    );
  }

  function clearPulling() {
    if (activePull) {
      setActivePull(undefined);
    }
  }

  function renderPulling(activePull: ActivePull) {
    if (activePull.state === "failed") {
      return (
        <NonIdealIcon
          action={<Button label="Try Again" onClick={() => handleSelect(activePull.patient)} />}
          description={`Failed to pull data data for ${activePull.patient.display} from ${searchEhr.current ? labels.Product : "Bells"}`}
          icon={<Offline />}
          intent="danger"
        />
      );
    }

    return (
      <NonIdealState
        action={<Button label="Cancel" onClick={() => abort.reset()} />}
        description={`Gathering data for ${activePull.patient.display} from ${searchEhr.current ? labels.Product : "Bells"}`}
        icon={<Spinner intent="primary" size={40} />}
      />
    );
  }

  function renderLoading() {
    return (
      <NonIdealState
        description={`Searching for ${labels.patients} in ${searchEhr.current ? labels.Product : "Bells"}`}
        icon={<Spinner intent="primary" size={40} />}
      />
    );
  }

  function handleSourceChange(ehr: boolean) {
    clearPulling();
    searchEhr.set(ehr);
    suggestRef.current?.resetQuery();
  }

  function patientRenderer(item: SearchItem, itemProps: ItemRendererProps) {
    const patientDisplay = isPatient(item)
      ? <PatientBanner highlight={itemProps.query} patient={item} scale="small" />
      : <EhrSearchItem highlight={itemProps.query} patient={item} />;

    const alias = isPatient(item)
      ? item.otherNames.find(n => n.use !== "Nickname" && startsWithAllWords(n.display, itemProps.query))?.display
      : undefined;

    return (
      <ListOption
        key={isPatient(item) ? item.id : item.identifier.value}
        multiline
        disabled={isPatient(item) ? isItemDisabled(item) : isSearchItemDisabled(item)}
        shouldDismissPopover={false}
        text={item.display}
      >
        <PatientItem className={itemProps.active ? "active" : ""}>
          {patientDisplay}
          {alias && <div className="alias">Alias: {highlightText(alias, itemProps.query)}</div>}
          {itemRightElement && (
            <div className="rightEl">{typeof itemRightElement === "function" ? itemRightElement(item, itemProps) : itemRightElement}</div>
          )}
        </PatientItem>
      </ListOption>
    );
  }

  async function fetchRecentPatients(abort: AbortSignal): Promise<Patient[]> {
    if (openPatients.recentPatientIds.length === 0) {
      return [];
    }

    const ids = openPatients.recentPatientIds.slice(0, 7);
    const response = await apollo.patients.fetch({
      fetchOptions: {
        ids,
      },
      abort,
    });

    const patients = response.results;
    patients.sort((a, b) => ids.indexOf(b.id) - ids.indexOf(a.id));
    return patients;
  }

  async function queryPatient(query: string, abort: AbortSignal): Promise<PagingResult<SearchItem>> {
    clearPulling();

    try {
      if (searchEhr.current) {
        return await queryEhr(query, abort);
      }
      return await queryApollo(query, abort);
    } catch (error) {
      handleError(error);
      return { items: [], hasMore: false };
    }
  }

  async function queryApollo(query: string, abort: AbortSignal): Promise<PagingResult<Patient>> {
    if (query === "") {
      return {
        items: await fetchRecentPatients(abort),
        hasMore: false,
      };
    }

    const filterSets = createPatientFilters({ query });

    const response = await apollo.patients.query({
      filters: filterSets,
      feedOptions: {
        maxItemCount: 6,
      },
      abort,
    });

    if (query) {
      tracking.track("Patient Search - Query", { location: "Search Bar", term: query });

      if (response.results.length > 0) {
        audit.log("PatientSearch", { query, entities: response.results });
      }

      if (response.results.length > 0) {
        tracking.track("Patient Search - Has Results");
      } else {
        tracking.track("Patient Search - No Results");
      }
    }

    return { items: response.results, hasMore: !!response.continuationToken };
  }

  async function queryEhr(query: string, abort: AbortSignal): Promise<PagingResult<ExternalPatient>> {
    const response = await ehr.searchPatients(query, { limit: 6 }, abort);
    return { items: response.results, hasMore: !!response.continuationToken };
  }

  function isItemDisabled(patient: Patient) {
    if (itemDisabled) {
      return itemDisabled(patient);
    }
    return false;
  }

  function isSearchItemDisabled(patient: ExternalPatient) {
    return !patient.birthDate;
  }

  function renderNoResults(query: string) {
    const description = query
      ? Text.NoSearchResults(labels.Patients)
      : Text.NoResults(labels.Patients);

    return (
      <NoResultContainer>
        <NonIdealIcon
          description={<div>{description}{!searchEhr.current ? <> Try searching <strong>{labels.Product}</strong> instead.</> : ""}</div>}
          icon={<Geosearch />}
          intent="warning"
          title={`No matching ${labels.patients} found`}
        />
      </NoResultContainer>
    );
  }
});

function isPatient(item: SearchItem): item is Patient {
  return "id" in item;
}

function itemEqual(left: SearchItem, right: SearchItem) {
  const leftId = isPatient(left) ? left.id : left.identifier.value;
  const rightId = isPatient(right) ? right.id : right.identifier.value;
  return leftId === rightId;
}

interface EhrSearchItemProps {
  patient: ExternalPatient;
  highlight?: string;
}

function EhrSearchItem(props: EhrSearchItemProps) {
  const { patient, highlight = "" } = props;
  return (
    <DetailsContainer className={!patient.birthDate ? "disabled" : ""}>
      <Ellipsize maxWidth={400}>
        <ClientData>
          <span>{highlightText(patient.display, highlight)}</span>
          {patient.mrn && <Mrn>{highlightText(patient.mrn, highlight)}</Mrn>}
        </ClientData>
      </Ellipsize>
      <DobInfo>
        {patient.birthDate && (
          <>
            {DateFormats.age(LocalDate.toDate(patient.birthDate))}
            <Dot />
          </>
        )}
        {patient.birthDate ? DateFormats.date(LocalDate.toDate(patient.birthDate)) : "Missing DOB"}
      </DobInfo>
    </DetailsContainer>
  );
}
