import React, { useContext } from "react";
import { AnalyticIngester } from "./analyticIngester";

export interface AnalyticsIngestContext {
  readonly ingester: AnalyticIngester;
}

export const unmountedAnalyticsIngestContext: AnalyticsIngestContext = {
  get ingester(): AnalyticIngester {
    throw new Error("AnalyticsIngestContext is not initialized");
  },
};

export const AnalyticsIngestContext = React.createContext<AnalyticsIngestContext>(unmountedAnalyticsIngestContext);

export const useAnalyticIngester = () => useContext(AnalyticsIngestContext).ingester;
