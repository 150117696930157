interface StandardJwt {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  auth_time?: number;
}

type JwtProperties = {
  [key: string]: string | string[] | number;
};

export type JwtPayload = JwtProperties & StandardJwt;

export function decodeJwt(jwt: string): JwtPayload {
  const [, payload] = jwt.split(".");
  const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));

  return JSON.parse(jsonPayload);
}
