import { useEffect, useRef } from "react";
import { useAbort, useStateIfMounted } from "@remhealth/ui";
import { PracticePreferences } from "@remhealth/apollo";
import { usePreferences } from "./usePreferences";
import { PracticePreferencesData, defaultPracticePreferences } from "./preferencesStore";

export function usePracticePreferencesRef() {
  const preferences = usePreferences();
  const abort = useAbort();
  const [practicePreferences, setPracticePreferences] = useStateIfMounted<PracticePreferencesData>(preferences.loadedPractice ?? defaultPracticePreferences);
  const practicePreferencesRef = useRef(practicePreferences);

  useEffect(() => {
    if (!preferences.isPracticeLoaded) {
      loadPracticePreferences();
    }
  }, []);

  useEffect(() => preferences.registerPractice(handleUpdate), [preferences]);

  async function loadPracticePreferences() {
    const loaded = await preferences.getPractice(abort.signal);
    practicePreferencesRef.current = loaded;
    setPracticePreferences(loaded);
  }

  function handleUpdate(preferences: PracticePreferences) {
    practicePreferencesRef.current = preferences;
    setPracticePreferences(preferences);
  }

  return practicePreferencesRef;
}

export function usePracticePreferences() {
  return usePracticePreferencesRef().current;
}
