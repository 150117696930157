/**
 * Utility functions related to Patient
 */
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { ItemRendererProps, ListOption, ListOptionProps, Tag } from "@remhealth/ui";
import { Patient } from "@remhealth/apollo";
import { PersonTag, type PersonTagProps } from "@remhealth/core";
import { PatientBanner } from "~/avatars/patientBanner";

export const patientTagRenderer = (patient: Patient) => <PatientTag patient={patient} />;

const InactiveTag = styled(Tag)`
  margin-left: 8px;
  padding: 2px 6px !important;
  min-height: 0px;
  min-width: 0px;
  font-size: 12px;
  line-height: 14px;
  color: inherit !important;
`;

export interface PatientTagProps extends Omit<PersonTagProps, "person"> {
  patient: Patient;
}

export function PatientTag(props: PropsWithChildren<PatientTagProps>) {
  const { patient, children, ...restProps } = props;
  return (
    <PersonTag {...restProps} person={patient}>
      {!patient.active && <InactiveTag minimal>Inactive</InactiveTag>}
      {children}
    </PersonTag>
  );
}

export function patientRenderer(patient: Patient, itemProps: ItemRendererProps, optionProps?: Partial<ListOptionProps>) {
  return (
    <ListOption
      {...optionProps}
      key={patient.id}
      multiline
      text={patient.display}
    >
      <PatientBanner fill highlight={itemProps.query} patient={patient} scale="small" />
    </ListOption>
  );
}
