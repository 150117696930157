import Inline from "quill/blots/inline";
import { css } from "styled-components";

export interface UndoBlotData {
  restore: string;
}

export class UndoBlot extends Inline {
  public static readonly blotName = "undoable";
  public static readonly tagName = "rh-undo";
  public readonly undoBlot = true;
  public ticks = 0;

  public static create(data: UndoBlotData) {
    const node = document.createElement(UndoBlot.tagName);
    if (data.restore) {
      node.setAttribute("restore", data.restore);
    }
    return node;
  }

  public static formats(domNode: HTMLElement) {
    return domNode.tagName === UndoBlot.tagName ? "undo" : undefined;
  }

  public static value(node: HTMLElement): UndoBlotData {
    return {
      restore: node.getAttribute("restore") ?? "",
    };
  }

  public tick() {
    this.ticks++;
  }
}

export function isUndoBlot(blot: any): blot is UndoBlot {
  return blot && blot instanceof UndoBlot;
}

export const UndoCss = css`
  rh-undo {
    border-bottom: 2px dotted #999;
  }
`;
