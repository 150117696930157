import { CarePlanActivity, CarePlanGoal, GoalElement, GoalElementActivity } from "@remhealth/apollo";
import { isApproximateRangeEffective } from "@remhealth/host";

// Special logic for CTOne to consolidate/group goals by text
export function groupGoals(goals: CarePlanGoal[]): CarePlanGoal[] {
  const groups = new Map<string, CarePlanGoal>();
  goals.forEach(goal => {
    const key = goal.detail ?? goal.code?.text ?? "";
    const group = groups.get(key);
    if (!group) {
      groups.set(key, { ...goal, children: [...goal.children] });
    } else {
      group.children.push(...goal.children);
    }
  });
  return Array.from(groups.values());
}

export function findInCarePlanActivities(carePlans: CarePlanActivity[], target: GoalElement): GoalElementActivity | null {
  for (const carePlan of carePlans) {
    const match = findInElements(carePlan.activity, target);
    if (match) {
      return match;
    }
  }
  return null;
}

function findInElements(elements: GoalElementActivity[], target: GoalElement): GoalElementActivity | null {
  for (const element of elements) {
    if (element.linkId === target.linkId) {
      return element;
    }
    // Support for legacy activities
    if (!element.linkId) {
      if (element.detail && element.detail.toLowerCase() === target.detail?.toLowerCase()) {
        return element;
      }
      if (element.code?.text && element.code.text.toLowerCase() === target.code?.text?.toLowerCase()) {
        return element;
      }
    }
    const match = findInElements(element.children, target);
    if (match) {
      return match;
    }
  }
  return null;
}

export function filterGoal(goal: CarePlanGoal, visitDate?: Date): boolean {
  return filterElement(goal) && isApproximateRangeEffective(goal.period, visitDate);
}

export function filterElement(element: GoalElement): boolean {
  switch (element.achievementStatus) {
    case "NotAchieved":
    case "InProgress":
      return true;
    default:
      return false;
  }
}

export function validateCarePlanActivities(list: CarePlanActivity[], leafLevelGoalComments: boolean, requireComments: boolean) : boolean {
  if (list.length === 0) {
    return true;
  }

  if (leafLevelGoalComments) {
    return list.every(i => i.activity.every(allHasComment));
  } else if (requireComments) {
    return list.every(i => i.activity.every(hasComment));
  }
  return true;
}

function allHasComment(item: GoalElementActivity) : boolean {
  if (item.children.length === 0) {
    return hasComment(item);
  }
  return item.children.every(allHasComment);
}

function hasComment(item: GoalElementActivity) : boolean {
  return (item.comments?.plainText?.trim() ?? "") !== "";
}
