import React, { Context, PropsWithChildren, SetStateAction, createContext, createElement, useMemo } from "react";

type Unsubscriber = () => void;

export type SubscriptionHandler<TState> = (state: TState) => void;
export type SubscriptionProvider<TState> = React.FunctionComponent<PropsWithChildren<SubscriptionProviderProps<TState>>>;

export interface SubscriptionContext<TState> {
  subscription: Subscription<TState>;
}

export interface Subscription<TState> {
  readonly state: TState;
  update(state: SetStateAction<TState>): void;
  subscribe(handler: SubscriptionHandler<TState>): Unsubscriber;
}

export interface SubscriptionInfo<TState> {
  context: Context<SubscriptionContext<TState>>;
  Provider: SubscriptionProvider<TState>;
}

export interface SubscriptionProviderProps<TState> {
  /** If provided, the given subscription will be used within nested components.  Otherwise, the global subscription is used. */
  subscription?: Subscription<TState>;
}

export function createSubscription<TState>(initialState: TState): SubscriptionInfo<TState> {
  const globalSubscription = new SubscriptionImpl<TState>(initialState);
  const contextValue: SubscriptionContext<TState> = { subscription: globalSubscription };
  const context = createContext<SubscriptionContext<TState>>(contextValue);
  return { context, Provider };

  function Provider(props: PropsWithChildren<SubscriptionProviderProps<TState>>) {
    const subscription = props.subscription ?? globalSubscription;
    const contextValue: SubscriptionContext<TState> = { subscription };
    return createElement(context.Provider, { value: contextValue }, props.children);
  }
}

export function useSubscriptionInstance<TState>(initialState: TState): Subscription<TState> {
  return useMemo(() => new SubscriptionImpl<TState>(initialState), []);
}

class SubscriptionImpl<TState> {
  public state: TState;

  private subscriptionIndex = 0;
  private readonly handlers = new Map<number, SubscriptionHandler<TState>>();

  constructor(initialState: TState) {
    this.state = initialState;
  }

  public update(state: SetStateAction<TState>) {
    this.state = isStateCallback(state) ? state(this.state) : state;
    this.notify();
  }

  public subscribe(handler: SubscriptionHandler<TState>): Unsubscriber {
    const index = this.subscriptionIndex++;
    this.handlers.set(index, handler);
    return () => this.handlers.delete(index);
  }

  private notify() {
    this.handlers.forEach(handler => handler(this.state));
  }
}

function isStateCallback<S>(a: SetStateAction<S>): a is ((prevState: S) => S) {
  return typeof a === "function";
}
