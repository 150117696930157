import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { executeItemsEqual } from "@blueprintjs/select";
import { AsyncQuery, DefaultMoreResultsAvailable, DefaultNoResults, DefaultSuggestInitialContent, ItemListRendererProps } from "../utils";
import { useStateRef } from "../hooks";
import { Suggest, SuggestProps } from "./suggest";
import { InputGroupProps } from "./inputGroup";
import { Menu } from "./menu";

export interface ItemAsyncListRendererProps<T> extends ItemListRendererProps<T> {
  hasMore: boolean;
  fetching: boolean;
}

export type AsyncItemListRenderer<T> = (itemListProps: ItemAsyncListRendererProps<T>) => JSX.Element | null;

export interface AsyncSuggestProps<T> extends Omit<SuggestProps<T>, "itemListRenderer" | "itemListPredicate" | "itemPredicate" | "items"> {
  /** A promise that will return new items to use when given a query. */
  queryable: AsyncQuery<T>;

  /**
   * Delay in milliseconds before changes to the query invoke the `queryable`.
   * @default 300
   */
  queryInvokeDelay?: number;

  /**
   * If true, the `queryable` will be invoked even if the query is empty.
   * @default false
   */
  fetchOnBlankQuery?: boolean;

  /**
   * If true, the list will be rendered while fetching results.
   * @default false
   */
  showListOnQuery?: boolean;

  itemListRenderer?: AsyncItemListRenderer<T>;
}

const defaultQueryDelay = 300;

type CancelFunction = () => void;

export interface AsyncSuggest {
  resetQuery(): Promise<void>;
  resetSuggestions(): void;
  clearQuery(): void;
}

function AsyncSuggestComponent<T>(props: AsyncSuggestProps<T>, ref: React.Ref<AsyncSuggest>) {
  const [suggestions, setSuggestions] = useState<T[] | null>(null);
  const [disablePopover, setDisablePopover] = useState(false);
  const [stateQuery, setStateQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const suggestRef = useRef<Suggest>(null);
  const activeQueryable = useStateRef<AbortController | null>(null);
  const cancelSetQuery = useRef<CancelFunction | null>(null);

  // Unmount
  useEffect(() => {
    return () => cancelActiveQueries();
  }, []);

  const {
    clearable,
    disabled,
    fetchOnBlankQuery,
    initialContent = fetchOnBlankQuery ? null : DefaultSuggestInitialContent,
    popoverProps: controlledPopoverProps,
    itemListRenderer = defaultItemListRenderer,
    itemsEqual,
    inputProps: controlledInputProps,
    noResults = DefaultNoResults,
    intent,
    queryable,
    query = stateQuery,
    showListOnQuery = false,
    resetOnSelect = false,
    rightElement: controlledRightElement,
    queryInvokeDelay = defaultQueryDelay,
    onItemSelect,
    onQueryChange,
    ...restProps
  } = props;

  const inputProps: InputGroupProps = {
    ...controlledInputProps,
    "aria-busy": !!activeQueryable.current,
    "onFocus": handleInputFocus,
  };

  const rightElement = activeQueryable.current
    ? <Spinner intent={intent} size={16} />
    : controlledRightElement;

  useImperativeHandle(ref, () => ({
    resetQuery,
    resetSuggestions,
    clearQuery,
  }));

  return (
    <Suggest<T>
      {...restProps}
      ref={suggestRef}
      clearable={clearable}
      disabled={disabled}
      initialContent={initialContent}
      inputProps={inputProps}
      intent={intent}
      itemListRenderer={customItemListRenderer}
      itemPredicate={_ => true} // Trust queryable results instead
      items={suggestions ?? []}
      itemsEqual={itemsEqual}
      popoverProps={controlledPopoverProps}
      query={query}
      rightElement={rightElement}
      onItemSelect={handleItemSelect}
      onQueryChange={handleQueryChange}
    />
  );

  function cancelActiveQueries() {
    // Cancel any query delays (keyboard debounce)
    cancelSetQuery.current?.();
    cancelSetQuery.current = null;
    activeQueryable.current?.abort();
    activeQueryable.set(null);
  }

  function clearQuery() {
    handleQueryChange("");
    suggestRef.current?.clearQuery();
  }

  async function resetQuery() {
    if (query || fetchOnBlankQuery) {
      await invokeSetQuery(query);
    } else {
      setSuggestions(null);
    }
  }

  function resetSuggestions() {
    setSuggestions(null);
  }

  async function invokeSetQuery(query: string): Promise<void> {
    cancelActiveQueries();

    const abort = new AbortController();

    activeQueryable.set(abort);
    setSuggestions(null);

    try {
      const response = await queryable(query, abort.signal);
      const suggestions = response.items;

      setSuggestions([...suggestions]);
      setHasMore(response.hasMore);
    } finally {
      setDisablePopover(!!cancelSetQuery.current);
      activeQueryable.set(null);
    }
  }

  function handleQueryChange(query: string, event?: React.ChangeEvent<HTMLInputElement>) {
    // Skip if query didn't really change
    if (stateQuery === query) {
      return;
    }

    // Always record the new query, even if we don't immediately act upon it
    setStateQuery(query);
    setDisablePopover(true);
    setSuggestions(null);

    onQueryChange?.(query, event);

    // Cancel any current requests
    cancelActiveQueries();

    if (query || fetchOnBlankQuery) {
      // Debounce in case the user is typing
      debounceSetQuery(query);
    } else {
      // When query is empty, we don't perform any search
      setSuggestions(null);
      setDisablePopover(false);
    }
  }

  function handleItemSelect(item: T, event?: React.SyntheticEvent<HTMLElement>) {
    // Reset query if chose "Create New Item" item
    if (suggestions && !suggestions.some(i => executeItemsEqual(itemsEqual, i, item))) {
      resetSuggestions();
      clearQuery();
      setDisablePopover(false);
    }

    if (resetOnSelect) {
      clearQuery();
    }

    onItemSelect?.(item, event);
  }

  function handleInputFocus(event: React.FocusEvent<HTMLInputElement>) {
    controlledInputProps?.onFocus?.(event);

    if (fetchOnBlankQuery && suggestions === null && !query && !activeQueryable.current && !cancelSetQuery.current) {
      invokeSetQuery(query);
    }
  }

  function defaultItemListRenderer(listProps: ItemAsyncListRendererProps<T>) {
    const createItem = listProps.renderCreateItem();
    const maybeNoResults = listProps.query && !createItem && listProps.filteredItems.length === 0 ? noResults : null;
    const moreResults = listProps.items.length > 0 && listProps.hasMore ? DefaultMoreResultsAvailable : null;
    const renderInitialContent = fetchOnBlankQuery && listProps.filteredItems.length > 0 ? null : initialContent;
    const menuContent = listProps.renderItems(maybeNoResults, renderInitialContent);

    if (!menuContent && !moreResults && !createItem) {
      return null;
    }

    return (
      <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        {menuContent}
        {moreResults}
        {createItem}
      </Menu>
    );
  }

  function customItemListRenderer(itemListProps: ItemListRendererProps<T>): JSX.Element | null {
    // Don't show popover while we are fetching results
    if (disablePopover || !!activeQueryable.current) {
      return null;
    }

    const fetching = !!activeQueryable.current || disablePopover;
    return itemListRenderer({ ...itemListProps, hasMore, fetching });
  }

  function debounceSetQuery(query: string) {
    const cancel = setTimeout(() => invokeSetQuery(query), queryInvokeDelay);
    cancelSetQuery.current = () => clearTimeout(cancel);
  }
}

export const AsyncSuggest = forwardRef(AsyncSuggestComponent) as <T>(props: AsyncSuggestProps<T> & { ref?: React.Ref<AsyncSuggest>}) => JSX.Element;
