import styled from "styled-components";
import { Card } from "@remhealth/ui";

export const AppointmentDetailsCard = styled(Card)`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 0 15px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
`;
