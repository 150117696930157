import { fromHtml } from "hast-util-from-html";
import { type HtmlToTextOptions, htmlToReact, htmlToString, htmlToText } from "@remhealth/compose";
import { trimEmptyBreaks } from "./break";
import { PlaceholderifyParserOptions, placeholderify } from "./placeholderify";
import { type TransformOptions, transform } from "./transform";
import { highlightify } from "./highlight";

export const htmlToNode = (html: string) => fromHtml(html, { fragment: true });

export const placeholderifyHtml = (value: string, options: PlaceholderifyParserOptions) => {
  return htmlToString(value, {
    plugins: [
      [placeholderify, options],
    ],
  });
};

export type PlaceholderifyTextOptions = PlaceholderifyParserOptions & HtmlToTextOptions;

export const placeholderifyText = (value: string, options: PlaceholderifyTextOptions) => {
  return htmlToText(value, {
    plugins: [
      [placeholderify, options],
    ],
  });
};

export const transformHtml = (html: string, options: TransformOptions) => {
  return htmlToString(html, {
    plugins: [
      [transform, options],
    ],
  });
};

export interface MarkupToReactOptions {
  placeholders?: PlaceholderifyParserOptions;
  highlights?: string[];
}

export const markupToReact = (html: string, options: MarkupToReactOptions) => {
  return htmlToReact(html, {
    plugins: [
      [trimEmptyBreaks],
      [highlightify, { highlights: options.highlights }],
      [placeholderify, options.placeholders ?? { retainUnknown: true }],
    ],
  });
};
