import { useState } from "react";
import { Patient, Reference } from "@remhealth/apollo";
import { IconButton, Popover, PopoverInteractionKind } from "@remhealth/ui";
import { PatientCard } from "./patientCard";

export interface PatientPopoverProps {
  patient: Patient | Reference<Patient>;
  children?: (isOpen: boolean) => React.ReactNode;
}

export const PatientPopover = (props: PatientPopoverProps) => {
  const { children, patient } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      fill
      content={renderContent()}
      interactionKind={PopoverInteractionKind.CLICK}
      isOpen={isOpen}
      onInteraction={handleInteraction}
    >
      {children?.(isOpen)}
    </Popover>
  );

  function renderContent() {
    return (
      <PatientCard patient={patient} onClick={handleClick}>
        <IconButton minimal small aria-label="Close Popover" className="close" icon="cross" onClick={handleClose} />
      </PatientCard>
    );
  }

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  function handleInteraction(nextIsOpen: boolean, e?: React.SyntheticEvent<HTMLElement>) {
    setIsOpen(nextIsOpen);
    e?.stopPropagation();
  }

  function handleClose() {
    setIsOpen(false);
  }
};
