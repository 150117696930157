import { useMemo } from "react";
import { DateTime } from "luxon";
import { DateFormats, FormatDate, RelativeDateFormatOptions } from "@remhealth/ui";
import { usePersonalPreferences } from "./usePersonalPreferences";

type MaybeDate = DateTime | Date | null | undefined;

export interface DateFormatter {
  /**
   * Outputs dates in the format of MM/DD/YYYY
   * @example
   * "12/31/2010"
   */
  date(date: MaybeDate): string;

  /**
   * Outputs dates in the format of dddd, MMMM Do YYYY
   * @example
   * "Wednesday, July 7th, 1985"
   */
  verbose(date: MaybeDate): string;

  /**
   * Outputs Today, Yesterday, Tomorrow, or the given format.
   * @example
   * "Today"
   * "Yesterday"
   * "Tomorrow"
   * "12/31/2010"
   */
  friendly(date: MaybeDate, format?: FormatDate): string;

  /**
   * Outputs time in the format of h:mma / HH:mm
   * @example
   * "3:31 PM"
   * "15:31"
   */
  time(date: MaybeDate): string;

  /**
   * Outputs dates relative from now.
   * @example
   * "a moment ago"
   * "5m ago"
   * "1h ago"
   * "13 days ago"
   * "3 years ago"
   */
  relative(date: MaybeDate, options?: RelativeDateFormatOptions): string;

  /**
   * Returns formatted age for the brith date passed in. Formatting is done based on condtions:
   * - 0-60 days : shown in days (d)
   * - 2 - 24 shown in months :  (m)
   * - greater than 24 months : shown in years (y)
   * @example
   * "32y"
   * "14m"
   * "3d"
   */
  age(birthDate: MaybeDate): string;

  /**
   * Outputs date/time in the format of MM/DD/YYYY, h:mm A / HH:mm
   * @example
   * "12/31/2010 3:31 PM"
   * "12/31/2010 15:31"
   */
  dateTime(date: MaybeDate): string;

  /**
   * Outputs dates in the format of dddd, MMMM Do YYYY h:mm a / HH:mm
   * @example
   * "Wednesday, July 7th, 1985 3:31 PM"
   * "Wednesday, July 7th, 1985 15:31"
   */
  verboseWithTime(date: MaybeDate): string;

  /**
   * Outputs Today, Yesterday, Tomorrow, or the given format.
   * @example
   * "Today at 9:31am"
   * "Yesterday at 9:31am"
   * "Tomorrow at 9:31am"
   * "12/31/2010 9:31am"
   */
  friendlyDateTime(date: MaybeDate, format?: FormatDate): string;

  /**
   * Outputs Today, Yesterday, Tomorrow, or the given format.
   * @example
   * "Today at 9:31 AM - 1:20 PM"
   * "Yesterday at 9:31 AM - 1:20 PM"
   * "Tomorrow at 9:31 AM - 1:20 PM"
   * "12/31/2010 9:31 AM"
   */
  friendlyDateTimeRange(start: MaybeDate, end: MaybeDate, format?: FormatDate): string;

  /**
   * Outputs a time range
   * @example
   * "9:31 AM – 1:20 PM"
   * "9:31–1:20 PM"
   * "13:31–15:20"
   */
  friendlyTimeRange(start: MaybeDate, end: MaybeDate): string;

  /**
 * Outputs a sortable date format.
 * @example
 * "2023-06-05"
 * "2023-05-01"
 * "2022-11-28"
 */
  sortableDate(date: MaybeDate): string;

  /**
   * Outputs a sortable date format.
   * @example
   * "2023-06-05 15:31"
   * "2023-05-01 08:11"
   * "2022-11-28 12:49"
   */
  sortableDateTime(date: MaybeDate): string;
}

export function useDateFormatter(): DateFormatter {
  const preferences = usePersonalPreferences();

  return useMemo(() => ({
    ...DateFormats,
    friendly: DateFormats.friendlyDate,
    friendlyDateTime: (date: MaybeDate, format?: FormatDate) => DateFormats.friendlyDateTime(date, format, preferences?.militaryTime),
    friendlyDateTimeRange: (start: MaybeDate, end: MaybeDate, format?: FormatDate) => DateFormats.friendlyDateTimeRange(start, end, format, preferences?.militaryTime),
  }), [preferences?.militaryTime]);
}
