import styled from "styled-components";
import { IconButton, Scrollbar } from "@remhealth/ui";

export const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.background.default};
  border-radius: 500px;
  padding: 10px;
  align-items: stretch;
  .today-btn {
    margin-right: 10px;
  }
`;

export const DaysScrollbar = styled(Scrollbar)`
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1 1 auto;
  height: 60px;
  margin: -10px 0;
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
`;

export const NavButton = styled(IconButton)`
  background-color: rgba(var(--pt-intent-primary-rgb), 0.2) !important;
  border: none !important;
  align-self: center;
`;
