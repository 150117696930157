import { useMemo } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import type { DataAttributes } from "~/types";
import { FormScope } from "./formScope";

export interface MenuProps extends Core.MenuProps, DataAttributes {
  /**
   * Gives the input a minimal style.
   * @default false
   */
  extraLarge?: boolean;

  /**
   * Sets a max height on the menu.
   * @default undefined
   */
  maxHeight?: number;

  /**
   * Sets a max height on the menu.
   * @default undefined
   */
  maxWidth?: number;

  /**
   * Sets a specific width on the menu.
   * @default undefined
   */
  width?: number;
}

export const Menu = (props: MenuProps) => {
  const { children, maxHeight, maxWidth, width, style, ...restProps } = props;

  const styles = useMemo(() => ({
    maxHeight,
    maxWidth,
    width,
    overflow: maxHeight !== undefined || maxWidth !== undefined ? "auto" : style?.overflow,
    ...style,
  }), [maxHeight, maxWidth, width, style]);

  return (
    <Core.Menu {...toNativeProps(restProps)} style={styles}>
      <FormScope controlId={restProps.id}>
        {children}
      </FormScope>
    </Core.Menu>
  );
};

export function toNativeProps(props: MenuProps): Core.MenuProps {
  const { extraLarge, className, ...restProps } = props;

  return {
    "aria-label": "Menu",
    ...restProps,
    "className": classnames(extraLarge ? "extra-large" : null, className),
  };
}
