import styled, { css } from "styled-components";
import * as Core from "@blueprintjs/core";

export interface HTMLTableProps extends Omit<Core.HTMLTableProps, "small" | "bordered" | "condensed"> {
  fixed?: boolean;
  stickyHeader?: boolean;
}

const HtmlTableComponent = (props: HTMLTableProps) => {
  const { fixed, stickyHeader = false, ...tableProps } = props;

  return (
    <Core.HTMLTable {...tableProps} />
  );
};

export const HTMLTable = styled(HtmlTableComponent)<HTMLTableProps>`
  ${props => props.fixed && css`
    table-layout: fixed;
  `};

  ${props => props.stickyHeader && css`
    thead th {
      position: sticky;
      top: 0;
      background: ${props => props.theme.background.default};
      z-index: 1;
    }
  `};
`;
