import { Ellipsize } from "@remhealth/ui";
import { GroupAvatar } from "./groupAvatar";

import { DetailsContainer, GroupContainer } from "./groupBanner.styles";

export interface GroupBannerProps {
  groupName?: string;
  small?: boolean;
}

export function GroupBanner(props: GroupBannerProps) {
  const { groupName, small } = props;

  return (
    <GroupContainer>
      <GroupAvatar size={small ? 28 : 36} />
      <DetailsContainer>
        <Ellipsize>
          {groupName}
        </Ellipsize>
      </DetailsContainer>
    </GroupContainer>
  );
}
