import { type LoginOAuthConfiguration, type Zone } from "@remhealth/host";
import { type UnzonedAiConfig, type ZoneValues, type ZonedAiConfig, routes } from "@remhealth/ai";
import { pathCombine } from "@remhealth/ui";

type Urls = UnzonedDatabaseConfig["urls"] | ZonedAiConfig["urls"];

// Loaded from public/config.js
interface StaticConfig {
  AUTH_CLIENT_ID: string;
  MAX_SINCE_LAST_AUTH?: number;
  URL_CONFIG: string;
  DISABLE_SENTRY?: boolean;
  __APP_ZONE?: Zone;
  __APP_VERSION?: string;

  urls?: { [T in keyof Urls]?: Urls[T] };
}

declare const config: Partial<StaticConfig>;

const walkmeDev = import.meta.env.VITE_WALKME_DEV === "true";

const staticConfig: StaticConfig = {
  // Default values
  AUTH_CLIENT_ID: "bells-desktop",
  URL_CONFIG: "https://config.remarkable.ai",
  MAX_SINCE_LAST_AUTH: 60,
  __APP_ZONE: undefined, // All zones by default

  // Overridden values
  ...config,
};

export interface UnzonedConfiguration extends UnzonedAiConfig {
  oauth: LoginOAuthConfiguration;
  lockedZone: Zone | undefined;
  version: string | undefined;
  sentryDsn: string | undefined;
  productionBuild: boolean;
  maximumMinutesSinceLastAuth: number | undefined;
}

interface UnzonedDatabaseConfig {
  urls: {
    oauth: string;
    apollo: ZoneValues;
  };
  sentryDsn: string | undefined;
}

export async function fetchUnzonedConfig(): Promise<UnzonedConfiguration> {
  const databaseConfig = await fetchConfig<UnzonedDatabaseConfig>();

  return {
    ...databaseConfig,
    urls: {
      ...databaseConfig.urls,
      ...staticConfig.urls,
      apollo: databaseConfig.urls.apollo,
    },
    productionBuild: !!import.meta.env.PROD,
    lockedZone: staticConfig.__APP_ZONE,
    version: staticConfig.__APP_VERSION,
    maximumMinutesSinceLastAuth: staticConfig.MAX_SINCE_LAST_AUTH,
    sentryDsn: staticConfig.DISABLE_SENTRY ? undefined : databaseConfig.sentryDsn,
    oauth: {
      authority: databaseConfig.urls.oauth,
      clientId: staticConfig.AUTH_CLIENT_ID,
      scope: "openid email offline_access rh.profile remarkable.ai:core mammoth:core spell:check translate search sync athena:ingest athena:query spell:words:profile",
      redirectUri: routes.authRedirect,
      postLogoutRedirectUri: routes.root,
      customLogoutEndpoint: pathCombine(databaseConfig.urls.oauth, "/logout"),
      loginCallbackPath: "/auth",
      logoutCallbackPath: "/loggedout",
      additionalParameters: {
        "X-RH-ApplicationID": "bells-web",
        "X-RH-ApplicationVersion": staticConfig.__APP_VERSION ?? "",
      },
    },
    identifierUrls: {
      "carefabric://transaction-id": (transactionId) => `https://cfes-prod-logging.netsmartcloud.com/_dashboards/app/dashboards#/view/9f862000-de63-11ee-af3e-4f56bdf9b862?_g=(time:(from:'now-30d',to:'now'))&_a=(query:(language:kuery,query:'transactionId:%22${transactionId}%22'))`,
    },
  };
}

export async function fetchZonedConfig(zone: Zone): Promise<ZonedAiConfig> {
  const zonedConfig = await fetchConfig<ZonedAiConfig>(zone);

  if (["int", "qa"].includes(zone) && !walkmeDev) {
    zonedConfig.urls.walkme = undefined;
  }

  zonedConfig.urls = {
    ...zonedConfig.urls,
    ...staticConfig.urls,
    apollo: zonedConfig.urls.apollo,
  };

  return zonedConfig;
}

async function fetchConfig<T>(zone?: Zone): Promise<T> {
  const search = new URLSearchParams();
  search.append("app", "bells-web");
  search.append("clientId", staticConfig.AUTH_CLIENT_ID);

  if (zone) {
    search.append("zone", zone);
  }

  const response = await fetch(`${staticConfig.URL_CONFIG}/api/config/public?` + search.toString(), {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-RH-ApplicationID": "bells-web",
      "X-RH-ApplicationVersion": staticConfig.__APP_VERSION ?? "",
    },
  });

  if (response.status !== 200) {
    throw new Error(`Unexpected status code ${response.status} when fetching config.`);
  }

  return await response.json() as T;
}
