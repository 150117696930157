import styled from "styled-components";
import { IconButton, Scrollbar } from "@remhealth/ui";

export const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.background.default};
  border-radius: 500px;
  padding: 10px;
  align-items: stretch;
  .today-btn {
    margin-right: 10px;
  }
`;

export const DaysScrollbar = styled(Scrollbar)`
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1 1 auto;
  height: 60px;
  margin: -10px 0;
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
`;

export const DayLabel = styled.div`
  position: relative;
  z-index: 1;
  font-weight: 600;
  pointer-events: none;
`;

export const DateLabel = styled.div`
  position: relative;
  z-index: 1;
  pointer-events: none;
`;

export const DateDayItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px 0;
  width: 56px;
  height: 40px;
  flex-shrink: 0;
  user-select: none;
  font-size: var(--font-size-small);

  &::before {
    position: absolute;
    content: "";
    width: calc(100% - 16px);
    height: 100%;
    border-radius: 20%;
    z-index: 0;
  }

  &.selected-date {
    &::before {
      background-color: var(--themecolor2);
    }
    color: white;
  }

  &:hover:not(.selected-date) {
    &::before {
      background-color: var(--button-background-color-hover);
    }
  }

  &:hover.selected-date {
    &::before {
      background-color: var(--themecolor4);
    }
  }

  &.today:not(:hover):not(.selected-date) {
    &::before {
      border: 1px dashed var(--gray3);
    }
    color: var(--gray1);
  }

  &:not(:hover):not(.today):not(.selected-date) {
    ${DateLabel} {
      color: ${props => props.theme.font.muted};
    }
  }
`;

export const NavButton = styled(IconButton)`
  background-color: rgba(var(--pt-intent-primary-rgb), 0.2) !important;
  border: none !important;
  align-self: center;
`;
