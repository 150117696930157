import styled from "styled-components";

const dateHeaderMarginTop = 0;
const dateHeaderMarginBottom = 5;
export const dateHeaderVerticalMargin = dateHeaderMarginTop + dateHeaderMarginBottom;

export const DateHeader = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: var(--font-size-small);
  color: ${props => props.theme.dark ? "var(--dark-gray)" : "var(--black)"};
  background-color: ${props => props.theme.dark ? "var(--dark-gray4)" : "var(--light-gray5)"};
  border-radius: var(--pt-border-radius);
  padding: 5px 10px;
  margin-top: ${dateHeaderMarginTop}px;
  margin-bottom: ${dateHeaderMarginBottom}px;
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: ${props => props.theme.elevations.one};
  &.active {
    box-shadow: ${props => props.theme.elevations.two};
    background-color: ${props => props.theme.dark ? "var(--dark-gray5)" : "var(--white)"};
  }
`;

export const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const NoEvents = styled.div`
  padding: 0 20px;
  margin: 10px 0;
  display: flex;
  flex: 1;
  height: 60px;
  span {
    font-size: var(--font-size-normal);
    color: ${props => props.theme.font.label};
  }
`;

export const AppointmentItem = styled.div`
  margin: 16px 4px;
`;
