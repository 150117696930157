import { useEffect } from "react";
import { Flavor, Media, Reference } from "@remhealth/apollo";
import { useStateRef } from "@remhealth/ui";
import { useApollo } from "@remhealth/host";

type CachedMediaKey = Flavor<string, "CachedMedia">;
type CachedMedia = { loader: Promise<Media>; loaded: Media | undefined };
const loaderCache = new Map<CachedMediaKey, CachedMedia>();

export function useMedia(media: Media | Reference<Media>): Media | undefined {
  const key = getMediaCacheKey(media);

  const apollo = useApollo();

  const loaded = useStateRef(isResource(media) ? media : loaderCache.get(key)?.loaded);

  useEffect(() => {
    if (!loaded.current || loaded.current.id !== key) {
      loadMedia();
    }
  }, [key]);

  return loaded.current;

  async function loadMedia() {
    let cacheItem = loaderCache.get(key);
    if (!cacheItem) {
      if (isResource(media)) {
        cacheItem = { loader: Promise.resolve(media), loaded: media };
        loaded.set(media);
      } else {
        cacheItem = { loader: expandMedia(media), loaded: undefined };
      }

      loaderCache.set(key, cacheItem);
    }

    const content = cacheItem.loaded ?? await cacheItem.loader;
    loaded.set(content);
  }

  async function expandMedia(mediaRef: Reference<Media>) {
    const media = await apollo.media.expand(mediaRef);
    const cacheItem = loaderCache.get(getMediaCacheKey(mediaRef));
    if (cacheItem) {
      cacheItem.loaded = media;
    }
    return media;
  }
}

function getMediaCacheKey(mediaOrRef: Media | Reference<Media>): CachedMediaKey {
  // Cache versioned media if it is versioned
  return (
    "versionId" in mediaOrRef && mediaOrRef.versionId
      ? mediaOrRef.versionId
      : "meta" in mediaOrRef && mediaOrRef.meta?.versionId
        ? mediaOrRef.meta.versionId
        : mediaOrRef.id
  ) as CachedMediaKey;
}

function isResource(item: any): item is Media {
  return typeof item.meta !== "undefined";
}
