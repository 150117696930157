import { Reference as ApolloReference, ResourceTypes, VisitStatus } from "@remhealth/apollo";

type Json = string | number | boolean | null | undefined | Json[] | { [key: string]: Json };
type Payload = { [key: string]: Json };

interface Reference {
  [eventType: string]: null | Json;
  type?: string;
  id: string;
  display?: string;
}

interface Coding {
  [eventType: string]: null | Json;
  system: string;
  code: string;
  display?: string;
}

export interface AnalyticEvents {
  // Some events support a Payload, whereas others do not
  [eventType: string]: null | Payload;

  "Text Expansion": null;

  IAuditEventV1: {
    succeeded: boolean;
    outcomeDesc: string | undefined;
    reason: string | undefined;
    type: Coding;
    subtype: Coding | undefined;
    entities: Reference[];
  };

  IAppSignInV1: {
    clientId: string;
    artifact: string;
    version: string;
    platform: string;
    channel: string;
  };

  ISuggestionOutcomeV2: {
    action: "Accepted" | "Ignored" | "Dismissed";
    source: "Language" | "Clinical";
    rule: string | undefined;
    note: Reference | undefined;
    context: string | undefined;
    highlighted: string | undefined;
    instructions: string | undefined;
    replacement: string | undefined;
  };

  IDraftNoteCreatedV1: {
    encounter: Reference;
    note: Reference;
    noteType: Reference;
    patient: Reference;
    primaryParticipant: Reference | undefined;
    serviceType: Reference | undefined;
    visitStatus: VisitStatus | undefined;
    visitTime: string | undefined;
    program: Reference | undefined;
    serviceLocation: Reference | undefined;
    locationRole: string | undefined;
    locationKind: Reference | undefined;
    appointment: Reference | undefined;
  };

  INoteSignedV4: {
    encounter: Reference;
    note: Reference;
    noteType: Reference | undefined;
    patient: Reference;
    primaryParticipant: Reference;
    serviceType: Reference | undefined;
    program: Reference | undefined;
    visitStatus: VisitStatus | undefined;
    visitTime: string;
    serviceLocation: Reference | undefined;
    locationRole: string | undefined;
    locationKind: Reference | undefined;
    appointment: Reference | undefined;
    dropIns: number;
    expansions: number;
    tags: number;
    wordsBellsWrote: number;
    totalWords: number;
    sessionMinutes: number;
    sessionToSignMinutes: number;
    sessionToSignLateMinutes: number;
    createdTime: string;
  };

  ISnippetTemplateImportedV1: {
    snippetTemplate: Reference;
  };

  ITextExpansionTemplateImportedV1: {
    textExpansionTemplate: Reference;
  };

  ISnippetUsedV1: {
    snippet: Reference;
    note: Reference | undefined;
  };

  ITextExpansionUsedV1: {
    textExpansion: Reference;
    note: Reference | undefined;
  };
}

type ExcludeIndex<T> = {
  [K in keyof T as {} extends Record<K, 1> ? never : K]: T[K];
};
export type AnalyticEventType = keyof ExcludeIndex<AnalyticEvents>;

export function createRef(reference: ApolloReference<ResourceTypes>): Reference;
export function createRef(reference: ApolloReference<ResourceTypes> | null): Reference | null;
export function createRef(reference: ApolloReference<ResourceTypes> | undefined): Reference | undefined;
export function createRef(reference: ApolloReference<ResourceTypes> | null | undefined): Reference | null | undefined;
export function createRef(reference: ApolloReference<ResourceTypes> | null | undefined): Reference | null | undefined {
  if (reference) {
    return { type: reference.resourceType, id: reference.id, display: reference.display };
  }
  return reference;
}
