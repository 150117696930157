import Inline from "quill/blots/inline";
import { css } from "styled-components";
import { type Suggestion, isSameSuggestion } from "~/modules/suggestions/types";

export interface AvailableSuggestion {
  choice?: undefined;
}

export interface AcceptedSuggestion {
  choice: "accepted";
  original: string;
}

export interface IgnoredSuggestion {
  choice: "ignored";
}

export type SuggestionBlotData = Suggestion & (AvailableSuggestion | AcceptedSuggestion | IgnoredSuggestion) & {
  reference?: string;
};

export class SuggestionBlot extends Inline {
  public static blotName = "tip" as const;
  public static readonly tagName = "rh-tip";

  public static create(data: SuggestionBlotData) {
    const node = document.createElement(SuggestionBlot.tagName);
    SuggestionBlot.init(node, data);
    return node;
  }

  public static value(node: HTMLElement): SuggestionBlotData {
    const replacement = node.getAttribute("replacement") ?? "";
    const reference = node.getAttribute("reference") || node.getAttribute("ref");
    let type = node.getAttribute("type") as string | null | undefined;

    // Legacy support
    const severity = node.getAttribute("severity");
    if (!type && severity) {
      type = severity === "recommend" ? "recommend" : "correct";
    }

    let source = node.getAttribute("source") as string | null | undefined;
    if (!source) {
      if (reference) {
        source = reference.startsWith("lang:") ? "lang" : "nlp";
      } else {
        source = "lang";
      }
    }

    return {
      source,
      severity: type,
      action: node.getAttribute("action") === "replace"
        ? "swap" // Legacy support
        : node.getAttribute("action") ?? "none",
      category: node.getAttribute("category") ?? undefined,
      description: node.getAttribute("description") ?? undefined,
      reference,
      replacements: replacement.split("|"),
      choice: node.hasAttribute("accepted") ? "accepted" : node.hasAttribute("ignored") ? "ignored" : undefined,
      original: node.getAttribute("original") ?? undefined,
      asExpansion: node.getAttribute("asExpansion") === "true",
    } as SuggestionBlotData;
  }

  public static formats(domNode: HTMLElement): {} | SuggestionBlotData {
    if (SuggestionBlot.tagName.toLowerCase() !== domNode.tagName.toLowerCase()) {
      return {};
    }

    return SuggestionBlot.value(domNode);
  }

  public static init(node: HTMLElement, suggestion: SuggestionBlotData) {
    if (suggestion.choice === "accepted") {
      node.toggleAttribute("accepted", true);
      node.setAttribute("original", suggestion.original);
    } else {
      node.toggleAttribute("accepted", false);
      node.removeAttribute("original");
    }

    node.toggleAttribute("ignored", suggestion.choice === "ignored");

    node.setAttribute("type", suggestion.severity);

    if (suggestion.reference) {
      node.setAttribute("reference", suggestion.reference);
    } else {
      node.removeAttribute("ref"); // Legacy attribute
      node.removeAttribute("reference");
    }

    if (suggestion.action && suggestion.action !== "none") {
      node.setAttribute("action", suggestion.action);
    } else {
      node.removeAttribute("action");
    }

    if (suggestion.category) {
      node.setAttribute("category", suggestion.category);
    } else {
      node.removeAttribute("category");
    }

    if (suggestion.description) {
      node.setAttribute("description", suggestion.description);
    } else {
      node.removeAttribute("description");
    }

    if (suggestion.choice !== "accepted") {
      if (suggestion.action === "swap") {
        node.setAttribute("replacement", suggestion.replacements.join("|"));
      } else {
        node.removeAttribute("replacement");
      }
    } else {
      node.removeAttribute("replacement");
    }

    if (suggestion.action === "swap") {
      if (suggestion.asExpansion) {
        node.setAttribute("asExpansion", "true");
      } else {
        node.removeAttribute("asExpansion");
      }
    }
  }

  public format(name: string, value: SuggestionBlotData) {
    if (name === SuggestionBlot.blotName && value) {
      SuggestionBlot.init(this.domNode, value);
    } else {
      super.format(name, value);
    }
  }

  public optimize(context: any) {
    super.optimize(context);
    const formats = this.formats();
    if (formats.tip) {
      const next = this.next;
      if (next instanceof Inline && next.prev === this) {
        const nextFormats = next.formats();
        if (nextFormats.tip && isSameSuggestion(formats.tip, nextFormats.tip)) {
          next.moveChildren(this);
          next.remove();
        }
      }
    }
  }
}

export function isSuggestionBlot(blot: any): blot is SuggestionBlot {
  return blot && blot instanceof SuggestionBlot;
}

export const SuggestionCss = css`
  @keyframes initial-appear {
    0% {
      background-position-x: -200px;
    }
    100% {
      background-position-x: 0;
    }
  }

  .rh-tip,
  rh-tip:not([accepted]):not([ignored]) {
    position: relative;
    padding-bottom: 2px;
    background-repeat: no-repeat;
    animation: initial-appear 0.4s ease forwards;
    background-image: linear-gradient(to top, rgba(var(--red3-rgb), 0.5) 0px, rgba(var(--red3-rgb), 0.5) 3px, transparent 3px);

    &.active,
    &:hover {
      transition-property: background-color;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      background-color: rgba(var(--red5-rgb), 0.25);
      background-image: linear-gradient(to top, var(--red3) 0px, var(--red3) 3px, transparent 3px);
    }

    &.recommend,
    &[type="recommend"] {
      background-image: linear-gradient(to top, rgba(var(--recommedation-purple-rgb), 0.5) 0px, rgba(var(--recommedation-purple-rgb), 0.5) 3px, transparent 3px);

      &.active,
      &:hover {
        background-color: rgba(var(--recommedation-purple-rgb), 0.2);
        background-image: linear-gradient(to top, var(--recommedation-purple) 0px, var(--recommedation-purple) 3px, transparent 3px);
      }
    }
  }
`;
