import styled from "styled-components";
import { ColorRange } from "@remhealth/ui";

interface ContainerProps {
  $gradient: ColorRange;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 250px;
  border-radius: var(--pt-border-radius);
  position: relative;
  background-clip: padding-box;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -3px !important;
    border-radius: calc(var(--pt-border-radius) + 3px);
    background: linear-gradient(-45deg, ${props => props.$gradient[0]}, ${props => props.$gradient[1]});
  }

  .close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

export const Details = styled.div`
  &:not(:empty) {
    margin: 0px 16px 16px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 30px 20px 30px;
`;

export const Name = styled.div`
  margin-top: 10px;
  font-size: var(--font-size-header3);
`;

export const DobInfo = styled.div`
  margin-top: 2px;
  color: ${props => props.theme.font.muted};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  border-top: 1px solid ${props => props.theme.border.muted};
`;
