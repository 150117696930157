import React, { memo, useMemo, useRef } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import type { DataAttributes } from "styled-components";
import { SmallCross } from "@remhealth/icons";
import { getHtmlAttributes } from "~/utils";
import { IconButton } from "./button";
import { Icon, IconName, IconProps } from "./icon";
import { Overlay } from "./overlay";
import { useColorScheme } from "./theme";
import { FormArea, cleanDomId } from "./formScope";

type DialogTitleProps = { title: string };
type DialogAriaTitleProps = {
  "aria-label": string;
  titleElement?: React.ReactNode;
};

export type CoreDialogProps = Omit<Core.DialogProps, "icon" | "title" | "hasBackdrop">;
export type DialogProps = CoreDialogProps
  & Omit<React.HTMLAttributes<HTMLDivElement>, "aria-label" | "title">
  & DataAttributes
  & {
  /**
   * Whether the overlay should acquire application focus when it first opens.
   *
   * @default false
   */
  autoFocus?: boolean;

  /**
   * Whether the overlay should prevent focus from leaving itself. That is, if the user attempts
   * to focus an element outside the overlay and this prop is enabled, then the overlay will
   * immediately bring focus back to itself. If you are nesting overlay components, either disable
   * this prop on the "outermost" overlays or mark the nested ones `usePortal={false}`.
   *
   * @default false
   */
  enforceFocus?: boolean;

  /** @default true */
  hasBackdrop?: boolean;

  /**
   * Whether pressing the `esc` key should invoke `onClose`.
   *
   * @default false
   */
  canEscapeKeyClose?: boolean;

  /**
   * Whether clicking outside the overlay element (either on backdrop when present or on document)
   * should invoke `onClose`.
   *
   * @default false
   */
  canOutsideClickClose?: boolean;

  /**
   * Whether the application should return focus to the last active element in the
   * document after this overlay closes.
   *
   * @default false
   */
  shouldReturnFocusOnClose?: boolean;

  icon?: IconName | React.ReactElement<IconProps>;
} & (DialogTitleProps | DialogAriaTitleProps);

export const Dialog = memo((props: React.PropsWithChildren<DialogProps>) => {
  const title = "title" in props ? props.title : undefined;
  const titleElement = "titleElement" in props ? props.titleElement : undefined;
  const ariaLabel = "title" in props ? props.title : props["aria-label"];
  const {
    autoFocus = false,
    children,
    isCloseButtonShown,
    canEscapeKeyClose = false,
    canOutsideClickClose = false,
    shouldReturnFocusOnClose = false,
    enforceFocus = false,
    hasBackdrop = true,
    className,
    onClose,
    icon,
    "data-form-name": formName = ariaLabel,
    id = cleanDomId(ariaLabel),
    ...restProps
  } = props;

  const { colorScheme } = useColorScheme();
  const childRef = useRef<HTMLDivElement>(null);

  const titleId = useMemo(() => `title-${id}`, [id]);

  return (
    <Overlay
      {...restProps}
      autoFocus={autoFocus}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
      childRef={childRef}
      className={hasBackdrop ? Core.Classes.OVERLAY_SCROLL_CONTAINER : "dialog-hidden-overlay"}
      enforceFocus={enforceFocus}
      hasBackdrop={hasBackdrop}
      shouldReturnFocusOnClose={shouldReturnFocusOnClose}
      onClose={onClose}
    >
      <FormArea ref={childRef} className={Core.Classes.DIALOG_CONTAINER} form={formName ?? ariaLabel}>
        <div
          {...getHtmlAttributes(restProps)}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabel ? undefined : props["aria-labelledby"] || (title ? titleId : undefined)}
          className={classnames(Core.Classes.DIALOG, colorScheme.dark ? "bp5-dark" : "", className)}
          id={id}
          role="dialog"
          style={props.style}
        >
          {maybeRenderHeader()}
          {children}
        </div>
      </FormArea>
    </Overlay>
  );

  function maybeRenderCloseButton() {
    // Show close button if prop is undefined or null
    // this gives us a behavior as if the default value were `true`
    if (isCloseButtonShown !== false) {
      return (
        <IconButton
          minimal
          aria-label="Close Dialog"
          className={Core.Classes.DIALOG_CLOSE_BUTTON}
          icon={<SmallCross size={Core.IconSize.LARGE} />}
          onClick={onClose}
        />
      );
    }

    return undefined;
  }

  function maybeRenderHeader() {
    const header = titleElement ?? title;
    if (header == null) {
      return undefined;
    }
    return (
      <div className={Core.Classes.DIALOG_HEADER}>
        {icon && <Icon icon={icon} size={Core.IconSize.LARGE} />}
        <Core.H4 id={titleId}>{header}</Core.H4>
        {maybeRenderCloseButton()}
      </div>
    );
  }
});

export type DialogStepButtonProps = Partial<Omit<Core.ButtonProps, "intent" | "icon" | "onClick">> & {
  icon?: IconName | React.ReactElement<IconProps>;
};

export interface MultistepDialogProps extends Omit<Core.MultistepDialogProps, "backButtonProps" | "nextButtonProps"> {
  backButtonProps?: DialogStepButtonProps;
  nextButtonProps?: DialogStepButtonProps;
}

export const MultistepDialog = memo((props: React.PropsWithChildren<MultistepDialogProps>) => {
  const { colorScheme } = useColorScheme();

  const backButtonProps = {
    ...props.backButtonProps,
    icon: props.backButtonProps?.icon ? <Icon icon={props.backButtonProps.icon} /> : undefined,
  };

  const nextButtonProps = {
    ...props.nextButtonProps,
    icon: props.nextButtonProps?.icon ? <Icon icon={props.nextButtonProps.icon} /> : undefined,
  };

  return (
    <Core.MultistepDialog
      {...props}
      backButtonProps={backButtonProps}
      className={classnames("multistep", colorScheme.dark ? "bp5-dark" : "", props.className)}
      nextButtonProps={nextButtonProps}
    >
      {props.children}
    </Core.MultistepDialog>
  );
});
