import styled from "styled-components";

export const GoalsList = styled.div`
  padding-left: 14px;
  margin: 0;

  &, ol, ul {
    padding-inline-start: 20px;
  }
`;

export const Goals = styled.li`
  margin-bottom: 12px;

  color: ${props => props.theme.font.muted};

  ol, ul {
    color: ${props => props.theme.font.default};
  }
`;

export const Comment = styled.div`
  font-weight: normal;
  color: ${props => props.theme.font.default};
`;
