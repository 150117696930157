import { createContext, useContext } from "react";
import { IStores, PracticeClients, RegistryClients } from "@remhealth/apollo";

export interface StoreContext {
  readonly store: Readonly<IStores<RegistryClients & PracticeClients>>;
}

export const StoreContext = createContext<StoreContext>({
  get store(): IStores<RegistryClients & PracticeClients> {
    throw new Error("StoreContext is not initialized");
  },
});

export const useStore = () => useContext(StoreContext).store;
