import { clinicalRoutes, scribbleRoutes } from "@remhealth/clinical";
import { sandboxRoutes } from "@remhealth/sandbox";
import { reportsRoutes } from "../reports/routes";
import { settingsRoutes } from "../settings/routes";
import { patientsRoutes } from "../patients";
import { supervisionRoutes } from "../supervision";
import { auditRoutes } from "../audit";

export const routes = {
  root: "/",
  sandbox: sandboxRoutes.root,
  clinical: clinicalRoutes.clinical,
  agenda: clinicalRoutes.agenda,
  settings: "/settings",
  authRedirect: "/signin-oidc",
  authSilentRenew: "/silent-renew",
  logout: "/logout",
  profileSwitch: "/profile",
  profileTab: settingsRoutes.profile,
  dashboard: "/dashboard",
  patients: patientsRoutes.patients,
  groups: patientsRoutes.groups,
  scribbles: scribbleRoutes.scribbles,
  supervision: supervisionRoutes.root,
  sharedScribbles: scribbleRoutes.sharedScribbles,
  reports: reportsRoutes.root,
  audit: auditRoutes.root,
} as const;
