import { isApolloRequestError, isApolloResponseError, knownCodings } from "@remhealth/apollo";
import { DynamicImportError } from "@remhealth/ui";

export function getErrorMessage(error: any): string {
  if (error instanceof DynamicImportError) {
    return "Looks like this page couldn't be loaded.  You may need to refresh the page.";
  }

  if (isApolloRequestError(error)) {
    if (isApolloResponseError(error)) {
      if (error.response.data?.subStatus) {
        switch (error.response.data.subStatus) {
          case knownCodings.subStatusCodes.expiredSecurityToken: return "Your temporary access security token has expired.";
        }
      }

      switch (error.response.status) {
        case 401: return "Session expired.  Please refresh the page.";
        case 403: return "You don't have permissions to perform this action.";
        case 404: return "The requested data was not found.";
        case 408: return "A timeout occurred while attempting your request.  Some of your requests may not have completed.";
        case 409: return "Your changes conflict with those made concurrently by another user.";
        case 410: return "The requested data was deleted or is no longer accessible.";
      }
    }

    return "Sorry, we couldn't complete your request. We have logged the issue and sent back to our team for further review.";
  }

  return "Oops! An unknown error has occurred.";
}
