import styled from "styled-components";
import { Card, Ellipsize } from "@remhealth/ui";

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 350px;
  min-height: 70px;
  background-color: ${props => props.theme.background.aside};
  .left {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .right {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const DetailSection = styled.div`
  display: flex;
  width: 100%;
`;

export const NameAndRelationship = styled(Ellipsize)`
  font-size: var(--font-size-normal);
  color: ${props => props.theme.font.label};
  margin: 5px 0;
`;

export const SignedDateTime = styled.div`
  font-size: var(--font-size-small);
  color: ${props => props.theme.font.muted};
  margin: 5px 0;
`;

export const SignatureWrapper = styled.div`
  width: fit-content;

  img {
    filter: ${props => props.theme.dark ? "invert(1)" : "none"};
  }
`;
