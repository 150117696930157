import styled from "styled-components";
import { Dialog, NonIdealState, Spinner } from "@remhealth/ui";

export const FullCenterScreen = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionCell = styled.td`
  && {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TabBar = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .bp5-tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .bp5-tab-panel {
      flex: 1 1 auto;
      min-height: 0;
    }
  }

  @media (max-width: 600px) {
    .bp5-tab-list {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

export const MenuItemContainer = styled.div`
  span {
    display: flex;
  }
  span.bp5-popover-target {
    flex: 1 1;
  }
`;

const DotContainer = styled.span`
  font-size: 0.8em;
  margin: 0 0.5em;
`;

export interface DotProps {
  className?: string;
}

export function Dot(props: DotProps) {
  return <DotContainer {...props}>&#8226;</DotContainer>;
}

export const TabbedDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  > .bp5-dialog-header {
    height: 50px;
    flex: 0 0 auto;

    > .bp5-heading {
      padding-left: 0;
    }

    .bp5-dialog-close-button {
      margin: 5px;
    }
  }

  > .bp5-dialog-footer {
    margin: 10px 20px 20px 20px;
  }

  > .bp5-dialog-body {
    padding: 0;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > .bp5-tabs {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > .bp5-tab-list {
        flex: 0 0 auto;
        padding-left: 30px;
      }

      > .bp5-tab-panel {
        padding: 20px 30px;
        flex: 1 1 auto;
      }
    }
  }
`;

export interface LoadingMessageProps {
  title: string;
}

export function LoadingMessage(props: LoadingMessageProps) {
  return (
    <FullCenterScreen>
      <NonIdealState
        icon={<Spinner intent="primary" size={64} />}
        title={props.title}
      />
    </FullCenterScreen>
  );
}
