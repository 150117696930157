import { Fragment, memo } from "react";
import styled from "styled-components";
import { Classes } from "@remhealth/ui";
import { HumanName } from "@remhealth/apollo";

export interface PersonNameProps {
  name: HumanName;
}

const Suffix = styled.span`
  font-size: 0.8em;
`;

export const PersonName = memo((props: PersonNameProps) => {
  const { name } = props;

  if (name.text?.trim()) {
    return <span>{name.text}</span>;
  }

  const given = name.given.filter(p => !!p?.trim()).join(" ");
  const prefix = name.prefix?.trim() ? <span key="prefix" className={Classes.TEXT_MUTED}>{name.prefix}</span> : undefined;
  const suffix = name.suffix?.trim() ? <Suffix key="suffix" className={Classes.TEXT_MUTED}>{name.suffix}</Suffix> : undefined;
  const tokens = [prefix, given, name.family, suffix].filter(p => !!p);

  return <>{tokens.map((t, i) => <Fragment key={i}>{t}{i === tokens.length - 1 ? "" : " "}</Fragment>)}</>;
}, arePropsEqual);

function arePropsEqual(prevProps: PersonNameProps, nextProps: PersonNameProps): boolean {
  return prevProps.name.display === nextProps.name.display;
}
