import { Note } from "@remhealth/apollo";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";

export interface CtoneCustomDropdownSectionContentProps {
  note: Note;
}

export function CtoneCustomDropdownSectionContent(props: CtoneCustomDropdownSectionContentProps) {
  const { note } = props;

  // Use name from note section if the section is a Ctone custom dropdown section
  const name = note.sections.find(section => section.format === "CtoneCustomDropdown")?.name;
  const description = note.customOption?.description;

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {!description ? <NoData>{Text.NoData}</NoData> : description}
    </Container>
  );
}
