import styled from "styled-components";

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const DetailsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1em;

  .practitioner-popover:hover {
    cursor: pointer;
    color: ${props => props.theme.font.active};
  }
`;

export const Container = styled.div`
  display: inline-flex;
  overflow: hidden;
`;
