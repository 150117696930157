import React, { type AriaAttributes } from "react";
import { Classes, FormGroupProps as CoreFormGroupProps, Intent } from "@blueprintjs/core";
import classnames from "classnames";
import { FieldValidation } from "../utils/form";
import { FormScope, useAutomationId } from "./formScope";

export interface FormGroupProps extends CoreFormGroupProps, AriaAttributes {
  /** Can be one or many field validations.  If multiple, the first invalidation is displayed. */
  field?: FieldValidation | (FieldValidation | undefined)[];
}

export const FormGroup = (props: FormGroupProps) => {
  const {
    className,
    children,
    contentClassName,
    field,
    label,
    helperText: controlledHelperText,
    intent: controlledIntent,
    disabled: controlledDisabled,
    inline,
    style,
    subLabel,
    labelInfo,
    labelFor,
  } = props;

  const fields = coalesceValidators(field);
  let intent = fields.some(v => v.error) ? "danger" as Intent : undefined;

  const errorField = fields.find(v => v.error);
  let helperText: React.ReactNode = errorField?.errorText;

  if (!intent && controlledIntent) {
    intent = controlledIntent;
  }

  if (!helperText && controlledHelperText) {
    helperText = controlledHelperText;
  }

  const disabled = controlledDisabled !== undefined
    ? controlledDisabled
    : fields.length > 0 && fields.every(v => v.disabled === true);

  const formLabel = props["aria-label"] ?? (typeof label === "string" ? label : undefined);

  const classNames = classnames(
    Classes.FORM_GROUP,
    Classes.intentClass(intent),
    {
      [Classes.DISABLED]: disabled,
      [Classes.INLINE]: inline,
    },
    className
  );

  const errorId = useAutomationId(errorField?.name && errorField?.errorText ? `field[${errorField.name}].error` : undefined);

  return (
    <FormScope errorId={errorId} label={formLabel}>
      <div className={classNames} style={style}>
        {label && (
          <label className={Classes.LABEL} htmlFor={labelFor}>
            {label} <span className={Classes.TEXT_MUTED}>{labelInfo}</span>
          </label>
        )}
        {subLabel && <div className={Classes.FORM_GROUP_SUB_LABEL}>{subLabel}</div>}
        <div className={classnames(Classes.FORM_CONTENT, contentClassName)}>
          {children}
          {helperText && <div className={Classes.FORM_HELPER_TEXT} id={errorId}>{helperText}</div>}
        </div>
      </div>
    </FormScope>
  );
};

function coalesceValidators(fields?: FieldValidation | (FieldValidation | undefined)[]): FieldValidation[] {
  return ([] as (FieldValidation | undefined)[]).concat(fields).flatMap(f => f ?? []);
}
