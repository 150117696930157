import { PropsWithChildren, Ref, createContext, createElement, forwardRef, useContext, useMemo } from "react";
import type { DataAttributes } from "~/types";

export interface FormScopeContext {
  readonly form: string | undefined;
  readonly controlId: string | undefined;
  readonly controlLabel: string | undefined;
  readonly errorId: string | undefined;
}

export const FormScopeContext = createContext<FormScopeContext>({
  form: undefined,
  controlId: undefined,
  controlLabel: undefined,
  errorId: undefined,
});

export interface FormAreaProps extends React.HTMLAttributes<HTMLElement>, DataAttributes {
  form: string | undefined;
  className?: string;
  /** @default "div" */
  tagName?: keyof JSX.IntrinsicElements;
  component?: React.ComponentType<any>;
}

export const FormArea = forwardRef((props: PropsWithChildren<FormAreaProps>, ref: Ref<HTMLElement>) => {
  const { form, children, tagName = "div", component, ...htmlProps } = props;
  const { controlId, controlLabel, errorId } = useContext(FormScopeContext);

  const contentProps = { ...htmlProps, "data-form-name": form, ref };
  const content = createElement(component ?? tagName, contentProps, children);

  const value = useMemo(() => ({
    form,
    controlId,
    controlLabel,
    errorId,
  }), [form, controlId, controlLabel, errorId]);

  return (
    <FormScopeContext.Provider value={value}>
      {content}
    </FormScopeContext.Provider>
  );
});

export interface FormScopeProps {
  controlId?: string;
  errorId?: string;
  label?: string;
}

export function FormScope(props: PropsWithChildren<FormScopeProps>) {
  const { controlId, errorId, label, children } = props;
  const { controlId: contextControlId, controlLabel, form } = useContext(FormScopeContext);

  const value = useMemo(() => ({
    form,
    controlId: cleanDomId([contextControlId, controlId].filter(c => !!c).join(".")),
    controlLabel: label ?? controlLabel,
    errorId,
  }), [form, controlId, label, contextControlId, controlLabel, errorId]);

  return (
    <FormScopeContext.Provider value={value}>
      {children}
    </FormScopeContext.Provider>
  );
}

export function getAutomationId(context: FormScopeContext, controlId: string | undefined): string | undefined {
  if (!context.form) {
    return undefined;
  }

  if (!controlId && !context.controlId) {
    return undefined;
  }

  const id = [`form[${context.form}]`, context.controlId, controlId]
    .filter(s => !!s)
    .join(".");
  return cleanDomId(id);
}

export function useAutomationId(controlId: string | undefined): string | undefined {
  const context = useContext(FormScopeContext);
  return getAutomationId(context, controlId);
}

export function cleanDomId(id: string | undefined): string | undefined {
  if (!id) {
    return undefined;
  }

  return id.replaceAll(" ", "_").replaceAll(",", "");
}

export function getComponentId(controlId: string | undefined, component: string): string | undefined {
  if (!controlId) {
    return undefined;
  }

  return controlId ? cleanDomId(`${controlId}.${component}`) : undefined;
}

export function useControlLabel(explicitLabel?: string) {
  const context = useContext(FormScopeContext);
  return explicitLabel || context.controlLabel;
}
