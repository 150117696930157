import React, { useContext, useMemo } from "react";
import { Article } from "@remhealth/compose";
import { PlaceholderContext } from "~/placeholders";

import { MarkupToReactOptions, markupToReact } from "./parsers";

export type { MarkupToReactOptions };

export interface MarkupProps extends MarkupToReactOptions, React.HTMLAttributes<HTMLSpanElement> {
  source: string | null | undefined;
  className?: string;
}

export class Markup extends React.PureComponent<MarkupProps> {
  public render() {
    return <MarkupComponent {...this.props} />;
  }
}

function MarkupComponent(props: MarkupProps) {
  const {
    highlights = [],
    className,
    source,
    ...spanProps
  } = props;

  const placeholderContext = useContext(PlaceholderContext);
  const element = useMemo(renderMarkup, [source, placeholderContext, highlights.join(",")]);

  if (!element) {
    return null;
  }

  return (
    <Article className={className} {...spanProps}>
      {element}
    </Article>
  );

  function renderMarkup(): JSX.Element | null {
    if (!source) {
      return null;
    }

    const options: MarkupToReactOptions = {
      highlights,
      placeholders: {
        placeholderContext,
        retainUnknown: true,
      },
    };

    return markupToReact(source, options);
  }
}
