import { css } from "styled-components";
import { BaseEmbedBlot } from "./baseEmbedBlot";

export interface ChoiceBlotData {
  multichoice: boolean;
  choices: string[];
  selections: string[];
  display: string;
}

export class ChoiceBlot extends BaseEmbedBlot {
  public static readonly blotName = "choice";
  public static readonly tagName = "rh-choice";

  public static create(data: ChoiceBlotData) {
    const node = document.createElement(this.tagName);
    node.dataset.choices = JSON.stringify(data.choices);

    if (data.selections.length !== 0) {
      node.dataset.selections = JSON.stringify(data.selections);
    } else {
      delete node.dataset.selections;
    }

    node.textContent = data.display;
    node.toggleAttribute("multi", data.multichoice);

    return node;
  }

  public static value(node: HTMLElement): ChoiceBlotData {
    return {
      multichoice: node.hasAttribute("multi"),
      choices: node.dataset.choices ? JSON.parse(node.dataset.choices) : [],
      selections: node.dataset.selections ? JSON.parse(node.dataset.selections) : [],
      display: node.firstElementChild ? node.firstElementChild.textContent ?? "" : node.textContent ?? "",
    };
  }
}

export const ChoiceBlotCss = css`
  rh-choice {
    cursor: pointer;

    > span {
      margin: 0;
    }

    &:not([data-selections]) {
      padding: 0 1px 1px 1px;
      border-radius: 3px;
      color: ${props => props.theme.dark ? "var(--yellow5)" : "var(--themecolor2)"};
      background-color: rgba(242, 199, 68, 0.4);
    }

    &[data-selections] {
      color: ${props => props.theme.dark ? "var(--themecolor5)" : "var(--themecolor3)"};
    }
  }
`;
