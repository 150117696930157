import React, { useEffect, useMemo, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App as AiApp, Text, Theme } from "@remhealth/ai";
import { ForwardingNotifier, OverlayToaster, OverlaysProvider, Toaster, isAbortError, lazy, useInterval } from "@remhealth/ui";
import {
  AccessTokenContext,
  AccessTokenStorage,
  AnalyticEvents,
  Authenticator,
  ErrorContext,
  FHIRSmartLogin,
  SentryService,
  TracingService,
  VoidTracingService
} from "@remhealth/host";
import { BrowserLoginAuthenticationEvents, ErrorBoundary, LoadingMessage, LoginRequired, ValidationError, getErrorMessage, useAuthentication } from "@remhealth/core";

import { type UnzonedConfiguration, fetchUnzonedConfig, fetchZonedConfig } from "./config";
import { getConfigVersion } from "./getConfigVersion";

const TokenDisplay = lazy(() => import("./tokenDisplay"));
const appName = "bells-web";
const authEvents = new BrowserLoginAuthenticationEvents();
const accessTokenStorage = new AccessTokenStorage("bells-web-tokens");

const AppContents = () => {
  const [config, setConfig] = useState<UnzonedConfiguration>();
  const authenticator = useMemo(() => config ? new Authenticator(config.oauth) : null, [config?.oauth]);
  const toaster = useRef<Toaster>(null);
  const forwardedToaster = useMemo(() => new ForwardingNotifier(toaster), []);

  useEffect(() => {
    initialize();
  }, []);

  if (!authenticator || !config) {
    return <LoadingMessage title={Text.Loading} />;
  }

  return (
    <OverlaysProvider>
      <OverlayToaster ref={toaster} maxToasts={3} />
      <AppAuthenticator authenticator={authenticator} config={config} toaster={forwardedToaster} onError={handleError} />
    </OverlaysProvider>
  );

  async function initialize(): Promise<void> {
    try {
      const config = await fetchUnzonedConfig();
      setConfig(config);
    } catch (error) {
      handleError(error);
    }
  }

  function handleError(error: any) {
    if (isAbortError(error)) {
      return;
    }

    // eslint-disable-next-line no-console
    console.error(error);

    toaster.current?.show({
      message: getErrorMessage(error),
      intent: "danger",
      timeout: 10000, // 10 seconds
    });
  }
};

interface AppAuthenticatorProps {
  authenticator: Authenticator;
  config: UnzonedConfiguration;
  toaster: Toaster;
  onError: (error: any) => void;
}

function AppAuthenticator(props: AppAuthenticatorProps) {
  const { config, authenticator, onError } = props;

  const { isSigningIn, tokenContext, login, logout, reauth, pauseRefreshTimer } = useAuthentication({
    authenticator,
    events: authEvents,
    loginConfiguration: config.oauth,
    storage: accessTokenStorage,
    maximumMinutesSinceLastAuth: config.maximumMinutesSinceLastAuth,
    handleError,
    reportError,
  });

  const [updateReady, setUpdateReady] = useState(false);
  const [tracingService, setTracingService] = useState<TracingService>();

  useInterval(handleCheckVersion, "5m");

  useEffect(() => {
    if (tokenContext) {
      loadTracing();
    }
  }, [tokenContext]);

  if (!tokenContext && !isSigningIn()) {
    // Show login required in screen
    return <LoginRequired onLogin={handleLogin} />;
  }

  if (!tokenContext || !tracingService) {
    return (
      <LoadingMessage title={Text.SigningInLoading} />
    );
  }

  return (
    <ErrorBoundary onError={handleError}>
      <AccessTokenContext.Provider value={tokenContext}>
        <Routes>
          <Route
            element={(
              <React.Suspense fallback={<></>}>
                <TokenDisplay />
              </React.Suspense>
            )}
            path="token"
          />
          <Route
            element={(
              <AiApp
                {...props}
                appName={appName}
                appType="Web"
                config={config}
                configLoader={fetchZonedConfig}
                lockedZone={config.lockedZone}
                tracingService={tracingService}
                updateReady={updateReady}
                version={config.version}
                onAfterSignIn={getSignInData}
                onError={handleError}
                onIdle={pauseRefreshTimer}
                onLogout={logout}
                onReauth={reauth}
              />
            )}
            path="*"
          />
        </Routes>
      </AccessTokenContext.Provider>
    </ErrorBoundary>
  );

  function handleLogin(fhirSmart?: FHIRSmartLogin): void {
    login({ fhirSmart, forceAuth: !!fhirSmart });
  }

  function handleError(error: any, context?: ErrorContext) {
    if (isAbortError(error)) {
      return;
    }

    tracingService?.reportError(error, context);
    onError(error);
  }

  function reportError(error: any, context?: ErrorContext) {
    tracingService?.reportError(error, context);
  }

  async function loadTracing() {
    try {
      setTracingService(await SentryService.create({
        config: {
          sentryDsn: config.sentryDsn,
          version: config.version,
          defaultIntegrations: true,
        },
        appName,
        filter: shouldLogError,
      }));
    } catch {
      // Let app load even if sentry fails to initialize
      setTracingService(new VoidTracingService());
    }
  }

  function getSignInData(): AnalyticEvents["IAppSignInV1"] {
    return {
      artifact: "remarkable/bells-web",
      clientId: config.oauth.clientId,
      platform: navigator.userAgent,
      version: config.version ?? "",
      channel: "public",
    };
  }

  async function handleCheckVersion() {
    const configVersion = await getConfigVersion(appName, config.version);

    if (!updateReady && configVersion && configVersion !== config.version) {
      setUpdateReady(true);
    }
  }
}

export const App = () => {
  return (
    <Theme>
      <BrowserRouter>
        <AppContents />
      </BrowserRouter>
    </Theme>
  );
};

function shouldLogError(error: unknown) {
  return !!error && !ValidationError.isError(error);
}
