import styled from "styled-components";
import { FormGroup, Icon, Scrollbar } from "@remhealth/ui";

export const ServiceTypeName = styled.div`
  font-weight: bold;
  font-size: var(--font-size-large);
  margin-right: 50px;
`;

export const AppointmentTime = styled.div`
  color: ${props => props.theme.font.muted};
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const Time = styled.div`
  margin-right: 8px;
`;

export const AppointmentDuration = styled.span`
  font-weight: bold;
  color: ${props => props.theme.font.default};
`;

export const Recurrence = styled.div`
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-small);
  display: flex;
  margin-top: 4px;
`;

export const RecurrenceIcon = styled(Icon)`
  margin-right: 6px;
  margin-top: 4px;
`;

export const Section = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 12px;
  }
`;

export const Sections = styled.div`
  padding: 12px 0;
`;

export const AppointmentInfo = styled(FormGroup)`
  display: flex;
  flex: 1;
  margin: 8px 0px;
`;

export const AttendeeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 8px 0;
  padding: 8px 0;
  gap: 5px;

  > * {
    flex: 1 1 auto;
  }
`;

export const SectionLabel = styled.div`
  font-weight: bold;
  padding-top: 12px;
`;

export const CommentSection = styled.div`
  margin-bottom: 12px;
`;

export const Attendees = styled.div`
  & > div:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.border.muted};
  }
`;

export const Content = styled.div`
  margin: 0 15px;
`;

export const Details = styled(Content)`
  flex: 0 0 auto;
`;

export const AttendeeWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const ScrollArea = styled(Scrollbar)`
  height: 100%;
`;
