import { useEffect, useMemo, useRef } from "react";
import { DateTime, Duration } from "luxon";
import { useStateIfMounted } from "./useStateIfMounted";

export type ClockInterval = "minute" | "second";

export interface IntervalTimerOptions {
  mode: "stopwatch" | "timer";
  updateFrequency: ClockInterval;
  initialValue?: Duration;
}

export function useIntervalTimer(options: IntervalTimerOptions): Duration {
  const { mode, initialValue = Duration.fromMillis(0), updateFrequency } = options;

  const [startTime, setStartTime] = useStateIfMounted(() => getCurrentTime(updateFrequency));
  const [currentTime, setCurrentTime] = useStateIfMounted(startTime);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setStartTime(getCurrentTime(updateFrequency));
    queueUpdate();
    return stop;
  }, [initialValue.toMillis(), updateFrequency]);

  const diff = mode === "stopwatch"
    ? currentTime.diff(startTime)
    : startTime.diff(currentTime);

  return useMemo(() => initialValue.plus(diff).normalize(), [currentTime, startTime]);

  function stop() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (interval.current) {
      clearInterval(interval.current);
    }
  }

  function update() {
    setCurrentTime(getCurrentTime(updateFrequency));
  }

  function queueUpdate() {
    const intervalTime = updateFrequency === "second" ? 1000 : 60000;
    const nextUpdateTime = updateFrequency === "second"
      ? 1000 - DateTime.now().millisecond
      : (60 - DateTime.now().second) * 1000;

    stop();

    if (mode === "stopwatch" || nextUpdateTime > 0) {
      timer.current = setTimeout(() => {
        update();
        interval.current = setInterval(update, intervalTime);
      }, nextUpdateTime);
    }
  }

  function getCurrentTime(interval: ClockInterval) {
    return DateTime.now().startOf(interval === "minute" ? "minute" : "second");
  }
}
