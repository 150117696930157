import React, { useState } from "react";
import { PagingController, useAbort, useLocalStorage, usePageLoader, useUpdateEffect } from "../hooks";
import { anyAbortSignal } from "../utils";
import { GridView, GridViewProps, GroupedRows } from "./gridView";

export interface IFeed<T> extends PagingController<T> {
  readonly loading: boolean;
  readonly canLoadMore: boolean;
  loadMore(limit: number, abort?: AbortSignal): Promise<void>;
}

export interface GridFeedProps<T> extends Omit<GridViewProps<T> & React.HTMLAttributes<HTMLDivElement>, "controller" | "loader" | "loading" | "canLoadMore" | "pageSize" | "onPageSizeChanged" | "onScroll" | "onLoadMore"> {
  feed: IFeed<T>;

  /** Unique key for the grid */
  pageKey: string;

  /** @default 15 */
  defaultPageSize?: number;

  /**
   * Amount of pages to preload.
   * @default 0
   */
  preload?: number;

  onPageSizeChanged?: (pageSize: number) => void;

  /**
   * Grouped Rows
   */
  group?: (items: T[]) => GroupedRows<T>[];
}

export const defaultGridViewPageSize = 15;

export function GridFeed<T>(props: GridFeedProps<T>) {
  const {
    feed,
    defaultPageSize = defaultGridViewPageSize,
    preload = 0,
    pageKey,
    pageSizeChoices,
    id = pageKey,
    onPageSizeChanged,
    group,
    ...viewProps
  } = props;

  const infiniteScroll = pageSizeChoices === "infinite";
  const abort = useAbort();
  const localStorage = useLocalStorage();
  const storedPageSize = localStorage.getItem(`${pageKey}-pageSize`);
  const [pageSize, setPageSize] = useState(storedPageSize ? Number(storedPageSize) : defaultPageSize);

  const loader = usePageLoader<T>({
    pageSize,
    preload,
    canLoadMore: feed.canLoadMore,
    controller: feed,
    onLoadMore: handleLoadMore,
  });

  useUpdateEffect(() => {
    if (infiniteScroll && pageSize !== defaultPageSize) {
      setPageSize(defaultPageSize);
    }
  }, [infiniteScroll, defaultPageSize, pageSize]);

  useUpdateEffect(() => {
    feed.reset();
    onPageSizeChanged?.(pageSize);
  }, [pageSize]);

  return (
    <GridView<T>
      {...viewProps}
      canLoadMore={feed.canLoadMore}
      controller={feed}
      group={group}
      id={id}
      loader={loader}
      loading={feed.loading}
      pageSize={pageSize}
      pageSizeChoices={pageSizeChoices}
      onLoadMore={handleGridLoadMore}
      onPageSizeChanged={handlePageSizeChanged}
    />
  );

  function handleGridLoadMore(limit: number) {
    if (!feed.loading && feed.canLoadMore) {
      feed.loadMore(limit, abort.signal);
    }
  }

  async function handleLoadMore(limit: number, signal: AbortSignal): Promise<void> {
    await feed.loadMore(limit, anyAbortSignal(signal, abort.signal));
  }

  function handlePageSizeChanged(pageSize: number) {
    localStorage.setItem(`${pageKey}-pageSize`, pageSize.toString());
    setPageSize(pageSize);
  }
}
