import { useEffect, useRef } from "react";
import { Debouncer } from "../utils";

/**
 * Used to maintain a Debouncer instance that will automatically clear on unmount.
 */
export function useDebouncer(debounceMs: number): Readonly<Debouncer> {
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  function delay(callback: () => void): void;
  function delay(timeMs: number, callback: () => void): void;
  function delay(arg1: number | (() => void), arg2?: () => void): void {
    clear();
    const callback = typeof arg1 === "number" ? arg2! : arg1;
    const timeMs = typeof arg1 === "number" ? arg1 : debounceMs;
    timerId.current = setTimeout(callback, timeMs);
  }

  function clear(): void {
    if (timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = null;
    }
  }

  useEffect(() => {
    return () => clear();
  }, []);

  return {
    delay,
    clear,
  };
}
