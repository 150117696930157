const root = "/reports";

export type ReportsTab = "unsigned-notes"
  | "signed-notes"
  | "patient-service-usage"
  | "staff-service-usage"
  | "patient-access"
  | "portal"
  | "recommendation-summary"
  | "audit-summary";

export const reportsRoutes = {
  root,
  tab: `${root}/:tab`,
} as const;
