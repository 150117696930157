import { isValidElement } from "react";
import { Intent } from "@blueprintjs/core";

export interface BaseIconProps {
  id?: string;
  "aria-label"?: string;
  size?: number;
  className?: string;
  intent?: Intent;
  color?: string | Readonly<[startColor: string, endColor: string]>;
}

export const IconDisplayName = "icon";

export function isIconType(type: unknown): type is React.ReactElement<BaseIconProps> {
  if (isValidElement(type) && isIconType(type.type)) {
    return true;
  }

  return !!type
    && (typeof type === "function" || typeof type === "object")
    && "displayName" in type
    && type.displayName === IconDisplayName;
}

export enum IconSize {
  STANDARD = 16,
  LARGE = 20
}

export type IconPaths = string[];
