import styled from "styled-components";

export const DetailsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 8px;
`;

export const GroupContainer = styled.div`
  display: inline-flex;
`;
