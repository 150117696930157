import { Instant, Signature } from "@remhealth/apollo";
import { useDateFormatter, useTracking } from "@remhealth/host";
import { MediaImage, useLabeling } from "@remhealth/core";
import { AnchorButton } from "@remhealth/ui";
import { DraftSignature, PatientSignature } from "~/notes/types";
import { Text } from "~/text";

import { Container, DetailSection, NameAndRelationship, SignatureWrapper, SignedDateTime } from "./signatureCard.styles";

export interface SignatureCardProps {
  signature: PatientSignature | DraftSignature | Signature;
  buttonLabel?: string;
  onRemove?: (signature: PatientSignature) => void;
  onSign?: () => void;
}

export function SignatureCard(props: SignatureCardProps) {
  const { signature, buttonLabel = Text.Sign, onRemove, onSign } = props;

  const dates = useDateFormatter();
  const labels = useLabeling();
  const tracking = useTracking();

  return (
    <Container>
      {renderSignature()}
      <DetailSection>
        <div className="left">
          {renderNameAndRelation()}
          {renderSignedTimestamp()}
        </div>
        <div className="right">
          {!isCompleteSignature(signature) && onSign && (
            <AnchorButton
              minimal
              aria-label={`Sign for ${getName(false)}`}
              intent="primary"
              label={buttonLabel}
              onClick={onSign}
            />
          )}
          {onRemove && <AnchorButton minimal intent="danger" label="Remove" onClick={() => handleRemove(signature as PatientSignature)} />}
        </div>
      </DetailSection>
    </Container>
  );

  function renderSignature() {
    if (isCompleteSignature(signature) && signature.signatureImage) {
      return (
        <SignatureWrapper>
          <MediaImage maxHeight={80} maxWidth={200} media={signature.signatureImage} />
        </SignatureWrapper>
      );
    }
    return null;
  }

  function renderSignedTimestamp() {
    if (isCompleteSignature(signature)) {
      return (
        <SignedDateTime>
          {dates.dateTime(Instant.toDateTime(signature.signed))}
        </SignedDateTime>
      );
    }

    return null;
  }

  function renderNameAndRelation() {
    return <NameAndRelationship>{getName(true)}</NameAndRelationship>;
  }

  function getName(includeRole: boolean): string {
    const displayParts = [];

    if (signature.signer?.display) {
      displayParts.push(signature.signer?.display);
    }

    if (includeRole) {
      if (signature.signer?.resourceType === "Patient") {
        displayParts.push(`(${labels.Patient})`);
      } else if (displayParts.length > 0 && signature.role?.text) {
        displayParts.push(`(${signature.role?.text})`);
      }
    }

    if (displayParts.length === 0 && "customLabel" in signature && signature.customLabel) {
      const label = signature.customLabel;
      displayParts.push(label);
    }

    if (displayParts.length === 0 && "type" in signature) {
      switch (signature.type) {
        case "patient":
          displayParts.push(`${labels.Patient} Signature`);
          break;
        case "guardian":
          displayParts.push("Guardian Signature");
          break;
        case "other":
        case "adhoc":
          displayParts.push("Other Signature");
          break;
      }
    }

    return displayParts.join(" ");
  }

  function handleRemove(signature: PatientSignature) {
    onRemove?.(signature);

    tracking.track("Note - Remove Patient Signature", {
      resourceType: "Patient",
      resourceName: signature.signer?.display,
      resourceRelationship: signature.role?.text,
    });
  }
}

function isCompleteSignature(signature: DraftSignature | PatientSignature | Signature): signature is Signature | PatientSignature {
  return "signed" in signature && !!signature.signed;
}
