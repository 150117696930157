import { useMemo } from "react";
import { getComponentId } from "./formScope";
import { MenuProps } from "./menu";
import { useColorScheme } from "./theme";

export interface SelectMenuProps extends React.HTMLAttributes<HTMLUListElement> {
  label: string | undefined;
  id: string | undefined;
  width?: number;
  maxWidth?: number | null;
  maxHeight?: number | null;
  matchTargetWidth?: boolean;

  /** @default 400 */
  defaultMaxWidth?: number;

  /** @default 350 */
  defaultMaxHeight?: number;
}

export function useSelectMenuProps(props: SelectMenuProps) {
  let { label, id, width, maxWidth, maxHeight, matchTargetWidth, defaultMaxWidth, defaultMaxHeight, ...restProps } = props;

  const { zoom } = useColorScheme();

  defaultMaxWidth = defaultMaxWidth ?? (400 * (1 / zoom));
  defaultMaxHeight = defaultMaxHeight ?? (350 * (1 / zoom));

  return useMemo<MenuProps>(() => ({
    // Use presentation because automation drivers don't handle listbox well
    "role": "presentation",
    "aria-label": label,
    "id": getComponentId(id, "listbox"),
    "maxHeight": maxHeight === null ? undefined : maxHeight ?? defaultMaxHeight,
    "maxWidth": matchTargetWidth || maxWidth === null || width !== undefined ? undefined : maxWidth ?? defaultMaxWidth,
    width,
    ...restProps,
  }), [props]);
}
