const root = "/settings";

export const settingsRoutes = {
  root,
  tab: `${root}/:tab`,
  practiceTab: `${root}/:tab/preferences/:tabId`,
  manageUsers: `${root}/users`,
  myProfile: `${root}/profile`,
  profile: `${root}/profile/:user_id`,
  user: `${root}/users/:user_id`,
  importUsers: `${root}/users/import`,
} as const;
