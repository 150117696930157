import { Appointment } from "@remhealth/apollo";
import { IconButton, NonIdealState, Svg } from "@remhealth/ui";
import { Illustrations } from "@remhealth/core";
import { Text } from "~/text";
import { AppointmentDetails } from "./appointmentDetails";
import { AppointmentDetailsCard, ButtonWrapper } from "./agendaRightPane.styles";

export interface AgendaRightPaneProps {
  appointment: Appointment | null;
  noEvents: boolean;
  onClose: () => void;
}

export const AgendaRightPane = (props: AgendaRightPaneProps) => {
  const { appointment, noEvents, onClose } = props;

  return (
    <AppointmentDetailsCard id="agenda-details">
      <ButtonWrapper><IconButton minimal tooltip aria-label="Close right pane" icon="cross" onClick={onClose} /></ButtonWrapper>
      {appointment ? (
        <AppointmentDetails appointment={appointment} />
      ) : (
        <NonIdealState
          description={noEvents ? Text.NoEvents : Text.SelectAnEvent}
          icon={<Svg height={200} src={Illustrations.NoContent} />}
        />
      )}
    </AppointmentDetailsCard>
  );
};
