import styled from "styled-components";
import { Accordion, AccordionItem, Card } from "@remhealth/ui";
import { GoalAchievementStatus } from "@remhealth/apollo";
import { resolveStatusColor } from "~/goalTracking/utils";

export const GoalsTitle = styled.div`
  padding: 5px 0px;
`;

export const CardContainer = styled(Card)`
 &.noPadding {
   &.bp5-card {
    padding: 0px;
   }
  }

  margin-bottom: 10px;
`;

export const ElementContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content auto;
  grid-column-gap: 20px;
  flex-direction: column;
  padding: 20px;

  .bp5-label {
    color: ${props => props.theme.font.label};
  }

  &.flex {
    display: flex;
  }
`;

export const ObjectiveTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  grid-column: span 4;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

interface DotProps {
  $statusType: GoalAchievementStatus;
}

export const Dot = styled.div<DotProps>`
  width: 0.4em;
  height: 0.4em;
  border-radius: 100%;
  background-color: ${props => `rgba(${resolveStatusColor(props.$statusType)}, 1)`};
  margin: 0 5px;
  align-self: center;
`;

export const CarePlanSessionAccordion = styled(Accordion)`
  background-color: ${props => props.theme.background.muted};
  position: relative;
  box-shadow: none;
  border-bottom: 1px solid var(--gray5);
  .bp5-button-text {
    font-size: var(--font-size-normal);
  }
  div:first-child {
    border-bottom: 0;
  }
  border-radius: 0px;
  .accordion-item {
    padding: 0px;
  }
`;

export const CarePlanSessionAccordionItem = styled(AccordionItem)`
  font-size: var(--font-size-normal);
`;
