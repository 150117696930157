import { useState } from "react";
import { isEmpty } from "lodash-es";
import { Appointment, Coding, type HealthcareService, LocalDate, ZonedDateTime } from "@remhealth/apollo";
import { areCodingsSame, useProductFlag, useReleaseCheck } from "@remhealth/host";
import { Form, FormContent, useLabeling, useStore } from "@remhealth/core";
import { AnchorButton, Checkbox, Classes, Dialog, FormGroup, Intent, ListOption, MenuItem, SelectInput } from "@remhealth/ui";

import { Text } from "~/text";
import { ServiceTypeSelect } from "~/notes/selects";
import { getAppointmentStatus, useAppointmentCancelStatuses, useAppointmentStatus } from "./utils";
import { CancelReasonTextArea, CancelStatusWrapper, Contents, DialogFooter, ModalAction } from "./cancelAppointment.styles";

export type CancelAppointmentControlType = "button" | "menuItem";

export interface CancelAppointmentProps {
  appointment: Appointment;
  large?: boolean;
  controlType?: CancelAppointmentControlType;
  minimal?: boolean;
}

export const CancelAppointment = (props: CancelAppointmentProps) => {
  const { appointment, large, controlType = "button", minimal = false } = props;

  const store = useStore();
  const cancelStatuses = useAppointmentCancelStatuses();
  const { isCancelledStatus } = useAppointmentStatus();

  const [isOpen, setIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const isCancelled = isCancelledStatus(appointment.status);
  const canCancel = !isCancelledStatusNotConfigured();
  const disabled = saving || !canCancel || isCancelled;

  return (
    <>
      {controlType === "button" ? (
        <AnchorButton
          disabled={disabled}
          intent={Intent.PRIMARY}
          label={Text.CancelAppointment}
          large={large}
          minimal={minimal}
          onClick={handleCancelAppointmentClick}
        />
      ) : (
        <MenuItem
          disabled={disabled}
          shouldDismissPopover={false}
          text={Text.CancelAppointment}
          onClick={handleCancelAppointmentClick}
        />
      )}
      <CancelAppointmentContent
        appointment={appointment}
        isOpen={isOpen}
        isSaving={saving}
        onCancelAppointment={handleCancelAppointment}
        onClose={() => setIsOpen(!isOpen)}
      />
    </>
  );

  function handleCancelAppointmentClick() {
    setIsOpen(true);
  }

  async function handleCancelAppointment(appointment: Appointment) {
    try {
      setIsOpen(false);
      setSaving(true);
      await store.appointments.upsertAsync({
        ...appointment,
      });
    } finally {
      setSaving(false);
    }
  }

  function isCancelledStatusNotConfigured() {
    return cancelStatuses.length === 0;
  }
};

interface CancelAppointmentForm {
  cancelReason?: string;
  status?: Coding;
  missedVisit?: boolean;
  missedVisitServiceCode?: HealthcareService;
}

interface CancelAppointmentContentProps {
  appointment: Appointment;
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  onCancelAppointment: (appointment: Appointment) => void;
}

function CancelAppointmentContent(props: CancelAppointmentContentProps) {
  const { isOpen, isSaving, appointment, onClose, onCancelAppointment } = props;

  const cancelStatuses = useAppointmentCancelStatuses();
  const { isCancelledStatus } = useAppointmentStatus();

  const missedVisit = useProductFlag("MissedVisit");

  const isMyAvatarMissedVisitReleased = useReleaseCheck("myAvatarMissedVisit");

  const canSubmit = !isSaving && !isCancelledStatus(appointment.status);
  const labels = useLabeling();
  const patient = appointment.attendees.find(a => a.subject.resourceType === "Patient");

  return (
    <Dialog
      enforceFocus={false}
      isOpen={isOpen}
      title={Text.CancelAppointment}
      onClose={onClose}
    >
      <Form<CancelAppointmentForm>
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
      >
        {form => {
          return (
            <>
              <div className={Classes.DIALOG_BODY}>
                {!isEmpty(cancelStatuses) && (
                  <CancelStatusWrapper>
                    <FormGroup label="Appointment Status">
                      <SelectInput<Coding>
                        clearable
                        disabled={cancelStatuses.length === 1}
                        field={form.fields.status}
                        items={cancelStatuses}
                        itemsEqual={areCodingsSame}
                        optionRenderer={statusRenderer}
                      />
                    </FormGroup>
                  </CancelStatusWrapper>
                )}
                <Contents>
                  {missedVisit && isMyAvatarMissedVisitReleased && (
                    <div>
                      <Checkbox
                        inline
                        field={form.fields.missedVisit}
                        label="Missed Visit"
                      />
                      {!!form.values.missedVisit && (
                        <ServiceTypeSelect
                          clearable
                          showLabel
                          field={form.fields.missedVisitServiceCode}
                          queryOptions={{
                            missedVisitsOnly: true,
                            episodeOfCareId: appointment.episodeOfCare?.id,
                            locationId: appointment.location.id,
                            patientId: patient?.subject.id,
                            programId: appointment.program?.id,
                            visitDate: LocalDate.fromDate(ZonedDateTime.toDate(appointment.start)),
                          }}
                        />
                      )}
                    </div>
                  )}
                  <FormGroup label="Cancelled Reason">
                    <CancelReasonTextArea field={form.fields.cancelReason} />
                  </FormGroup>
                </Contents>
              </div>
              <DialogFooter className={Classes.DIALOG_FOOTER}>
                <ModalAction
                  large
                  label="Cancel"
                  onClick={onClose}
                />
                <ModalAction
                  large
                  disabled={!canSubmit}
                  intent={Intent.PRIMARY}
                  label={Text.CancelAppointment}
                  onClick={handleSubmitForm(form)}
                />
              </DialogFooter>
            </>
          );
        }}
      </Form>
    </Dialog>
  );

  function getInitialValues(): CancelAppointmentForm {
    const status = cancelStatuses.length === 1 ? cancelStatuses[0] : undefined;

    return {
      cancelReason: undefined,
      status,
      missedVisit: false,
      missedVisitServiceCode: undefined,
    };
  }

  function handleSubmitForm(form: FormContent<CancelAppointmentForm>) {
    return async () => {
      await form.submitForm();
    };
  }

  function statusRenderer(item: Coding) {
    const status = getAppointmentStatus(item, labels.Patient);
    return (
      <ListOption
        key={item.code}
        text={status ?? ""}
        onClick={e => e.stopPropagation()}
      />
    );
  }

  function handleSubmit(values: CancelAppointmentForm) {
    if (isEmpty(cancelStatuses) || !values.status) {
      return;
    }

    onCancelAppointment({
      ...appointment,
      status: values.status,
      cancelReason: values.cancelReason ? {
        text: values.cancelReason,
        codings: [],
      } : undefined,
      missedVisitServiceCode: values.missedVisitServiceCode,
    });
  }
}
