import styled from "styled-components";
import { Button } from "@remhealth/ui";

export const Container = styled.div`
  text-align: center;
`;

export const SourceButton = styled(Button)`
  width: 100px;
`;
