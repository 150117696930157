import React from "react";
import styled, { css } from "styled-components";
import { PaginationControls } from "./pagination.styles";

export const ContainerBody = styled.div`
  overflow-y: auto;
  max-height: 100%;
  flex-grow: 1;

  > .bp5-button {
    font-size: var(--font-size-normal) !important;
  }
`;

export interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  $fill: boolean;
  $allowWrap: boolean;
  $stickyHeader: boolean;
  $transparent: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  overflow-y: auto;
  background: ${props => props.$transparent ? "transparent" : props.theme.background.default};

  ${props => props.$fill && css`
    width: 100%;
    height: 100%;

    table.bp5-html-table {
      width: 100%;
    }
  `};

  ${props => !props.$allowWrap && css`
    th, td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `};

  ${PaginationControls} {
    padding: 10px;
    flex-grow: 0;
  }

  ${props => props.$stickyHeader && css`
    display: flex;
  `};

   table.bp5-html-table {
    font-size: var(--font-size-normal);
  }

   .bp5-button {
    font-size: var(--font-size-normal) !important;
  }
`;

export const SpinnerContainer = styled.div`
  margin: 15px 0;
`;

export const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ScrollArea = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
