import { css } from "styled-components";
import { BaseEmbedBlot } from "./baseEmbedBlot";

export interface PlaceholderBlotData {
  name: string;
  display: string;
  locked: boolean;
}

export class PlaceholderBlot extends BaseEmbedBlot {
  public static readonly blotName = "placeholder";
  public static readonly tagName = "rh-placeholder";
  public declare domNode: HTMLElement;

  public static create(data: PlaceholderBlotData) {
    const node = document.createElement(this.tagName);
    PlaceholderBlot.init(node, data);
    return node;
  }

  public static value(node: HTMLElement): PlaceholderBlotData {
    return {
      name: node.getAttribute("name")!,
      display: node.firstElementChild ? node.firstElementChild.textContent ?? "" : node.textContent ?? "",
      locked: node.hasAttribute("locked"),
    };
  }

  public static init(node: HTMLElement, data: PlaceholderBlotData): void {
    node.setAttribute("name", data.name);
    node.toggleAttribute("locked", data.locked);
    node.textContent = data.display;
  }

  public deleteAt(index: number, length: number) {
    if (this.domNode.hasAttribute("locked")) {
      return false;
    }

    return super.deleteAt(index, length);
  }

  public format(name: string, value: PlaceholderBlotData) {
    if (name === PlaceholderBlot.blotName && value) {
      PlaceholderBlot.init(this.domNode, value);
    } else {
      super.format(name, value);
    }
  }
}

export function isPlaceholderBlot(blot: any): blot is PlaceholderBlot {
  return blot && blot instanceof PlaceholderBlot;
}

export const PlaceholderCss = css`
  rh-placeholder {
    padding: 0 1px 1px 1px;
    border-radius: 3px;
    color: ${props => props.theme.dark ? "var(--yellow5)" : "var(--themecolor2)"};
    background-color: rgba(242, 199, 68, 0.4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;

    > span {
      margin: 0;
    }
  }
`;
