import { useState } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { EyeOff, EyeOpen } from "@remhealth/icons";
import type { DataAttributes } from "~/types";
import { FormField, getIntent } from "../utils";
import { useAutomation, useDebouncer, useUpdateEffect } from "../hooks";
import { Icon, IconName, IconProps } from "./icon";
import { IconButton } from "./button";
import { CustomInput } from "./inputGroup.styles";

type HTMLInputProps = Omit<Core.HTMLInputProps, "defaultValue" | "type" | "value" | "onChange">;

export type InputGroupType =
  /** Search field */
  | "search"
  /** Email field */
  | "email"
  /** Telephone field */
  | "tel"
  /** Url field */
  | "url"
  /** Password field (allows password managers) */
  | "password"
  /** Visually appears as password field, but doesn't allow password managers */
  | "masked"
  /** Gives the input a button behavior.  Used for internal purposes. */
  | "button"
  /** Default input type. */
  | "text"
  /** Default file type. */
  | "file";

export interface InputGroupProps extends HTMLInputProps, DataAttributes, Omit<Core.InputGroupProps, "type" | "leftIcon" | "onChange"> {
  /**
   * If true, defaults the `rightElement` to a clear button that will clear the selection.
   * Ignored if `rightElement` is provided.  Defaults to `true` if type="search".
   * @default false
   */
  clearable?: boolean;

  /**
   * Collapses the input and expands on focus.
   * @default false
   */
  expandable?: boolean;

  /**
   * Gives the input a minimal style.
   * @default false
   */
  minimal?: boolean;

  /**
   * Gives the input a soft border-less style.
   * @default false
   */
  soft?: boolean;

  leftIcon?: IconName | React.ReactElement<IconProps>;

  field?: FormField<string | undefined>;

  /** @default "text" */
  type?: InputGroupType;

  onChange?: (value: string) => void;
  onUnbufferedChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputGroup = (props: InputGroupProps) => {
  const {
    clearable,
    field,
    expandable,
    name = field?.name,
    intent = getIntent(field),
    readOnly = field?.readOnly,
    disabled = field?.disabled,
    defaultValue, // We always control the value
    rightElement: controlledRightElement,
    round: controlledRound,
    soft,
    type,
    tabIndex,
    value: controlledValue,
    autoComplete = "off",
    inputRef,
    onChange,
    onBlur,
    onUnbufferedChange,
    ...restProps
  } = props;

  const buttonVariant = type === "button";
  const changeDebouncer = useDebouncer(100);

  const [uncontrolledValue, setUncontrolledValue] = useState<string>(defaultValue ?? "");
  const [bufferValue, setBufferValue] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const value = bufferValue !== undefined
    ? bufferValue
    : controlledValue !== undefined
      ? controlledValue
      : field ? field.value ?? "" : uncontrolledValue;

  useUpdateEffect(() => {
    if (controlledValue) {
      field?.onChange(controlledValue);
      setUncontrolledValue(controlledValue);
    }
  }, [controlledValue]);

  const { label, id, errorId } = useAutomation(props);

  const { className, ...inputProps } = toNativeProps({ ...restProps, soft, type });

  const round = type === "search" || soft ? controlledRound !== false : controlledRound === true;

  let rightElement = controlledRightElement;
  const hasClearButton = type === "search" ? clearable !== false : clearable === true;

  if (hasClearButton) {
    rightElement = (
      <>
        {controlledRightElement}
        {value && (
          <IconButton
            minimal
            aria-label="Clear"
            disabled={disabled}
            icon="cross"
            square={!round}
            onClick={handleClear}
          />
        )}
      </>
    );
  }

  const masked = type === "password" || type === "masked";

  if (masked && !rightElement) {
    rightElement = (
      <IconButton
        minimal
        aria-label={showPassword ? "Hide Password" : "Show Password"}
        icon={showPassword ? <EyeOff /> : <EyeOpen />}
        square={!round}
        onClick={handlePassword}
      />
    );
  }

  const nativeType = type === "search" || type === "button" || type === "masked" || (masked && showPassword)
    ? "text"
    : type;

  return (
    <CustomInput
      {...inputProps}
      aria-errormessage={errorId}
      aria-invalid={field?.error ? true : inputProps["aria-invalid"]}
      aria-label={label}
      autoComplete={autoComplete}
      className={classnames(className, { button: buttonVariant, masked: masked && !showPassword, expandable })}
      data-errormessage={field?.error ? field.errorText : undefined}
      disabled={disabled}
      id={buttonVariant ? undefined : id}
      inputRef={handleInputRef}
      intent={intent}
      name={name}
      readOnly={readOnly || buttonVariant}
      rightElement={rightElement}
      round={round}
      tabIndex={tabIndex !== undefined ? tabIndex : buttonVariant ? -1 : undefined}
      type={nativeType}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );

  function handleInputRef(input: HTMLInputElement | null) {
    // Hack to get access to the wrapper element and add a tabIndex=-1
    // to enable keyboard up/down for SelectInput
    if (input?.parentElement && buttonVariant) {
      input.parentElement.tabIndex = 0;
      input.parentElement.ariaLabel = label ?? null;
      input.parentElement.role = "button";
      input.parentElement.id = id ?? "";
    }

    Core.setRef(inputRef, input);
  }

  function change(value: string, event?: React.ChangeEvent<HTMLInputElement>) {
    setUncontrolledValue(value);
    setBufferValue(value);

    if (event) {
      onUnbufferedChange?.(event);
    }

    changeDebouncer.delay(() => fireBufferedChange(value));
  }

  function handleClear(): void {
    change("");
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    change(event.currentTarget.value, event);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.(event);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }
  }

  function fireBufferedChange(value: string) {
    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(value);
      field?.onTouched();
    }

    onChange?.(value);
    setBufferValue(undefined);
  }

  function handlePassword() {
    setShowPassword(prev => !prev);
  }
};

export function toNativeProps(props: InputGroupProps): Core.InputGroupProps {
  const { minimal, className, leftIcon, soft, type, onChange, ...restProps } = props;

  const leftIconEl: Core.MaybeElement | undefined = leftIcon
    ? <Icon icon={leftIcon} />
    : !props.leftElement && type === "search" ? <Icon icon="search" /> : undefined;

  return {
    ...restProps,
    leftIcon: leftIconEl,
    type,
    className: classnames({ [Core.Classes.MINIMAL]: minimal, soft }, className),
  };
}
