import { CodeableConcept, Coding, type DateRange, Instant, LocalDate, type Months, ReferenceFilter } from "@remhealth/apollo";
import { isValidDate, parseDate } from "@remhealth/ui";

interface ModifyItem {
  meta?: {
    lastModified?: Instant;
  };
}

export function lastModifiedAscending(left: ModifyItem, right: ModifyItem) {
  const leftModified = Instant.toDate(left.meta?.lastModified) ?? new Date();
  const rightModified = Instant.toDate(right.meta?.lastModified) ?? new Date();
  return leftModified.valueOf() - rightModified.valueOf();
}

export function lastModifiedDescending(left: ModifyItem, right: ModifyItem) {
  return lastModifiedAscending(right, left);
}

export function hasCoding(concept: CodeableConcept, coding: Coding): boolean {
  return concept.codings.some(c => {
    return c.code === coding.code && c.system === coding.system;
  });
}

export function matchesReferenceFilter(ids: Set<string>, filter: ReferenceFilter): boolean {
  if (filter.all && filter.all.length > 0) {
    if (!filter.all.every(id => ids.has(id))) {
      return false;
    }
  }

  if (filter.in && filter.in.length > 0) {
    if (!filter.in.some(id => ids.has(id))) {
      return false;
    }
  }

  // No criteria means filter passes
  return true;
}

export interface ParsedDateQuery {
  query?: string;
  date?: DateRange;
}

export function parseDateQuery(query: string): ParsedDateQuery {
  const result: ParsedDateQuery = {};

  const parts = query.split(" ").filter(p => !!p.trim());

  const yearMatch = parts.findIndex(p => /^(19|20)\d{2}$/.test(p));
  if (yearMatch !== -1) {
    const matchedYear = Number.parseInt(parts[yearMatch], 10);
    result.date = { start: LocalDate.create(matchedYear, 1, 1), end: LocalDate.create(matchedYear, 12, 31) };
    parts.splice(yearMatch, 1);
  }

  const monthYearMatch = parts.findIndex(p => /^(0?([1-9])|(1([0-2])))[/-](19|20)\d{2}$/.test(p));
  if (monthYearMatch !== -1) {
    const matchedMonthYear = parts[monthYearMatch];
    const monthYearParts = matchedMonthYear.split(/[/-]/);
    const month = Number.parseInt(monthYearParts[0], 10) as Months;
    const year = Number.parseInt(monthYearParts[1], 10) as number;
    const monthStart = LocalDate.create(year, month, 1);
    const monthEnd = LocalDate.fromDateTime(LocalDate.toDateTime(monthStart).endOf("month"));
    result.date = { start: LocalDate.create(year, month, 1), end: monthEnd };
    parts.splice(monthYearMatch, 1);
  }

  const dateMatches = parts.map(p => parseDate(p));
  const dateMatch = dateMatches.findIndex(d => d && isValidDate(d));
  if (dateMatch !== -1) {
    const matchedDate = dateMatches[dateMatch] as Date;
    result.date = { start: LocalDate.fromDate(matchedDate), end: LocalDate.fromDate(matchedDate) };
    parts.splice(dateMatch, 1);
  }

  query = parts.join(" ");

  if (query) {
    result.query = query;
  }

  return result;
}
