import styled, { css } from "styled-components";
import { AvatarColors } from "./avatarColors";

export interface ContentProps {
  $colors: AvatarColors;
}

export const Content = styled.div.attrs<ContentProps>(props => ({
  style: {
    backgroundColor: props.$colors.backgroundColor,
  },
}))`
  position: relative;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border-radius: 50%;
  user-select: none;
  color: ${props => !props.$colors.isDarkColor ? "var(--dark-gray3)" : "var(--white)"};

  &:has(img) {
    background-color: unset;
  }

  .bp5-menu-item.bp5-disabled && {
    opacity: 0.5;
  }

  .initials {
    line-height: 1.25em;
    z-index: 1;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface AvatarContainerProps {
  $interactive: boolean;
  $size: number;
  $loading: boolean;
  $active: boolean;
  $colors: AvatarColors;
}

export const AvatarContainer = styled.div.attrs<AvatarContainerProps>(props => ({
  style: {
    background: `linear-gradient(-45deg, ${props.$colors.outlineGradient[0]}, ${props.$colors.outlineGradient[1]})`,
  },
}))`
  position: relative;
  display: inline-flex;
  position: relative;
  flex: 0 0 auto;
  z-index: 0;
  border-radius: 50%;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  padding: 4px;
  outline-offset: -4px;
  outline: 2px solid ${props => props.theme.dark ? "var(--dark-gray1)" : "var(--white)"};;

  .minor-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0;
    color: var(--red3) !important;
    display: inline-flex;
    pointer-events: none;
    margin-right: -3px;
    margin-bottom: -3px;
  }

  .bp5-spinner {
    position: absolute;
    top: 4px;
    left: 4px;

    svg {
      stroke-width: 5;
    }
  }

  ${ImageContainer} {
    max-width: 100%;
    max-height: 100%;
    opacity: ${props => props.$loading ? 0.5 : 1};
  }

  ${Content} {
    width: 100%;
    width: 100%;
    font-size: ${props => evenNumber((props.$size * 0.5) - 4)}px;
    opacity: ${props => props.$loading ? 0.5 : 1};

    + .bp5-spinner .bp5-spinner-head {
      stroke: white;
    }
  }

  ${props => props.$interactive && css`
    cursor: pointer;

    ${Content} {
      &:after {
        content: "";
        position: absolute;
        inset: 0px;
        border-radius: inherit;
        pointer-events: none;
      }
    }

    &:hover {
      ${Content} {
        &:after {
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    &:active {
      ${Content} {
        &:after {
          background-color: rgba(0, 0, 0, 0.35);
        }
      }
    }

    ${props.$active && css`
      &:not(:hover), &:hover {
        ${Content} {
          &:after {
            background-color: rgba(0, 0, 0, 0.35);
          }
        }
      }
    `};
  `};
`;

function evenNumber(value: number): number {
  return 2 * Math.round(value / 2);
}
