import styled from "styled-components";
import { Markup } from "@remhealth/core";

export const MarkupContainer = styled(Markup)`
  ol {
    margin: 0;
  }
`;

export const EmptyMessageContainer = styled.div`
  padding-left: 8px;
`;
