import styled from "styled-components";
import { Popover } from "./popover";
import { Menu } from "./menu";

export const Container = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const ContentHeader = styled.div`
  padding: 5px;
`;

export const Content = styled(Menu)`
  && {
    max-height: 200px;
    overflow-y: auto;
    padding: 0 8px 5px 8px;
  }
`;

export const FilterPopover = styled(Popover)`
  max-width: 100%;

  th > && {
    margin: -7px 0 -7px 5px;
  }
`;

export const ItemWrapper = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-Items: center;
  padding: 2px 0px 2px 5px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  border-top: 1px solid ${props => props.theme.border.muted};

  .filter {
    margin-left: auto;
  }
`;

export const EmptyContainer = styled.div`
  padding: 5px 10px 10px 10px;
`;
