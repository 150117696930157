import { Coding, type EvidenceBasedPracticeCoding, Note, NoteSectionFormat } from "@remhealth/apollo";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";

export interface EvidenceBasedPracticesSectionContentProps {
  note: Note;
}

export function EvidenceBasedPracticesSectionContent(props: EvidenceBasedPracticesSectionContentProps) {
  const { note } = props;

  const section = note.sections.find(section => section.format === NoteSectionFormat.EvidenceBasedPractices);
  const name = section?.name;

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {renderEvidenceBasedPracticesCodings()}
    </Container>
  );

  function renderEvidenceBasedPracticesCodings() {
    if (note.evidenceBasedPracticesCodings.length === 0) {
      return <NoData>{Text.NoData}</NoData>;
    }

    const serviceCode = note.evidenceBasedPracticesCodings.at(0);

    if (serviceCode?.elements) {
      const elementList = <ul>{serviceCode.elements.map((c, i) => <li key={i}>{getCodingText(c)}</li>)}</ul>;

      if (!serviceCode.code) {
        return elementList;
      }

      return (
        <div>
          <div>{getCodingText(note.evidenceBasedPracticesCodings[0])}</div>
          {elementList}
        </div>
      );
    }

    return <ul>{note.evidenceBasedPracticesCodings.map(c => <li key={c.code}>{getCodingText(c)}</li>)}</ul>;
  }
}

function getCodingText(coding: Coding | EvidenceBasedPracticeCoding) {
  return [coding.code, coding.display].filter(c => !!c?.trim()).join(" - ");
}
