import { type Ref, forwardRef, useLayoutEffect, useRef } from "react";
import { Classes, mergeRefs } from "@blueprintjs/core";
import classnames from "classnames";
import { BaseIconProps, IconDisplayName, IconSize } from "~/shared";
import { intentClass } from "./misc";
import { injectGradient } from "./svgGradient";

type SvgModule = { default: string };
type DynamicSvgModule = () => Promise<SvgModule>;

export interface SvgIconProps extends BaseIconProps {
  onLoad?: (svg: SVGSVGElement) => void;
}

interface DynamicSvgIconProps extends SvgIconProps {
  small: DynamicSvgModule;
  large: DynamicSvgModule;
}

export const SvgIcon = forwardRef((props: DynamicSvgIconProps, ref: Ref<HTMLElement>) => {
  const { className, size = IconSize.STANDARD, small, large, color, intent = "none", onLoad, ...restProps } = props;

  const svg = useRef<SVGSVGElement>();
  const anchor = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    load();
    return unload;
  }, [className, size, small, large, color]);

  return (
    <span
      ref={mergeRefs(anchor, ref)}
      aria-hidden
      className={classnames(Classes.ICON, className, intentClass(intent), { colored: !!color || intent !== "none" })}
      {...restProps}
    />
  );

  function unload() {
    if (svg.current?.parentElement) {
      svg.current.remove();
      svg.current = undefined;
    }
  }

  async function load() {
    if (!anchor.current?.parentElement) {
      return;
    }

    const { default: svgUrlOrData } = size >= IconSize.LARGE ? await large() : await small();
    const data = await loadSvg(svgUrlOrData);

    const container = document.createElement("div");
    container.innerHTML = data;

    const svgElement = container.querySelector("svg");

    if (!svgElement) {
      return;
    }

    if (color) {
      if (typeof color !== "string") {
        injectGradient(svgElement, color);
      } else {
        svgElement.setAttribute("fill", color);
      }

      svgElement.classList.add("colored");
    }

    svgElement.setAttribute("width", String(size));
    svgElement.setAttribute("height", String(size));

    if (anchor.current) {
      anchor.current.append(svgElement);
      svg.current = svgElement;
      onLoad?.(svgElement);
    }
  }
});

SvgIcon.displayName = "SvgIcon";

export function createSvgIcon(large: DynamicSvgModule, small?: DynamicSvgModule) {
  const icon = forwardRef((props: SvgIconProps, ref: Ref<HTMLElement>) => {
    return <SvgIcon ref={ref} large={large} small={small ?? large} {...props} />;
  });
  icon.displayName = IconDisplayName;

  return icon;
}

const dataSvgPrefix = "data:image/svg+xml,";
const dataBase64Prefix = "data:image/svg+xml;base64,";

function decodeBase64(data: string): string {
  const base64 = data.replace(/-/g, "+").replace(/_/g, "/");
  return decodeURIComponent(window.atob(base64).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));
}

function getInlinedSvgData(src: string) {
  // Source is "data:image/svg+xml,%3c?xml%20version..."
  if (src.startsWith(dataSvgPrefix)) {
    return decodeURIComponent(src.slice(dataSvgPrefix.length));
  }

  // Source is "data:image/svg+xml;base64,XYZZZZ..."
  if (src.startsWith(dataBase64Prefix)) {
    return decodeBase64(src.slice(dataBase64Prefix.length));
  }

  return null;
}

const loadedSvgs = new Map<string, string>();
const loadingSvgs = new Map<string, Promise<string>>();
async function loadSvg(svgUrlOrData: string) {
  const cached = loadedSvgs.get(svgUrlOrData);
  if (cached) {
    return cached;
  }

  const svgData = getInlinedSvgData(svgUrlOrData);
  if (svgData) {
    loadedSvgs.set(svgUrlOrData, svgData);
    return svgData;
  }

  // If not inlined, then it must be a url
  let loading = loadingSvgs.get(svgUrlOrData);
  if (!loading) {
    loading = fetchSvg(svgUrlOrData);
    loadingSvgs.set(svgUrlOrData, loading);
  }

  return await loading;
}

async function fetchSvg(svgUrl: string) {
  const response = await fetch(svgUrl);
  const data = await response.text();
  loadedSvgs.set(svgUrl, data);
  loadingSvgs.delete(svgUrl);
  return data;
}
