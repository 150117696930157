import styled from "styled-components";
import { Label } from "@remhealth/ui";
import { getBellsAvatar } from "@remhealth/core";
import { Text, isAndroid, isIOS, isWindows } from "@remhealth/ai";
import AppleStoreBadge from "./apple-store-badge.svg";
import GoogleStoreBadge from "./google-play-badge.png";

declare const appStoreUrl: string;
declare const playStoreUrl: string;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 100px;
  p {
    text-align: left;
    line-height: 1.36;
    letter-spacing: 0.19px;
  }
  .container {
    display: flex;
    flex: 1;
    width: calc(100vw / var(--zoom));
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(127deg, #d7d7d7, #f7f7f7 102%);
  }
`;

const ContentWrapper = styled.div`
  width: 250px;
  margin-bottom: 25px;
`;

const ContentLabel = styled(Label)`
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: center;
  font-size: var(--font-size-header3);
`;

const StoreImage = styled.img`
  padding: 0px 10px 10px;
`;

const BellsImage = styled.img`
  height: 250px;
`;

export const RestrictedMobileView = () => {
  const deviceSpecificStore = isAndroid ? "Play" : "App";
  return (
    <Container>
      <BellsImage alt="Bells" src={getBellsAvatar(undefined)} />
      <div className="container">
        <ContentWrapper>
          <ContentLabel>
            {isWindows
              ? <p>{Text.UnsupportedBrowser(true)}</p>
              : (
                <>
                  <p>{Text.UnsupportedBrowser(false)}</p>
                  <p>{Text.DownloadAppGuidance(deviceSpecificStore)}</p>
                </>
              )}
          </ContentLabel>
        </ContentWrapper>
        {isAndroid && <a href={playStoreUrl}><StoreImage src={GoogleStoreBadge} width="300px" /></a>}
        {isIOS && <a href={appStoreUrl}><StoreImage src={AppleStoreBadge} width="300px" /></a>}
      </div>
    </Container>
  );
};
