import { Dispatch, SetStateAction, useState } from "react";
import { useUpdateEffect } from "./useUpdateEffect";
import { useDebouncer } from "./useDebouncer";

/**
 * Like `useState`, but has a debouncer so that the setter will wait for a quiet period before
 * committing the new value.  This is useful against components that may make rapid state changes.
 * @param initialValue The initial value of the state.
 * @param debounceMs The quiet period in milliseconds to wait for before setting the new value.
 * @param onSet An optional handler on when the set value is changed after the debounce period is elapsed.
 * @returns Returns the value, a debounced set setter, and a non-debounced setter.
 */
export function useDebouncedState<T>(initialValue: T, debounceMs: number, onSet?: (newValue: T) => void): [value: T, setValue: Dispatch<SetStateAction<T>>, setActiveValue: Dispatch<SetStateAction<T>>] {
  const debouncer = useDebouncer(debounceMs);
  const [activeValue, setActiveValue] = useState(initialValue);

  useUpdateEffect(() => {
    onSet?.(activeValue);
  }, [activeValue]);

  return [activeValue, setValue, setActiveValue];

  function setValue(newValue: T | ((prevState: T) => T)) {
    debouncer.delay(() => setActiveValue(newValue));
  }
}
