import { Node } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import { type ElementNode, type TextNode, isElementNode, isTextNode } from "@remhealth/compose";

export interface HighlightParserOptions {
  highlights?: string[];
}

export function highlightify(this: Processor, config?: Partial<HighlightParserOptions>): Transformer {
  const ignoreList = new Set<TextNode>();

  return function transformer(tree: Node | ElementNode): Node {
    const { highlights = [] } = config ?? {};
    if (highlights.length === 0) {
      return tree;
    }

    visit(tree, isElementNode, element => {
      const children = element.children;
      if (!children) {
        return;
      }

      children.map((textNode, nodeIndex) => {
        if (!isTextNode(textNode) || ignoreList.has(textNode)) {
          return;
        }

        let text = textNode.value;

        highlights.forEach(highlight => {
          if (!text) {
            return;
          }

          let start = text.toLowerCase().indexOf(highlight.toLowerCase());
          if (start === -1) {
            return;
          }

          do {
            const end = start + highlight.length;
            const before = text.slice(0, start);
            const match = text.slice(start, end);
            text = text.slice(end);

            const marked: TextNode = { type: "text", value: match };
            ignoreList.add(marked);

            children.splice(
              nodeIndex,
              0,
              {
                type: "text",
                value: before,
              },
              {
                type: "element",
                tagName: "mark",
                children: [marked],
                properties: {},
              }
            );

            nodeIndex += 2;

            textNode.value = text;

            if (textNode.position) {
              textNode.position.start.column = end;
            }
          }
          while ((start = text.indexOf(highlight)) !== -1);
        });
      });
    });

    return tree;
  };
}
