import { useEffect, useState } from "react";
import { sortBy } from "lodash-es";
import { Refresh } from "@remhealth/icons";
import {
  Appointment,
  Duration,
  Encounter,
  Patient,
  Practitioner,
  Reference,
  ZonedDateTime
} from "@remhealth/apollo";
import {
  DateFormats,
  HslOptions,
  IconButton,
  Intent,
  Spinner,
  stringToHsl,
  useAbort
} from "@remhealth/ui";
import { usePersonalPreferences, useProductFlag } from "@remhealth/host";
import { PractitionerBanner, useAudit, useStore, useStoreItem } from "@remhealth/core";
import { PatientBanner } from "~/avatars/patientBanner";
import { StackedAvatar } from "~/avatars/stackedAvatar";
import { getAppointmentAttendees, getAppointmentTime, isGroupAppointment } from "./utils";
import { AppointmentGroupBanner } from "./appointmentGroupBanner";
import { AppointmentDetailFooter } from "./appointmentDetailFooter";
import {
  AppointmentDate,
  AppointmentDuration,
  AppointmentLocation,
  AppointmentTime,
  Container,
  Date,
  Details,
  Footer,
  Header,
  Participant,
  Recurrence,
  RecurrenceIcon,
  ServiceTypeName
} from "./appointmentDetail.styles";

type PatientAttendee = Patient | Reference<Patient>;

export const AppointmentHsl: HslOptions = {
  hue: [148, 42],
  saturation: [35, 80],
  lightness: [25, 65],
};

export interface AppointmentDetailProps {
  appointment: Appointment;
  associatedEncounter?: Encounter;
  location: string;
  onClose: () => void;
}

export const AppointmentDetail = (props: AppointmentDetailProps) => {
  const { associatedEncounter, location, onClose } = props;
  const audit = useAudit();
  const abort = useAbort();
  const preferences = usePersonalPreferences();
  const store = useStore();

  const invitedAttendeesOnly = useProductFlag("AppointmentInvitedAttendeesOnly");

  const [patients, setPatients] = useState<PatientAttendee[]>([]);
  const [staffs, setStaffs] = useState<Practitioner[]>([]);

  const appointment = useStoreItem(store.appointments, props.appointment);

  useEffect(() => {
    loadAppointmentDetails(appointment);
  }, [appointment, associatedEncounter?.id]);

  if (!appointment) {
    return (
      <Spinner intent={Intent.PRIMARY} size={60} />
    );
  }

  return (
    <Container $serviceColor={appointment.serviceType ? stringToHsl(appointment.serviceType?.id, AppointmentHsl) : undefined}>
      <Header><IconButton minimal aria-label="Close appointment details window" icon="cross" onClick={onClose} /></Header>
      <Details>
        {appointment.serviceType && <ServiceTypeName>{appointment.serviceType.display}</ServiceTypeName>}
        <AppointmentLocation>{getAppointmentLocation(appointment)}</AppointmentLocation>
        <AppointmentDate>
          <Date>
            {DateFormats.verbose(ZonedDateTime.toDate(appointment.start))}
          </Date>
          {appointment.schedule?.resource?.recurrence && (
            <Recurrence>
              <RecurrenceIcon icon={<Refresh />} size={10} />
            </Recurrence>
          )}
        </AppointmentDate>
        <AppointmentTime>
          {getAppointmentTime(appointment, preferences?.militaryTime)}
          <AppointmentDuration>{getAppointmentDuration(appointment)}</AppointmentDuration>
        </AppointmentTime>
        {staffs.length === 1 && <Participant><PractitionerBanner interactive practitioner={staffs[0]} /></Participant>}
        {staffs.length > 1 && <Participant><StackedAvatar people={staffs} scale="normal" /></Participant>}
        {isGroupAppointment(appointment, true) ? <Participant><AppointmentGroupBanner appointment={appointment} /></Participant> : renderPatient()}
        {patients.length > 1 && <Participant><StackedAvatar people={patients} scale="small" /></Participant>}
      </Details>
      <Footer>
        <AppointmentDetailFooter
          appointment={appointment}
          associatedEncounter={associatedEncounter}
          location={location}
        />
      </Footer>
    </Container>
  );

  async function loadAppointmentDetails(appointment: Appointment) {
    const [staffExpandables, patientExpandables] = getAppointmentAttendees(appointment, invitedAttendeesOnly);

    // Audit that the appointment was selected and the associated patients
    if (patientExpandables.length > 0) {
      audit.log("AppointmentSelected", { entities: patientExpandables });
    }

    const patients: PatientAttendee[] = await store.patients.expand(patientExpandables, { abort: abort.signal });
    const restrictedPatients = patientExpandables.filter(e => !patients.some(p => p.id === e.id)) as Reference<Patient>[];
    patients.push(...restrictedPatients);

    const staffs = await store.practitioners.expand(staffExpandables, { abort: abort.signal });

    setPatients(sortBy(patients, "display"));
    setStaffs(sortBy(staffs, "display"));
  }

  function renderPatient() {
    if (patients.length === 1) {
      return <Participant><PatientBanner interactive patient={patients[0]} scale="normal" /></Participant>;
    }
    return undefined;
  }
};

export function getAppointmentDuration(appointment: Appointment) {
  return Duration.humanize(appointment.duration);
}

export function getAppointmentLocation(appointment: Appointment) {
  let location = appointment.location.display;

  if (appointment.serviceLocation?.display) {
    location += ` (${appointment.serviceLocation?.display})`;
  }
  return location;
}
