import { Duration } from "luxon";

export enum DurationTimeUnit {
  HOUR = "hours",
  MINUTE = "minutes",
  SECOND = "seconds",
  MS = "milliseconds"
}

export const DurationTimeMagnitude = {
  HOUR: "hour",
  MINUTE: "minute",
} as const;
export type DurationTimeMagnitude = (typeof DurationTimeMagnitude)[keyof typeof DurationTimeMagnitude];

export const DurationTimePrecision = {
  MILLISECOND: "millisecond",
  MINUTE: "minute",
  SECOND: "second",
} as const;
export type DurationTimePrecision = (typeof DurationTimePrecision)[keyof typeof DurationTimePrecision];

export function areSameDuration(left: Duration | undefined, right: Duration | undefined) {
  if (left === undefined || right === undefined) {
    return left === right;
  }

  const leftObj = left.toObject();
  const rightObj = right.toObject();

  return leftObj.years === rightObj.years
    && leftObj.quarters === rightObj.quarters
    && leftObj.months === rightObj.months
    && leftObj.weeks === rightObj.weeks
    && leftObj.days === rightObj.days
    && leftObj.hours === rightObj.hours
    && leftObj.minutes === rightObj.minutes
    && leftObj.seconds === rightObj.seconds
    && leftObj.milliseconds === rightObj.milliseconds;
}

export function isEmptyDuration(duration: Duration): boolean {
  const obj = duration.toObject();
  return obj.years === undefined
    && obj.quarters === undefined
    && obj.months === undefined
    && obj.weeks === undefined
    && obj.days === undefined
    && obj.hours === undefined
    && obj.minutes === undefined
    && obj.seconds === undefined
    && obj.milliseconds === undefined;
}

export interface RelativeDurationFormatOptions {
  /** @default true */
  capitalized?: boolean;
}

/**
 * Outputs a duration as it is relative to now.
 * @example
 * "a moment ago"
 * "5m ago"
 * "1h ago"
 * "13 days ago"
 * "3 years ago"
 */
function relative(duration: Duration, options: RelativeDurationFormatOptions = {}) {
  const { capitalized = true } = options;

  const diff = duration.shiftTo("years", "months", "days", "hours", "minutes");

  const relativize = diff.as("minute") > 0
    ? (str: string) => `${capitalized ? "In" : "in"} ${str}`
    : (str: string) => `${str} ago`;

  if (Math.abs(diff.as("minutes")) <= 3) {
    return "a moment ago";
  }

  const years = Math.floor(Math.abs(diff.years));

  if (years === 1) {
    return relativize("a year");
  } else if (years > 0) {
    return relativize(`${years} years`);
  }

  const months = Math.floor(Math.abs(diff.months));

  if (months === 1) {
    return relativize("a month");
  } else if (months > 0) {
    return relativize(`${months} months`);
  }

  const days = Math.floor(Math.abs(diff.days));
  const hours = Math.floor(Math.abs(diff.hours));

  if (days === 1 || (days === 0 && hours > 18)) {
    return relativize("a day");
  } else if (days > 1) {
    return relativize(`${days} days`);
  }

  const minutes = Math.floor(Math.abs(diff.minutes));

  if (hours > 0) {
    if (minutes > 0) {
      return relativize(`${hours}h ${minutes}m`);
    }
    return relativize(hours === 1 ? "1 hr" : `${hours} hrs`);
  }

  if (minutes > 0) {
    return relativize(minutes === 1 ? "1 min" : `${minutes} mins`);
  }

  return "a moment ago";
}

/**
 * Outputs dates relative from now in HH:MM / HH:MM:SS format.
 * @example
 * "13:30"
 * "02:00"
 * "03:00:01"
 */
function time(duration: Duration, showSeconds: boolean = false) {
  const diff = duration.shiftTo("hours", "minutes", "seconds");
  const hours = Math.floor(Math.abs(diff.hours));
  const minutes = Math.floor(Math.abs(diff.minutes));
  const seconds = Math.floor(Math.abs(diff.seconds));
  return `${hours.toFixed(0).toString().padStart(2, "0")}:${minutes.toFixed(0).toString().padStart(2, "0")}${showSeconds ? ":" + seconds.toFixed(0).toString().padStart(2, "0") : ""}`;
}

export const DurationFormats = {
  relative,
  time,
};

export type DurationStringUnit = "ms" | "s" | "m" | "h" | "d";
export type DurationString = `${number}${DurationStringUnit}`;

export function durationToMs(value: DurationString): number {
  if (value.endsWith("ms")) {
    return Number.parseInt(value.slice(0, -2), 10);
  } else if (value.endsWith("s")) {
    return Number.parseInt(value.slice(0, -1), 10) * 1000;
  } else if (value.endsWith("m")) {
    return Number.parseInt(value.slice(0, -1), 10) * 60 * 1000;
  } else if (value.endsWith("h")) {
    return Number.parseInt(value.slice(0, -1), 10) * 60 * 60 * 1000;
  } else if (value.endsWith("d")) {
    return Number.parseInt(value.slice(0, -1), 10) * 24 * 60 * 60 * 1000;
  }
  return 0;
}
