import { css } from "styled-components";
import { BaseEmbedBlot } from "./baseEmbedBlot";

export interface ExpansionBlotData {
  name: string;
  display: string;
  reference?: string;
}

export class ExpansionBlot extends BaseEmbedBlot {
  public static readonly blotName = "expansion";
  public static readonly tagName = "rh-expansion";

  public static create(data: ExpansionBlotData) {
    const node = document.createElement(this.tagName);
    node.setAttribute("name", data.name);

    if (data.reference) {
      node.setAttribute("reference", data.reference);
    }

    node.textContent = data.display;
    return node;
  }

  public static value(node: HTMLElement): ExpansionBlotData {
    return {
      name: node.getAttribute("name") ?? "",
      display: node.firstElementChild
        ? node.firstElementChild.textContent ?? ""
        : node.textContent ?? "",
      reference: node.getAttribute("reference") || node.getAttribute("ref") || undefined,
    };
  }
}

export const ExpansionCss = css`
  rh-expansion {
    color: ${props => props.theme.dark ? "var(--gray5)" : "var(--dark-gray2)"};
    background-color: ${props => props.theme.dark ? "var(--gray1)" : "var(--light-gray3)"};
    border-radius: 3px;
    > span {
      margin: 0px 3px;
    }
  }
`;
