import { useRef, useState } from "react";
import { HealthcareService, LocalDate } from "@remhealth/apollo";
import { isDateRangeEffective, resourceEquals } from "@remhealth/host";
import { Labeling, useLabeling } from "@remhealth/core";
import {
  AsyncSuggest,
  DefaultMoreResultsAvailable,
  DefaultSuggestInitialContent,
  FormField,
  FormGroup,
  FormScope,
  ItemAsyncListRendererProps,
  Menu,
  MenuDivider,
  Tooltip,
  useCallbackRef,
  useUpdateEffect
} from "@remhealth/ui";

import { Text } from "~/text";
import { ServiceTypeDialog } from "~/serviceTypes/serviceTypeDialog";
import { EhrServiceTypeDialog } from "~/serviceTypes/ehrServiceTypeDialog";
import { type ServiceTypeQueryOptions, useServiceTypeQuery } from "~/forms/useServiceTypeQuery";
import { ViewAllButton } from "./common.styles";

export interface ServiceTypeSelectProps {
  allowEhr?: boolean;
  clearable?: boolean;
  large?: boolean;
  resetOnSelect?: boolean;
  disabled?: boolean;
  selectedItem?: HealthcareService | null;
  name?: string;
  field?: FormField<HealthcareService | undefined>;
  showLabel?: boolean;
  queryOptions?: ServiceTypeQueryOptions;
  onChange?: (serviceType: HealthcareService | null) => void;
  renderOption?: (serviceType: HealthcareService) => string | JSX.Element;
}

export const ServiceTypeSelect = (props: ServiceTypeSelectProps) => {
  const {
    queryOptions,
    selectedItem,
    clearable,
    large,
    resetOnSelect,
    name,
    field,
    showLabel = true,
    onChange,
    renderOption,
  } = props;

  // Hooks
  const labels = useLabeling();
  const serviceTypeSelect = useRef<AsyncSuggest>(null);

  const { allowEhr, queryable, ehrLoad, getMissingDependency } = useServiceTypeQuery(queryOptions);

  // State
  const [showDialog, setShowDialog] = useState(false);

  const clearSelectionCallback = useCallbackRef(clearSelectionIfNeeded);

  const missingDependency = getMissingDependency();
  const disabled = props.disabled || !!missingDependency;

  useUpdateEffect(() => {
    clearSelectionCallback();
    resetSuggestions();
  }, [queryOptions?.locationRole, queryOptions?.visitDate]);

  return (
    <FormScope label={labels.ServiceType}>
      {showLabel ? (
        <FormGroup
          field={field}
          label={labels.ServiceType}
          labelInfo={(
            <Tooltip content={missingDependency ?? ""} disabled={!missingDependency} targetTagName="span">
              <ViewAllButton
                minimal
                small
                disabled={disabled}
                intent="primary"
                label="View All"
                onClick={handleViewAll}
              />
            </Tooltip>
          )}
        >
          {renderSelect()}
        </FormGroup>
      ) : renderSelect()}
      {renderDialog()}
    </FormScope>
  );

  function renderSelect() {
    return (
      <Tooltip content={missingDependency ?? ""} disabled={!missingDependency}>
        <AsyncSuggest<HealthcareService>
          ref={serviceTypeSelect}
          fill
          clearable={clearable}
          disabled={disabled}
          fetchOnBlankQuery={allowEhr}
          field={field}
          infoRenderer={i => i.aliases[0]}
          itemListRenderer={i => renderHealthcareServiceList(i, labels)}
          itemsEqual={resourceEquals}
          large={large}
          leftIcon="search"
          name={name}
          optionRenderer={i => renderOption ? renderOption(i) : i.display}
          queryable={queryable}
          resetOnSelect={resetOnSelect}
          selectedItem={selectedItem}
          onSelectedItemChange={onChange}
        />
      </Tooltip>
    );
  }

  function clearSelectionIfNeeded() {
    if (field && clearable) {
      field.setError(undefined);
      field.setTouched(false);

      if (field.value) {
        // Don't auto-clear if we defaulted the value from an appointment
        if (allowEhr && field.initialValue !== undefined && field.value?.id === field.initialValue.id) {
          field.onChange(undefined);
        } else if (queryOptions?.locationRole && field.value.allowedLocations.length > 0 && !field.value.allowedLocations.includes(queryOptions.locationRole)) {
          field.onChange(undefined);
        } else if (!isDateRangeEffective(field.value.effective, LocalDate.toDate(queryOptions?.visitDate))) {
          field.onChange(undefined);
        }
      }
    }
  }

  function resetSuggestions() {
    serviceTypeSelect.current?.resetQuery();
  }

  function renderDialog() {
    if (!showDialog || disabled) {
      return null;
    }

    if (allowEhr && !queryOptions?.missedVisitsOnly) {
      return (
        <EhrServiceTypeDialog
          isOpen
          onClose={handleCloseDialog}
          onLoad={ehrLoad}
          onSelect={handleSelect}
        />
      );
    }

    return (
      <ServiceTypeDialog
        isOpen
        excludedServiceTypeIds={queryOptions?.excludedServiceTypeIds}
        limitToServiceTypeIds={queryOptions?.limitToServiceTypeIds}
        locationRole={queryOptions?.locationRole}
        missedVisitsOnly={queryOptions?.missedVisitsOnly}
        visitDate={queryOptions?.visitDate}
        onClose={handleCloseDialog}
        onSelect={handleSelect}
      />
    );
  }

  function handleViewAll() {
    setShowDialog(true);
  }

  function handleCloseDialog() {
    setShowDialog(false);
  }

  function handleSelect(serviceType: HealthcareService) {
    if (field) {
      field.onChange(serviceType);
      field.onTouched();
    }

    handleCloseDialog();
    onChange?.(serviceType);
  }
};

function renderHealthcareServiceList(listProps: ItemAsyncListRendererProps<HealthcareService>, labels: Labeling) {
  if (listProps.items.length === 0 && listProps.filteredItems.length === 0 && !listProps.query) {
    return (
      <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        {DefaultSuggestInitialContent}
      </Menu>
    );
  }

  return (
    <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
      {!listProps.query && listProps.items.length > 0 && (
        <MenuDivider title={Text.RecentServiceTypes(labels)} />
      )}
      {listProps.renderItems()}
      {listProps.query && listProps.items.length > 0 && listProps.hasMore && DefaultMoreResultsAvailable}
      {!listProps.query && DefaultSuggestInitialContent}
    </Menu>
  );
}
