import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { ItemsEqualProp } from "@blueprintjs/select";
import { useStateRef } from "~/hooks";
import { AsyncQuery, DefaultMoreResultsAvailable, DefaultNoResults, DefaultSuggestInitialContent } from "../utils";
import { MultiSelect, MultiSelectListRendererProps, MultiSelectProps, MultiSelectTagInputProps } from "./multiSelect";
import { Menu } from "./menu";

export interface ItemAsyncMultiSelectListRendererProps<T> extends MultiSelectListRendererProps<T> {
  hasMore: boolean;
}

export type MultiSelectListRenderer<T> = (itemListProps: ItemAsyncMultiSelectListRendererProps<T>) => JSX.Element | null;

export type AsyncMultiSelectProps<T> = Omit<MultiSelectProps<T>, "itemListRenderer" | "itemListPredicate" | "itemPredicate" | "items"> & {
  /** A promise that will return new items to use when given a query. */
  queryable: AsyncQuery<T>;

  /**
   * Delay in milliseconds before changes to the query invoke the `queryable`.
   * @default 300
   */
  queryInvokeDelay?: number;

  /**
   * If true, the `queryable` will be invoked even if the query is empty.
   * @default false
   */
  fetchOnBlankQuery?: boolean;

  /**
   * Specifies how to test if two items are equal. By default, simple strict
   * equality (`===`) is used to compare two items.
   *
   * If your items have a unique identifier field, simply provide the name of
   * a property on the item that can be compared with strict equality to
   * determine equivalence: `itemsEqual="id"` will check `a.id === b.id`.
   *
   * If more complex comparison logic is required, provide an equality
   * comparator function that returns `true` if the two items are equal. The
   * arguments to this function will never be `null` or `undefined`, as those
   * values are handled before calling the function.
   */
  itemsEqual: ItemsEqualProp<T>;

  itemListRenderer?: MultiSelectListRenderer<T>;
};

const defaultQueryDelay = 300;

type CancelFunction = () => void;

export function AsyncMultiSelect<T>(props: AsyncMultiSelectProps<T>) {
  const [suggestions, setSuggestions] = useState<T[] | null>(null);
  const [disablePopover, setDisablePopover] = useState(false);
  const [stateQuery, setStateQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const activeQueryable = useStateRef<AbortController | null>(null);
  const cancelSetQuery = useRef<CancelFunction | null>(null);

  // Unmount
  useEffect(() => {
    return () => cancelActiveQueries();
  }, []);

  const {
    disabled,
    fetchOnBlankQuery,
    initialContent = fetchOnBlankQuery ? null : DefaultSuggestInitialContent,
    popoverProps: controlledPopoverProps,
    tagInputProps: controlledTagInputProps,
    itemListRenderer = defaultItemListRenderer,
    noResults = DefaultNoResults,
    queryable,
    field,
    query = stateQuery,
    rightElement: controlledRightElement,
    resetOnBlur = true,
    resetOnSelect = false,
    queryInvokeDelay = defaultQueryDelay,
    onQueryChange,
    ...restProps
  } = props;

  const { inputProps = {} } = controlledTagInputProps ?? {};

  const rightElement = activeQueryable.current
    ? <Spinner intent={controlledTagInputProps?.intent} size={16} />
    : controlledRightElement;

  const popoverProps = {
    ...controlledPopoverProps,
    onClosing: handlePopoverClosing,
  };

  if (disablePopover) {
    popoverProps.isOpen = false;
  }

  const tagInputProps: MultiSelectTagInputProps<T> = {
    ...controlledTagInputProps,
    values: controlledTagInputProps?.values ?? [],
    inputProps: {
      ...inputProps,
      "aria-busy": !!activeQueryable.current,
      "onFocus": handleInputFocus,
    },
  };

  return (
    <MultiSelect<T>
      {...restProps}
      disabled={disabled}
      field={field}
      initialContent={initialContent}
      itemListRenderer={customItemListRenderer}
      itemPredicate={_ => true} // Trust queryable results instead
      items={suggestions ?? []}
      popoverProps={popoverProps}
      query={query}
      resetOnBlur={false}
      resetOnSelect={resetOnSelect}
      rightElement={rightElement}
      tagInputProps={tagInputProps}
      onQueryChange={handleQueryChange}
    />
  );

  async function invokeSetQuery(query: string): Promise<void> {
    cancelActiveQueries();

    const abort = new AbortController();

    activeQueryable.set(abort);
    setSuggestions(null);

    try {
      const response = await queryable(query, abort.signal);
      const suggestions = response.items;

      setSuggestions([...suggestions]);
      setHasMore(response.hasMore);
    } finally {
      setDisablePopover(!!cancelSetQuery.current);
      activeQueryable.set(null);
    }
  }

  function cancelActiveQueries() {
    // Cancel any query delays (keyboard debounce)
    cancelSetQuery.current?.();
    cancelSetQuery.current = null;
    activeQueryable.current?.abort();
    activeQueryable.set(null);
  }

  function handleInputFocus(event: React.FocusEvent<HTMLInputElement>) {
    inputProps.onFocus?.(event);

    if (fetchOnBlankQuery && suggestions === null && !query && !activeQueryable.current && !cancelSetQuery.current) {
      setDisablePopover(true);
      invokeSetQuery(query);
    }
  }

  function handleQueryChange(query: string, event?: React.ChangeEvent<HTMLInputElement>) {
    // Skip if query didn't really change
    if (stateQuery === query) {
      return;
    }

    // Always record the new query, even if we don't immediately act upon it
    setStateQuery(query);
    setDisablePopover(true);
    setSuggestions(null);

    onQueryChange?.(query, event);

    // Cancel any current requests
    cancelActiveQueries();

    if (query || fetchOnBlankQuery) {
      // Debounce in case the user is typing
      debounceSetQuery(query);
    } else {
      // When query is empty, we don't perform any search
      setSuggestions(null);
      setDisablePopover(false);
    }
  }

  function handlePopoverClosing(node: HTMLElement) {
    controlledPopoverProps?.onClosing?.(node);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }

    if (resetOnBlur && !disablePopover && !activeQueryable.current) {
      handleQueryChange("");
    }
  }

  function defaultItemListRenderer(listProps: ItemAsyncMultiSelectListRendererProps<T>) {
    const createItem = listProps.renderCreateItem();
    const maybeNoResults = !createItem && listProps.filteredItems.length === 0 ? noResults : null;
    const moreResults = listProps.items.length > 0 && listProps.hasMore ? DefaultMoreResultsAvailable : null;
    const menuContent = listProps.renderItems(maybeNoResults, fetchOnBlankQuery ? null : initialContent);

    if (!menuContent && !moreResults && !createItem) {
      return null;
    }

    return (
      <Menu {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        {menuContent}
        {moreResults}
        {createItem}
      </Menu>
    );
  }

  function customItemListRenderer(itemListProps: MultiSelectListRendererProps<T>): JSX.Element | null {
    // Don't show popover while we are fetching results
    if (disablePopover || !!activeQueryable.current) {
      return null;
    }

    return itemListRenderer({ ...itemListProps, hasMore });
  }

  function debounceSetQuery(query: string) {
    const cancel = setTimeout(() => invokeSetQuery(query), queryInvokeDelay);
    cancelSetQuery.current = () => clearTimeout(cancel);
  }
}
