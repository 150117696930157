import styled from "styled-components";
import { Button, Scrollbar } from "@remhealth/ui";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const AvatarWrapper = styled.div`
  position: relative;

  .edit-avatar {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const Name = styled.div`
  flex: 0 0 auto;
  margin: 10px 15px 0 15px;
  font-size: var(--font-size-header3);
  text-align: center;
`;

export const DetailLabel = styled.div`
  font-size: var(--font-size-small);
  font-weight: bold;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 1em;
  }
`;

export const DetailValue = styled.div`
  margin: 3px 0 0 7px;
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-small);

  > div:not(:first-child) {
    margin-top: 2px;
  }
`;

export const Details = styled(Scrollbar)`
  flex: 1 1 auto;
  width: 100%;
  padding: 0 15px 10px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:not(:empty) {
    margin-top: 1rem;
  }
`;

export const UserTagsRow = styled.div`
  margin-top: 1rem;

  &:empty {
    display: none;
  }
`;

export const PractitionerButton = styled(Button)`
  padding: 4px;
  margin: -4px;
`;
