export function excludeKeyOf<T>(exclusions: (keyof T)[]) {
  return (prop: string) => !(exclusions as string[]).includes(prop);
}

export function removeUndefined<T>(data: T): T {
  if (!data) {
    return data;
  }

  return Object.entries(data)
    .filter(([_, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {} as any);
}

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
export function randomAlpha(length: number) {
  let value = "";
  for (let i = 0; i < length; i++) {
    value += letters[Math.floor(Math.random() * letters.length)];
  }
  return value;
}

export function padWithZeroes(str: string, minLength: number) {
  if (str.length < minLength) {
    return `${"0".repeat(minLength - str.length)}${str}`;
  }
  return str;
}

/** Utility function to help deal with null/undefined values in a sort algorithm */
export function coalesceSort<T>(left: T | null | undefined, right: T | null | undefined, sort?: (left: T, right: T) => number, blanksLast = true): number {
  const leftIsNotBlank = isNotBlank(left);
  const rightIsNotBlank = isNotBlank(right);
  if (!leftIsNotBlank || !rightIsNotBlank) {
    if (blanksLast) {
      return (leftIsNotBlank ? 0 : 1) - (rightIsNotBlank ? 0 : 1);
    }
    return (rightIsNotBlank ? 0 : 1) - (leftIsNotBlank ? 0 : 1);
  }
  if (sort) {
    return sort(left, right);
  }
  if (left > right) {
    return 1;
  }
  if (left < right) {
    return -1;
  }
  return 0;
}

function isNotBlank<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined;
}

export function isEnterKeyPress(event: React.KeyboardEvent) {
  return !event.ctrlKey && !event.altKey && !event.shiftKey && event.key === "Enter";
}

export function sanitizeBaseUrl(baseUrl: string): string {
  return baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
}

export function pathCombine(...paths: string[]) {
  return paths.map((value, index) => {
    let [path, query] = value.split("?", 2);
    if (path.length > 0 && index > 0) {
      const slashed = path.startsWith("/");
      const prevSlashed = paths[index - 1].endsWith("/");
      if (slashed && prevSlashed) {
        path = path.slice(1);
      } else if (!slashed && !prevSlashed) {
        path = "/" + path;
      }
    }
    return path + (query ? "?" + query : "");
  }).join("");
}
