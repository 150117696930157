import styled, { css } from "styled-components";
import { IconButton, IconButtonProps } from "./button";

export interface ClearButtonProps extends IconButtonProps {
  $hidden: boolean;
}

export const ClearButton = styled(IconButton)<ClearButtonProps>`
  ${props => props.$hidden && css`
    visibility: hidden;
    pointer-events: none;
  `};
`;
