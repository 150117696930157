import { type Intent } from "@blueprintjs/core";
import styled, { css } from "styled-components";
import { colorSpectrums, intentClass } from "../utils";
import { Icon, IconName, IconProps } from "./icon";
import { useColorScheme } from "./theme";

interface IllustrationProps {
  $size: number;
  $interactive: boolean;
}

const Illustration = styled.div<IllustrationProps>`
  position: relative;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  border-radius: 50%;
  background-color: ${props => props.theme.dark ? props.theme.button.default : props.theme.background.card};
  color: var(--pt-text-color);
  box-shadow: ${props => props.theme.dark ? "none" : props.theme.elevations.wide};
  display: flex;
  align-items: center;
  justify-content: center;
  transition-timing-function: var(--pt-transition-ease);
  transition-duration: 250ms;
  transition-property: scale;

  > * {
    z-index: 1;
  }

  ${props => props.$interactive && css`
    cursor: pointer;

    &:hover:not(:active) {
      scale: 1.1;
    }
  `};

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
  }

  &.bp5-intent-primary:before {
    background-color: rgba(var(--blue5-rgb), 0.15);
  }
  &.bp5-intent-success:before {
    background-color: rgba(var(--green5-rgb), 0.15);
  }
  &.bp5-intent-warning:before {
    background-color: rgba(var(--orange5-rgb), 0.15);
  }
  &.bp5-intent-danger:before {
    background-color: rgba(var(--red5-rgb), 0.15);
  }
`;

export interface IllustrationIconProps {
  icon: IconName | React.ReactElement<IconProps>;
  /** @default 120 */
  size?: number;
  intent?: Intent;
  onClick?: () => void;
}

export const IllustrationIcon = (props: IllustrationIconProps) => {
  const { icon, size = 120, intent = "none", onClick } = props;

  const { colorScheme } = useColorScheme();

  return (
    <Illustration $interactive={!!onClick} $size={size} className={intentClass(intent)} onClick={onClick}>
      <Icon color={getIntentGradient(intent)} icon={icon} size={size / 2} />
    </Illustration>
  );

  function getIntentGradient(intent: Intent) {
    switch (intent) {
      case "none": return colorScheme.font.default;
      case "primary": return colorSpectrums.primary;
      case "success": return colorSpectrums.success;
      case "warning": return colorSpectrums.warning;
      case "danger": return colorSpectrums.danger;
    }
  }
};
