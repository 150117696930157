import styled from "styled-components";
import { Dialog } from "@remhealth/ui";

export const DetailsDialog = styled(Dialog)`
  && {
    width: auto;
    min-width: 500px;

    dl {
      display: flex;
      flex-flow: row wrap;
    }

    dt {
      flex-basis: 20%;
      text-align: right;
      font-weight: bold;
    }

    dd {
      flex-basis: 80%;
      padding: 0 5px;
      margin: 0;
    }
  }
`;

export const ResponseBody = styled.div`
  min-height: 30px;
  line-height: 22px;
  padding: 4px 10px;
  white-space: pre;
  font-family: monospace;
  font-size: var(--font-size-small);
`;
