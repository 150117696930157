import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 15px;
`;

export const Muted = styled.div`
  color: ${props => props.theme.font.muted};
  margin-top: 2px;
`;
