import React, { type PropsWithoutRef, type Ref, forwardRef, memo, useCallback, useContext, useRef, useState } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import type { DataAttributes } from "~/types";
import { removeNonHtmlProps } from "../utils";
import { getAutomationProps } from "../hooks";
import { Icon, IconName, IconProps } from "./icon";
import { FormScopeContext } from "./formScope";
import { Tooltip } from "./tooltip";

interface ButtonExtensionProps {
  square?: boolean;
  elevated?: boolean;
  icon?: IconName | React.ReactElement<IconProps>;
  rightIcon?: IconName | React.ReactElement<IconProps>;
  iconSize?: number;

  /**
   * Collapses the button and expands on hover.
   * @default false
   */
  expandable?: boolean;
}

export interface ButtonProps extends ButtonExtensionProps, DataAttributes, PropsWithoutRef<Omit<Core.ButtonProps, "icon" | "rightIcon" | "text" | "onClick">>, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onFocus"> {
  label: string;
}

export const Button = memo(forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const formScope = useContext(FormScopeContext);
  return (
    <Core.Button {...toButtonNativeProps(props, formScope)} ref={ref} />
  );
}));

export interface AnchorButtonProps extends ButtonExtensionProps, DataAttributes, PropsWithoutRef<Omit<Core.AnchorButtonProps, "type" | "icon" | "rightIcon" | "text" | "onClick">>, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "onFocus"> {
  label: string;
}

export const AnchorButton = memo(forwardRef((props: AnchorButtonProps, ref: Ref<HTMLAnchorElement>) => {
  const formScope = useContext(FormScopeContext);
  return (
    <Core.AnchorButton {...toAnchorButtonNativeProps(props, formScope)} ref={ref} />
  );
}));

export interface IconButtonProps extends DataAttributes, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  "aria-label": string;
  name?: string;

  className?: string;
  active?: boolean;
  disabled?: boolean;
  small?: boolean;
  large?: boolean;
  loading?: boolean;
  minimal?: boolean;
  outlined?: boolean;
  elevated?: boolean;
  square?: boolean;
  icon: IconName | React.ReactElement<IconProps>;
  iconSize?: number;
  intent?: Core.Intent;
  tabIndex?: number;

  /** If true, aria-label will also appear in a tooltip on hover. */
  tooltip?: boolean;
}

export const IconButton = memo((props: IconButtonProps) => {
  const {
    "aria-label": ariaLabel,
    active,
    className,
    disabled,
    elevated = false,
    icon,
    iconSize,
    id,
    large,
    loading,
    intent = "none",
    minimal,
    outlined,
    small,
    square = false,
    tabIndex = 0,
    tooltip,
    onFocus,
    onBlur,
    onClick,
    onKeyDown,
    onKeyUp,
    ...htmlProps
  } = props;

  const formScope = useContext(FormScopeContext);

  const iconRef = useRef<HTMLElement>(null);

  const [currentKeyPressed, setCurrentKeyPressed] = useState<string | undefined>();

  const onKeyDownCallback = useCallback(handleKeyDown, [currentKeyPressed, onKeyDown]);
  const onKeyUpCallback = useCallback(handleKeyUp, [onKeyUp]);

  return (
    <Tooltip<React.AnchorHTMLAttributes<HTMLAnchorElement> & React.RefAttributes<HTMLAnchorElement>>
      className={classnames(
        Core.Classes.BUTTON,
        Core.Classes.intentClass(intent),
        {
          [Core.Classes.ACTIVE]: !disabled && active,
          [Core.Classes.DISABLED]: disabled,
          [Core.Classes.LARGE]: large,
          [Core.Classes.MINIMAL]: getMinimal(minimal, elevated),
          [Core.Classes.OUTLINED]: outlined,
          [Core.Classes.SMALL]: small,
          [Core.Classes.ROUND]: !square,
          elevated,
        },
        "icon-button",
        "no-right-element",
        className
      )}
      content={ariaLabel}
      disabled={!tooltip}
      placement="top"
      targetProps={{
        role: "button",
        tabIndex,
        onFocus: disabled ? undefined : onFocus,
        onBlur: disabled ? undefined : onBlur,
        onClick: disabled ? undefined : onClick,
        onKeyDown: disabled ? undefined : onKeyDownCallback,
        onKeyUp: disabled ? undefined : onKeyUpCallback,
        ...getButtonAutomationProps(props, formScope),
        ...removeNonHtmlProps(htmlProps),
      }}
      targetTagName="a"
    >
      {loading
        ? <Core.Spinner key="loading" className={Core.Classes.BUTTON_SPINNER} size={Core.SpinnerSize.SMALL} />
        : <Icon ref={iconRef} icon={icon} size={iconSize} />}
    </Tooltip>
  );

  function handleKeyDown(e: React.KeyboardEvent<HTMLAnchorElement>) {
    if (Core.Utils.isKeyboardClick(e)) {
      e.preventDefault();
    }
    setCurrentKeyPressed(e.key);
    onKeyDown?.(e);
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLAnchorElement>) {
    if (Core.Utils.isKeyboardClick(e)) {
      iconRef.current?.click();
    }
    setCurrentKeyPressed(undefined);
    onKeyUp?.(e);
  }
});

function toButtonNativeProps(props: ButtonProps, formScope: FormScopeContext): Core.ButtonProps & DataAttributes & React.ButtonHTMLAttributes<HTMLButtonElement> {
  const {
    className,
    children,
    elevated = false,
    expandable,
    icon,
    iconSize,
    minimal,
    rightIcon,
    square,
    label,
    onClick,
    ...restProps
  } = props;

  const classes = classnames(className, {
    "bp5-round": square !== true,
    elevated,
    expandable,
    "no-right-element": !rightIcon,
  });

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} size={iconSize} />
    : undefined;

  const nativeRightIcon: Core.MaybeElement | undefined = rightIcon
    ? <Icon icon={rightIcon} size={iconSize} />
    : undefined;

  return {
    ...restProps,
    children,
    className: classes,
    onClick: (evt: React.MouseEvent) => onClick?.(evt as React.MouseEvent<HTMLButtonElement>),
    icon: nativeIcon,
    minimal: getMinimal(minimal, elevated),
    rightIcon: nativeRightIcon,
    text: children ? undefined : label,
    ...getButtonAutomationProps(props, formScope),
  };
}

function toAnchorButtonNativeProps(props: AnchorButtonProps, formScope: FormScopeContext): Core.AnchorButtonProps & DataAttributes & React.AnchorHTMLAttributes<HTMLAnchorElement> {
  const {
    className,
    children,
    elevated = false,
    expandable,
    icon,
    iconSize,
    label,
    minimal,
    rightIcon,
    square,
    type,
    onClick,
    ...restProps
  } = props;

  const classes = classnames(className, {
    "bp5-round": square !== true,
    elevated,
    expandable,
    "no-right-element": !rightIcon,
  });

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} size={iconSize} />
    : undefined;

  const nativeRightIcon: Core.MaybeElement | undefined = rightIcon
    ? <Icon icon={rightIcon} size={iconSize} />
    : undefined;

  return {
    ...restProps,
    className: classes,
    children,
    onClick: (evt: React.MouseEvent) => onClick?.(evt as React.MouseEvent<HTMLAnchorElement>),
    icon: nativeIcon,
    minimal: getMinimal(minimal, elevated),
    rightIcon: nativeRightIcon,
    text: children ? undefined : label,
    ...getButtonAutomationProps(props, formScope),
  };
}

function getMinimal(minimal: boolean | undefined, elevated: boolean): boolean {
  if (elevated) {
    return false;
  }

  return minimal === true;
}

function getButtonAutomationProps(props: ButtonProps | AnchorButtonProps | IconButtonProps, formScope: FormScopeContext) {
  const { label, id } = getAutomationProps(formScope, props, "button");

  return {
    "aria-label": label,
    id,
  };
}
