import styled from "styled-components";
import { InputGroup } from "@blueprintjs/core";

export const CustomInput = styled(InputGroup)`
  &.button {
    border-radius: var(--pt-border-radius);
  }

  &.masked {
    .bp5-input {
      -webkit-text-security: disc;
      -moz-text-security: disc;
    }
  }

  &.expandable {
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: var(--pt-transition-ease);
    width: 100%;
    min-width: min-content;

    .bp5-input {
      transition-property: padding-left, padding-right;
      transition-duration: 200ms;
      transition-timing-function: var(--pt-transition-ease);
    }

    .bp5-input + .bp5-input-action {
      transition-property: transform;
      transition-duration: 100ms;
      transition-delay: 20ms;
      transition-timing-function: var(--pt-transition-ease);
      transform: scaleX(1);
      transform-origin: 100% 50%;
    }

    &:not(:focus-within) {
      width: 30px;

      .bp5-input {
        padding-right: 0px !important;
      }

      .bp5-input-action {
        transform: scaleX(0);
      }

      .bp5-input-left-container,
      .bp5-icon {
        pointer-events: none;
      }
    }
  }
`;
