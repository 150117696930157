import styled from "styled-components";

export const ActionHeader = styled.th`
  width: 3.25rem;
`;

export const BillableHeader = styled.th`
  text-align: center;
`;

export const BillableCell = styled.td`
  text-align: center !important;
  .bp5-control {
    margin: 0 !important;
  }
`;

export const NoteTypeHeader = styled.th`
  min-width: 20rem;
`;

export const LongTextCell = styled.div`
  line-height: 24px;
  display: flex;
  margin-right: auto;
  word-break: break-word;
  flex-direction: column;
`;

export const SectionTypeCell = styled.td``;

export const UnitsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
`;
