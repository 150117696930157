import styled, { css } from "styled-components";

export interface ExpansionProps {
  $isExpanded: boolean;
  $lines: number;
}

export const TextWrapper = styled.span<ExpansionProps>`
  ${props => !props.$isExpanded && css`
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props.$lines};
    -webkit-box-orient: vertical;
  `};
`;

export const ActionText = styled.div`
  white-space: nowrap;
  word-break: keep-all;
  color: var(--themecolor3);
  cursor: pointer;
`;
