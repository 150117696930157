import styled from "styled-components";
import { Icon, IconProps } from "@remhealth/ui";
import { Account, BlockedPerson } from "@remhealth/icons";

interface ContainerProps {
  $size: number;
}

const Container = styled(Icon)<ContainerProps>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  border-radius: 50%;
  border: 1px dashed var(--gray3);
  color: var(--gray3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashedContainer = styled(Container)<IconProps & ContainerProps>`
  border: 1px solid var(--gray3);
`;

export interface AvatarPlaceholderProps {
  className?: string;
  size?: number;
}

export const AvatarPlaceholder = (props: AvatarPlaceholderProps) => {
  const { className, size = 36 } = props;
  return <Container $size={size} className={className} icon={<Account />} />;
};

export const RestrictedAvatar = (props: AvatarPlaceholderProps) => {
  const { className, size = 36 } = props;
  return <DashedContainer $size={size} className={className} icon={<BlockedPerson />} />;
};
