import { Fragment } from "react";
import { CarePlanActivity, GoalElementActivity, groupBy } from "@remhealth/apollo";
import { useFeatureCheck } from "@remhealth/host";
import { Markup } from "@remhealth/core";
import { FormGroup, NonIdealState } from "@remhealth/ui";
import {
  CardContainer,
  CarePlanSessionAccordion,
  CarePlanSessionAccordionItem,
  Dot,
  ElementContainer,
  GoalsTitle,
  ObjectiveTitle
} from "./carePlanSessionInfo.styles";

export interface CarePlanSessionInfoProps {
  carePlanActivities: CarePlanActivity[];
  collapsable?: boolean;
  label?: string;
}

export function CarePlanSessionInfo(props: CarePlanSessionInfoProps) {
  const { carePlanActivities, collapsable = true, label = "Goal Tracking Information" } = props;

  const longTermGoals = useFeatureCheck("LongTermGoals");
  const showLongTermGoals = longTermGoals;

  const content = carePlanActivities.length === 0
    ? (
      <NonIdealState
        description="There are no careplan activities."
        title="No Goals/Objectives"
      />
    )
    : carePlanActivities.map(c => {
      const groups = Array.from(groupBy(c.activity, g => g.addresses?.display ?? ""));
      return groups.map((g, i) => renderGroupedGoalActivityItem(g[0], g[1], i));
    });

  if (collapsable) {
    return (
      <CarePlanSessionAccordion defaultChecked>
        <CarePlanSessionAccordionItem defaultIsOpen label={label}>
          {content}
        </CarePlanSessionAccordionItem>
      </CarePlanSessionAccordion>
    );
  }

  return (
    <div>{content}</div>
  );

  function renderGroupedGoalActivityItem(title: string, goalElement: GoalElementActivity[], index: number) {
    return (
      <Fragment key={index}>
        {title}
        {goalElement.map(renderGoal)}
      </Fragment>
    );
  }

  function renderGoal(goal: GoalElementActivity, index: number) {
    if (showLongTermGoals) {
      return renderLongTermGoals(goal.detail ?? "", goal.children);
    }
    return (
      <Fragment key={index}>
        <GoalsTitle>{goal.detail ?? goal.code?.text}</GoalsTitle>
        <CardContainer key={goal.linkId} className="noPadding" elevation={1}>
          {renderGoalElements(goal)}
        </CardContainer>
      </Fragment>
    );
  }

  function renderLongTermGoals(problem: string, goals: GoalElementActivity[]) {
    return (
      <div>
        {goals.map(goal => {
          return (
            <>
              <CardContainer key={goal.linkId} elevation={1}>
                <div>
                  <div>{problem}</div>
                  <ObjectiveTitle>
                    {goal.detail ?? goal.code?.text}
                    <Dot $statusType={goal.achievementStatus ?? "NotAchieved"} />
                  </ObjectiveTitle>
                </div>
                {renderLongTermElements(goal, [])}
              </CardContainer>
            </>
          );
        })}
      </div>
    );
  }

  function renderLongTermElements(goal: GoalElementActivity, parents: GoalElementActivity[]) {
    return (
      <Fragment key={goal.linkId}>
        {goal.children.length === 0 && (
          <>
            <ObjectiveTitle>
              {goal.detail ?? goal.code?.text}
              <Dot $statusType={goal.achievementStatus ?? "NotAchieved"} />
            </ObjectiveTitle>
            {renderGoalElementFields(goal)}
          </>
        )}
        {goal.children.map(child => renderLongTermElements(child, [goal, ...parents]))}
      </Fragment>
    );
  }

  function renderGoalElements(goal: GoalElementActivity) {
    if (goal.children.length > 0) {
      return (
        <ElementContainer className={showLongTermGoals ? "flex" : ""}>
          {goal.children.map((child, index) => {
            const childDetails = (
              <Fragment key={index}>
                <ObjectiveTitle>
                  {child.detail ?? child.code?.text}
                  <Dot $statusType={child.achievementStatus ?? "NotAchieved"} />
                </ObjectiveTitle>
                {renderGoalElementFields(child)}
                {renderGoalElements(child)}
              </Fragment>
            );
            return childDetails;
          })}
        </ElementContainer>
      );
    }
    return null;
  }

  function renderGoalElementFields(activity: GoalElementActivity) {
    return (
      <>
        <FormGroup label="Level of Assistance">
          {activity.levelOfAssistance?.text}
        </FormGroup>

        <FormGroup label="Number of Prompts">
          {activity.numberOfPromptsCode?.display}
        </FormGroup>

        <FormGroup label="Achieved">
          {activity.achievementStatus === "Achieved" ? "Yes" : "No"}
        </FormGroup>

        <FormGroup label="Comments">
          <Markup source={activity.comments?.value} />
        </FormGroup>
      </>
    );
  }
}
