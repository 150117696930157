import { useEffect, useState } from "react";
import { useProductFlag } from "@remhealth/host";
import { useEhr, useErrorHandler, useLabeling } from "@remhealth/core";
import { Alert, Intent, Spinner } from "@remhealth/ui";
import { CompositionStatus, PatientNote } from "@remhealth/apollo";
import { PullOutcome, isMastodonResponseError } from "@remhealth/mastodon";
import { Text } from "~/text";

export interface DeleteNoteAlertProps {
  isOpen: boolean;
  note: PatientNote;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteNoteAlert = (props: DeleteNoteAlertProps) => {
  const { isOpen, onCancel, onConfirm, note } = props;

  const ehr = useEhr();
  const labels = useLabeling();
  const handleError = useErrorHandler();

  const [noteState, setNoteState] = useState<PullOutcome>();

  const adminNoteDelete = useProductFlag("AdminNoteDelete");

  useEffect(() => {
    if (isOpen) {
      fetchNoteState();
    }
  }, [isOpen]);

  const isNoteSigned = note.status === CompositionStatus.Final && noteState !== "NotLinked";
  const isNoteDeleted = noteState === "Deleted" || noteState === "NotFound";

  return (
    <Alert
      canEscapeKeyCancel
      cancelButtonText={getCancelButtonText()}
      confirmButtonText={getConfirmButtonText()}
      header={getHeader()}
      intent={getIntent()}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {!noteState
        ? <Spinner intent="primary" size={25} />
        : <p>{getContent()}</p>}
    </Alert>
  );

  function getHeader() {
    return noteState && adminNoteDelete && !isNoteDeleted && isNoteSigned
      ? Text.DeleteSignedNoteAlertHeader
      : "";
  }

  function getContent() {
    return adminNoteDelete
      ? isNoteDeleted
        ? Text.ExistingNoteIsDeleted(labels)
        : isNoteSigned ? Text.DeleteExistingNote(labels) : Text.ConfirmDeleteNote
      : Text.ConfirmDeleteNote;
  }

  function getCancelButtonText() {
    return !noteState
      ? ""
      : adminNoteDelete && isNoteDeleted ? "" : Text.No;
  }

  function getConfirmButtonText() {
    return !noteState
      ? ""
      : adminNoteDelete && isNoteDeleted
        ? Text.Okay
        : Text.ConfirmDelete;
  }

  function getIntent() {
    return adminNoteDelete && isNoteDeleted ? Intent.PRIMARY : Intent.DANGER;
  }

  async function fetchNoteState() {
    setNoteState(undefined);

    try {
      const response = await ehr.pull("Note", note.id);
      setNoteState(response.outcome);
    } catch (error) {
      if (isMastodonResponseError(error) && error.response?.status === 404) {
        setNoteState("Deleted");
      } else {
        setNoteState("NoChange");
        handleError(error);
      }
    }
  }
};
