import classnames from "classnames";
import { ContactPoint, LocalDate, knownCodings } from "@remhealth/apollo";
import { containsCoding } from "@remhealth/host";
import { formatPhone, useLabeling } from "@remhealth/core";
import { Classes, Ellipsize } from "@remhealth/ui";
import { Text } from "~/text";
import { OpenPatient } from "~/contexts";
import { useEpisodeOfCares, useRelatedPeople } from "~/patientAccess";
import { Container, PreferedContactData, SectionHeader } from "./patientDemographics.styles";

const enrollmentClassName = classnames(Classes.TEXT_SMALL, Classes.TEXT_MUTED);

export interface PatientDemographicsProps {
  patient: OpenPatient;
  vertical?: boolean;
}

export const PatientDemographics = (props: PatientDemographicsProps) => {
  const { patient: openPatient, vertical = false } = props;
  const patient = openPatient.patient;

  const labels = useLabeling();

  const episodesFeed = useEpisodeOfCares(openPatient, {
    effectiveOn: LocalDate.today(),
  });

  const relatedPeopleFeed = useRelatedPeople(openPatient, {
    relationship: knownCodings.relationship.guardian,
    effectiveOn: LocalDate.today(),
  });

  const episodes = episodesFeed.items;
  const levelOfCares = episodes.filter(e => e.type?.codings[0]?.display);
  const guardian = relatedPeopleFeed.items.length > 0 ? relatedPeopleFeed.items[0] : undefined;
  const guardianRelationship = getGuardianRelationship();

  const preferedContact = getPreferredContact(patient.telecoms);

  if (!preferedContact && !guardian && levelOfCares.length === 0) {
    return <></>;
  }

  return (
    <Container $vertical={vertical}>
      {preferedContact && (
        <div>
          <SectionHeader>{Text.PreferredContact}</SectionHeader>
          <div>
            {preferedContact.type}: <PreferedContactData>{preferedContact.value}</PreferedContactData>
          </div>
        </div>
      )}
      {guardian && (
        <div>
          <SectionHeader>{Text.Guardian}</SectionHeader>
          <div>
            <Ellipsize>
              {guardian.display}
              {guardianRelationship && (
                <span className={Classes.TEXT_MUTED}>
                  <span>, </span>
                  <span data-patient="relationship">{guardianRelationship}</span>
                </span>
              )}
            </Ellipsize>
          </div>
        </div>
      )}
      {levelOfCares.length > 0 && (
        <div>
          <SectionHeader>{Text.LevelOfCare}</SectionHeader>
          <div>
            {levelOfCares.map(e => {
              // Only one coding is expected
              const text = e.type?.codings[0]?.display;

              return (
                <div key={e.id}>
                  <Ellipsize tooltipContent={<>{labels.Enrollment}: {e.display || ""}</>}>
                    {text}
                    {e.display && levelOfCares.length > 1 && (
                      <>{" "}<span className={enrollmentClassName}>{e.display || ""}</span></>
                    )}
                  </Ellipsize>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );

  function getGuardianRelationship() {
    return guardian?.relationships.find(r => !containsCoding(r, knownCodings.relationship.guardian))?.text;
  }
};

function getPreferredContact(telecoms: ContactPoint[]) {
  const preferredContact = telecoms.find(telecom => telecom.preferred);
  if (!preferredContact) {
    return;
  }
  return getContact(preferredContact);
}

function getPhone(phone: ContactPoint) {
  if (phone.value) {
    return formatPhone(phone.value);
  }
  return "";
}

function getContact(telecom: ContactPoint) {
  const type = telecom.use && telecom.system === "Phone" ? `${telecom.use} ${telecom.system}` : telecom.system;
  const value = telecom.system === "Phone" ? getPhone(telecom) : telecom.value;
  return { type, value };
}
