import { useEffect, useRef } from "react";
import { DurationString, durationToMs } from "../utils";
import { useCallbackRef } from "./useCallbackRef";

export function useInterval(action: () => void, interval: DurationString): void {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const onIntervalCallback = useCallbackRef(action);

  useEffect(() => {
    start();
    return stop;
  }, [interval]);

  function start() {
    stop();
    intervalRef.current = setInterval(onIntervalCallback, durationToMs(interval));
  }

  function stop() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }
}
