import { DateUtils } from "@blueprintjs/datetime";
import enUsLocale from "date-fns/locale/en-US";
import { DateTime } from "luxon";
import { DurationFormats, RelativeDurationFormatOptions } from "./duration";

type MaybeDate = Date | DateTime | null | undefined;
export type FormatDate = (date: MaybeDate) => string;

export { DateUtils };

export function setTimeUnits(date: Date, time: Date) {
  const newDate = DateUtils.clone(date);
  newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  return newDate;
}

function isDate(maybe: MaybeDate): maybe is Date | DateTime {
  return maybe !== undefined && maybe !== null;
}

export const DateFormats = {
  date,
  dateTime,
  attrDateTime,
  time,
  attrTime,
  verbose,
  verboseWithTime,
  relative,
  friendlyDate,
  friendlyWeekday,
  friendlyDateTime,
  friendlyDateTimeRange,
  friendlyTimeRange,
  age,
  sortableDate,
  sortableDateTime,
};

const formats = [
  "MM'/'dd'/'yyyy",
  "MM'/'dd'/'yyyy t",
  "MM'/'dd'/'yyyy T",
  "M'-'d'-'yy",
  "M'-'d'-'yy t",
  "M'-'d'-'yy T",
  "M'-'d'-'yyyy",
  "M'-'d'-'yyyy t",
  "M'-'d'-'yyyy T",
  "MM'-'dD'-'yyyy",
  "MM'-'dD'-'yyyy t",
  "MM'-'dD'-'yyyy T",
  "MM'-'dD'-'yy",
  "MM'-'dD'-'yy t",
  "MM'-'dD'-'yy T",
  "M'/'d'/'yy",
  "M'/'d'/'yy, T",
  "M'/'d'/'yyyy",
  "M'/'d'/'yyyy t",
  "M'/'d'/'yyyy T",
  "MM'/'dd'/'yy",
  "MM'/'dd'/'yy t",
  "MM'/'dd'/'yy T",
];

export function parseDate(str: string): Date | null | false {
  if (!str) {
    return null;
  }

  // Replace non-breaking spaces with normal space
  // https://github.com/moment/luxon/issues/1382#issuecomment-1436776974
  str = str.replace(/ am$/i, String.fromCharCode(8239) + "AM");
  str = str.replace(/ pm$/i, String.fromCharCode(8239) + "PM");

  for (const format of formats) {
    const parsed = DateTime.fromFormat(str, format);
    if (parsed.isValid) {
      return parsed.toJSDate();
    }
  }

  return false;
}

export function createInvalidDate() {
  return new Date(Number.NaN);
}

export function isValidDate(date: Date) {
  return !Number.isNaN(date.valueOf());
}

export function dateNow(precision: "minute" | "second" | "millisecond" = "minute"): Date {
  const date = new Date();

  if (precision === "minute") {
    date.setSeconds(0);
  }

  if (precision !== "millisecond") {
    date.setMilliseconds(0);
  }

  return date;
}

/**
 * Outputs dates in the format of MM'/'dd'/'yyyy
 * @example
 * "12/31/2010"
 */
function date(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("MM'/'dd'/'yyyy");
}

/**
 * Outputs date/time in the format of MM'/'dd'/'yyyy, h:mm A / T
 * @example
 * "12/31/2010 3:31 PM"
 * "12/31/2010 15:31"
 */
function dateTime(date: MaybeDate, militaryClock?: boolean) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("MM'/'dd'/'yyyy ") + time(date, militaryClock);
}

/**
 * Outputs time in the format of h:mm a / HH:MM
 * @example
 * "3:31 PM"
 * "15:31"
 */
function time(date: MaybeDate, militaryClock?: boolean) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat(militaryClock ? "T" : "t", { locale: "en-US" })
    // Force non-breaking space to prevent wrapping
    // https://github.com/moment/luxon/issues/1382#issuecomment-1436776974
    .replace(new RegExp(String.fromCharCode(32), "g"), String.fromCharCode(8239));
}

/**
 * Outputs a datetime format suitable for element attributes used by selector engines.
 * @example
 * "12/31/2010 3:31 PM"
 */
function attrDateTime(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("MM'/'dd'/'yyyy ") + attrTime(date);
}

/**
 * Outputs a time format suitable for element attributes used by selector engines.
 * @example
 * "03:31 PM"
 */
function attrTime(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("hh':'mm a", { locale: "en-US" });
}

/**
 * Outputs dates in the format of Wednesday, July 7, 1985
 * @example
 * "Wednesday, July 7th, 1985"
 */
function verbose(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("DDDD");
}

/**
 * Outputs dates in the format of Wednesday, July 7, 1985 3:25 AM
 * @example
 * "Wednesday, July 7th, 1985 3:31 PM"
 * "Wednesday, July 7th, 1985 15:31"
 */
function verboseWithTime(date: MaybeDate, militaryClock?: boolean) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("DDDD' '") + time(date, militaryClock);
}

export interface RelativeDateFormatOptions extends RelativeDurationFormatOptions {
  /** @default DateTime.now() */
  relativeTo?: MaybeDate;
}

/**
 * Outputs dates relative from now.
 * @example
 * "a moment ago"
 * "5m ago"
 * "1h ago"
 * "13 days ago"
 * "3 years ago"
 */
function relative(date: MaybeDate, options: RelativeDateFormatOptions = {}) {
  if (!isDate(date)) {
    return "";
  }

  const { relativeTo } = options;
  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  const relativeToDateTime = relativeTo ? DateTime.isDateTime(relativeTo) ? relativeTo : DateTime.fromJSDate(relativeTo) : DateTime.now();
  return DurationFormats.relative(dateTime.diff(relativeToDateTime), options);
}

type FormatDateTime = (dateTime: DateTime) => string;

export interface RelativeCalendarFormats {
  sameDay: string | FormatDateTime;
  nextDay: string | FormatDateTime;
  nextWeek: string | FormatDateTime;
  lastDay: string | FormatDateTime;
  lastWeek: string | FormatDateTime;
  sameElse: string | FormatDateTime;
}

export function relativeCalendar(date: DateTime, relativeTo: DateTime, formats: RelativeCalendarFormats) {
  if (!isDate(date)) {
    return "";
  }

  const diff = date.diff(relativeTo.startOf("day"), "days").as("days");
  const formatType = diff < -6 ? "sameElse"
    : diff < -1 ? "lastWeek"
      : diff < 0 ? "lastDay"
        : diff < 1 ? "sameDay"
          : diff < 2 ? "nextDay"
            : diff < 7 ? "nextWeek" : "sameElse";

  const format = formats[formatType];
  return typeof format === "function" ? format(date) : date.toFormat(format);
}

/**
 * Outputs Today, Yesterday, Tomorrow, or the given format.
 * @example
 * "Today"
 * "Yesterday"
 * "Tomorrow"
 * "12/31/2010"
 */
function friendlyDate(date: MaybeDate, format: FormatDate = DateFormats.date) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return relativeCalendar(dateTime, DateTime.now(), {
    sameDay: "'Today'",
    nextDay: "'Tomorrow'",
    lastDay: "'Yesterday'",
    nextWeek: format,
    lastWeek: format,
    sameElse: format,
  });
}

/**
 * Outputs a friendly relative output with weekday.
 * @example
 * "Today (Wednesday, 09/22/2010)"
 * "Yesterday (Wednesday, 09/22/2010)"
 * "Tomorrow (Wednesday, 09/22/2010)"
 * "Wednesday, 12/31/2010"
 */
function friendlyWeekday(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return relativeCalendar(dateTime, DateTime.now(), {
    sameDay: "'Today ('EEEE', 'MM'/'dd'/'yyyy')'",
    nextDay: "'Tomorrow ('EEEE', 'MM'/'dd'/'yyyy')'",
    lastDay: "'Yesterday ('EEEE', 'MM'/'dd'/'yyyy')'",
    nextWeek: "EEEE', 'MM'/'dd'/'yyyy",
    lastWeek: "EEEE', 'MM'/'dd'/'yyyy",
    sameElse: "EEEE', 'MM'/'dd'/'yyyy",
  });
}

/**
 * Outputs Today, Yesterday, Tomorrow, or the given format.
 * @example
 * "Today at 9:31am"
 * "Yesterday at 9:31am"
 * "Tomorrow at 9:31am"
 * "12/31/2010 9:31am"
 */
function friendlyDateTime(date: MaybeDate, format: FormatDate = DateFormats.dateTime, militaryClock = false) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  const timeFormat = militaryClock ? "T" : "t";
  return relativeCalendar(dateTime, DateTime.now(), {
    sameDay: "'Today at '" + timeFormat,
    nextDay: "'Tomorrow at '" + timeFormat,
    lastDay: "'Yesterday at '" + timeFormat,
    nextWeek: format,
    lastWeek: format,
    sameElse: format,
  });
}

/**
 * Outputs a sortable date format.
 * @example
 * "2023-06-05"
 * "2023-05-01"
 * "2022-11-28"
 */
function sortableDate(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("yyyy-MM-dd");
}

/**
 * Outputs a sortable date format.
 * @example
 * "2023-06-05 15:31"
 * "2023-05-01 08:11"
 * "2022-11-28 12:49"
 */
function sortableDateTime(date: MaybeDate) {
  if (!isDate(date)) {
    return "";
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("yyyy-MM-dd T", { locale: "en-US" });
}

/**
 * Outputs Today, Yesterday, Tomorrow, or the given format.
 * @example
 * "Today at 9:31 AM – 1:20 PM"
 * "Yesterday at 9:31 AM – 1:20 PM"
 * "Tomorrow at 9:31 AM – 1:20 PM"
 * "12/31/2010 9:31 AM"
 */
function friendlyDateTimeRange(start: MaybeDate, end: MaybeDate, format: FormatDate = DateFormats.date, militaryClock = false) {
  if (!isDate(start)) {
    return friendlyDateTime(end, format, militaryClock);
  } else if (!isDate(end)) {
    return friendlyDateTime(start, format, militaryClock);
  }

  const startDateTime = DateTime.isDateTime(start) ? start : DateTime.fromJSDate(start);
  const endDateTime = DateTime.isDateTime(end) ? end : DateTime.fromJSDate(end);

  if (!isSameDay(startDateTime, endDateTime)) {
    return `${friendlyDateTime(start, format, militaryClock)} – ${friendlyDateTime(end, format, militaryClock)}`;
  }

  const timeStr = friendlyTimeRange(start, end, militaryClock);

  return relativeCalendar(DateTime.now(), startDateTime, {
    sameDay: `'Today at ${timeStr}'`,
    nextDay: `'Tomorrow at ${timeStr}'`,
    lastDay: `'Yesterday at ${timeStr}'`,
    nextWeek: () => `'${format(start)} at ${timeStr}'`,
    lastWeek: () => `'${format(start)} at ${timeStr}'`,
    sameElse: () => `'${format(start)} at ${timeStr}'`,
  });
}

/**
 * Outputs a time range
 * @example
 * "9:31 AM – 1:20 PM"
 * "9:31–1:20 PM"
 * "13:31 – 15:20"
 */
function friendlyTimeRange(start: MaybeDate, end: MaybeDate, militaryClock = false) {
  if (!isDate(start)) {
    return time(end, militaryClock);
  } else if (!isDate(end)) {
    return time(start, militaryClock);
  }

  const startDateTime = DateTime.isDateTime(start) ? start : DateTime.fromJSDate(start);
  const endDateTime = DateTime.isDateTime(end) ? end : DateTime.fromJSDate(end);
  const diff = endDateTime.diff(startDateTime);

  const sameMinute = diff.as("minute") >= 0 && diff.as("minute") < 1;
  return sameMinute
    ? time(start, militaryClock)
    : militaryClock || startDateTime.toFormat("a") !== endDateTime.toFormat("a")
      ? `${time(start, militaryClock)} – ${time(end, militaryClock)}` // "14:30 - 14:45" or "11:30 AM – 2:45 PM"
      : `${startDateTime.toFormat("h':'mm")}–${time(end, false)}`; // "2:30–2:45 PM"
}

/**
 * Returns formatted age for the brith date passed in.  Formatting is done based on condtions:
 * - 0-60 days : shown in days (d)
 * - 2 - 24 shown in months :  (m)
 * - greater than 24 months : shown in years ( y )
 * @example
 * "32y"
 * "14m"
 * "3d"
 * "32 years"
 * "14 months"
 * "3 days"
 */
function age(birthDate: Date, format: "short" | "long" = "short") {
  const birthDateTime = DateTime.fromJSDate(birthDate);

  const days = Math.round(DateTime.now().diff(birthDateTime, "days").days);
  if (days <= 60) {
    return format === "long"
      ? `${days} day${days === 1 ? "" : "s"}`
      : `${days}d`;
  }

  const months = Math.floor(DateTime.now().diff(birthDateTime, "months").months);
  if (months <= 24) {
    return format === "long"
      ? `${months} month${months === 1 ? "" : "s"}`
      : `${months}m`;
  }

  const years = Math.floor(DateTime.now().diff(birthDateTime, "years").years);
  return format === "long"
    ? `${years} year${years === 1 ? "" : "s"}`
    : `${years}y`;
}

export function groupByDate<T>(items: T[], dateSelector: (item: T) => DateTime, reverseSort = false): [date: DateTime, items: T[]][] {
  const groups = new Map<number, T[]>();

  for (const item of items) {
    const day = dateSelector(item).startOf("day").toMillis();
    const group = groups.get(day);
    if (group) {
      group.push(item);
    } else {
      groups.set(day, [item]);
    }
  }

  const sorted = Array.from(groups).sort((a, b) => (a[0] - b[0]) * (reverseSort ? -1 : 1));

  return sorted.map(([day, groupItems]) => {
    const dateTime = DateTime.fromMillis(day);
    groupItems = groupItems.sort((a, b) => dateSelector(a).diff(dateSelector(b)).toMillis() * (reverseSort ? -1 : 1));
    return [dateTime, groupItems];
  });
}

export function isSameDay(left: Date | DateTime, right: Date | DateTime) {
  const leftDateTime = DateTime.isDateTime(left) ? left : DateTime.fromJSDate(left);
  const rightDateTime = DateTime.isDateTime(right) ? right : DateTime.fromJSDate(right);

  const startOfDay = leftDateTime.startOf("day");
  const endOfDay = leftDateTime.endOf("day");
  return rightDateTime >= startOfDay && rightDateTime <= endOfDay;
}

/** Returns same as date.startOf("week") except interprets the start of the week as Sunday instead of Monday */
export function startOfWeekSunday(date: DateTime) {
  return date.plus({ day: 1 }).startOf("week").minus({ day: 1 });
}

/** Returns same as date.endOf("week") except interprets the start of the week as Sunday instead of Monday */
export function endOfWeekSunday(date: DateTime) {
  return date.plus({ day: 1 }).endOf("week").minus({ day: 1 });
}

// Always hard-coded to en-US
export const dateFnsLocaleLoader = () => Promise.resolve(enUsLocale);

export const defaultDayPickerProps = {
  fixedWeeks: true,
  formatters: {
    formatWeekdayName: (date: Date) => date.toLocaleDateString(undefined, { weekday: "short" }),
  },
};
