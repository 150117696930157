import styled from "styled-components";
import { Card } from "@blueprintjs/core";
import { Button } from "./button";

export const Header = styled(Button)`
  && {
    font-weight: 600;
    font-size: ${props => props.large ? "16px" : "14px"};
    width: 100%;

    .bp5-button-text {
      flex-grow: 1;
    }
  }
`;

export interface ContentProps {
  $large: boolean;
}

export const Content = styled.div<ContentProps>`
  transition-property: height, padding;
  padding: ${props => props.$large ? "var(--button-padding-large)" : "var(--button-padding)"};
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Item = styled.div`
  padding: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.border.muted};
  }
`;

export const Container = styled(Card)`
  padding: 0;
`;
