import { type AriaAttributes, Ref, forwardRef } from "react";
import * as Core from "@blueprintjs/core";
import classnames from "classnames";
import { useAutomation } from "~/hooks";
import { Icon, IconName, IconProps } from "./icon";
import { IllustrationIcon, type IllustrationIconProps } from "./illustrationIcon";

export interface NonIdealStateProps extends Omit<Core.NonIdealStateProps, "icon">, AriaAttributes {
  icon?: IconName | React.ReactElement<IconProps>;
}

// Overridden native component because BlueprintJS added custom styles
// to change the stroke and colors of nested icons in the visual area
export const NonIdealState = forwardRef((props: NonIdealStateProps, ref: Ref<HTMLDivElement>) => {
  const {
    action,
    children,
    icon,
    iconSize = Core.NonIdealStateIconSize.STANDARD,
    className,
    layout = "vertical",
    description,
    title,
  } = props;

  const ariaLabel = props["aria-label"] ?? (typeof title === "string" ? title : undefined) ?? (typeof description === "string" ? description : undefined);
  const { id } = useAutomation({ label: ariaLabel }, "presentation");

  return (
    <div ref={ref} aria-label={ariaLabel} className={classnames(Core.Classes.NON_IDEAL_STATE, `${Core.Classes.NON_IDEAL_STATE}-${layout}`, className)} id={id} role="presentation">
      {icon && (
        <Icon aria-hidden icon={icon} size={iconSize} />
      )}
      {(title || description) && (
        <div className={Core.Classes.NON_IDEAL_STATE_TEXT}>
          {title && <Core.H4>{title}</Core.H4>}
          {description && typeof description === "string" && <div>{description}</div>}
          {description && typeof description !== "string" && description}
        </div>
      )}
      {action}
      {children}
    </div>
  );
});

export function toNativeProps(props: NonIdealStateProps): Core.NonIdealStateProps {
  const { icon, ...restProps } = props;

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} size={Core.IconSize.LARGE * 3} />
    : undefined;

  return {
    ...restProps,
    icon: nativeIcon,
  };
}

export type NonIdealIconProps = Omit<NonIdealStateProps, "icon" | "iconSize"> & IllustrationIconProps;

export const NonIdealIcon = (props: NonIdealIconProps) => {
  const { icon, size, intent, onClick, ...restProps } = props;

  const content = (
    <IllustrationIcon icon={icon} intent={intent} size={size} onClick={onClick} />
  );

  return (
    <NonIdealState {...toNativeProps(restProps)} icon={content} />
  );
};
