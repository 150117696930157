import { createContext, useContext } from "react";
import { PreferencesStore } from "./preferencesStore";

export interface PreferencesContext {
  readonly preferences: Readonly<PreferencesStore>;
}

export const unmountedPreferencesContext: PreferencesContext = {
  get preferences(): PreferencesStore {
    throw new Error("PreferencesContext is not initialized");
  },
};

export const PreferencesContext = createContext<PreferencesContext>(unmountedPreferencesContext);

export const usePreferences = () => useContext(PreferencesContext).preferences;
