import styled from "styled-components";

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
`;
