import { useEffect, useState } from "react";
import { Approximate, Condition, ProblemActivity } from "@remhealth/apollo";
import { Markup, useErrorHandler, useStore } from "@remhealth/core";
import { DateFormats, useAbort } from "@remhealth/ui";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";
import { Comment, List, Problems } from "./problemListSectionContent.styles";

export interface ProblemListSectionContentProps {
  name: string;
  problems: ProblemActivity[];
}

export function ProblemListSectionContent(props: ProblemListSectionContentProps) {
  const { name, problems } = props;

  const store = useStore();
  const abort = useAbort();
  const handleError = useErrorHandler();

  const [conditions, setConditions] = useState<Condition[]>([]);

  useEffect(() => {
    loadConditions();
  }, [problems]);

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {problems.length === 0
        ? <NoData>{Text.NoData}</NoData>
        : (
          <List>
            {problems.map((p, i) => renderGroupedProblem(p, i))}
          </List>
        )}
    </Container>
  );

  function renderGroupedProblem(problem: ProblemActivity, index: number) {
    const condition = conditions.find(c => c.id === problem.problem.id);
    const date = Approximate.toDate(condition?.period?.start);
    return (
      <li key={index}>
        <Problems key={index}>
          {date && `${DateFormats.date(date)} - `}{problem.problem.display}
          {problem.comments?.value && <Comment><Markup source={problem.comments.value} /></Comment>}
        </Problems>
      </li>
    );
  }

  async function loadConditions() {
    try {
      const conditions = await store.conditions.expand(problems.map(p => p.problem), { abort: abort.signal });
      setConditions(conditions);
    } catch (error) {
      handleError(error);
    }
  }
}
