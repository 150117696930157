import { useEffect, useState } from "react";
import { NoteReviewAssignment, safeStringify } from "@remhealth/apollo";
import { PractitionerBanner, useStore } from "@remhealth/core";
import { Grid, Icon, NonIdealState, Spinner } from "@remhealth/ui";
import { Text } from "~/text";
import { CenterCell } from "./noteReviewers.styles";

export interface NoteReviewersProps {
  reviewers: NoteReviewAssignment[];
}

export function NoteReviewers(props: NoteReviewersProps) {
  const store = useStore();

  const [reviewers, setReviewers] = useState<NoteReviewAssignment[]>();

  useEffect(() => {
    initialize();
  }, [safeStringify(props.reviewers)]);

  return (
    <>
      <h5>Requested Approvers</h5>
      {!reviewers
        ? <Spinner intent="primary" />
        : (
          <Grid<NoteReviewAssignment>
            allowWrap
            compact
            fill={false}
            headerRenderer={renderHeader}
            items={reviewers}
            noResults={renderEmptyRecord}
            rowRenderer={renderRow}
          />
        )}
    </>

  );

  function renderEmptyRecord() {
    return (
      <tr>
        <td colSpan={3}>
          <NonIdealState title={Text.NoResults("Reviewers")} />
        </td>
      </tr>
    );
  }

  function renderHeader() {
    return (
      <tr>
        <th>Name</th>
        <th>Role</th>
        <th>Final Approval</th>
      </tr>
    );
  }

  function renderRow(item: NoteReviewAssignment, index: number) {
    return (
      <tr key={index}>
        <td><PractitionerBanner practitioner={item.assignee} scale="small" /></td>
        <CenterCell>{item.supervisor ? "Supervisor" : "Approver"}</CenterCell>
        <CenterCell>{item.isFinalApprover && <Icon icon="tick" />}</CenterCell>
      </tr>
    );
  }

  async function initialize() {
    await store.practitioners.expand(props.reviewers.map(r => r.assignee));
    setReviewers(props.reviewers);
  }
}
