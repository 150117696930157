import styled from "styled-components";
import { AnchorButton } from "@remhealth/ui";

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  > .bp5-popover-target {
    margin-top: 0;
  }
`;

// Using AnchorButton because its rendered inside a <label>
export const ViewAllButton = styled(AnchorButton)`
  margin-left: 4px;
`;
