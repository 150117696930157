import { css } from "styled-components";
import { BaseEmbedBlot } from "./baseEmbedBlot";

export interface DateBlotData {
  value: string;
  display: string;
}

export class DateBlot extends BaseEmbedBlot {
  public static readonly blotName = "date";
  public static readonly tagName = "rh-date";

  public static create(data: DateBlotData) {
    const node = document.createElement(this.tagName);
    node.setAttribute("value", data.value);
    node.textContent = data.display;
    return node;
  }

  public static value(node: HTMLElement): DateBlotData {
    return {
      value: node.getAttribute("value") ?? "",
      display: node.firstElementChild ? node.firstElementChild.textContent ?? "" : node.textContent ?? "",
    };
  }
}

export const DateCss = css`
  rh-date {
    color: ${props => props.theme.dark ? "var(--themecolor5)" : "var(--themecolor3)"};
    cursor: pointer;
  }
`;
