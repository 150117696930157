import styled from "styled-components";
import { Tag } from "@remhealth/ui";

export const InactiveTag = styled(Tag)`
  margin-left: 5px;
`;

export const Mrn = styled.span`
  font-size: var(--font-size-small);
  margin-left: 5px;
  opacity: 0.7;
`;
