import { Node } from "unist";
import type { Element, ElementContent, Root, RootContent, Text } from "hast";

export type RootNode = Root;
export type ElementNode = Element;
export type TextNode = Text;
export { ElementContent, RootContent };

const blockTags = ["p", "article", "h1", "h2", "h3", "h4", "h5", "h6", "section", "blockquote", "div", "ol", "ul", "dl"] as const;

export interface BlockNode extends ElementNode {
  tagName: (typeof blockTags)[number];
}

export function isElementNode(node: Node): node is ElementNode {
  return node && node.type === "element";
}

export function isRootNode(node: any): node is RootNode {
  return node && node.type === "root";
}

export function isTextNode(node: any): node is TextNode {
  return node && node.type === "text";
}

export function isBlockNode(node: any): node is BlockNode {
  return node && node.type === "element" && blockTags.includes(node.tagName.toLowerCase());
}

export function startsWithBlockTag(html: string) {
  html = html.trim();

  if (!html.startsWith("<")) {
    return false;
  }

  html = html.slice(1);
  if (!html) {
    return false;
  }

  return blockTags.some(tag => {
    if (!html.startsWith(tag)) {
      return false;
    }

    const following = html.slice(tag.length, tag.length + 1);
    return following === ">" || following === " " || following === "\t" || following === "\n";
  });
}
