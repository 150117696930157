import { Card, Icon, NonIdealState } from "@remhealth/ui";
import { WarningSign } from "@remhealth/icons";
import { Text } from "~/text";

import { BrowserNotFoundTitle, BrowserVersionLabel, Main } from "./browserNotSupported.styles";

export const BrowserNotSupported = () => {
  return (
    <Main>
      <Card elevation={2}>
        <NonIdealState
          description={(
            <>
              <BrowserVersionLabel>
                {Text.UnsupportedBrowserHeader}<br />{Text.UnsupportedBrowserContent}<br />
              </BrowserVersionLabel>
            </>
          )}
          icon={<Icon color="#f5a623" icon={<WarningSign />} size={80} />}
          title={<BrowserNotFoundTitle>Browser Not Supported</BrowserNotFoundTitle>}
        />
      </Card>
    </Main>
  );
};
