import { Dispatch, SetStateAction, useState } from "react";
import { useMounted } from "./useMounted";

/**
 * Like `useState`, except will not fire the setState callback if the component is unmounted.
 * Useful in combination with async/promise scenarios.
 */
export function useStateIfMounted<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];
export function useStateIfMounted<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>];
export function useStateIfMounted<S>(initialState?: S | (() => S)): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const isMounted = useMounted();
  const [state, setState] = useState(initialState);
  return [state, safeSetState];

  function safeSetState(value: SetStateAction<S | undefined>): void {
    if (isMounted.current) {
      setState(value);
    }
  }
}
