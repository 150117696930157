import { createContext } from "react";
import {
  type AdministrativeGender,
  Approximate,
  type ApproximateRange,
  type CodeableConcept,
  HealthcareService,
  Identifier,
  type LocalDate,
  Location,
  LocationRole,
  Organization,
  Program,
  QuestionnaireAnswer,
  QuestionnaireAnswerValue,
  QuestionnaireElement,
  QuestionnaireValueToken,
  Reference
} from "@remhealth/apollo";
import { createSubscription } from "@remhealth/ui";

export interface QuestionnaireAnswerContext {
  getValuesByLinkId: (linkId: string) => QuestionnaireAnswerValue[];
}

export interface QuestionnaireRenderContext extends QuestionnaireAnswerContext {
  getElementAt: (index: number) => QuestionnaireElement | undefined;
  getElementIndex: (linkId: string) => number;
}

export interface QuestionnaireFormContext extends QuestionnaireRenderContext {
  getValuesByIdentifier: (identifier: Identifier, parentOnly: boolean) => QuestionnaireAnswerValue[];
  getValuesByIdentifierSystem: (system: string) => QuestionnaireAnswerValue[];
  getTokenValues: (token: QuestionnaireValueToken) => QuestionnaireAnswerValue[];
  getParentIdentifiers: () => Identifier[];
}

export const QuestionnaireFormContext = createContext<QuestionnaireFormContext>({
  getElementAt: () => undefined,
  getElementIndex: () => -1,
  getValuesByLinkId: () => [],
  getValuesByIdentifier: () => [],
  getValuesByIdentifierSystem: () => [],
  getTokenValues: () => [],
  getParentIdentifiers: () => [],
});

// Subscription for when an individual question needs to rerender if any answer changes across the entire CNS
// e.g. Calculated
export const { context: QuestionnaireFormAnswersContext, Provider: QuestionnaireFormAnswersProvider } = createSubscription<QuestionnaireAnswer[]>([]);

export interface QuestionnairePatient {
  id?: string;
  birthDate: LocalDate;
  gender?: AdministrativeGender;
  genderIdentity?: CodeableConcept;
}

export interface QuestionnaireContext {
  patient: QuestionnairePatient | undefined;
  visitDate: Approximate | undefined;
  period: ApproximateRange | undefined;
  location: Reference<Location> | undefined;
  locationRole: LocationRole | undefined;
  program: Reference<Program> | undefined;
  serviceLocation: Reference<Location> | undefined;
  serviceType: Reference<HealthcareService> | undefined;
  insurances: Reference<Organization>[];
  narrativeOverrideLinkId: string | undefined;
  hideElementLinkIds?: Set<string>;
}

export const blankQuestionnaireContext = {
  patient: undefined,
  visitDate: undefined,
  location: undefined,
  locationRole: undefined,
  program: undefined,
  serviceLocation: undefined,
  serviceType: undefined,
  insurances: [],
  narrativeOverrideLinkId: undefined,
  period: undefined,
} satisfies QuestionnaireContext;

export const QuestionnaireContext = createContext<QuestionnaireContext>(blankQuestionnaireContext);

const hashKeys = Object.keys(blankQuestionnaireContext) as (keyof typeof blankQuestionnaireContext)[];
export function getQuestionnaireContextHashKey(context: QuestionnaireContext) {
  return hashKeys.map(key => getHashValue(context, key)).join("|");
}

// Ensures we always have a serializable QuestionnaireContext
function getHashValue(context: QuestionnaireContext, key: keyof QuestionnaireContext): string {
  const value = context[key];
  switch (typeof value) {
    case "string": return value;
    case "boolean": return `${value}`;
    case "undefined": return "";
    case "object":
      if (Array.isArray(value)) {
        return value.join("|");
      }
      if ("versionId" in value && value.versionId) {
        return value.versionId;
      }
      if (value instanceof Set) {
        return [...value.values()].join("|");
      }
      if ("id" in value || "birthDate" in value) {
        return value.id ?? "";
      }
      return `${value.start ?? ""}|${value.end ?? ""}`;
  }
}
