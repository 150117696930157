import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Spinner } from "@blueprintjs/core";
import { PageLoaderState, PagingController, useDebouncer, useScrollEffect, useUpdateEffect } from "../hooks";
import { HTMLTable, HTMLTableProps } from "./htmlTable";
import { Pagination } from "./pagination";
import { Container, ContainerBody, ScrollArea, ScrollWrapper, SpinnerContainer } from "./gridView.styles";

const defaultPageSizeChoices = [15, 25, 50];

export interface GroupedRows<T> {
  /** Items in this group */
  items: T[];
  /** A header for this group (must return a <tr colSpan{N}...) */
  header?: JSX.Element;
  /** A footer for this group (must return a <tr colSpan{N}...) */
  footer?: JSX.Element;
}

export interface GridRowProps {
  page: number;
  pageSize: number;
  startIndex: number;
}

export interface GridViewProps<T> extends Omit<HTMLTableProps & React.HTMLAttributes<HTMLDivElement>, "striped" | "bordered" | "onScroll"> {
  controller: PagingController<T>;
  loader: PageLoaderState<T>;
  loading?: boolean;
  canLoadMore: boolean;
  pageSize: number;
  stickyHeader?: boolean;

  /**
   * If true, the table will fill its container.
   * @default true
   */
  fill?: boolean;

  /**
   * If true, the content in table cells will be allowed to wrap lines.
   * @default false
   */
  allowWrap?: boolean;

  /**
   * If true, the table will not have a background color.
   * @default false
   */
  transparent?: boolean;

  paginationChildren?: React.ReactNode;

  /**
   * If set to "infinite", an infinite scroller will replace pagination controls.
   */
  pageSizeChoices?: number[] | "infinite";

  /**
   * If true, the table will scroll to the header on page change
   * @default false
   */
  scrollWithPaging?: boolean;

  onLoadMore: (limit: number) => void;

  /**
   * Grouped Rows
   */
  group?: (items: T[]) => GroupedRows<T>[];
  onViewChanged?: (items: T[], startIndex: number, page: number) => void;
  onPageSizeChanged: (pageSize: number) => void;
  headerRenderer?: () => JSX.Element;
  rowRenderer: (item: T, index: number, props: GridRowProps) => JSX.Element;
  noResults?: () => JSX.Element;
}

export function GridView<T>(props: GridViewProps<T>) {
  const {
    canLoadMore,
    className,
    controller,
    fill = true,
    loader,
    loading: controlledLoading,
    allowWrap = false,
    paginationChildren,
    pageSize,
    pageSizeChoices,
    scrollWithPaging = props.stickyHeader ?? false,
    stickyHeader = false,
    interactive,
    id,
    compact,
    fixed,
    transparent = false,
    "aria-label": ariaLabel = id,
    noResults,
    headerRenderer,
    rowRenderer,
    group,
    onLoadMore,
    onPageSizeChanged,
    onViewChanged,
    ...divProps
  } = props;

  const { items, page } = loader;
  const loading = controlledLoading || loader.loading;

  const infiniteScroll = pageSizeChoices === "infinite";
  const debouncer = useDebouncer(100);
  const [fillingContainer, setFillingContainer] = useState(infiniteScroll);
  const header = useRef<HTMLTableSectionElement>(null);
  const body = useRef<HTMLDivElement>(null);

  const pagesLoaded = Math.ceil(items.length / pageSize);
  const pageCount = pagesLoaded + (canLoadMore ? 1 : 0);
  const pageToShow = Math.max(1, Math.min(page, pagesLoaded));
  const startIndex = (pageToShow - 1) * pageSize;
  const pagedItems = infiniteScroll ? [...items] : items.slice(startIndex, startIndex + pageSize);

  const scrollArea = useScrollEffect<HTMLDivElement>(handleScrollAreaScroll);

  // Scroll to top when page changes
  useUpdateEffect(() => {
    scrollToTop();
  }, [page]);

  useEffect(() => {
    if (infiniteScroll) {
      debouncer.delay(() => loadPage());
    }
  }, [controller.session]);

  useEffect(() => {
    let shouldLoad = false;

    if (infiniteScroll && fillingContainer) {
      shouldLoad = shouldLoadInfiniteScroll();
      setFillingContainer(shouldLoad);
    }

    if (shouldLoad) {
      debouncer.delay(() => loadPage());
    }
  });

  useEffect(() => {
    onViewChanged?.(pagedItems, startIndex, pageToShow);
  }, [items.length, startIndex, pageToShow]);

  const rowProps: GridRowProps = {
    page,
    pageSize,
    startIndex,
  };

  const hasNoResults = !loading && items.length === 0;
  const table = (
    <HTMLTable aria-label={ariaLabel} compact={compact} fixed={fixed} id={id} interactive={!hasNoResults ? interactive : false} stickyHeader={stickyHeader}>
      {!!headerRenderer && (
        <thead ref={header}>
          {headerRenderer()}
        </thead>
      )}
      {hasNoResults
        ? <tbody className="no-results" id={id ? `${id}-no-results` : undefined}>{noResults?.()}</tbody>
        : group
          ? group(pagedItems).map(groupRenderer)
          : <tbody>{pagedItems.map(itemRenderer)}</tbody>}
    </HTMLTable>
  );

  const content = infiniteScroll
    ? (
      <ScrollWrapper>
        <ScrollArea ref={scrollArea}>
          {table}
          {loading && <SpinnerContainer><Spinner intent="primary" /></SpinnerContainer>}
        </ScrollArea>
      </ScrollWrapper>
    ) : (
      <>
        <ContainerBody ref={body} className={classnames("paging-grid-body", { interactive: interactive && !hasNoResults })} id={id ? `${id}-body` : undefined}>
          {table}
        </ContainerBody>
        <Pagination
          id={id ? `pagination-${id}` : undefined}
          page={page}
          pageCount={pageCount}
          pageSize={pageSize}
          pageSizeChoices={pageSizeChoices ?? defaultPageSizeChoices}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChanged}
        >
          {paginationChildren}
          {loading && <Spinner className="loader" size={20} />}
        </Pagination>
      </>
    );

  return (
    <Container
      $allowWrap={allowWrap}
      $fill={fill}
      $stickyHeader={stickyHeader}
      $transparent={transparent}
      aria-label={ariaLabel}
      className={classnames("paging-grid", className)}
      {...divProps}
    >
      {content}
    </Container>
  );

  function itemRenderer(item: T, index: number) {
    return rowRenderer(item, index, rowProps);
  }

  function handlePageChange(page: number) {
    controller.setPage(page);
  }

  function handlePageSizeChanged(pageSize: number) {
    onPageSizeChanged(pageSize);
  }

  function handleScrollAreaScroll() {
    if (scrollArea.current && infiniteScroll) {
      const shouldLoad = shouldLoadInfiniteScroll();

      if (shouldLoad) {
        onLoadMore(pageSize);
      }
    }
  }

  function shouldLoadInfiniteScroll() {
    if (scrollArea.current) {
      const clientHeight = scrollArea.current.clientHeight;
      const distanceFromEnd = scrollArea.current.scrollHeight - clientHeight - scrollArea.current.scrollTop;
      return distanceFromEnd < (clientHeight * 0.5);
    }
    return false;
  }

  function scrollToTop() {
    if (scrollWithPaging) {
      requestAnimationFrame(() => {
        header.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      });
    }
  }

  function groupRenderer(groupedRow: GroupedRows<T>, index: number) {
    const { items, header, footer } = groupedRow;

    const className = classnames({
      "with-header": !!header,
      "with-footer": !!footer,
    });

    return (
      <tbody key={index} className={className}>
        {header}
        {items.map(itemRenderer)}
        {footer}
      </tbody>
    );
  }

  async function loadPage() {
    if (!loading && !canLoadMore) {
      setFillingContainer(false);
      return;
    }

    if (!fillingContainer) {
      setFillingContainer(true);
    }

    onLoadMore(pageSize);
  }
}
