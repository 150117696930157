import styled, { css } from "styled-components";
import { ColorRange } from "@remhealth/ui";

export interface BackgroundCoverProps {
  $image?: string;
}

export const BackgroundCover = styled.div<BackgroundCoverProps>`
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: calc(var(--pt-border-radius) - 1px) calc(var(--pt-border-radius) - 1px) 0 0;
  overflow: hidden;
  background-color: ${props => props.theme.background.card};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: -70px;

  ${props => props.$image && css`
    background-image: url(${props.$image});
    height: 140px;
  `};

  &:before {
    content: "";
    position: absolute;
    height: 15px;
    width: 100%;
    bottom: 0;
    border-radius: 100% 100% 0 0;
    background: ${props => props.theme.background.card};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px 0px 0px;

  .details:not(:empty) {
    margin-top: 5px;
  }

  .user-tags {
    margin-top: 5px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  height: 40px;
  border-top: 1px solid ${props => props.theme.border.muted};
`;

interface ContainerProps {
  $gradient: ColorRange;
  $hasImage: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  min-width: 250px;
  max-height: ${props => props.$hasImage ? "470px" : "400px"};
  border-radius: var(--pt-border-radius);
  position: relative;
  background-clip: padding-box;
  z-index: 0;

  > * {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -3px !important;
    border-radius: calc(var(--pt-border-radius) + 3px);
    background: linear-gradient(-45deg, ${props => props.$gradient[0]}, ${props => props.$gradient[1]});
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    border-radius: var(--pt-border-radius);
    background: ${props => props.theme.background.card};
  }

  .close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .avatar-wrapper {
    border-radius: 50%;
    background: ${props => props.theme.background.card};
    padding: 5px;
  }

  ${Body} {
    height: calc(100% - ${props => props.$hasImage ? "125px" : "55px"});
    overflow: hidden;
  }
`;
