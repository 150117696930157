import styled from "styled-components";

export const NoData = styled.div`
  margin-left: 8px;
`;

export const SectionHeader = styled.h4`
  margin-top: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
