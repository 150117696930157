import React, { type PropsWithoutRef, type Ref, cloneElement, forwardRef } from "react";
import { IntentProps, Props } from "@blueprintjs/core";
import type { IconName } from "@remhealth/icons";
import { IconDisplayName, isIconType, standardIcons } from "@remhealth/icons";
import { Color, ColorRange, removeUndefined } from "~/utils";

export { IconName };

export interface IconProps extends IntentProps, Props, React.RefAttributes<HTMLElement>, React.DOMAttributes<HTMLElement> {
  icon: IconName | React.ReactElement<IconProps>;
  id?: string;
  "aria-label"?: string;
  color?: string | Color | ColorRange;

  /**
   * Size of the icon, in pixels. Blueprint contains 16px and 20px SVG icon
   * images, and chooses the appropriate resolution based on this prop.
   *
   * @default 16
   */
  size?: number;
}

export const Icon = forwardRef((props: PropsWithoutRef<IconProps>, ref: Ref<HTMLElement>) => {
  const { icon, ...restProps } = props;

  if (typeof icon === "string") {
    const IconComponent = standardIcons[icon];
    return <IconComponent {...restProps} ref={ref} />;
  }

  if (!isIconType(icon)) {
    return icon;
  }

  const iconProps: Partial<IconProps> = {
    key: icon.key,
    ...removeUndefined(icon.props),
    ...removeUndefined(restProps),
    ref,
  };

  return cloneElement(icon, iconProps, icon.props.children);
});

Icon.displayName = IconDisplayName;
