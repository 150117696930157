import styled from "styled-components";
import { Popover } from "./popover";
import { Menu } from "./menu";

export const Container = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const Content = styled(Menu)`
  && {
    max-height: 200px;
    overflow-y: auto;
    padding: 8px;
  }
`;

export const FilterPopover = styled(Popover)`
  margin: -7px 0 -7px 5px;
`;

export const ItemWrapper = styled.li`
  display: flex;
  flex-direction: column;

  .bp5-control {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  border-top: 1px solid ${props => props.theme.border.muted};

  .filter {
    margin-left: auto;
  }
`;
