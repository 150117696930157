import React, { ComponentType, LazyExoticComponent } from "react";

export function lazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>): LazyExoticComponent<T> {
  return React.lazy(loadModule);

  async function loadModule() {
    try {
      return await factory();
    } catch (error) {
      if (error instanceof Error) {
        throw new DynamicImportError(error.message, error);
      } else {
        throw new DynamicImportError("Unknown error while loading dynamically imported module");
      }
    }
  }
}

export class DynamicImportError extends Error {
  public innerError?: Error;

  constructor(message: string, innerError?: Error) {
    super(message);
    this.name = "DynamicImportError";
    this.innerError = innerError;
  }

  public get [Symbol.toStringTag]() {
    return "DynamicImportError";
  }
}
