import styled from "styled-components";
import { Scrollbar } from "@remhealth/ui";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
  flex: auto;
  overflow: hidden;
  margin-top: 20px;
`;

export const ScrollArea = styled(Scrollbar)`
  overflow-y: auto;
  height: 100%;
  padding: 0 15px 0 20px;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.background.muted};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading-message {
    font-size: var(--font-size-large);
    font-weight: bold;
    color: ${props => props.theme.dark ? "var(--white)" : "var(--black)"};
    margin-top: 20px;
  }
`;
