import { Appointment } from "@remhealth/apollo";
import { GroupBanner } from "@remhealth/core";

export interface AppointmentGroupBannerProps {
  appointment: Appointment;
  small?: boolean;
}

export function AppointmentGroupBanner(props: AppointmentGroupBannerProps) {
  const { appointment, small } = props;

  const groupName = appointment.attendees.find(a => a.subject.resourceType === "Group")?.subject.display;
  return (
    <GroupBanner groupName={groupName} small={small} />
  );
}
