import { Node } from "unist";
import rehype from "rehype-dom-parse";
import { type Processor, unified } from "unified";
import {
  type ElementContent,
  type ElementNode,
  type RootContent,
  type RootNode,
  type TextNode,
  isElementNode,
  isRootNode
} from "@remhealth/compose";

export type SplitResults = [unmatched: Node] | [before: ElementContent[], element: ElementContent, after: ElementContent[]];

export function splitAtNode(html: string, inline: boolean, predicate: (node: Node) => boolean): SplitResults {
  const transformer = unified()
    .use(rehype, { fragment: true })
    .use(splitAtNodePlugin, { inline, predicate });

  return transformer.processSync(html).result as SplitResults;
}

declare module "unified" {
  interface CompileResultMap {
    splitResults: SplitResults;
  }
}

interface PluginOptions {
  inline: boolean;
  predicate: (node: Node) => boolean;
}

function splitAtNodePlugin(this: Processor, options: PluginOptions): void {
  const { inline, predicate } = options;

  this.compiler = (tree) => {
    const split = trySplitNodes(tree, predicate);

    if (split.length !== 3) {
      return [tree];
    }

    let beforeNodes = split[0];
    const elementNode = split[1];
    let afterNodes = split[2];

    // Don't allow inlining if multi-line
    if (inline) {
      beforeNodes = removeParagraphs(beforeNodes);
      afterNodes = removeParagraphs(afterNodes);
    }

    return [beforeNodes, elementNode, afterNodes];
  };
}

type SplitMatch<T> = T extends ElementNode | RootNode ? [before: T["children"], match: T["children"][number], after: T["children"]] : never;
type SplitNoMatch<T> = [unmatched: T];
type SplitResult<T> = SplitMatch<T> | SplitNoMatch<T>;

function trySplitNodes(tree: ElementNode, predicate: (node: Node) => boolean): SplitResult<Element>;
function trySplitNodes(tree: RootNode, predicate: (node: Node) => boolean): SplitResult<RootNode>;
function trySplitNodes(tree: Node, predicate: (node: Node) => boolean): SplitResult<ElementContent>;
function trySplitNodes(tree: Node, predicate: (node: Node) => boolean): SplitResult<any> {
  if (!isElementNode(tree) && !isRootNode(tree)) {
    return [tree];
  }

  const index = tree.children.findIndex(predicate);
  if (index === -1) {
    for (let i = 0; i < tree.children.length; i++) {
      const split = trySplitNodes(tree.children[i], predicate);

      // Found successful match
      if (split.length === 3) {
        return [
          [...tree.children.slice(0, i), { ...tree.children[i], children: split[0] }],
          split[1],
          [{ ...tree.children[i], children: split[2] }, ...tree.children.slice(i + 1)],
        ];
      }
    }

    return [tree];
  }

  return [tree.children.slice(0, index), tree.children[index], tree.children.slice(index + 1)];
}

function removeParagraphs(nodes: ElementContent[]): ElementContent[];
function removeParagraphs(nodes: RootContent[]): ElementContent[];
function removeParagraphs(nodes: (RootContent | ElementContent)[]) {
  if (nodes.length !== 1) {
    return nodes;
  }

  const node = nodes[0];
  if (isElementNode(node) && node.tagName.toLowerCase() === "p") {
    return node.children.flatMap((child, index) => {
      return index > 0
        // Force space between what used to be paragraphs
        ? [child, { type: "text", value: " " } as TextNode]
        : [child];
    });
  }

  return nodes;
}
