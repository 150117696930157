import Quill from "quill";
import { ReadonlyTableBlot } from "./readonlyTableBlot";
import { ChoiceBlot } from "./choiceBlot";
import { DateBlot } from "./dateBlot";
import { DeleteBlot } from "./deleteBlot";
import { ExpansionBlot } from "./expansionBlot";
import { InsertBlot } from "./insertBlot";
import { MentionBlot } from "./mentionBlot";
import { PlaceholderBlot } from "./placeholderBlot";
import { SuggestionBlot } from "./suggestionBlot";
import { UndoBlot } from "./undoBlot";

Quill.register(`blots/${ChoiceBlot.blotName}`, ChoiceBlot);
Quill.register(`blots/${DateBlot.blotName}`, DateBlot);
Quill.register(`blots/${DeleteBlot.blotName}`, DeleteBlot);
Quill.register(`blots/${ExpansionBlot.blotName}`, ExpansionBlot);
Quill.register(`blots/${InsertBlot.blotName}`, InsertBlot);
Quill.register(`blots/${MentionBlot.blotName}`, MentionBlot);
Quill.register(`blots/${PlaceholderBlot.blotName}`, PlaceholderBlot);
Quill.register(`blots/${SuggestionBlot.blotName}`, SuggestionBlot);

// Override default table blots
Quill.register(ReadonlyTableBlot, true);

// List of blots always enabled for Compose
export const defaultBlots = [
  ChoiceBlot,
  DeleteBlot,
  InsertBlot,
  UndoBlot,
  ReadonlyTableBlot,
];

// List of embed blots that are text-based
export const textEmbeds = [
  PlaceholderBlot,
  MentionBlot,
  DateBlot,
  ChoiceBlot,
  ExpansionBlot,
];
