import React, { type Ref, forwardRef, memo, useState } from "react";
import { CardProps, Collapse } from "@blueprintjs/core";
import { ChevronDown, ChevronUp } from "@remhealth/icons";
import { Container, Content, Header, Item } from "./accordion.styles";

export interface AccordionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Set if controlled mode.  Use `onOpenClose` to listen for user interaction.  Leave unset for uncontrolled mode.
   */
  isOpen?: boolean;

  /** @default true */
  defaultIsOpen?: boolean;

  label: string;
  labelElement?: JSX.Element;

  /**
   * Gives a large appearance.  If using `Accordion`, this is defaulted to its `large` prop value.
   * @default true
   */
  large?: boolean;

  disabled?: boolean;

  /**
   * Fires when user clicks the header.
   */
  onOpenClose?: (isOpen: boolean, event?: React.MouseEvent) => void;
}

export const AccordionItem = memo((props: React.PropsWithChildren<AccordionItemProps>) => {
  const {
    className,
    disabled,
    label,
    labelElement,
    large = true,
    onOpenClose,
    children,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(props.defaultIsOpen);
  const isOpen = props.isOpen ?? stateIsOpen;

  const handleHeaderClick = (event: React.MouseEvent) => {
    setStateIsOpen(!isOpen);
    onOpenClose?.(!isOpen, event);
  };

  return (
    <Item className="accordion-item">
      <Header
        minimal
        square
        className="accordion-header"
        disabled={disabled}
        label={label}
        large={large}
        rightIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
        onClick={handleHeaderClick}
      >
        {labelElement}
      </Header>
      <Collapse isOpen={isOpen}>
        <Content $large={large} className={className}>{children}</Content>
      </Collapse>
    </Item>
  );
});

export interface AccordionProps extends CardProps {
  children: React.ReactElement<AccordionItemProps>[] | React.ReactElement<AccordionItemProps> | undefined;

  /**
   * Gives a large appearance.  Applies to all children items.
   * @default true
   */
  large?: boolean;
}

export const Accordion = memo(forwardRef((props: AccordionProps, ref: Ref<HTMLDivElement>) => {
  const { children, elevation = 2, large, ...cardProps } = props;

  if (!children) {
    return null;
  }

  const items = Array.isArray(children) ? children : [children];

  if (items.length === 0) {
    return null;
  }

  return (
    <Container elevation={elevation} {...cardProps} ref={ref}>
      {items.map((item: React.ReactElement<AccordionItemProps>, index: number) => {
        return [
          React.cloneElement<AccordionItemProps>(item, {
            key: `${index}_item`,
            large,
            ...item.props,
          }),
        ];
      })}
    </Container>
  );
}));
