import styled from "styled-components";
import { Dialog, Switch } from "@remhealth/ui";

export const Modal = styled(Dialog)`
  min-height: 691px;
  min-width: 1093px;
  height: calc(90vh / var(--zoom));
  width: 80% !important;
  padding-bottom: 0px !important;
  margin: 0 10px !important;
`;

export const DialogBody = styled.div`
  overflow-y: auto;
  padding: 0 0 20px 0;
  @media print {
    @page {
      size: A4 portrait;
      margin: 5mm;
    }
    margin: 0px !important;
    padding: 0px !important;
    width: 1140px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${props => props.theme.border.default};
  margin: 0px !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .preview-controls {
    display: flex;
    align-items: center;
    user-select: none;
    .count-message {
      margin: 0 5px;
    }
    .next {
      margin-left: 5px;
    }
  }
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const PatientViewSwitch = styled(Switch)`
  margin: 0px;
`;
