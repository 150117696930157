import { RefObject, useEffect, useRef } from "react";
import { ScrollState } from "./useScroll";
import { useCallbackRef } from "./useCallbackRef";

const blank: ScrollState = {
  scrollTop: 0,
  scrollLeft: 0,
  scrollHeight: 0,
  scrollWidth: 0,
};

/**
 * Can be used to listen on scroll state changes of the given container.  The effect callback
 * will only be called when the scroll state values change.  This can be used instead of listening
 * to a scroll event directly, as scroll events can fire many times with the same values.
 */
export function useScrollEffect<T extends Element>(effect: (state: ScrollState) => void): RefObject<T> {
  const container = useRef<T>();
  const state = useRef<ScrollState>(blank);

  const effectCallback = useCallbackRef(effect);

  useEffect(() => {
    if (container.current) {
      container.current.removeEventListener("scroll", handleScroll);
      container.current = undefined;
    }
  }, []);

  return {
    get current(): T | null {
      return container.current ?? null;
    },
    set current(value: T | null) {
      handleRef(value);
    },
  };

  function handleRef(value: T | null) {
    if (value) {
      state.current = {
        scrollTop: value.scrollTop,
        scrollLeft: value.scrollLeft,
        scrollHeight: value.scrollHeight,
        scrollWidth: value.scrollWidth,
      };

      container.current = value;
      value.addEventListener("scroll", handleScroll);
    } else if (container.current) {
      container.current.removeEventListener("scroll", handleScroll);
      container.current = undefined;
    }
  }

  function handleScroll(event: Event) {
    if (!event.currentTarget || !(event.currentTarget instanceof Element)) {
      return;
    }

    const newState: ScrollState = {
      scrollTop: event.currentTarget.scrollTop,
      scrollLeft: event.currentTarget.scrollLeft,
      scrollHeight: event.currentTarget.scrollHeight,
      scrollWidth: event.currentTarget.scrollWidth,
    };

    // Ignore duplicate scroll events
    if (
      state.current.scrollTop === newState.scrollTop
      && state.current.scrollLeft === newState.scrollLeft
      && state.current.scrollHeight === newState.scrollHeight
      && state.current.scrollWidth === newState.scrollWidth
    ) {
      return;
    }

    effectCallback(newState);

    state.current = newState;
  }
}
