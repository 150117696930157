import { createContext, useContext } from "react";

import { ApolloClients } from "./apollo";

export interface ApolloContext {
  readonly apollo: Readonly<ApolloClients>;
}

export const unmountedApolloContext: ApolloContext = {
  get apollo(): ApolloClients {
    throw new Error("ApolloContext is not initialized");
  },
};

export const ApolloContext = createContext<ApolloContext>(unmountedApolloContext);

export const useApollo = () => useContext(ApolloContext).apollo;
