import { AllZones, AssociateRole, ClaimType, ProfileClaim, associateRoles, parseProfileClaim } from "@remhealth/apollo";
import type { JwtPayload } from "./jwt";

export interface AccessTokenState {
  redirectPath?: string;
}

export type AccessTokenClaims = {
  [K in ClaimType]?: string | string[] | number;
};

export interface AccessTokenIdentity {
  accessToken: string;
  refreshToken: string | null;
  idToken: string;
  issuedAt: number;
  expirationTime: number | null;
  jwt: JwtPayload;
  profile?: AccessTokenClaims;
  state?: AccessTokenState;
}

export class AccessToken {
  public identity: AccessTokenIdentity;

  constructor(identity: AccessTokenIdentity) {
    this.identity = identity;
  }

  public get identityToken() {
    return this.identity.idToken;
  }

  public get accessToken() {
    return this.identity.accessToken;
  }

  public get refreshToken() {
    return this.identity.refreshToken;
  }

  public get claims(): AccessTokenClaims {
    return {
      ...this.identity.jwt,
      ...this.identity.profile ?? {},
    };
  }

  public get state(): AccessTokenState {
    if (this.identity.state) {
      return this.identity.state;
    }
    return {};
  }

  public refresh(identity: AccessTokenIdentity) {
    this.identity = identity;
  }

  public hasClaim(claimType: ClaimType): boolean {
    return !!this.firstClaim(claimType);
  }

  public firstClaim(claimType: "auth_time"): number | undefined;
  public firstClaim(claimType: "rh.zone"): AllZones | undefined;
  public firstClaim(claimType: "rh.staff.role"): AssociateRole | undefined;
  public firstClaim(claimType: "sub"): string | undefined;
  public firstClaim(claimType: ClaimType): string | undefined;
  public firstClaim(claimType: ClaimType): string | number | undefined {
    const claims = this.claims[claimType];
    if (typeof claims === "string" || typeof claims === "number") {
      return claims;
    }
    if (claims && Array.isArray(claims)) {
      return claims[0];
    }
    return undefined;
  }

  public allClaims(claimType: "rh.staff.role"): AssociateRole[];
  public allClaims(claimType: "rh.profiles"): ProfileClaim[];
  public allClaims(claimType: ClaimType): string[];
  public allClaims(claimType: ClaimType): number[] | string[] | ProfileClaim[] {
    const claims = allClaims(this.claims, claimType);

    // Parse certain claims into more special types for easier usage
    switch (claimType) {
      case "rh.staff.role": {
        return claims.map(parseAssociateRole).filter(isValidAssociateRole);
      }

      case "rh.profiles": {
        return claims.map(c => parseProfileClaim(String(c))).filter(isValidProfileClaim);
      }

      default:
        return claims;
    }

    function isValidAssociateRole(value: string): value is AssociateRole {
      return !!value && value in associateRoles;
    }

    function isValidProfileClaim(claim: ProfileClaim | null): claim is ProfileClaim {
      return claim !== null;
    }
  }
}

function parseAssociateRole(value: string | number) {
  return String(value).toLowerCase();
}

function allClaims(claims: AccessTokenClaims, claimType: ClaimType): string[] | number[] {
  const values = claims[claimType];
  if (typeof values === "string") {
    return [values];
  }
  if (typeof values === "number") {
    return [values];
  }
  if (values && Array.isArray(values)) {
    return values;
  }
  return [];
}
