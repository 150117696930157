import { Node } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import { BlockNode, ElementNode, TextNode, isBlockNode } from "./utils";

export interface PrettifyOptions {
  enabled: boolean;
}

export function prettify(this: Processor, options?: PrettifyOptions): Transformer {
  return function transformer(tree: Node | BlockNode | ElementNode): Node {
    if (options?.enabled) {
      visit(tree, isBlockNode, (_, index, parent) => {
        if (parent && index !== undefined) {
          parent.children.splice(index + 1, 0, {
            type: "text",
            value: "\n",
          } as TextNode);
        }
      });
    }

    return tree;
  };
}
