import styled, { css } from "styled-components";

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Mrn = styled.span`
  font-size: var(--font-size-small);
  opacity: 0.7;
  word-break: break-word;
`;

export const DetailsContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  line-height: 1.3em;
  font-weight: normal;
  font-size: var(--font-size-normal);
  color: ${props => props.theme.font.default};
`;

export const DobInfo = styled.div`
  white-space: nowrap;
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  width: 100%;
`;

export const ClientData = styled.div`
  width: 100%;
  white-space: nowrap;

  > span + span {
    margin-left: 5px;
  }
`;

export const Container = styled.div<{ $fill: boolean }>`
  display: inline-flex;

  ${props => !props.$fill && css`
    ${DetailsContainer} {
      overflow: hidden;
      flex-shrink: 1;
    }

    ${ClientData},
    ${DobInfo} {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `};

  ${props => props.$fill && css`
    width: max-content;
  `};

  &.large {
    ${DetailsContainer} {
      ${ClientData} {
        font-size: var(--font-size-large);
        line-height: calc(var(--font-size-large) + 0.25rem);
      }
    }
  }

  &.extra-large {
    ${DetailsContainer} {
      ${ClientData} {
        font-size: var(--font-size-header4);
        line-height: calc(var(--font-size-header4) + 0.25rem);
      }

      ${DobInfo} {
        font-size: var(--font-size-normal);
        line-height: var(--font-size-normal);
      }
    }
  }

  &.small ${DetailsContainer} {
    ${DobInfo} {
      font-size: var(--font-size-tiny);
      line-height: var(--font-size-tiny);
    }
  }
`;
