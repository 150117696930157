import type { ErrorEvent, EventHint } from "@sentry/react";
import { isAbortError } from "@remhealth/ui";
import { AccessToken, UserSession, isInvalidGrantError } from "~/auth";
import { Zone } from "~/apollo";
import { SentryConfig } from "./sentryConfig";
import type { ErrorContext, TracingService } from "./tracingService";

export interface SentryServiceSettings {
  appName: string;
  config: SentryConfig;
  filter?: (error: unknown) => boolean;
}

type Sentry = typeof import("@sentry/react");

export class SentryService implements TracingService {
  private readonly settings: SentryServiceSettings;
  private readonly sentry: Sentry;

  private constructor(settings: SentryServiceSettings, module: Sentry) {
    this.settings = settings;
    this.sentry = module;
  }

  public static async create(settings: SentryServiceSettings): Promise<TracingService> {
    const sentry = await import("@sentry/react");
    const service = new SentryService(settings, sentry);
    // Errors logged before the user has chosen a profile go to development
    service.init("development");
    return service;
  }

  public identifyUser = (user: UserSession, token: AccessToken): void => {
    this.init(user.zone);

    this.sentry.setTags({
      "rh.accountId": token.firstClaim("sub"),
      "rh.profile": `${user.person.resourceType}/${user.person.id}`,
      "rh.practice": user.practice.networkId,
    });

    this.sentry.setUser({
      id: user.person.id,
      email: token.firstClaim("email"),
      accountId: token.firstClaim("sub"),
      display: user.person.display,
    });

    this.sentry.setContext("session", {
      networkid: user.practice.networkId,
      practice: user.practice.display,
      name: user.person.display,
      profile: `${user.person.resourceType}/${user.person.id}`,
    });
  };

  public reportError = (error: any, context?: ErrorContext): void => {
    if (this.filter(error)) {
      this.sentry.captureException(error, { extra: context?.data });
    }
  };

  public endSession = (): void => {
    // Not supported by Sentry
  };

  private init = (zone: Zone) => {
    this.sentry.init({
      dsn: this.settings.config.sentryDsn,
      defaultIntegrations: this.settings.config.defaultIntegrations ? undefined : false,
      environment: zone,
      release: this.settings.appName + "@" + this.settings.config.version,
      autoSessionTracking: false, // We currently don't rely on Sentry's Release Health features
      attachStacktrace: true,
      ignoreErrors: [
        // https://github.com/quasarframework/quasar/issues/2233#issuecomment-475848249
        "ResizeObserver loop limit exceeded",
      ],
      integrations: [this.sentry.browserTracingIntegration()],
      tracesSampleRate: 0, // Disable perf monitoring
      beforeSend: this.beforeSend,
    });
  };

  private beforeSend = (event: ErrorEvent, hint?: EventHint): ErrorEvent | null => {
    if (!this.filter(hint?.originalException)) {
      return null;
    }

    return beforeSend(event, hint);
  };

  private filter = (error: unknown): boolean => {
    if (!shouldLogError(error)) {
      return false;
    }

    if (this.settings.filter && !this.settings.filter(error)) {
      return false;
    }

    return true;
  };
}

function beforeSend(event: ErrorEvent, hint?: EventHint): ErrorEvent | null {
  const error = hint?.originalException;

  if (error && typeof error === "object") {
    event.tags = event.tags ?? {};
    event.tags.errorCode = getString(error, "code");

    if ("request" in error && typeof error.request === "object") {
      const config = "config" in error && typeof error.config == "object" ? error.config : error;

      const url = getString(config, "url");
      event.request = {
        url,
        method: getString(config, "method"),
        data: getProperty(config, "data"),
        headers: normalizeHeaders(getObject(config, "headers")),
      };

      if (url) {
        const queryStringIndex = url.indexOf("?");
        if (queryStringIndex !== -1) {
          event.request.url = url.slice(0, queryStringIndex);
          event.request.query_string = url.slice(queryStringIndex + 1);
        }
      }

      const baseUrl = getString(config, "baseURL");
      if (baseUrl && event.request.url) {
        event.request.url = baseUrl.replace(/\/+$/, "") + "/" + event.request.url.replace(/^\/+/, "");
      }

      event.fingerprint = ["http-request-error"];
    }

    if ("response" in error && typeof error.response === "object") {
      event.tags.statusCode = getNumber(error.response, "status");
      event.extra = event.extra ?? {};
      event.extra.response = {
        headers: getObject(error.response, "headers"),
        data: getObject(error.response, "data"),
      };
      event.fingerprint = ["http-response-error", String(event.tags.statusCode)];
    }
  }

  return event;
}

function normalizeHeaders(obj?: Record<string, any>): Record<string, string> {
  const newObj: Record<string, string> = {};
  if (obj) {
    for (const prop in obj) {
      const propertyValue = obj[prop];

      if (typeof propertyValue === "string" && !prop.toUpperCase().startsWith("X-RH")) {
        newObj[prop] = propertyValue;
      }
    }
  }
  return newObj;
}

function getString(obj: object | null, key: string): string | undefined {
  const value = getProperty(obj, key);
  return value && typeof value === "string" ? value : undefined;
}

function getNumber(obj: object | null, key: string): number | undefined {
  const value = getProperty(obj, key);
  return value && typeof value === "number" ? value : undefined;
}

function getObject(obj: object | null, key: string): object | undefined {
  const value = getProperty(obj, key);
  return value && typeof value === "object" ? value : undefined;
}

function getProperty(obj: object | null, key: string): any | undefined {
  return hasKey(obj, key) ? obj[key] : undefined;
}

function hasKey<K extends string>(obj: unknown, key: K): obj is { [T in K]: unknown } {
  return typeof obj === "object" && obj !== null && key in obj;
}

function shouldLogError(error: unknown) {
  return !!error
    && !isAbortError(error)
    && !isInvalidGrantError(error);
}
