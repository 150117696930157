import { AdditionalService, Duration } from "@remhealth/apollo";
import { Markup } from "@remhealth/core";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";
import { Comment, List, Service } from "./addOnServiceSectionContent.styles";

export interface AddOnServiceSectionContentProps {
  additionalServices: AdditionalService[];
  name: string;
}

export function AddOnServiceSectionContent(props: AddOnServiceSectionContentProps) {
  const { additionalServices, name } = props;

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {additionalServices.length === 0
        ? <NoData>{Text.NoData}</NoData>
        : (
          <List>
            {additionalServices.map((p, i) => renderAddOnServices(p, i))}
          </List>
        )}
    </Container>
  );

  function renderAddOnServices(service: AdditionalService, index: number) {
    return (
      <li key={index}>
        <div>
          <Service>{service.coding.display}</Service>
          <span>{service.duration && Duration.humanize(service.duration)}</span>
          {service.comment?.value && <Comment><Markup source={service.comment.value} /></Comment>}
        </div>
      </li>
    );
  }
}
