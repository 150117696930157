import { useMemo } from "react";
import { IStore, New, NoteRule, NoteRuleCriteriaType, NoteRuleFilterSet, NoteRuleOutcomeType, NoteRulesClient, ParameterPresence } from "@remhealth/apollo";
import { EmptyView } from "@remhealth/host";
import { useStore } from "./useStore";

export interface NoteRuleFilterOptions {
  disabled?: boolean;
  criteriaTypes?: NoteRuleCriteriaType[];
  outcomeTypes?: NoteRuleOutcomeType[];
  limitToServiceIds?: string[] | null;
}

export function useNoteRulesView(options: NoteRuleFilterOptions = {}) {
  const store = useStore();

  return useMemo(() => createNoteRulesView(store.noteRules, options), [JSON.stringify(options)]);
}

export function createNoteRulesView(store: IStore<NoteRulesClient>, options: NoteRuleFilterOptions = {}) {
  if (options.disabled) {
    return new EmptyView<NoteRule>();
  }

  return store.view({
    filters: {
      online: createNoteRuleFilters(options),
      offline: s => noteRulePredicate(s, options),
    },
  });
}

export function createNoteRuleFilters(options: NoteRuleFilterOptions): NoteRuleFilterSet[] {
  const criteriaTypeFilter: NoteRuleFilterSet = !options.criteriaTypes || options.criteriaTypes?.length === 0 ? {} : {
    criteriaType: { in: options.criteriaTypes },
  };

  const outcomeTypeFilter: NoteRuleFilterSet = !options.outcomeTypes || options.outcomeTypes?.length === 0 ? {} : {
    outcomeType: { in: options.outcomeTypes },
  };

  const serviceTypeFilter: NoteRuleFilterSet = options.limitToServiceIds === undefined || options.limitToServiceIds?.length === 0
    ? {}
    : options.limitToServiceIds === null
      // Rules limited to services should only apply when we have a service
      ? { limitToServices: { presence: ParameterPresence.NotPresent } }
      : { limitToServices: { in: options.limitToServiceIds, presence: ParameterPresence.MaybePresent } };

  return [
    {
      ...criteriaTypeFilter,
      ...outcomeTypeFilter,
      ...serviceTypeFilter,
    },
  ];
}

export function noteRulePredicate(rule: New<NoteRule>, options: NoteRuleFilterOptions) {
  if (options.criteriaTypes && options.criteriaTypes?.length > 0 && !options.criteriaTypes.includes(rule.criteria.type)) {
    return false;
  }

  if (options.outcomeTypes && options.outcomeTypes?.length > 0 && !options.outcomeTypes.includes(rule.outcome.type)) {
    return false;
  }

  const limitToServiceIds = options.limitToServiceIds;
  if (limitToServiceIds !== undefined) {
    if (limitToServiceIds === null && rule.limitToServices.length > 0) {
      return false;
    }
    if (limitToServiceIds !== null && rule.limitToServices.length > 0 && !rule.limitToServices.some(s => limitToServiceIds.includes(s.id))) {
      return false;
    }
  }

  return true;
}
