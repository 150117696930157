import type { HealthcareService, INoteRuleOutcome, NoteRule, Reference } from "@remhealth/apollo";
import type { Labeling } from "@remhealth/core";

export interface BuiltInNoteRule {
  id: string;
  name: string;
  instruction: string;
  outcome: INoteRuleOutcome;
  basedOnNoteType?: boolean;
}

export function isBuiltInRule(rule: NoteRule | BuiltInNoteRule): rule is BuiltInNoteRule {
  return !("criteria" in rule);
}

export const missedVisitServiceRule = (labels: Labeling, missedVisitServices: Reference<HealthcareService>[]): BuiltInNoteRule => ({
  id: "missedVisit",
  name: `Missed Visit ${labels.ServiceType}`,
  instruction: `The ${labels.serviceType} you've chosen is not allowed for missed visits. Please choose a valid missed visit ${labels.serviceType}.`,
  outcome: {
    type: "ChangeService",
    services: missedVisitServices,
  },
  basedOnNoteType: true,
});
