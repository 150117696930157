import styled from "styled-components";

export const PageSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-basis: calc(var(--grid-size) * 20);

  > span {
    margin: 0 10px;
  }

  .bp5-html-select select {
    background-color: transparent;
    &:hover {
      background-color: var(--minimal-button-background-color-hover);
    }
    &:active {
      background-color: var(--minimal-button-background-color-active);
    }
  }
`;

export const PageNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: calc(var(--grid-size) * 20);

  .page {
    margin: 0 10px;

    &.first {
      margin-left: 40px;
    }

    &.last {
      margin-right: 40px;
    }
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;

  .bp5-spinner {
    margin: 0 auto;
  }
`;

export const PaginationControls = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;
