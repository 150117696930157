export interface StoredToken {
  refreshToken: string;
  idToken: string;
  expirationTime: number | null;
}

export class AccessTokenStorage {
  private readonly tokensKey: string;

  constructor(tokensKey: string) {
    this.tokensKey = tokensKey;
  }

  public async loadTokens(): Promise<StoredToken | null> {
    const value = localStorage.getItem(this.tokensKey);
    const token = value ? JSON.parse(value) as StoredToken : null;

    if (token?.refreshToken && token.idToken) {
      return { idToken: token.idToken, refreshToken: token.refreshToken, expirationTime: token.expirationTime };
    }

    return null;
  }

  public async saveTokens(token: StoredToken): Promise<void> {
    localStorage.setItem(this.tokensKey, JSON.stringify({ idToken: token.idToken, refreshToken: token.refreshToken, expirationTime: token.expirationTime }));
  }

  public async deleteTokens(): Promise<void> {
    localStorage.removeItem(this.tokensKey);
  }
}
