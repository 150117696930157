import type { SuggestionBlotData } from "~/blots/suggestionBlot";

export type SuggestionAction = "swap" | "remove" | "none";

export interface SuggestionKind {
  severity: string;
  source: string;
  action?: SuggestionAction;
  category?: string;
  description?: string;
  rule: SuggestionRule;
  asExpansion?: boolean;
  ignoreForIncompleteSentence: boolean;
}

export interface SuggestionRule {
  type: string;
  id?: string;
}

/**
 * Suggest something needs fixing but only the user can perform the fix.
 */
export interface UnactionableSuggestion extends SuggestionKind {
  action: "none" | undefined;
  description: string;
}

/**
 * Suggest removal of text.
 */
export interface RemoveSuggestion extends SuggestionKind {
  action: "remove";
}

/**
 * Present suggestions to replace the text with.
 */
export interface SwapSuggestion extends SuggestionKind {
  action: "swap";
  asExpansion?: boolean;
  replacements: string[];
}

export type Suggestion = UnactionableSuggestion | RemoveSuggestion | SwapSuggestion;

export interface SuggestionToken {
  offset: number;
  length: number;
  suggestion: Suggestion;
}

export function isSameSuggestion(left: Suggestion, right: Suggestion) {
  if (left.source !== right.source) {
    return false;
  }

  if (left.action !== right.action) {
    return false;
  }

  if (left.description !== right.description) {
    return false;
  }

  return true;
}

export interface LanguageChecker<T extends ParseResult = ParseResult> {
  parse(text: string, abort: AbortSignal): Promise<T>;
  process(parseResult: T): SuggestionToken[];
  merge(start: number, end: number, deltaLength: number, old: T, updated: T): T;
}

export interface ParseResult {
  text: string;
  sentences: {
    text: string;
    start: number;
    end: number;
  }[];
}

export interface SuggestionReport {
  disabled: false | "AllCapsWritingStyle" | "QuillDisabled";
  isEmpty: boolean;
  suggestions: SuggestedToken[];
}

export interface SuggestedToken {
  suggestion: SuggestionBlotData;
  context: string;
  target: string;
  ruleRef: string | undefined;
  focus(): void;
  blur(): void;
  confirm(replacement?: string): void;
  dismiss(): void;
}
