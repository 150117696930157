import { Tooltip } from "@remhealth/ui";
import { Identifier, Patient, Reference, isReference, systems } from "@remhealth/apollo";
import { useReleaseCheck } from "@remhealth/host";
import { Avatar, RestrictedAvatar, useLabeling } from "@remhealth/core";
import { Text } from "~/text";
import { PatientPopover } from "./patientPopover";
import { InactiveTag, Mrn } from "./patientAvatar.styles";

export interface PatientAvatarProps {
  patient: Reference<Patient> | Patient;
  size?: number;
  className?: string;
  /**
   * Gives avatar appearance of active (mousedown)
   * @default false
   */
  active?: boolean;
  /**
   * Gives avatar appearance on hover
   * @default false
   */
  interactive?: boolean;
  /**
   * Highlights avatar (notice of activity)
   * @default false
   */
  highlight?: boolean;
  /** @default false */
  tooltip?: boolean;
}

const inactiveTag = <InactiveTag minimal>Inactive</InactiveTag>;

export const PatientAvatar = (props: PatientAvatarProps) => {
  const { size, className, active, highlight, interactive = false, tooltip = false } = props;

  const labels = useLabeling();
  const isPatientCardsReleased = useReleaseCheck("PatientCards");

  const patient = props.patient && isReference(props.patient) ? props.patient.resource : props.patient;

  if (!patient) {
    return (
      <Tooltip content={Text.RestrictedPatient(labels)} disabled={!interactive}>
        <RestrictedAvatar className={className} size={size} />
      </Tooltip>
    );
  }

  if (isPatientCardsReleased && interactive) {
    return (
      <PatientPopover patient={patient}>
        {isOpen => (
          <Tooltip content={getTooltip(patient)} disabled={!tooltip || isOpen}>
            <Avatar interactive active={isOpen || active} className={className} highlight={highlight} person={patient} size={size} />
          </Tooltip>
        )}
      </PatientPopover>
    );
  }

  if (tooltip) {
    return (
      <Tooltip content={getTooltip(patient)}>
        <Avatar active={active} className={className} highlight={highlight} person={patient} size={size} />
      </Tooltip>
    );
  }

  return <Avatar active={active} className={className} highlight={highlight} person={patient} size={size} />;

  function getTooltip(item: Patient) {
    return (
      <>
        {item.display}
        {!item.active ? inactiveTag : null}
        <Mrn>{getPatientId(item.identifiers)}</Mrn>
      </>
    );
  }

  function getPatientId(identifiers: Identifier[] | undefined) {
    return (identifiers ?? []).find(id => id.system === systems.patientId)?.value ?? "";
  }
};
