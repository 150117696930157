import styled from "styled-components";

export const List = styled.ol`
  padding-left: 14px;
  margin: 0;

  & {
    padding-inline-start: 20px;
  }
`;

export const Comment = styled.div`
  font-weight: normal;
  color: ${props => props.theme.font.default};
  margin-bottom: 5px;
`;

export const Service = styled.span`
  margin-right: 8px;
`;
