import { Expandable, ResourceTypes } from "@remhealth/apollo";
import { LoadableMap, useLoadables } from "./useLoadables";

interface Store<T extends ResourceTypes> {
  expand(references: Expandable<T>[]): Promise<T[]>;
  expand(references: Expandable<T>[], storeOnly: true): T[];
}

export function useExpandables<TResource extends ResourceTypes>(store: Store<TResource>, references: Expandable<TResource>[]): LoadableMap<TResource> {
  const expanded = store.expand(references, true);

  const expandableIds = references.flatMap(r => r.id && !expanded.some(e => e.id === r.id) ? r.id : []);

  const map = useLoadables(expandableIds, expand);

  // Add already expanded items that are missing from map
  const itemsToSet = new Map<string, TResource>();
  expanded.forEach(expandedItem => {
    if (!map.get(expandedItem.id)) {
      itemsToSet.set(expandedItem.id, expandedItem);
    }
  });

  if (itemsToSet.size > 0) {
    map.set(itemsToSet);
  }

  return map;

  async function expand(expandableIds: string[]): Promise<Map<string, TResource>> {
    if (references.length === 0) {
      return new Map();
    }

    const unexpandedReferences = references.filter(r => r.id && expandableIds.includes(r.id));
    const resources = await store.expand(unexpandedReferences);
    return new Map(resources.map(r => [r.id, r]));
  }
}
