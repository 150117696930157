import { CodeableConcept, Coding, systems } from "@remhealth/apollo";
import { GenderIdentityFilter } from "@remhealth/core";

interface GenderCode {
  system?: string;
  code: string;
}

export interface GenderIdentity {
  label: string;
  values: GenderCode[];
}

export const maleGenderCodes: GenderCode[] = [
  { code: "male" },
  { system: systems.oids.genderIdentity, code: "446151000124109" },
  { system: systems.oids.snomed, code: "446151000124109" },
];

export const femaleGenderCodes: GenderCode[] = [
  { code: "female" },
  { system: systems.oids.genderIdentity, code: "446141000124107" },
  { system: systems.oids.snomed, code: "446141000124107" },
];

export function isGenderCode(coding: Coding, genderCode: GenderCode): boolean {
  if (genderCode.system) {
    return coding.system.toLowerCase() === genderCode.system.toLowerCase() && coding.code.toLowerCase() === genderCode.code.toLowerCase();
  }
  return coding.code.toLowerCase() === genderCode.code.toLowerCase();
}

export function genderIdentitiesToFilter(genderIdentities: GenderIdentity[]): GenderIdentityFilter[] {
  const filters: GenderIdentityFilter[] = [];
  const identityCodings = genderIdentities.flatMap(i => i.values);

  identityCodings.forEach(coding => {
    const existing = filters.find(f => f.system === coding.system);
    if (existing) {
      existing.anyCodes.push(coding.code);
    } else {
      filters.push({ system: coding.system, anyCodes: [coding.code] });
    }
  });

  return filters;
}

export const genderFilterCodings = {
  male: { system: systems.genderIdentity, code: "male" },
  female: { system: systems.genderIdentity, code: "female" },
} satisfies Record<string, Coding>;

export function isGenderIdentity(genderIdentity: CodeableConcept, value: "male" | "female") {
  return genderIdentity.codings.some(identityCode => {
    return (value === "male" ? maleGenderCodes : femaleGenderCodes).some(genderCode => isGenderCode(identityCode, genderCode));
  });
}
