import { CaretDown, CaretRight, CaretUp, ChevronDown, ChevronUp, Cross, Plus, Search, Tick, Trash } from "./icons";

// These is a small list of icons we want to be able to reference by name
export const standardIcons = {
  "caret-down": CaretDown,
  "caret-right": CaretRight,
  "caret-up": CaretUp,
  "chevron-down": ChevronDown,
  "chevron-up": ChevronUp,
  "cross": Cross,
  "plus": Plus,
  "search": Search,
  "tick": Tick,
  "trash": Trash,
};

export type IconName = keyof typeof standardIcons;
