import { useEffect, useRef } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useAbort, useStateRef } from "@remhealth/ui";
import {
  AccessToken,
  AccessTokenContext,
  type AccessTokenIdentity,
  AccessTokenStorage,
  type Authenticator,
  type ErrorContext,
  FHIRSmartLogin,
  type JwtPayload,
  type LoginOAuthConfiguration,
  LoginRequestHandler,
  LogoutRequestHandler,
  decodeJwt,
  useTokenRefresh
} from "@remhealth/host";

export interface AuthenticationProps {
  authenticator: Authenticator;
  events: AuthenticationEvents;
  storage: AccessTokenStorage;
  loginConfiguration: LoginOAuthConfiguration;
  // Max minutes since last login allowed before reauth is required
  maximumMinutesSinceLastAuth?: number;
  handleError: (error: any) => void;
  reportError: (error: any, context?: ErrorContext) => void;
}

export interface AuthenticationEvents {
  createLoginHandler(): LoginRequestHandler;
  createLogoutHandler(): LogoutRequestHandler;
}

export interface LoginOptions {
  forceAuth?: boolean;
  fhirSmart?: FHIRSmartLogin;
}

const lastLoginRedirectKey = "lastLoginRedirect";

export function useAuthentication(props: AuthenticationProps) {
  const { authenticator, events, storage, loginConfiguration, maximumMinutesSinceLastAuth, handleError, reportError } = props;

  const tokenContext = useStateRef<AccessTokenContext | null>(null);
  const loggingIn = useRef(false);
  const abort = useAbort();
  const navigate = useNavigate();

  // Convert relative URL to absolute (unless it is already absolute)
  const redirectUri = new URL(loginConfiguration.redirectUri, window.location.origin).toString();
  const redirectUriPath = new URL(loginConfiguration.redirectUri, window.location.origin).pathname;
  const customLogoutEndpoint = new URL(loginConfiguration.customLogoutEndpoint, window.location.origin).toString();
  const scope = loginConfiguration.scope;

  useEffect(() => {
    signInCallback();
  }, [window.location.pathname]);

  const { pause: pauseRefreshTimer, unpause: unpauseRefreshTimer } = useTokenRefresh({
    authenticator,
    token: tokenContext.current?.token ?? null,
    onTokenChanged: updateTokenContext,
  });

  return {
    get tokenContext() {
      return tokenContext.current;
    },
    isSigningIn,
    pauseRefreshTimer,
    unpauseRefreshTimer,
    reauth,
    login,
    logout,
  };

  function isSigningIn(): boolean {
    return !!matchPath(redirectUriPath, window.location.pathname);
  }

  async function reauth() {
    await login({ forceAuth: true });
  }

  async function login(options: LoginOptions = {}) {
    try {
      // First check for stored tokens
      if (!options?.forceAuth) {
        if (await tryRefreshStoredLogin()) {
          return;
        }
      }

      await loginRedirect(options);
    } catch (error) {
      handleError(error);
    }
  }

  async function tryRefreshStoredLogin(): Promise<boolean> {
    // First check for stored tokens
    const storedToken = await storage.loadTokens();
    if (!storedToken) {
      return false;
    }

    // Ignore expired refresh tokens
    if (storedToken.expirationTime && Date.now() > storedToken.expirationTime) {
      return false;
    }

    const jwt = decodeJwt(storedToken.idToken);

    if (exceedsMaximumMinutesSinceLastAuth(jwt)) {
      return false;
    }

    const newToken = await authenticator.refreshToken(storedToken.refreshToken, abort.signal);
    if (!newToken) {
      return false;
    }

    // Maintain existing details if required
    newToken.refreshToken = newToken.refreshToken ?? storedToken.refreshToken;
    newToken.idToken = newToken.idToken ?? storedToken.idToken;
    updateTokenContext(newToken);

    return true;
  }

  async function loginRedirect(options: LoginOptions) {
    if (loggingIn.current) {
      return;
    }

    trackLastLoginRedirect();
    try {
      loggingIn.current = true;

      // Remember current path so that we return the user back
      const returnUri = window.location.pathname.endsWith("logout") || isSigningIn() || options.fhirSmart
        ? "/"
        : window.location.pathname;

      const handler = events.createLoginHandler();
      const newTokenData = await authenticator.login({ scope, redirectUri, returnUri, ...options }, handler, abort.signal);

      if (newTokenData.token.refreshToken) {
        await storage.saveTokens({
          idToken: newTokenData.token.idToken,
          refreshToken: newTokenData.token.refreshToken,
          expirationTime: newTokenData.token.expirationTime,
        });
      } else {
        await storage.deleteTokens();
      }
    } catch (error) {
      handleError(error);
    } finally {
      loggingIn.current = false;
    }
  }

  async function logout() {
    try {
      const idToken = tokenContext.current?.token.identityToken
        ?? (await storage.loadTokens())?.idToken
        ?? null;

      await storage.deleteTokens();

      const handler = events.createLogoutHandler();
      await authenticator.logout({ redirectUri, customLogoutEndpoint, idToken }, handler, abort.signal);
    } catch (error) {
      handleError(error);
    }
  }

  function trackLastLoginRedirect() {
    const lastLoginRedirectTime = window.sessionStorage.getItem(lastLoginRedirectKey);
    if (lastLoginRedirectTime) {
      const lastLoginRedirect = DateTime.fromISO(lastLoginRedirectTime);
      if (lastLoginRedirect.plus({ seconds: 30 }).toMillis() > Date.now()) {
        reportError(new Error("Repeated login redirect detected."));
      }
    }
    window.sessionStorage.setItem(lastLoginRedirectKey, DateTime.now().toISO());
  }

  function exceedsMaximumMinutesSinceLastAuth(jwt: JwtPayload): boolean {
    if (!maximumMinutesSinceLastAuth) {
      return false;
    }

    const minutesSinceLastAuth = sinceLastAuth(jwt);
    if (minutesSinceLastAuth && minutesSinceLastAuth > maximumMinutesSinceLastAuth) {
      return true;
    }

    return false;
  }

  async function signInCallback(): Promise<void> {
    if (isSigningIn() && !tokenContext.current) {
      try {
        const handler = events.createLoginHandler();
        const response = await authenticator.completeAuthorizationRequest(redirectUri, handler, abort.signal);

        if (response) {
          updateTokenContext(response.token);

          navigate(response.state?.returnUri ?? "/", { replace: true });
        } else {
          navigate("/");
        }
      } catch (error) {
        handleError(error);
      }
    }
  }

  function updateTokenContext(token: AccessTokenIdentity | null) {
    if (token) {
      if (tokenContext.current) {
        tokenContext.current.token.refresh(token);
      } else {
        tokenContext.set({ token: new AccessToken(token) });
      }
    } else {
      tokenContext.set(null);
    }

    handleTokenDataChanged(token);
  }

  async function handleTokenDataChanged(token: AccessTokenIdentity | null) {
    if (token?.refreshToken) {
      await storage.saveTokens({
        idToken: token.idToken,
        refreshToken: token.refreshToken,
        expirationTime: token.expirationTime,
      });
    } else {
      await storage.deleteTokens();
    }

    if (!token) {
      // If no token is returned, then force them to login again
      await loginRedirect({ forceAuth: true });
    }
  }

  function sinceLastAuth(jwt: JwtPayload) {
    const lastAuth = getLastAuthDate(jwt);
    if (!lastAuth) {
      return null;
    }
    return (Date.now() - lastAuth.valueOf()) / 60000;
  }

  function getLastAuthDate(jwt: JwtPayload) {
    const authTime = jwt.auth_time;
    if (authTime) {
      return new Date(Number(authTime) * 1000);
    }

    return null;
  }
}
