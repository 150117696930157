import { Note } from "@remhealth/apollo";
import { Illustrations } from "@remhealth/core";
import { Button, NonIdealState, Svg } from "@remhealth/ui";
import { Text } from "~/text";
import { IllustrationWrapper } from "./inaccessibleNote.styles";

export interface InaccessibleNoteProps {
  note: Note;
  onRequestAccessClick?: () => void;
}

export const InaccessibleNote = (props: InaccessibleNoteProps) => {
  const { note, onRequestAccessClick } = props;

  return (
    <IllustrationWrapper>
      <NonIdealState
        action={note.subject.resource && <Button minimal intent="primary" label={Text.RequestAccess} onClick={onRequestAccessClick} />}
        icon={<Svg src={Illustrations.NoAccess} />}
        title={Text.NoNoteAccess}
      />
    </IllustrationWrapper>
  );
};
