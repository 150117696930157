import styled, { css } from "styled-components";

export const BlockContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InlineContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface MultilineContentProps {
  $lines: number;
}

export const MultilineContent = styled.div<MultilineContentProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: ${props => props.$lines};
  -webkit-box-orient: vertical;
`;

export interface TooltipContentProps {
  $maxWidth?: number | null;
}

export const TooltipContent = styled.div<TooltipContentProps>`
  ${props => props.$maxWidth && css`
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props.$maxWidth}px;
    word-break: ${props.$maxWidth > 400 ? "break-all" : "normal"};
  `};
`;
