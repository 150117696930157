import styled, { css } from "styled-components";
import { PlaceholderCss } from "~/blots/placeholderBlot";
import { MentionCss } from "~/blots/mentionBlot";
import { ListCss, TypographyCss } from "./editableContent.styles";

export const ArticleCss = css`
  line-height: calc(20 / 14);

  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin: 0;
    &+ h1, &+ h2, &+ h3, &+ h4, &+ h5, &+ h6, &+ p, &+ ul, &+ ol {
      margin-top: 10px;
    }
  }

  && + && {
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child,
    p:first-child,
    ul:first-child,
    ol:first-child {
      margin-top: 10px;
    }
  }

  ul, ol {
    padding-left: 1.5em;
  }

  ${TypographyCss};
  ${ListCss};
  ${MentionCss};
  ${PlaceholderCss};

  rh-mention.interactive {
    cursor: pointer;
  }
`;

export const Article = styled.span`
  ${ArticleCss};
`;
