import { DependencyList, EffectCallback, useEffect } from "react";
import { useWasMounted } from "./useWasMounted";

/**
 * Like `useEffect`, but does not fire on the initial mount.
 */
export function useUpdateEffect(effect: EffectCallback, dependencies?: DependencyList) {
  const mounted = useWasMounted();

  useEffect(() => {
    if (mounted) {
      return effect();
    }
  }, dependencies);
}
