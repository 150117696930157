import React from "react";
import classnames from "classnames";

import { HTMLTable, HTMLTableProps } from "./htmlTable";
import { Container, ContainerBody } from "./gridView.styles";

export interface GridProps<T> extends Omit<HTMLTableProps, "striped" | "bordered"> {
  items: T[];
  headerRenderer: () => JSX.Element;
  rowRenderer: (item: T, index: number) => JSX.Element;
  noResults?: () => JSX.Element;
  stickyHeader?: boolean;

  /**
   * If true, the table will not have a background color.
   * @default false
   */
  transparent?: boolean;

  /**
   * If true, the table will fill its container.
   * @default true
   */
  fill?: boolean;

  /**
   * If true, the content in table cells will be allowed to wrap lines.
   * @default false
   */
  allowWrap?: boolean;
}

export function Grid<T>(props: GridProps<T> & React.HTMLAttributes<HTMLDivElement>) {
  const {
    allowWrap = false,
    className,
    compact,
    fill = true,
    fixed,
    headerRenderer,
    interactive,
    items,
    noResults,
    rowRenderer,
    stickyHeader = false,
    transparent = false,
    ...divProps
  } = props;

  const hasNoResults = items.length === 0;

  return (
    <Container $allowWrap={allowWrap} $fill={fill} $stickyHeader={stickyHeader} $transparent={transparent} className={classnames("paging-grid", className)} {...divProps}>
      <ContainerBody className="paging-grid-body">
        <HTMLTable compact={compact} fixed={fixed} interactive={interactive && !hasNoResults} stickyHeader={stickyHeader}>
          <thead>
            {headerRenderer()}
          </thead>
          <tbody>
            {hasNoResults
              ? noResults?.()
              : items.map(rowRenderer)}
          </tbody>
        </HTMLTable>
      </ContainerBody>
    </Container>
  );
}
