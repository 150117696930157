import styled from "styled-components";
import { ArticleCss } from "@remhealth/compose";
import { NoteSummaryPreview } from "./noteSummaryPreview";

export const Container = styled.div`
  display: none;
`;

export const StyledNotePrint = styled(NoteSummaryPreview)`
  overflow-x: hidden;
  padding: 0;
  img.signature-image {
    max-height: 67px;
    max-width: 200px;
  }
  @media print {
    h1, h2, h3, h4, h5, h6 {
      page-break-after: avoid;
    }
    section {
      page-break-inside: avoid;
    }
    .signature-section {
      page-break-before: avoid;
    }
    .form-section {
      page-break-inside: auto;
    }
    .text-section {
      page-break-inside: auto;

      article {
        ${ArticleCss};
      }
    }
  }
`;
