// eslint-disable-next-line no-restricted-imports
import * as Yup from "yup";
import { ValidationError } from "yup";

import "./requiredWhen";
import "./unless";
import "./unique";

export * from "./prepareForValidation";

Yup.setLocale({
  mixed: {
    notType: ({ path }) => {
      return `${path} is invalid.`;
    },
  },
  date: {
    min: ({ path, min }) => `${path ?? ""} field must be later than ${min.toString()}`,
    max: ({ path, max }) => `${path ?? ""} field must be earlier than ${max.toString()}`,
  },
  string: {
    // Improve some of Yup's default warnings that are too tech-savvy
    trim: ({ path }) => `${path ?? ""} cannot begin or end with whitespace`,
    lowercase: ({ path }) => `${path ?? ""} must be lowercase`,
    uppercase: ({ path }) => `${path ?? ""} must be uppercase`,
  },
});

// Ensures we cannot leave out optional properties (although they can be undefined)
export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P] | undefined);
};

export { ValidationError, Yup };
