import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import classnames from "classnames";
import { useDebouncedEffect, useDebouncer } from "../hooks";
import type { SimpleBarHandle, SimpleBarProps } from "./simplebar";
import { useColorScheme } from "./theme";

const LazySimpleBar = React.lazy(async () => import("./simplebar").then(({ default: Component }) => {
  return {
    default: forwardRef((props: SimpleBarProps, ref: Ref<SimpleBarHandle>) => (
      <Component ref={ref} {...props} />
    )),
  };
}));

export interface IScrollbarProps extends Omit<React.HTMLAttributes<HTMLElement>, "onScroll"> {
  /**
   * If true, the scrollbar will hide when mouse is not hovering.
   * @default true
   */
  autoHide?: boolean;

  /**
   * The minimum height (or width if horizontal) that the scrollbar thumb can be.
   * @default 25
   */
  minThumbSize?: number;

  /**
   * The maximum height (or width if horizontal) that the scrollbar thumb can be.
   * Null means no max is enforced.
   * @default null
   */
  maxThumbSize?: number | null;

  /**
   * Set to true if scrollbar is meant for dark areas (scrollbar will be white).
   */
  darkTheme?: boolean;

  /**
   * Allow user to click on track to scroll.
   * @default true
   */
  clickOnTrack?: boolean;

  onScroll?: (scrollTop: number, scrollHeight: number, containerHeight: number) => void;
}

export type Scrollbar = SimpleBarHandle;

export const Scrollbar = forwardRef((props: IScrollbarProps, ref: Ref<Scrollbar | null>) => {
  const {
    "aria-label": ariaLabel = "Scrollable Content",
    children,
    autoHide = true,
    clickOnTrack = true,
    minThumbSize = 25,
    maxThumbSize,
    className,
    darkTheme,
    onScroll,
    onWheel,
    ...divProps
  } = props;

  const { colorScheme } = useColorScheme();
  const scrollDebouncer = useDebouncer(20);
  const innerRef = useRef<SimpleBarHandle>(null);
  const horizontal = useRef(false);
  const scrollingTo = useRef<number>();
  const checkScrollFrame = useRef<number>();

  useDebouncedEffect(() => {
    const scrollEl = innerRef.current?.getScrollElement();
    if (scrollEl) {
      const style = window.getComputedStyle(scrollEl);
      horizontal.current = (style.overflowX === "scroll" || style.overflowX === "auto") && style.overflowY === "hidden";
    }
  }, 100);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    const scrollEl = innerRef.current?.getScrollElement();
    scrollEl?.addEventListener("scroll", handleScroll);

    return () => {
      scrollEl?.removeEventListener("scroll", handleScroll);
    };
  }, [onScroll]);

  const isDarkTheme = darkTheme !== undefined ? darkTheme : colorScheme.dark;

  return (
    <React.Suspense fallback={<>{children}</>}>
      <LazySimpleBar
        ref={innerRef}
        ariaLabel={ariaLabel}
        autoHide={autoHide}
        className={classnames(className, isDarkTheme ? "dark-theme" : "")}
        clickOnTrack={clickOnTrack}
        scrollbarMaxSize={maxThumbSize ?? undefined}
        scrollbarMinSize={minThumbSize}
        onWheel={handleWheel}
        {...divProps}
      >
        {children}
      </LazySimpleBar>
    </React.Suspense>
  );

  function handleScroll() {
    scrollDebouncer.delay(fireScroll);
  }

  function fireScroll() {
    if (innerRef.current && onScroll) {
      const scrollEl = innerRef.current.getScrollElement();
      if (scrollEl) {
        onScroll(scrollEl.scrollTop, scrollEl.scrollHeight, scrollEl.clientHeight);
      }
    }
  }

  // Automatically convert wheel scrolling to horizontal scroll if scroll area is only horizontally scrollable
  function handleWheel(event: React.WheelEvent<HTMLElement>) {
    if (horizontal.current) {
      const scrollEl = innerRef.current?.getScrollElement();

      if (scrollEl && event.deltaY && !event.shiftKey) {
        const newLeft = scrollingTo.current ? scrollingTo.current + event.deltaY : scrollEl.scrollLeft + event.deltaY;
        scrollingTo.current = newLeft;

        scrollEl.scrollTo({ left: newLeft, behavior: "smooth" });

        stopCheckScrolling();
        checkScrollFrame.current = requestAnimationFrame(() => checkScrolling(newLeft, event.deltaY > 0));
      }
    }

    onWheel?.(event);
  }

  function checkScrolling(expectedLeft: number, scrollingRight: boolean) {
    stopCheckScrolling();

    const scrollEl = innerRef.current?.getScrollElement();
    if (!scrollEl) {
      scrollingTo.current = undefined;
      return;
    }

    const reachedGoal = scrollingRight ? scrollEl.scrollLeft >= expectedLeft : scrollEl.scrollLeft <= expectedLeft;
    if (reachedGoal) {
      scrollingTo.current = undefined;
      return;
    }

    checkScrollFrame.current = requestAnimationFrame(() => checkScrolling(expectedLeft, scrollingRight));
  }

  function stopCheckScrolling() {
    if (checkScrollFrame.current) {
      cancelAnimationFrame(checkScrollFrame.current);
      checkScrollFrame.current = undefined;
    }
  }
});
