import styled from "styled-components";
import { FormGroup, GridDialog } from "@remhealth/ui";

export const Modal = styled(GridDialog)`
   && {
    height: calc(70vh / var(--zoom));
    width: 55%;
    min-height: 460px;
    min-width: 540px;
  }
`;

export const SearchGroup = styled(FormGroup)`
  margin: 10px 0;
`;
