import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { sortBy } from "lodash-es";
import { Disable, Refresh } from "@remhealth/icons";
import { Appointment, Encounter, Note, Patient, Practitioner, Reference, ZonedDateTime } from "@remhealth/apollo";
import { DateFormats, Elevation, Ellipsize, Tag, Tooltip, stringToHsl, useAbort, useStopwatch } from "@remhealth/ui";
import { usePersonalPreferences, useProductFlag, useUserProfile } from "@remhealth/host";
import { PractitionerBanner, useStore, useStoreItem } from "@remhealth/core";
import { PatientBanner } from "~/avatars/patientBanner";
import { StackedAvatar } from "~/avatars/stackedAvatar";
import { getAppointmentAttendees, getAppointmentTime, isGroupAppointment, useAppointmentStatus } from "./utils";
import { AppointmentHsl, getAppointmentDuration, getAppointmentLocation } from "./appointmentDetail";
import { AppointmentGroupBanner } from "./appointmentGroupBanner";
import {
  AppointmentCardWrapper,
  AppointmentDuration,
  AppointmentLocation,
  CancelledIcon,
  Column,
  EmptyColumn,
  Grid,
  Muted,
  Next,
  Recurrence,
  RecurrenceIcon,
  RowSection,
  ServiceType,
  TagColumn,
  TimeWrapper
} from "./appointmentCard.styles";

export interface AppointmentCardProps {
  appointment: Appointment;
  associatedEncounter?: Encounter;
  showPatient?: boolean;
  showStaff?: boolean;
  showDate?: boolean;
  isNext?: boolean;
  active?: boolean;
  condensed?: boolean;
  interactive?: boolean;
  onClick?: () => void;
  onEncounterDelete?: (resourceId: string) => void;
  onEncounterSet?: (item: Encounter) => void;
}

export const AppointmentCard = (props: AppointmentCardProps) => {
  const {
    associatedEncounter,
    active = false,
    condensed = false,
    showPatient = false,
    showStaff = false,
    showDate = false,
    isNext = false,
    interactive = true,
    onClick,
    onEncounterDelete,
    onEncounterSet,
  } = props;

  const preferences = usePersonalPreferences();
  const { getStatus } = useAppointmentStatus();
  const store = useStore();
  const user = useUserProfile();
  const abort = useAbort();

  const appointment = useStoreItem(store.appointments, props.appointment);
  const invitedAttendeesOnly = useProductFlag("AppointmentInvitedAttendeesOnly");

  const [currentNote, setCurrentNote] = useState<Note>();
  const [patients, setPatients] = useState<Patient[] | Reference<Patient>[]>([]);
  const [staffs, setStaffs] = useState<Practitioner[]>([]);

  const minuteTimer = useStopwatch();
  const nextDuration = useMemo(() => {
    if (isNext) {
      const appointmentTime = appointment.start ? ZonedDateTime.toDateTime(appointment.start) : DateTime.now();
      return DateFormats.relative(appointmentTime);
    }
    return undefined;
  }, [isNext, appointment.start, minuteTimer]);

  useEffect(() => associatedEncounter
    ? store.encounters.onItemSet(associatedEncounter.partition, associatedEncounter.id, handleEncounterSet)
    : undefined, [associatedEncounter]);

  useEffect(() => appointment
    ? store.encounters.onSet(handleEncounterSet)
    : undefined, [appointment.id]);

  useEffect(() => associatedEncounter
    ? store.encounters.onDeleted(handleEncounterDelete)
    : undefined, [associatedEncounter]);

  useEffect(() => currentNote
    ? store.notes.onItemSet(currentNote.partition, currentNote.id, setCurrentNote)
    : undefined, [currentNote]);

  useEffect(() => {
    loadResources();
  }, []);

  const status = getStatus(appointment, associatedEncounter);

  return (
    <div>
      {isNext && <Next>{nextDuration}</Next>}
      <AppointmentCardWrapper
        $active={active}
        $condensed={condensed}
        $serviceColor={appointment.serviceType ? stringToHsl(appointment.serviceType?.id, AppointmentHsl) : undefined}
        aria-label={`Appointment on ${DateFormats.dateTime(ZonedDateTime.toDate(appointment.start))}`}
        data-appt-time={DateFormats.attrDateTime(ZonedDateTime.toDate(appointment.start))}
        elevation={Elevation.ONE}
        interactive={interactive}
        role={interactive ? "row" : undefined}
        onClick={onClick}
      >
        <Grid $condensed={condensed}>
          <Column>
            <TimeWrapper>
              {showDate
                ? DateFormats.date(ZonedDateTime.toDate(appointment.start))
                : getAppointmentTime(appointment, preferences?.militaryTime)}
              {appointment.schedule?.resource?.recurrence && (
                <Recurrence>
                  <RecurrenceIcon icon={<Refresh />} size={10} />
                </Recurrence>
              )}
              {status.isCancelled && (
                <Tooltip content={appointment.cancelReason?.text ?? ""}>
                  <CancelledIcon icon={<Disable />} intent="danger" size={10} />
                </Tooltip>
              )}
            </TimeWrapper>
            <Muted>
              {showDate && <span>{getAppointmentTime(appointment, preferences?.militaryTime)}</span>}
              <AppointmentDuration>{getAppointmentDuration(appointment)}</AppointmentDuration>
            </Muted>
          </Column>
          <Column>
            <ServiceType><Ellipsize>{appointment.serviceType?.display}</Ellipsize></ServiceType>
            <AppointmentLocation><Ellipsize>{getAppointmentLocation(appointment)}</Ellipsize></AppointmentLocation>
          </Column>
          {!condensed && (
            <>
              {showPatient && isGroupAppointment(appointment) && (
                <Column>
                  <AppointmentGroupBanner small appointment={appointment} />
                </Column>
              )}
              {showPatient && !isGroupAppointment(appointment) && (
                patients.length === 0
                  ? <EmptyColumn />
                  : patients.length === 1
                    ? <Column><PatientBanner patient={patients[0]} scale="small" /></Column>
                    : <Column><StackedAvatar people={patients} /></Column>
              )}
              {!showPatient && showStaff && (
                staffs.length === 0
                  ? <EmptyColumn />
                  : <Column>{staffs.length === 1 ? <PractitionerBanner practitioner={staffs[0]} scale="small" /> : <StackedAvatar people={staffs} />}</Column>
              )}
              {!showPatient && !showStaff && <Column />}
              <TagColumn>{renderTag()}</TagColumn>
            </>
          )}
        </Grid>
        {condensed && (
          <RowSection>
            {renderAvatar()}
            <TagColumn>{renderTag()}</TagColumn>
          </RowSection>
        )}
      </AppointmentCardWrapper>
    </div>
  );

  function renderTag() {
    return <Tag minimal intent={status.intent}>{status.status}</Tag>;
  }

  function renderAvatar() {
    if (!showPatient) {
      return <div />;
    }

    if (isGroupAppointment(appointment)) {
      return <AppointmentGroupBanner small appointment={appointment} />;
    }

    if (patients.length === 0) {
      return <div />;
    }

    if (patients.length === 1) {
      return <PatientBanner patient={patients[0]} scale="small" />;
    }

    return <StackedAvatar people={patients} />;
  }

  function handleEncounterSet(item: Encounter) {
    if (item.appointment?.id === appointment.id && !item.partOf) {
      onEncounterSet?.(item);
    }
  }

  function handleEncounterDelete(resourceId: string) {
    if (associatedEncounter?.id === resourceId) {
      onEncounterDelete?.(resourceId);
    }
  }

  async function loadResources() {
    const [staffExpandables, patientExpandables] = getAppointmentAttendees(appointment, invitedAttendeesOnly);

    const patients = await store.patients.expand(patientExpandables, { abort: abort.signal });
    const staffs = await store.practitioners.expand(staffExpandables.filter(s => s.id !== user.id), { abort: abort.signal });
    const unexpandedPatients = patientExpandables.filter(p => !patients.some(e => e.id === p.id)) as Reference<Patient>[];

    setPatients(sortBy([...unexpandedPatients, ...patients], "display"));
    setStaffs(sortBy(staffs, "display"));
  }
};
