import styled from "styled-components";

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
`;

export interface ImageProps {
  $cover: boolean;
}

export const Image = styled.img<ImageProps>`
  background-size: ${props => props.$cover ? "cover" : "contain"};
  background-repeat: no-repeat;
  background-position: center center;
`;
