import Inline from "quill/blots/inline";

export type InsertBlotData = {
  source?: string;
  reference?: string;
};

export class InsertBlot extends Inline {
  public static readonly blotName = "insert";
  public static readonly tagName = "rh-ins";

  public static create(data: InsertBlotData) {
    const node = document.createElement(InsertBlot.tagName);
    InsertBlot.init(node, data);
    return node;
  }

  public static value(node: HTMLElement): InsertBlotData {
    const source = node.getAttribute("source");
    const reference = node.getAttribute("reference");

    return {
      source: source ?? undefined,
      reference: reference ?? undefined,
    } satisfies InsertBlotData;
  }

  public static formats(domNode: HTMLElement): {} | InsertBlotData {
    if (InsertBlot.tagName.toLowerCase() !== domNode.tagName.toLowerCase()) {
      return {};
    }

    return InsertBlot.value(domNode);
  }

  public static init(node: HTMLElement, insert: InsertBlotData) {
    if (insert.source) {
      node.setAttribute("source", insert.source);
    } else {
      node.removeAttribute("source");
    }

    if (insert.reference) {
      node.setAttribute("reference", insert.reference);
    } else {
      node.removeAttribute("reference");
    }
  }

  public format(name: string, value: InsertBlotData) {
    if (name === InsertBlot.blotName && value) {
      InsertBlot.init(this.domNode, value);
    } else {
      super.format(name, value);
    }
  }
}

export function isInsertBlot(blot: any): blot is InsertBlot {
  return blot && blot instanceof InsertBlot;
}
