// eslint-disable-next-line no-restricted-imports
import * as Yup from "yup";

declare module "yup" {
  export interface ArraySchema<T> {
    unique(message?: TestOptionsMessage, equalityComparer?: (a: T, b: T) => boolean): ArraySchema<T>;
  }
}

Yup.addMethod<Yup.ArraySchema<any>>(Yup.array, "unique", function(
  // eslint-disable-next-line no-template-curly-in-string
  message = "${path} cannot have duplicates.",
  equalityComparer = (a: any, b: any) => a === b
) {
  return this.test("unique", message, (list: any[]) => {
    const a: any[] = [];
    for (const b of list) {
      if (a.some(t => equalityComparer(t, b))) {
        return false;
      }

      a.push(b);
    }
    return true;
  });
});
