import { isPlainObject } from "lodash-es";
import { isReference } from "@remhealth/apollo";

export function prepareForValidation<T extends Record<string, any>>(values: T): T {
  if (isPlainObject(values)) {
    const replacer = getCircularReferenceReplacer();
    removeCircularReferences(replacer, [], values);
  }
  return values;
}

function removeCircularReferences(replacer: CircularReferenceReplacer, path: string[], values: Record<string, any>): void {
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const item = values[key];
      if (Array.isArray(item)) {
        const newValues = item.filter((value: any, index: number) => {
          if (value) {
            value = replacer(item, String(index), value);

            if (!value) {
              return false;
            }
          }

          if (isPlainObject(value)) {
            removeCircularReferences(replacer, [...path, key, String(index)], value);
          }

          return true;
        });

        // Ensure same array instance
        values[key].length = 0;
        values[key].push(...newValues);
      } else {
        let value = values[key];

        if (value) {
          value = replacer(values, key, value);
        }

        if (isPlainObject(value)) {
          removeCircularReferences(replacer, [...path, key], value);
        }

        values[key] = value;
      }
    }
  }
}

type CircularReferenceReplacer = (owner: any, key: string, value: any) => any;

function getCircularReferenceReplacer(): CircularReferenceReplacer {
  const stack: any[] = [];
  const keys: string[] = [];

  return function(owner: any, key: string, value: any) {
    if (stack.length > 0) {
      const thisPos = stack.indexOf(owner);

      if (~thisPos) {
        stack.splice(thisPos + 1);
        keys.splice(thisPos, Number.POSITIVE_INFINITY, key);
      } else {
        stack.push(owner);
        keys.push(key);
      }

      const circIndex = stack.indexOf(value);
      if (~circIndex) {
        if (isReference(value)) {
          value = {
            ...value,
            resource: undefined,
          };
        } else {
          value = undefined;
        }
        stack[circIndex] = value;
      }
    } else {
      stack.push(owner);
    }

    return value;
  };
}
