import { toHtml } from "hast-util-to-html";
import { type ElementNode, type TextNode, isElementNode } from "@remhealth/compose";
import { transformHtml } from "./parsers";
import { splitAtNode } from "./split";

export type RemoveParagraphCondition =
  // Remove all paragraphs
  | "always"
  // Only the first paragraph
  | "first-only"
  // Only if there is one paragraph, otherwise none
  | "single-or-none";

export function removeParagraphs(html: string, condition: RemoveParagraphCondition): string;
export function removeParagraphs(html: string, condition: RemoveParagraphCondition, returnTuple: true): [html: string, isMultiparagraph: boolean];
export function removeParagraphs(html: string, condition: RemoveParagraphCondition, returnTuple = false): string | [html: string, isMultiparagraph: boolean] {
  const isMultiparagraph = html.lastIndexOf("<p>") > 0;

  if (condition === "single-or-none" && isMultiparagraph) {
    return returnTuple ? [html, true] : html;
  }

  html = transformHtml(html, {
    transformElement: (element, index) => {
      if (condition !== "first-only" || index === 0) {
        if (element.tagName.toLowerCase() === "p") {
          element.tagName = "span";

          // Force space between what used to be paragraphs
          element.children.push({ type: "text", value: " " } as TextNode);
        }
      }
    },
  });

  return returnTuple ? [html, isMultiparagraph] : html;
}

export function mergeParagraphs(left: string, right: string, delimiter?: string): string;
export function mergeParagraphs(left: string, right: string, delimiter: string | undefined, returnTuple: true): [html: string, isMultiparagraph: boolean];
export function mergeParagraphs(left: string, right: string, delimiter?: string, returnTuple = false): string | [html: string, isMultiparagraph: boolean] {
  let leftHasElements = false;
  let leftParagraphCount = 0;
  const leftContent = transformHtml(left, {
    transformElement: (element) => {
      leftHasElements = true;
      if (element.tagName.toLowerCase() === "p") {
        leftParagraphCount++;
        element.tagName = "span";
      }
    },
  });

  let rightHasElements = false;
  let rightParagraphCount = 0;
  const rightContent = transformHtml(right, {
    transformElement: (element) => {
      rightHasElements = true;

      if (element.tagName.toLowerCase() === "p") {
        element.tagName = "span";
        rightParagraphCount++;
      }
    },
  });

  if (leftParagraphCount > 1 || rightParagraphCount > 1) {
    left = leftHasElements ? left : `<p>${left}</p>`;
    right = rightHasElements ? right : `<p>${right}</p>`;
    const result = left + right; // No delimiter for multiparagraphs
    return returnTuple ? [result, true] : result;
  }

  const result = `<p>${leftContent}${delimiter ?? ""}${rightContent}</p>`;
  return returnTuple ? [result, false] : result;
}

export function splitAtElement(html: string, inline: boolean, predicate: (element: ElementNode) => boolean): [before: string | null, element: string | null, after: string | null] {
  const split = splitAtNode(html, inline, n => isElementNode(n) && predicate(n));

  if (split.length !== 3) {
    return [null, html, null];
  }

  const [beforeNodes, elementNode, afterNodes] = split;

  let before: string | null = beforeNodes.length > 0 ? toHtml(beforeNodes) : null;
  let element: string | null = elementNode ? toHtml(isElementNode(elementNode) ? elementNode.children : elementNode) : null;
  const after: string | null = afterNodes.length > 0 ? toHtml(afterNodes) : null;

  if (!element) {
    element = before;
    before = null;
  }

  return [before, element, after];
}
