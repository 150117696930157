import { useEffect, useRef } from "react";
import { useDebouncer } from "./useDebouncer";
import { useStateIfMounted } from "./useStateIfMounted";
import { useUpdateEffect } from "./useUpdateEffect";
import { PagingController } from "./usePagingController";

export interface PagingResult<T> {
  items: T[];
  hasMore: boolean;
}

export interface PageLoaderProps<T> {
  controller: PagingController<T>;
  canLoadMore: boolean;
  pageSize: number;
  preload: number;
  onLoadMore: (limit: number, abort: AbortSignal) => void | Promise<void>;
  onItemsChange?: (items: T[]) => void;
  onPageChange?: (page: number) => void;
}

export interface PageLoaderState<T> {
  /** The items loaded. */
  readonly items: ReadonlyArray<T>;

  /** The current page. */
  readonly page: number;

  /** If loading is occurring. */
  readonly loading: boolean;
}

export function usePageLoader<T>(props: PageLoaderProps<T>): PageLoaderState<T> {
  const debouncer = useDebouncer(100);

  const {
    canLoadMore,
    controller,
    pageSize,
    preload,
    onLoadMore,
    onItemsChange,
    onPageChange,
  } = props;

  const pagesLoaded = Math.ceil(controller.count / pageSize);
  const [loading, setLoading] = useStateIfMounted(false);

  const failedSession = useRef<number>();
  const abortLoad = useRef<AbortController>();

  // Change of page size should cause reset
  useUpdateEffect(() => {
    controller.reset(false);
  }, [pageSize]);

  // Load more pages if necessary
  useEffect(() => {
    if (failedSession.current !== controller.session) {
      failedSession.current = undefined;
      setLoading(true);
      debouncer.delay(() => loadNextPage(controller.page, controller.count, pageSize));
    }
  }, [controller.page, pageSize, canLoadMore, controller.count, controller.session]);

  // Report page changes
  useEffect(() => {
    // Normalize "last" to an actual final page number
    if (canLoadMore && controller.page === "last") {
      const page = Math.ceil(controller.count / pageSize);
      controller.setPage(page);
      onPageChange?.(page);
    } else if (controller.page !== "last") {
      onPageChange?.(controller.page);
    }
  }, [controller.page, canLoadMore]);

  return {
    items: controller.items,
    page: controller.page === "last" ? pagesLoaded : controller.page,
    loading,
  };

  async function loadNextPage(currentPage: number | "last", itemsCount: number, pageSize: number) {
    const pagesLoaded = Math.ceil(itemsCount / pageSize);

    try {
      if (!canLoadMore) {
        return;
      }

      if (currentPage === "last") {
        // Load next page
        await loadMore(pageSize);
      } else {
        const fullPageCount = currentPage * pageSize;
        const remainder = itemsCount > fullPageCount ? 0 : itemsCount % pageSize;
        if (remainder !== 0) {
          // Load remainder of current page if not filled
          await loadMore(Math.min(pageSize - remainder, 100));
        } else if (currentPage > (pagesLoaded - preload)) {
          // Load next page(s)
          const pagesWanted = currentPage - pagesLoaded + preload;
          await loadMore(Math.min(pagesWanted * pageSize, 100));
        }
      }
    } finally {
      setLoading(false);
    }
  }

  async function loadMore(limit: number) {
    if (abortLoad.current) {
      abortLoad.current.abort();
    }

    const abort = new AbortController();
    abortLoad.current = abort;

    try {
      await onLoadMore(limit, abort.signal);

      if (!abort.signal.aborted) {
        onItemsChange?.(controller.items);
      }
    } catch (error) {
      failedSession.current = controller.session;
      throw error;
    } finally {
      if (abortLoad.current === abort) {
        abortLoad.current = undefined;
      }
    }
  }
}
