import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Practitioner, Reference, isReference } from "@remhealth/apollo";
import { Button, FormScope, Spinner } from "@remhealth/ui";
import { PractitionerProfile, useProfileNav } from "~/users";
import { useStore } from "~/stores";
import { getAvatarColors } from "./avatarColors";
import { BackgroundCover, Body, Container, Footer } from "./practitionerCard.styles";

export interface PractitionerCardProps {
  practitioner: Practitioner | Reference<Practitioner>;
  cover?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const PractitionerCard = (props: PropsWithChildren<PractitionerCardProps>) => {
  const { children, cover, onClick } = props;

  const { openProfile } = useProfileNav();
  const store = useStore();

  const colors = useMemo(() => getAvatarColors(props.practitioner.id), [props.practitioner.id]);
  const [practitioner, setPractitioner] = useState<Practitioner | undefined>(isReference(props.practitioner) ? props.practitioner.resource : props.practitioner);

  useEffect(() => {
    load();
  }, [props.practitioner.id]);

  return (
    <FormScope controlId={`Profile for ${props.practitioner.display}`}>
      <Container $gradient={colors.outlineGradient} $hasImage={!!cover} onClick={onClick}>
        {cover && <BackgroundCover $image={cover} />}
        <Body>
          {practitioner ? <PractitionerProfile showSupervisors practitioner={practitioner} /> : <Spinner size={60} />}
          {children}
        </Body>
        <Footer>
          <Button minimal intent="primary" label="View Profile" onClick={handleViewProfile} />
        </Footer>
      </Container>
    </FormScope>
  );

  function handleViewProfile() {
    openProfile(props.practitioner);
  }

  async function load() {
    if (!practitioner && isReference(props.practitioner)) {
      const expanded = await store.practitioners.expand(props.practitioner);
      setPractitioner(expanded);
    }
  }
};
