import { lazy } from "@remhealth/ui";

export type { BellsPlaceholdersProps } from "./bellsPlaceholders";
export type { LanguageProps, SpeechRuleProvider } from "./language/language";
export type { PatientMentionsProps } from "./patientMentions";
export type { TranslateProps } from "./translate";

export const BellsPlaceholders = lazy(() => import("./bellsPlaceholders"));
export const Language = lazy(() => import("./language/language"));
export const PatientMentions = lazy(() => import("./patientMentions"));
export const SelectionMenu = lazy(() => import("./selectionMenu"));
export const Translate = lazy(() => import("./translate"));

export * from "./snippetDialog";
export * from "./language/speechContext";
export * from "./textExpansionDialog";
export * from "./usageMetrics";
