import { PropsWithChildren } from "react";
import { ButtonGroup, FormScope } from "@remhealth/ui";
import { Container, SourceButton } from "./productToggle.styles";

export interface ProductToggleProps {
  ehr: boolean;
  productLabel: string;
  small?: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (ehr: boolean) => void;
}

export const ProductToggle = (props: PropsWithChildren<ProductToggleProps>) => {
  const { children, ehr, className, disabled, small, productLabel, onChange } = props;
  return (
    <FormScope controlId="productToggle">
      <Container className={className}>
        <ButtonGroup toggles small={small}>
          <SourceButton active={!ehr} disabled={disabled} label="Bells" onClick={handleBellsClick} />
          <SourceButton active={ehr} disabled={disabled} label={productLabel} onClick={handleEhrClick} />
        </ButtonGroup>
        {children}
      </Container>
    </FormScope>
  );

  function handleBellsClick() {
    if (ehr) {
      onChange(false);
    }
  }

  function handleEhrClick() {
    if (!ehr) {
      onChange(true);
    }
  }
};
