interface KeyMap {
  [key: string]: string;
}

const Aliases: KeyMap = {
  cmd: "meta",
  command: "meta",
  escape: "esc",
  minus: "-",
  mod: isMac() ? "meta" : "ctrl",
  option: "alt",
  plus: "+",
  return: "enter",
  win: "meta",
};

/**
* Splits a key combo string into its constituent key values and looks up
* aliases, such as `return` -> `enter`.
*
* Unlike the parseKeyCombo method, this method does NOT convert shifted
* action keys. So `"@"` will NOT be converted to `["shift", "2"]`).
*/
export function normalizeKeyCombo(combo: string, platformOverride?: string): string[] {
  const keys = combo.replace(/\s/g, "").split("+");
  return keys.map(key => {
    const keyName = Aliases[key] != null ? Aliases[key] : key;
    return keyName === "meta" ? isMac(platformOverride) ? "cmd" : "ctrl" : keyName;
  });
}

function isMac(platformOverride?: string) {
  const platform = platformOverride != null ? platformOverride : typeof navigator !== "undefined" ? navigator.platform : undefined;
  return platform == null ? false : /Mac|iPod|iPhone|iPad/.test(platform);
}
