import { useMemo } from "react";
import { find, findKey, uniqBy, upperFirst, values } from "lodash-es";
import { DateTime } from "luxon";
import {
  Coding,
  Coverage,
  CoveragesClient,
  Duration,
  EncounterStatus,
  Group,
  HealthcareService,
  IStore,
  IStores,
  Instant,
  LocalDate,
  LocationRole,
  NoteDefinition,
  NoteSection,
  NoteSectionDefinition,
  type NoteSectionForm,
  NoteSectionFormat,
  NoteTemplate,
  Patient,
  PracticeClients,
  Practitioner,
  Reference,
  RelatedPeopleClient,
  RelatedPerson,
  ServiceCharacteristics,
  VisitStatus,
  knownCodings,
  plusWeekdays,
  weekdayDuration
} from "@remhealth/apollo";
import { createVersionedReference, isDateRangeEffective, useProductFlag } from "@remhealth/host";
import { PlaceholderContext, getCoveragesView, getRelatedPeopleView, replacePlaceholders } from "@remhealth/core";
import { removeUndefined } from "@remhealth/ui";
import { htmlToText } from "@remhealth/compose";
import { Text } from "~/text";

export interface NoteTemplateContext extends PlaceholderContext {
  noteTemplate: NoteTemplate | undefined;
}

export async function initializeNoteSections(
  store: IStores<PracticeClients>,
  sectionDefinitions: NoteSectionDefinition[],
  visitStatus: VisitStatus,
  customDropdownLabel: string | undefined,
  templateContext?: NoteTemplateContext
): Promise<NoteSection[]> {
  const forms = await store.noteSectionForms.expand(sectionDefinitions.flatMap(s => s.form ?? []));

  return sectionDefinitions.flatMap<NoteSection>(sectionDefinition => {
    const section: NoteSection = { ...sectionDefinition, formAnswers: [] };

    // If dropdown label has been updated use latest one in the note
    if (sectionDefinition.format === "CtoneCustomDropdown" && customDropdownLabel) {
      section.name = customDropdownLabel;
    }

    // Must grab versioned reference of form
    if (section.form) {
      const form = forms.find(f => f.id === section.form?.id);
      if (!form) {
        return [];
      }

      if (!isAllowedVisitStatus(form, visitStatus)) {
        return [];
      }

      section.form = {
        ...createVersionedReference(form),
        resource: form,
      };
    }

    if (templateContext?.noteTemplate) {
      const resolvedSection = resolveTemplateTextSection(sectionDefinition, templateContext.noteTemplate, templateContext)
        ?? resolveTemplateFormSection(sectionDefinition, templateContext.noteTemplate, templateContext);

      if (resolvedSection) {
        // Only take the form answers and text sections from the template
        section.text = resolvedSection.text;
        if (section.format === "Form" && resolvedSection.format === "Form") {
          section.formAnswers = resolvedSection.formAnswers;
        }
      }
    }

    return section;
  });
}

export function getSectionsFromNoteDefinition(definition: NoteDefinition, customDropdownLabel?: string): NoteSectionDefinition[] {
  const customDropdownSection = definition.sections.find(section => section.format === "CtoneCustomDropdown");

  return definition.sections.map(section => {
    // If dropdown label has been updated use latest one in the note
    if (customDropdownSection && customDropdownLabel && customDropdownSection.name !== customDropdownLabel && section.format === "CtoneCustomDropdown") {
      return {
        ...section,
        name: customDropdownLabel,
      };
    }
    return section;
  });
}

export async function expandNoteSectionForms(store: IStores<PracticeClients>, references: Reference<NoteSectionForm>[], abort: AbortSignal) {
  const seenIds = new Set<string>();

  while (references.length > 0) {
    const forms = await store.noteSectionForms.expand(references, { abort });

    for (const form of forms) {
      seenIds.add(form.id);

      if (form.meta?.versionId) {
        seenIds.add(form.meta.versionId);
      }
    }

    references = forms.flatMap(f => f.elements.flatMap(e => e.form && !seenIds.has(e.form.versionId ?? e.form.id) ? e.form : []));
  }
}

export function isAllowedVisitStatus(form: NoteSectionForm, visitStatus: VisitStatus): boolean {
  if (form.visitStatuses.length === 0) {
    return true; // No visit status listed means all visit statuses are allowed
  }

  return form.visitStatuses.includes(visitStatus);
}

export function getEncounterStatus(visitStatus: VisitStatus) {
  switch (visitStatus) {
    case VisitStatus.Cancelled: return EncounterStatus.Cancelled;
    case VisitStatus.NoShow: return EncounterStatus.NoShow;
    default: return EncounterStatus.Unknown;
  }
}

export function getVisitStatus(encounterStatus?: EncounterStatus) {
  switch (encounterStatus) {
    case EncounterStatus.Cancelled: return VisitStatus.Cancelled;
    case EncounterStatus.NoShow: return VisitStatus.NoShow;
    default: return VisitStatus.Show;
  }
}

const visitStatusLabels = {
  [VisitStatus.Show]: Text.Show,
  [VisitStatus.NoShow]: Text.NoShow,
  [VisitStatus.Cancelled]: Text.Cancelled,
} as const;

export function renderVisitStatus(item: VisitStatus) {
  return visitStatusLabels[item];
}

export async function fetchCoverages(store: IStore<CoveragesClient>, patient: Reference<Patient>, abort?: AbortSignal, refresh?: boolean): Promise<Coverage[]> {
  const view = getCoveragesView(store, { field: "Order", direction: "Ascending" }, {
    beneficiaryId: patient.id,
    effectiveOn: LocalDate.today(),
  });

  if (refresh) {
    view.reset();
  }

  while (view.canLoadMore) {
    await view.loadMore({ maxItemCount: 100, abort });
  }

  return view.items();
}

export async function fetchGuardian(store: IStore<RelatedPeopleClient>, patient: Reference<Patient>, abort?: AbortSignal, refresh?: boolean): Promise<RelatedPerson | undefined> {
  const guardiansView = getRelatedPeopleView(store, { field: "Name", direction: "Ascending" }, {
    patientId: patient.id,
    effectiveOn: LocalDate.today(),
    relationship: knownCodings.relationship.guardian,
  });

  if (refresh) {
    guardiansView.reset();
  }

  if (guardiansView.canLoadMore && guardiansView.items().length === 0) {
    await guardiansView.loadMore({ maxItemCount: 1, abort });
  }
  return guardiansView.items()[0];
}

export function doesServiceTypeRequireUnits(healthcareService: HealthcareService) {
  return healthcareService.characteristics.includes(ServiceCharacteristics.RequireUnits);
}

export function isServiceTypeBillable(healthcareService: HealthcareService) {
  return healthcareService.characteristics.includes(ServiceCharacteristics.Billable);
}

export function serviceTypeHasSupportsGroup(healthcareService: HealthcareService) {
  return healthcareService.characteristics.includes(ServiceCharacteristics.SupportsGroups);
}

export function isBillableConflict(noteDefinition: NoteDefinition, healthcareService: HealthcareService) {
  return noteDefinition.billable !== isServiceTypeBillable(healthcareService);
}

export function isRequireUnitsSessionTimeConflict(noteDefinition: NoteDefinition, healthcareService: HealthcareService) {
  return doesServiceTypeRequireUnits(healthcareService)
    && !noteDefinition.sections.some(s => s.format === NoteSectionFormat.SessionTime)
    && isServiceTypeBillable(healthcareService);
}

export const multipleOccurringNoteSectionFormats = new Set<NoteSectionFormat>([NoteSectionFormat.Text, NoteSectionFormat.Form]);

export function isNoteSection(section: NoteSectionDefinition | NoteSection, format: NoteSectionFormat, sectionName: string): boolean;
export function isNoteSection(section: NoteSectionDefinition | NoteSection, format: string, sectionName: string): boolean;
export function isNoteSection(section: NoteSectionDefinition | NoteSection, format: string, sectionName: string): boolean {
  if (multipleOccurringNoteSectionFormats.has(section.format)) {
    return section.format === format && section.name === sectionName;
  }
  return section.format === format;
}

export function isSameSection(left: NoteSectionDefinition | NoteSection, right: NoteSectionDefinition | NoteSection): boolean {
  return isNoteSection(left, right.format, right.name);
}

export function isExtraSection(section: NoteSection, definition: NoteDefinition, serviceType: HealthcareService | undefined, visitStatus: VisitStatus): boolean {
  if (section.form?.resource && !isAllowedVisitStatus(section.form.resource, visitStatus)) {
    return true;
  }

  // Defined in Note Type
  if (definition.sections.some(s => isSameSection(s, section))) {
    return false;
  }

  // Defined in service (service based section)
  if (serviceType && serviceType.additionalSections.some(s => isSameSection(s, section))) {
    return false;
  }

  // Defined in service (service based section)
  if (serviceType && serviceType.groupNoteSections.some(s => isSameSection(s, section))) {
    return false;
  }

  // Special sections appended for group notes
  switch (section.format) {
    case NoteSectionFormat.ItineraryTopics:
      return false;
  }

  return true;
}

export function getLocationRole(placeOfService: Coding) {
  const { code, system } = placeOfService;
  const role = findKey(knownCodings.placeOfServices, pos => pos.code === code && pos.system === system) as LocationRole;
  return upperFirst(role) as LocationRole;
}

export function resolveTemplateFormSection(sectionDefinition: NoteSectionDefinition, template: NoteTemplate, placeholderContext: PlaceholderContext): NoteSection | undefined {
  const templateSection = template.sections.flatMap(section => section.format === "Form" && isSameSection(section, sectionDefinition) ? section : []).at(0);
  if (templateSection?.formAnswers) {
    return {
      ...removeUndefined(sectionDefinition),
      ...removeUndefined(templateSection),
      form: sectionDefinition.form,
      format: "Form",
      formAnswers: templateSection.formAnswers.map(answers => ({
        ...answers,
        values: answers.values.map(value => {
          if (!value.valueText?.value) {
            return value;
          }

          const html = replacePlaceholders(value.valueText?.value, placeholderContext);
          return {
            ...value,
            valueText: {
              value: html,
              plainText: htmlToText(html),
            },
          };
        }),
      })),
    };
  }
  return undefined;
}

export function resolveTemplateTextSection(sectionDefinition: NoteSectionDefinition, template: NoteTemplate, placeholderContext: PlaceholderContext): NoteSection | undefined {
  const templateSection = template.sections.find(section => isSameSection(section, sectionDefinition));
  if (templateSection?.text?.value) {
    const html = replacePlaceholders(templateSection.text.value, placeholderContext);
    const plainText = htmlToText(html);
    return {
      ...sectionDefinition,
      ...templateSection,
      text: {
        ...templateSection.text,
        value: html,
        plainText,
      },
    };
  }
  return undefined;
}

export function showSignTimeoutWarning(deadline: Instant | undefined, signed?: Instant | undefined): boolean {
  if (deadline) {
    if (signed) {
      return deadline < signed;
    }
    return Instant.toDateTime(deadline) < DateTime.now();
  }
  return false;
}

export function healthcareServiceDisplay(item: HealthcareService) {
  return item.aliases.length > 0
    ? `${item.display} - ${item.aliases[0]}`
    : item.display;
}

export function getPlaceOfService(locationRole: LocationRole) {
  return find(knownCodings.placeOfServices, (_, key) => key.toLowerCase() === locationRole.toLowerCase());
}

export function getAllPlaceOfServices() {
  return values(LocationRole).map(l => getPlaceOfService(l)).flatMap<Coding>(l => l ? l : []);
}

export function sortPlaceOfService(left: Coding, right: Coding) {
  const comparison = left.code.localeCompare(right.code);
  if (comparison === 0) {
    return (left.display ?? "").localeCompare(right.display ?? "");
  }
  return comparison;
}

export function sortLocationRoles(left: LocationRole, right: LocationRole) {
  const leftPos = getPlaceOfService(left);
  const rightPos = getPlaceOfService(right);
  return leftPos && rightPos ? sortPlaceOfService(leftPos, rightPos) : 0;
}

export function getGroupMembers(group: Group): (Reference<Patient> | Reference<Practitioner> | Reference<RelatedPerson>)[] {
  const members = group.members.flatMap(member => {
    if (!isDateRangeEffective(member.effective)) {
      return [];
    }

    if (member.member.resourceType === "Patient") {
      return member.member as Reference<Patient>;
    }

    if (member.member.resourceType === "RelatedPerson") {
      return member.member as Reference<RelatedPerson>;
    }

    if (member.member.resourceType === "Practitioner") {
      return member.member as Reference<Practitioner>;
    }

    return [];
  });

  return uniqBy(members, m => m.id);
}

const allVisitStatuses = [...values(VisitStatus)];

export function useVisitStatuses(allowedStatuses?: VisitStatus[]) {
  const showCancelledStatus = useProductFlag("ShowCancelledStatus");

  return useMemo(() => {
    const visitStatuses = allowedStatuses ?? allVisitStatuses;
    return !showCancelledStatus ? visitStatuses.filter(v => v !== "Cancelled") : visitStatuses;
  }, [showCancelledStatus, JSON.stringify(allowedStatuses)]);
}

export function getDeadline(start: Instant, duration: Duration, includesWeekends: boolean): Instant {
  if (includesWeekends) {
    return Instant.plus(start, Duration.toLuxon(duration));
  }

  return Instant.fromDateTime(plusWeekdays(Instant.toDateTime(start), duration));
}

export function getInstantRangeDuration(start: Instant, end: Instant, includeWeekends: boolean): Duration {
  if (includeWeekends) {
    return Instant.diff(end, start);
  }

  const duration = weekdayDuration(Instant.toDateTime(start), Instant.toDateTime(end));
  return Duration.fromLuxon(duration);
}

export const commonRelationships: Coding[] = [
  knownCodings.relationship.authorizedRepresentative,
  knownCodings.relationship.brother,
  knownCodings.relationship.daughter,
  knownCodings.relationship.father,
  knownCodings.relationship.grandchild,
  knownCodings.relationship.grandparent,
  knownCodings.relationship.guardian,
  knownCodings.relationship.husband,
  knownCodings.relationship.mother,
  knownCodings.relationship.powerOfAttorney,
  knownCodings.relationship.parent,
  knownCodings.relationship.significantOther,
  knownCodings.relationship.sister,
  knownCodings.relationship.schoolStaff,
  knownCodings.relationship.son,
  knownCodings.relationship.uncle,
  knownCodings.relationship.wife,
];
