import React, { forwardRef, memo } from "react";
import { DateTime } from "luxon";
import { isEqual } from "lodash-es";
import { Appointment, Encounter } from "@remhealth/apollo";
import { useTracking } from "@remhealth/host";
import { Dot } from "@remhealth/core";
import { DateFormats, useDebouncedSubscriptionState, useSubscriptionDispatch } from "@remhealth/ui";
import { AgendaContext } from "~/contexts";
import { AppointmentCard } from "~/appointments/appointmentCard";
import { Agenda, AppointmentItem, DateHeader, NoEvents } from "./agendaDay.styles";

export interface AgendaDayComponentProps {
  date: DateTime;
  appointments: Appointment[];
  encounters: Encounter[];
  upcomingAppointment?: Appointment;
}

const noAppointments = (
  <NoEvents>
    <span>No events</span>
  </NoEvents>
);

export function AgendaDayComponent(props: AgendaDayComponentProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, ref: React.Ref<HTMLDivElement>) {
  const { appointments, className, date, encounters, upcomingAppointment } = props;
  const key = keyForDate(date);

  const agenda = useDebouncedSubscriptionState(AgendaContext, 100);
  const setAgenda = useSubscriptionDispatch(AgendaContext);
  const tracking = useTracking();

  return (
    <Agenda ref={ref} className={className} data-date={key}>
      <DateHeader className="sticky">
        {renderDate()}
      </DateHeader>
      {appointments.length === 0
        ? noAppointments
        : renderAppointments()}
    </Agenda>
  );

  function renderAppointments() {
    return appointments.map(appointment => (
      <AppointmentItem key={appointment.id}>
        <AppointmentCard
          showPatient
          showStaff
          active={appointment.id === agenda.selectedAppointment?.id}
          appointment={appointment}
          associatedEncounter={encounters.find(e => e.appointment?.id === appointment.id)}
          isNext={appointment.id === upcomingAppointment?.id}
          onClick={() => handleAppointmentClick(appointment)}
        />
      </AppointmentItem>
    ));
  }

  function handleAppointmentClick(appointment: Appointment) {
    setAgenda(prev => ({
      ...prev,
      selectedAppointment: appointment,
      showRightPane: true,
    }));

    tracking.track("Agenda - Appointment selected");
  }

  function renderDate() {
    const relativeDateLabel = relativeDate(date);
    if (["Today", "Tomorrow", "Yesterday"].includes(relativeDateLabel)) {
      return (
        <div>
          <span>{relativeDate(date)}</span>
          <span><Dot /></span>
          <span>{formatDate(date)}</span>
        </div>
      );
    }
    return formatDate(date);
  }
}
function relativeDate(date: DateTime) {
  return DateFormats.friendlyDate(date, formatDate);
}

function formatDate(date: DateTime | Date | null | undefined) {
  if (!date) {
    return "";
  }
  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return dateTime.toFormat("EEEE', 'MMMM' 'd");
}

function keyForDate(date: DateTime) {
  return DateFormats.date(date);
}

function arePropsEqual(prevProps: AgendaDayComponentProps, nextProps: AgendaDayComponentProps) {
  return isEqual(prevProps, nextProps);
}

export const AgendaDay = memo(forwardRef(AgendaDayComponent), arePropsEqual);
