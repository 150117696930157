import { SetStateAction, useMemo, useRef } from "react";
import { useStateIfMounted } from "./useStateIfMounted";

export interface StatefulRefObject<T> extends StatefulRefValue<T> {
  set(value: SetStateAction<T>): void;
}

export interface StatefulRefValue<T> {
  readonly current: T;
}

/**
 * Same as useState, but provides a Ref which always reflects the current state.
 * Useful for uses within callback handlers that need latest state.
 */
export function useStateRef<S>(initialState: S | (() => S)): StatefulRefObject<S> {
  const [state, setState] = useStateIfMounted(initialState);
  const ref = useRef(state);

  return useMemo(() => ({
    get current() {
      return ref.current;
    },
    set,
  }), [ref]);

  function set(newState: SetStateAction<S>) {
    const value = isStateCallback(newState) ? newState(ref.current) : newState;
    ref.current = value;
    setState(value);
  }
}

function isStateCallback<S>(a: SetStateAction<S>): a is ((prevState: S) => S) {
  return typeof a === "function";
}
