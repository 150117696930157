import styled from "styled-components";

export const CodeableText = styled.div``;

export const CodingList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 0 1rem;
    padding: 0;
  }

  li.selected {
    font-weight: bold;
  }

  &:first-child {
    li:first-child:last-child {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  ${CodeableText} + && {
    margin-top: 4px;
  }
`;
