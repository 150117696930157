import styled from "styled-components";
import { Overlay } from "./overlay";

export const OverlayWrap = styled(Overlay)`
  &.bp5-overlay-inline {
    .bp5-overlay-backdrop {
      background: var(--overlay-minimal-background-color);
    }

    /* Fix strange stacking issue of backdrop and content */
    .bp5-overlay-content {
      top: 0;
      left: 0;

      /* Prevent user selection if user is aggressively clicking during load */
      user-select: none;
    }
  }

  &:not(.bp5-overlay-inline) {
    .bp5-overlay-content.bp5-non-ideal-state {
      .bp5-spinner-track {
        stroke: rgba(var(--gray4-rgb), 0.8);
      }

      .bp5-heading {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        color: white !important;
      }
    }
  }
`;
