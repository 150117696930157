import { useMemo } from "react";
import { Coding, IItemView, IStore, LocalDate, RelatedPeopleClient, RelatedPerson, RelatedPersonFilterSet, RelatedPersonSortField, SortField } from "@remhealth/apollo";
import { startsWithAllWords } from "@remhealth/ui";
import { containsCoding, isDateRangeEffective } from "@remhealth/host";
import { useStore } from "./useStore";

export interface RelatedPeopleFilterOptions {
  patientId: string;
  query?: string;
  relationship?: Coding;
  effectiveOn?: LocalDate;
}

export function useRelatedPeopleView(sort: SortField<RelatedPersonSortField>, options: RelatedPeopleFilterOptions): IItemView<RelatedPerson> {
  const store = useStore();
  return useMemo(() => getRelatedPeopleView(store.relatedPeople, sort, options), [JSON.stringify([options, sort])]);
}

export function getRelatedPeopleView(store: IStore<RelatedPeopleClient>, sort: SortField<RelatedPersonSortField>, options: RelatedPeopleFilterOptions) {
  return store.view({
    filters: {
      online: createRelatedPeopleFilters(options),
      offline: s => offlinefilter(s, options),
    },
    orderBy: {
      online: sort,
      offline: sortOffline(sort),
    },
    feedOptions: { partition: options.patientId },
  });
}

export function createRelatedPeopleFilters(options: RelatedPeopleFilterOptions): RelatedPersonFilterSet[] {
  const patientFilter: RelatedPersonFilterSet = {
    patient: {
      in: [options.patientId],
    },
  };

  const relationshipFilter: RelatedPersonFilterSet = !options.relationship ? {} : {
    relationship: {
      system: options.relationship.system,
      code: {
        equalTo: options.relationship.code,
      },
    },
  };

  const periodFilter: RelatedPersonFilterSet = !options.effectiveOn ? {} : {
    period: {
      wraps: options.effectiveOn,
    },
  };

  const displayFilter: RelatedPersonFilterSet = {
    display: {
      startsWithAllWords: options.query,
      // Ensure related person has name
      presence: "MustBePresent",
    },
  };

  return [
    {
      ...displayFilter,
      ...patientFilter,
      ...relationshipFilter,
      ...periodFilter,
    },
  ];
}

function offlinefilter(relatedPerson: RelatedPerson, options: RelatedPeopleFilterOptions) {
  if (!relatedPerson.display) {
    return false;
  }

  if (options.patientId !== relatedPerson.patient.id) {
    return false;
  }

  const relationship = options.relationship;
  if (relationship && !relatedPerson.relationships.some(r => containsCoding(r, relationship))) {
    return false;
  }

  if (options.effectiveOn && !isDateRangeEffective(relatedPerson.period, LocalDate.toDate(options.effectiveOn))) {
    return false;
  }

  if (options.query && !startsWithAllWords(relatedPerson.display, options.query)) {
    return false;
  }

  return true;
}

function sortOffline(sort: SortField<RelatedPersonSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: RelatedPerson, b: RelatedPerson) => {
    switch (sort.field) {
      case "ProxyConsentDate":
        return dir * (a.proxy?.whenConsented ?? "").localeCompare(b.proxy?.whenConsented ?? "");
      case "ProxyConsentExpiration":
        return dir * (a.proxy?.expirationDate ?? "").localeCompare(b.proxy?.expirationDate ?? "");
      case "Name":
      default:
        return dir * a.display.localeCompare(b.display);
    }
  };
}
