import React, { useContext } from "react";
import { TrackingService } from "./trackingService";

export interface TrackingContext {
  readonly tracking: TrackingService;
}

export const unmountedTrackingContext: TrackingContext = {
  get tracking(): TrackingService {
    throw new Error("TrackingContext is not initialized");
  },
};

export const TrackingContext = React.createContext<TrackingContext>(unmountedTrackingContext);

export const useTracking = () => useContext(TrackingContext).tracking;
