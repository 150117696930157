import { DateTime, Duration } from "luxon";
import { useDateFormatter } from "@remhealth/host";
import { Callout, DurationFormats } from "@remhealth/ui";
import { Approximate, Encounter, Note, Participant, ParticipantRole, isPatientNote } from "@remhealth/apollo";
import { getLengthOfSessionMinutes, spansMidnight } from "~/notes/utils";
import { Session } from "~/notes/types";
import { Text } from "~/text";
import { Container, SectionHeader } from "./common.styles";
import {
  DurationContainer,
  LeftContent,
  RightContent,
  SessionContent,
  SessionLabel,
  TimeContainer,
  Units,
  UnitsLabel
} from "./sessionTimeSectionContent.styles";

export interface SessionTimeSectionContentProps {
  name: string;
  showInvalid?: boolean;
  note: Note;
  encounter: Encounter | undefined;
}

export function SessionTimeSectionContent(props: SessionTimeSectionContentProps) {
  const { name, note, encounter, showInvalid } = props;
  const dates = useDateFormatter();

  const showDay = showDate(note.participants);

  const sessions: Session[] = note.participants
    .filter(p => p.role === ParticipantRole.PrimaryPerformer)
    .map(p => ({
      start: Approximate.toDate(p.period?.start),
      end: Approximate.toDate(p.period?.end),
    }));

  const lengthOfSession = getLengthOfSessionMinutes(sessions);

  return (
    <>
      <Container>
        {showInvalid && isSignatureTimeInvalid() && (
          <Callout intent="danger">
            {Text.InvalidSignatureTime}
          </Callout>
        )}
        <SectionHeader>{name}</SectionHeader>
        {sessions.map((s, index) => {
          const isFirstRow = index === 0;
          const duration = s.start && s.end ? Math.max(0, Math.round(DateTime.fromJSDate(s.end).diff(DateTime.fromJSDate(s.start)).as("minutes"))) : 0;
          return (
            <SessionContent key={index} className="session-content">
              <LeftContent className="left-content">
                <TimeContainer $showDay={showDay}>
                  {isFirstRow && <SessionLabel>{Text.Start}</SessionLabel>}
                  {showDay ? dates.dateTime(s.start) : dates.time(s.start)}
                </TimeContainer>
                <TimeContainer $showDay={showDay}>
                  {isFirstRow && <SessionLabel>{Text.Stop}</SessionLabel>}
                  {showDay ? dates.dateTime(s.end) : dates.time(s.end)}
                </TimeContainer>
                <DurationContainer>
                  {isFirstRow && <SessionLabel>{Text.Duration}</SessionLabel>}
                  {duration === 0 ? duration : DurationFormats.time(Duration.fromDurationLike({ minutes: duration }))}
                </DurationContainer>
              </LeftContent>
              <RightContent className="right-content">
                {isFirstRow && (
                  <TimeContainer>
                    <SessionLabel>{Text.LengthOfSession}</SessionLabel>
                    {DurationFormats.time(Duration.fromDurationLike({ minutes: lengthOfSession }))}
                  </TimeContainer>
                )}
              </RightContent>
            </SessionContent>
          );
        })}
        {encounter?.serviceQuantity && (
          <Units>
            <UnitsLabel>{Text.Units}</UnitsLabel>
            {encounter?.serviceQuantity}
          </Units>
        )}
      </Container>
    </>
  );

  function isSignatureTimeInvalid() {
    const lastSession = sessions[sessions.length - 1];
    const earlySignatureAllowedMinutes = isPatientNote(note) ? note.definition.resource?.earlySignatureAllowedMinutes ?? 0 : 0;
    return lastSession?.end && DateTime.now() < DateTime.fromJSDate(lastSession.end).minus({ minutes: earlySignatureAllowedMinutes });
  }
}

function showDate(participants: Participant[]) {
  return participants.some(spansMidnight);
}
