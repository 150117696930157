import { useEffect, useRef } from "react";

/**
 * Returns true if the component was already mounted prior to the current render.  If it returns false,
 * this is the first render since being mounted.
 */
export function useWasMounted() {
  const wasMounted = useRef(false);

  useEffect(() => {
    wasMounted.current = true;
  }, []);

  return wasMounted.current;
}
