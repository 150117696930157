import React, { PropsWithChildren, useState } from "react";

import { Button, DynamicImportError, isAbortError } from "@remhealth/ui";
import { Illustrations } from "~/assets";
import { ErrorDetailsDialog } from "./errorDetailsDialog";
import { getErrorMessage } from "./utils";
import { ErrorContainer, Illustration } from "./errorBoundary.styles";

export interface ErrorBoundaryProps extends PropsWithChildren {
  mountKey?: string;
  onError?: (error: Error) => void;
}

interface ErrorBoundaryState {
  error?: Error;
}

/**
 * A boundary that catches errors.
 */
export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.mountKey !== this.props.mountKey && this.state.error) {
      return this.setState({ error: undefined });
    }
  }

  public render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <ErrorBoundaryComponent error={error} />
    );
  }

  public componentDidCatch(error: Error) {
    this.handleError(error);
  }

  private handleError(error: Error) {
    if (isAbortError(error)) {
      return;
    }

    this.props.onError?.(error);
  }
}

interface ErrorBoundaryComponentProps {
  error: Error;
}

function ErrorBoundaryComponent(props: ErrorBoundaryComponentProps) {
  const { error } = props;

  const [showDialog, setShowDialog] = useState(false);

  if (error instanceof DynamicImportError) {
    return (
      <>
        <ErrorContainer>
          <Illustration height="100%" src={Illustrations.Update} style={{ maxHeight: "200px", maxWidth: "200px" }} width="100%" />
          <h3>Updates are ready!</h3>
          <p>Looks like you&apos;ll need to refresh the page to go further.</p>
          <Button large intent="primary" label="Refresh the page" onClick={handleRefresh} />
        </ErrorContainer>
      </>
    );
  }

  return (
    <>
      <ErrorContainer>
        <Illustration height="100%" src={Illustrations.OhNo} style={{ maxHeight: "300px", maxWidth: "300px" }} width="100%" />
        {getErrorMessage(error)}
        <Button label="Show me nerd stuff" onClick={handleDetailsOpen} />
      </ErrorContainer>
      <ErrorDetailsDialog error={showDialog ? error : undefined} onClose={handleDetailsClose} />
    </>
  );

  function handleDetailsOpen() {
    setShowDialog(true);
  }

  function handleDetailsClose() {
    setShowDialog(false);
  }
}

function handleRefresh() {
  window.location.reload();
}
