import styled from "styled-components";

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
`;

export const ConfigureAlertTitle = styled.h3`
  margin-top: 0;
  font-weight: normal;
`;

export const ConfigureAlertMessage = styled.p`
  text-align: left;
`;
