import React from "react";
import classnames from "classnames";
import { HTMLSelect } from "@blueprintjs/core";
import { ChevronLeft, ChevronRight } from "@remhealth/icons";
import { useAutomation } from "../hooks";
import { IconButton } from "./button";
import { getComponentId } from "./formScope";
import { ChildrenContainer, PageNav, PageSize, PaginationControls } from "./pagination.styles";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The current page number
   */
  page: number;
  /**
   * The total number of pages
   */
  pageCount: number;
  /**
   * The current page size
   */
  pageSize: number;
  /**
   * The page size choices
   */
  pageSizeChoices?: number[];
  /**
   * Function to be called when user changes the page
   */
  onPageChange: (page: number) => void;
  /**
   * Function to be called when user changes the page size
   */
  onPageSizeChange: (size: number) => void;
}

const defaultPageSizes = [10, 20, 30];

export const Pagination = (props: PaginationProps) => {
  const {
    page,
    pageCount,
    pageSize,
    pageSizeChoices = defaultPageSizes,
    onPageChange,
    onPageSizeChange,
    children,
    ...divProps
  } = props;

  const isLastPage = page === pageCount;

  const { id } = useAutomation(props);
  const pageSizeId = getComponentId(id, "page-size");
  const prevPageId = getComponentId(id, "prev-page");
  const nextPageId = getComponentId(id, "next-page");

  return (
    <PaginationControls {...divProps} className={classnames(divProps.className, "pagination-controls")}>
      <PageSize>
        {pageSizeChoices.length > 1 && (
          <>
            <HTMLSelect aria-label="Page Size Select" id={pageSizeId} name="page-size" value={pageSize} onChange={handlePageSizeChange}>
              {pageSizeChoices.map((size, index) => <option key={`op-${index}`} value={`${size}`}>{size}</option>)}
            </HTMLSelect>
            <span> Results per page</span>
          </>
        )}
      </PageSize>
      <ChildrenContainer>{children}</ChildrenContainer>
      <PageNav>
        {pageCount > 1 && (
          <>
            {page > 1 && <IconButton minimal aria-label="Previous Page" data-name="page-nav-back" icon={<ChevronLeft />} id={prevPageId} onClick={handleNavigateBack} />}
            <span className={classnames("page", { first: page <= 1, last: isLastPage })}>Page {page > 0 ? page : ""}</span>
            {!isLastPage && <IconButton minimal aria-label="Next Page" data-name="page-nav-next" icon={<ChevronRight />} id={nextPageId} onClick={handleNavigateNext} />}
          </>
        )}
      </PageNav>
    </PaginationControls>
  );

  function handleNavigateBack() {
    onPageChange(Math.max(page - 1, 0));
  }

  function handleNavigateNext() {
    onPageChange(page + 1);
  }

  function handlePageSizeChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    const selectedPageSize = event.target.value;
    onPageSizeChange(Number.parseInt(selectedPageSize, 10));
  }
};
