import { Intent, Spinner } from "@blueprintjs/core";
import { NonIdealState } from "./nonIdealState";
import { OverlayWrap } from "./loadingMask.styles";

export interface LoadingMaskProps {
  /**
   * Text to show with the spinner
   * @default undefined
   */
  title?: string;

  /** An action that appears after the title. */
  action?: JSX.Element;

  /**
   * The intent of the spinner
   * @default Intent.PRIMARY
   */
  intent?: Intent;

  /**
   * The size of the spinner
   * @default 64
   */
  size?: number;

  /**
   * If true, loading mask will appear full screen.
   * Otherwise, it will stretch to the size of the nearest relative parent.
   * @default false
   */
  usePortal?: boolean;

  /**
   * Value from 0 to 1 to indicate the progress of the spinner.  If no value, the spinner will
   * animate by spinning.
   * @default undefined
   */
  progress?: number;

  isOpen?: boolean;
}

export const LoadingMask = (props: LoadingMaskProps) => {
  const { isOpen = true, usePortal = false, size = 64, intent = "primary", action, title, progress } = props;

  return (
    <OverlayWrap isOpen={isOpen} transitionDuration={0} usePortal={usePortal}>
      <NonIdealState
        action={action}
        icon={<Spinner intent={intent} size={size} value={progress} />}
        title={title}
      />
    </OverlayWrap>
  );
};
