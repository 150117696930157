import { useContext, useMemo } from "react";
import { QuestionnaireAnswer, QuestionnaireElement } from "@remhealth/apollo";
import { usePersonalPreferences } from "@remhealth/host";
import { PlaceholderContext } from "@remhealth/core";
import { QuestionnaireContext } from "../contexts";
import { answerValueHasValue } from "../utils";
import { QuestionFlavor, resolveQuestionFlavor } from "../flavors";
import { renderQuestionnaire } from "./utils";
import { EmptyMessageContainer, MarkupContainer } from "./questionnaireOutput.styles";

export interface QuestionnaireOutputProps {
  elements: QuestionnaireElement[];
  answers: QuestionnaireAnswer[];
  noDataMessage?: string;
  isPatientView?: boolean;
}

export const QuestionnaireOutput = (props: QuestionnaireOutputProps) => {
  const {
    elements,
    answers,
    noDataMessage,
    isPatientView = false,
  } = props;

  const personalPreferences = usePersonalPreferences();
  const questionnaireContext = useContext(QuestionnaireContext);
  const placeholderContext = useContext(PlaceholderContext);

  const hasAnswer = useMemo(() => answers.some(a => a.values.some(answerValueHasValue)), [answers]);
  const hasContent = useMemo(() => elements.some(a => resolveQuestionFlavor(a) === QuestionFlavor.Content), [elements]);

  const paragraphOutput = useMemo(() => {
    return renderQuestionnaire(elements, answers, {
      questionnaireContext,
      placeholderContext,
      isPatientView,
      militaryTime: personalPreferences?.militaryTime ?? false,
    });
  }, [answers, placeholderContext, isPatientView]);

  return (
    <div>
      {hasAnswer || hasContent
        ? <MarkupContainer source={paragraphOutput} />
        : noDataMessage ? <EmptyMessageContainer>{noDataMessage}</EmptyMessageContainer> : undefined}
    </div>
  );
};
