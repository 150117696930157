import { Ellipsize } from "@remhealth/ui";
import { Practitioner, Reference, isReference } from "@remhealth/apollo";
import { PractitionerAvatar } from "./practitionerAvatar";
import { PractitionerName } from "./practitionerName";
import { AvatarContainer, Container, DetailsContainer } from "./practitionerBanner.styles";

export interface PractitionerBannerProps {
  practitioner: Practitioner | Reference<Practitioner>;
  scale?: "normal" | "small" | "large";
  className?: string;
  interactive?: boolean;
  /** @default 2 */
  lines?: number;
}

export const PractitionerBanner = (props: PractitionerBannerProps) => {
  const { scale = "normal", className, interactive = false, lines } = props;

  const avatarSize = scale === "large" ? 40 : scale === "small" ? 28 : 36;
  const practitioner = isReference(props.practitioner) ? props.practitioner.resource : props.practitioner;

  return (
    <Container className={className}>
      <AvatarContainer className="avatar">
        <PractitionerAvatar interactive={interactive} practitioner={props.practitioner} size={avatarSize} />
      </AvatarContainer>
      <DetailsContainer>
        {renderDetails(practitioner)}
      </DetailsContainer>
    </Container>
  );

  function renderDetails(practitioner: Practitioner | undefined) {
    if (!practitioner) {
      return (
        <Ellipsize lines={lines ?? 2} maxWidth={400}>
          {props.practitioner.display}
        </Ellipsize>
      );
    }

    return (
      <Ellipsize lines={lines ?? 2} maxWidth={400}>
        <PractitionerName practitioner={practitioner} />
      </Ellipsize>
    );
  }
};
