import { Classes, Intent } from "@blueprintjs/core";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
export function randomAlpha(length: number) {
  let value = "";
  for (let i = 0; i < length; i++) {
    value += letters[Math.floor(Math.random() * letters.length)];
  }
  return value;
}

export function intentClass(intent: Intent): string {
  switch (intent) {
    case Intent.NONE: return "";
    case Intent.PRIMARY: return Classes.INTENT_PRIMARY;
    case Intent.SUCCESS: return Classes.INTENT_SUCCESS;
    case Intent.WARNING: return Classes.INTENT_WARNING;
    case Intent.DANGER: return Classes.INTENT_DANGER;
  }
}
