import * as Core from "@blueprintjs/core";
import { useAutomation } from "~/hooks";
import { Icon, IconName, IconProps } from "./icon";

export interface TagProps extends Omit<Core.TagProps, "icon" | "rightIcon"> {
  icon?: IconName | React.ReactElement<IconProps>;
  iconSize?: number;
  rightIcon?: IconName | React.ReactElement<IconProps>;
}

export function Tag(props: TagProps) {
  const { label, id } = useAutomation(props, "button");
  return (
    <Core.Tag {...toNativeProps(props)} aria-label={label} id={id} />
  );
}

export function toNativeProps(props: TagProps): Core.TagProps {
  const { icon, rightIcon, iconSize, interactive, ...restProps } = props;

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} size={iconSize} />
    : undefined;

  const nativeRightIcon: Core.MaybeElement | undefined = rightIcon
    ? <Icon icon={rightIcon} size={iconSize} />
    : undefined;

  return {
    ...restProps,
    interactive,
    icon: nativeIcon,
    rightIcon: nativeRightIcon,
    role: interactive ? "button" : undefined,
  };
}

Tag.displayName = Core.Tag.displayName;
