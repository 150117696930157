import { PropsWithChildren, useEffect, useState } from "react";
import { FontAppearance, LoadingMask } from "@remhealth/ui";
import { BellsThemeName } from "./useTheme";

type ThemeModule = { default: string };
type DynamicThemeModule = Promise<ThemeModule>;

export interface ThemeMounterProps {
  fontAppearance: FontAppearance;
  theme: BellsThemeName;
}

export function ThemeMounter(props: PropsWithChildren<ThemeMounterProps>) {
  const { children, theme, fontAppearance } = props;

  const [state, setState] = useState<"initializing" | "switching" | "loaded">("initializing");

  useEffect(() => {
    mountStyleElement(getThemeLoader());
  }, [theme, fontAppearance]);

  // Don't render anything until we have at least one stylesheet mounted
  if (state === "initializing") {
    return <></>;
  }

  return (
    <>
      {children}
      {state === "switching" && <LoadingMask usePortal title="Loading theme..." />}
    </>
  );

  async function mountStyleElement(module: DynamicThemeModule) {
    setState(state => state === "loaded" ? "switching" : "initializing");

    const { default: theme } = await module;

    const oldStyleEl = getStyleEl();
    const styleEl = createStyleElement(theme);

    oldStyleEl?.remove();
    insertAtTop(styleEl);
    setState("loaded");
  }

  function getThemeLoader(): DynamicThemeModule {
    switch (theme) {
      case "high-contrast": return import("./themes/themeHighcontrast.scss?inline");
      case "custom1": return import("./themes/themeCustom1.scss?inline");
      case "custom2": return import("./themes/themeCustom2.scss?inline");
      case "custom3": return import("./themes/themeCustom3.scss?inline");
      case "myAvatar": return import("./themes/myAvatarTheme.scss?inline");
      case "default": return import("./themes/themeNormal.scss?inline");
    }
  }
}

function getStyleEl() {
  return document.getElementById("bells-theme");
}

function createStyleElement(theme: string): HTMLStyleElement {
  const style = document.createElement("style");
  style.id = "bells-theme";
  style.append(document.createTextNode(theme));
  return style;
}

let lastInsertedElement: HTMLElement | null = null;
function insertAtTop(element: HTMLElement) {
  const head = document.head;

  // Only use lastInsertedElement if it is still mounted
  const lastInserted = lastInsertedElement?.parentNode ? lastInsertedElement : null;

  if (!lastInserted) {
    head.insertBefore(element, head.firstChild);
  } else if (lastInserted.nextSibling) {
    head.insertBefore(element, lastInserted.nextSibling);
  } else {
    head.append(element);
  }

  lastInsertedElement?.remove();
  lastInsertedElement = element;
}
