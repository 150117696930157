import { useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { ChevronLeft, ChevronRight } from "@remhealth/icons";
import { useTracking } from "@remhealth/host";
import { FormScope, Intent, Scrollbar, useCallbackRef, useSubscriptionDispatch } from "@remhealth/ui";
import { AgendaContext } from "~/contexts";
import { TodayButton } from "./todayButton";
import { DateBarItem } from "./dateBarItem";
import { Container, DaysContainer, DaysScrollbar, NavButton } from "./dateBar.styles";

export interface DateBarProps {
  selectedDate?: DateTime;
}

export const DateBar = (props: DateBarProps) => {
  const { selectedDate = DateTime.now() } = props;

  const scrollbar = useRef<Scrollbar>(null);
  const today = useRef<HTMLDivElement>(null);
  const setAgenda = useSubscriptionDispatch(AgendaContext);
  const tracking = useTracking();
  const dragStart = useRef<number>();

  const onMouseMove = useCallbackRef(handleDateDrag);
  const onMouseUp = useCallbackRef(handleDateDragEnd);

  useEffect(() => {
    if (today.current) {
      today.current.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
    }
  }, []);

  return (
    <FormScope controlId="date-bar" label="Date Bar">
      <Container className="date-bar" id="date-bar">
        <TodayButton onClick={handleDateSelect} />
        <NavButton
          large
          outlined
          tooltip
          aria-label="Previous week"
          icon={<ChevronLeft />}
          intent={Intent.PRIMARY}
          onClick={prevWeek}
        />
        <DaysScrollbar ref={scrollbar}>
          <DaysContainer>
            {renderCalendar()}
          </DaysContainer>
        </DaysScrollbar>
        <NavButton
          large
          outlined
          tooltip
          aria-label="Next week"
          icon={<ChevronRight />}
          intent={Intent.PRIMARY}
          onClick={nextWeek}
        />
      </Container>
    </FormScope>
  );

  function renderCalendar() {
    const startDate = DateTime.now().minus({ days: 14 });
    const endDate = DateTime.now().plus({ days: 10 });
    const numberOfDays = endDate.diff(startDate).as("days") + 1;
    const days = Array.from({ length: numberOfDays })
      .fill("")
      .map((_, i) => DateTime.now().plus({ days: i - 14 }));
    return days.map(renderDate);
  }

  function renderDate(date: DateTime) {
    return (
      <DateBarItem
        key={date.toFormat("d'/'M")}
        ref={today}
        date={date}
        selectedDate={selectedDate}
        onClick={() => handleDateSelect(date)}
        onMouseDown={handleDateDragStart}
        onMouseUp={onMouseUp}
      />
    );
  }

  function handleDateDragStart(e: React.DragEvent<HTMLDivElement>) {
    dragStart.current = e.clientX;

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }

  function handleDateDragEnd() {
    dragStart.current = undefined;
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  }

  function handleDateDrag(e: MouseEvent) {
    if (dragStart.current !== undefined) {
      const scrollElement = scrollbar.current?.getScrollElement();
      if (scrollElement) {
        scrollElement.scrollBy({ left: dragStart.current - e.clientX });
        dragStart.current = e.clientX;
      }
    }
  }

  function handleDateSelect(date: DateTime) {
    tracking.track("Agenda - Change Date");

    setAgenda(prev => ({
      ...prev,
      selectedDate: {
        date,
        scrollToDate: true,
      },
    }));
  }

  function nextWeek() {
    if (!scrollbar.current) {
      return;
    }
    scrollbar.current.getScrollElement()?.scrollBy({ left: 400, behavior: "smooth" });
    tracking.track("Agenda - Next Week");
  }

  function prevWeek() {
    if (!scrollbar.current) {
      return;
    }
    scrollbar.current.getScrollElement()?.scrollBy({ left: -400, behavior: "smooth" });
    tracking.track("Agenda - Previous Week");
  }
};
