import { AdministrativeGender, CodeableConcept, Coding, ParameterPresence, PractitionerFilterSet, systems } from "@remhealth/apollo";
import { UserAccessStatus, getRoundedInstantNow } from "@remhealth/host";

export function getAccessStatusFilters(statusFilteredItems: UserAccessStatus[]): PractitionerFilterSet[] {
  if (statusFilteredItems.length === Object.values(UserAccessStatus).length) {
    return [];
  }

  return statusFilteredItems.flatMap(getAccessStatusFilter);
}

export function getAccessStatusFilter(statusFilter: UserAccessStatus): PractitionerFilterSet[] {
  const currentTime = getRoundedInstantNow();
  switch (statusFilter) {
    case UserAccessStatus.Active:
      return [{
        accessPeriod: {
          wraps: currentTime,
        },
      }];

    case UserAccessStatus.Pending:
      return [
        {
          accessPeriod: {
            start: {
              after: currentTime,
              presence: ParameterPresence.MustBePresent,
            },
          },
        },
      ];

    case UserAccessStatus.Expired:
      return [
        {
          accessPeriod: {
            end: {
              onOrBefore: currentTime,
              presence: ParameterPresence.MustBePresent,
            },
          },
        },
      ];

    default:
      return [];
  }
}

export const UserActiveStatus = {
  Active: "Active" as const,
  Inactive: "Inactive" as const,
  Suspended: "Suspended" as const,
};
export type UserActiveStatus = typeof UserActiveStatus[keyof typeof UserActiveStatus];

export function getActiveStatusFilters(statusFilteredItems: UserActiveStatus[]): PractitionerFilterSet[] {
  if (statusFilteredItems.length === Object.values(UserActiveStatus).length) {
    return [];
  }

  return statusFilteredItems.flatMap(getActiveStatusFilter);
}

export function getActiveStatusFilter(statusFilter: UserActiveStatus): PractitionerFilterSet[] {
  const currentTime = getRoundedInstantNow();
  switch (statusFilter) {
    case UserActiveStatus.Active:
      return [{
        accessPeriod: {
          wraps: currentTime,
        },
        suspended: { equalTo: false, presence: "MaybePresent" },
      }];

    case UserActiveStatus.Inactive:
      return [
        {
          accessPeriod: {
            start: {
              after: currentTime,
              presence: ParameterPresence.MustBePresent,
            },
          },
          suspended: { equalTo: false, presence: "MaybePresent" },
        },
        {
          accessPeriod: {
            end: {
              onOrBefore: currentTime,
              presence: ParameterPresence.MustBePresent,
            },
          },
          suspended: { equalTo: false, presence: "MaybePresent" },
        },
      ];

    case UserActiveStatus.Suspended:
      return [{
        suspended: { equalTo: true, presence: "MustBePresent" },
      }];

    default:
      return [];
  }
}

export interface Genderable {
  gender: AdministrativeGender;
  genderIdentity?: CodeableConcept;
}

export function getPatientGender(patient: Genderable) {
  if (patient.genderIdentity) {
    // Favor CT1 codings over FHIR, due to their custom options
    const titanCodings = patient.genderIdentity.codings.map(mapTitanGender).filter(isGenderCoding);
    if (titanCodings.length > 0) {
      return titanCodings[0];
    }

    const snomedCodings = patient.genderIdentity.codings.map(mapSnomedGender).filter(isGenderCoding);
    if (snomedCodings.length > 0) {
      return snomedCodings[0];
    }

    // Otherwise use standard FHIR coding if we can
    const standardCodings = patient.genderIdentity.codings.map(mapStandardGender).filter(isGenderCoding);
    if (standardCodings.length > 0) {
      return standardCodings[0];
    }
  }

  // Last resort based on Administrative Gender
  switch (patient.gender) {
    case AdministrativeGender.Male: return { code: "M", display: "Male" };
    case AdministrativeGender.Female: return { code: "F", display: "Female" };
    case AdministrativeGender.Other: return { code: "O", display: "Other gender identity" };
    case AdministrativeGender.Unknown:
    default: return { code: "U", display: "Unknown" };
  }
}

function mapStandardGender(coding: Coding): GenderCoding | null {
  switch (coding.code) {
    case "male": return { code: "M", display: "Male" };
    case "female": return { code: "F", display: "Female" };
    case "non-binary": return { code: "X", display: "Non-Binary gender" };
    case "transgender-female": return { code: "TF", display: "Transgender Male-To-Female" };
    case "transgender-male": return { code: "TM", display: "Transgender Female-To-Male" };
    case "non-disclose": return { code: "U", display: "Does not wish to disclose gender identity" };
    case "other": return { code: "O", display: "Other gender identity" };
    case "ASKU": return { code: "U", display: "Does not wish to disclose gender identity" };
    case "OTH": return { code: "O", display: "Other gender identity" };
    case "UNK": return { code: "U", display: "Unknown" };
  }

  return null;
}

function mapTitanGender(coding: Coding): GenderCoding | null {
  if (coding.system === "ctone:gender-identity") {
    switch (coding.code) {
      case "male": return { code: "M", display: "Male" };
      case "female": return { code: "F", display: "Female" };
      case "transgender-female": return { code: "TF", display: "Transgender Male-To-Female" };
      case "transgender-male": return { code: "TM", display: "Transgender Female-To-Male" };
      case "non-disclose": return { code: "U", display: "Does not wish to disclose gender identity" };
      case "transgender": return { code: "T", display: "Transgender" };
      case "genderless": return { code: "I", display: "Intersex" };
      case "questioning": return { code: "Q", display: "Questioning" };
      case "other": return { code: "O", display: "Other gender identity" };
      case "unknown": return { code: "U", display: "Unknown" };
    }
  }

  return null;
}

function mapSnomedGender(coding: Coding): GenderCoding | null {
  if (coding.system === systems.oids.genderIdentity || coding.system === systems.oids.snomed || coding.system === systems.oids.nullFlavor) {
    switch (coding.code) {
      case "446151000124109": return { code: "M", display: "Male" };
      case "446141000124107": return { code: "F", display: "Female" };
      case "446131000124102": return { code: "X", display: "Non-Binary gender" };
      case "407376001": return { code: "TF", display: "Transgender Male-To-Female" };
      case "407377005": return { code: "TM", display: "Transgender Female-To-Male" };
    }
  }

  return null;
}

interface GenderCoding {
  code: string;
  display: string;
}

function isGenderCoding(coding: GenderCoding | null): coding is GenderCoding {
  return coding !== null;
}
