import { useEffect, useState } from "react";
import { Approximate, Condition, Diagnosis, DiagnosisRole } from "@remhealth/apollo";
import { DateFormats, Tooltip, useAbort } from "@remhealth/ui";
import { useErrorHandler, useStore } from "@remhealth/core";
import { Codings } from "~/codeable";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";
import {
  DiagnosisCode,
  DiagnosisContainer,
  DiagnosisDate,
  DiagnosisLeftContent,
  DiagnosisTitle,
  IndexValue,
  PrimaryDiagnosis
} from "./diagnosisSectionContent.styles";

export interface DiagnosisSectionContentProps {
  name: string;
  diagnoses: Diagnosis[];
}

export function DiagnosisSectionContent(props: DiagnosisSectionContentProps) {
  const { name, diagnoses } = props;

  const store = useStore();
  const abort = useAbort();
  const handleError = useErrorHandler();

  const [conditions, setConditions] = useState<Condition[]>([]);

  useEffect(() => {
    loadConditions();
  }, [diagnoses]);

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {diagnoses.length === 0
        ? <NoData>{Text.NoData}</NoData>
        : (
          <div>
            {diagnoses.map((d, index) => {
              const condition = conditions.find(c => c.id === d.condition.id);
              const isPrimary = d.rank === 1 || d.role === DiagnosisRole.Billing || condition?.priority === 1;
              return (
                <DiagnosisContainer key={index}>
                  <DiagnosisLeftContent><IndexValue>{index + 1}. <PrimaryDiagnosis>{isPrimary ? <Tooltip content={Text.Primary}>P</Tooltip> : ""}</PrimaryDiagnosis></IndexValue></DiagnosisLeftContent>
                  <DiagnosisCode>{condition?.code && <Codings codings={condition.code.codings} type="diagnosis" />}</DiagnosisCode>
                  <DiagnosisTitle>{condition?.display ?? d.condition.display}</DiagnosisTitle>
                  <DiagnosisDate>{DateFormats.date(Approximate.toDate(condition?.period?.start))}</DiagnosisDate>
                </DiagnosisContainer>
              );
            })}
          </div>
        )}
    </Container>
  );

  async function loadConditions() {
    try {
      const conditions = await store.conditions.expand(diagnoses.map(g => g.condition), { abort: abort.signal });
      setConditions(conditions);
    } catch (error) {
      handleError(error);
    }
  }
}
