import React, { useRef } from "react";
import { Clipboard } from "@remhealth/icons";
import { AnchorButton, AnchorButtonProps, IconButton, Tooltip, useStateIfMounted } from "@remhealth/ui";

export interface CopyButtonProps extends Omit<AnchorButtonProps, "label"> {
  label?: string;
  copyContent: string;

  /** @default "Copied to clipboard!" */
  copyMessage?: string;
}

export const CopyButton = (props: CopyButtonProps) => {
  const {
    "aria-label": ariaLabel = "Copy to clipboard",
    copyContent,
    copyMessage = "Copied to clipboard!",
    icon = <Clipboard />,
    label,
    onClick,
    ...buttonProps
  } = props;

  const [copied, setCopied] = useStateIfMounted(false);
  const copyTimeout = useRef<ReturnType<typeof setTimeout>>();

  return (
    <Tooltip content={copyMessage} isOpen={copied} placement="top">
      {label
        ? <AnchorButton {...buttonProps} icon={icon} label={label} onClick={handleCopy} />
        : <IconButton {...buttonProps} aria-label={ariaLabel} icon={icon} onClick={handleCopy} />}
    </Tooltip>
  );

  async function handleCopy(event: React.MouseEvent<HTMLAnchorElement>) {
    if (copyTimeout.current) {
      clearTimeout(copyTimeout.current);
      copyTimeout.current = undefined;
    }

    await navigator.clipboard.writeText(copyContent);
    setCopied(true);

    copyTimeout.current = setTimeout(() => setCopied(false), 2000);

    onClick?.(event);
  }
};
