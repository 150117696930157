import { AccessToken, UserSession } from "~/auth";

export interface ErrorContext {
  data: Record<string, unknown>;
}

export interface TracingService {
  identifyUser(user: UserSession, token: AccessToken): void;
  reportError(error: any, context?: ErrorContext): void;
  endSession(): void;
}

export class VoidTracingService implements TracingService {
  public identifyUser(): void {}
  public reportError(): void {}
  public endSession(): void {}
}
