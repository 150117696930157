import { useEffect, useRef } from "react";

export interface AbortHook extends AbortController {
  readonly signal: AbortSignal;
  abort(): void;
  reset(): void;
}

/**
 * Used to manage a abort signal that will automatically abort on unmount.
 */
export function useAbort(): Readonly<AbortHook> {
  const controller = useRef(new AbortController());

  useEffect(() => {
    controller.current = new AbortController();
    return () => controller.current.abort();
  }, []);

  return {
    get signal() {
      return controller.current.signal;
    },
    abort() {
      controller.current.abort();
    },
    reset() {
      controller.current.abort();
      controller.current = new AbortController();
    },
  };
}
