import { GoalAchievementStatus, GoalElementActivity } from "@remhealth/apollo";

export function goalTrackingFormStatus(element: GoalElementActivity[]): GoalAchievementStatus {
  if (!element.length) {
    return GoalAchievementStatus.NotAchieved;
  }

  if (element.every(c => c.children.every(e => e.achievementStatus === "Achieved"))) {
    return GoalAchievementStatus.Achieved;
  }

  if (element.some(c => c.children.some(e => e.achievementStatus === "NotAchieved")) && element.some(c => c.children.some(e => e.achievementStatus === "Achieved"))) {
    return GoalAchievementStatus.InProgress;
  }

  return GoalAchievementStatus.NotAchieved;
}

export function resolveStatusColor(type: GoalAchievementStatus) {
  let color = "var(--pt-intent-danger-rgb)";

  switch (type) {
    case GoalAchievementStatus.Achieved:
      color = "var(--pt-intent-success-rgb)";
      break;
    case GoalAchievementStatus.InProgress:
      color = "var(--pt-intent-warning-rgb)";
      break;
    case GoalAchievementStatus.NotAchieved:
      color = "var(--pt-intent-danger-rgb)";
      break;
  }

  return color;
}
