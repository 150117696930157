import { createElement, forwardRef } from "react";
import classnames from "classnames";
import { Classes, Text } from "@blueprintjs/core";
import { SmallTick } from "@remhealth/icons";
import { removeNonHtmlProps } from "../utils";
import { useAutomation } from "../hooks";
import { MenuItemProps } from "./menuItem";
import { Icon } from "./icon";

export interface ListOptionProps extends Omit<MenuItemProps, "htmlTitle" | "role" | "roleStructure" | "label" | "labelElement" | "text"> {
  selected?: boolean;
  text: string;
}

// Props to ignore when disabled
const DISABLED_PROPS: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
  href: undefined,
  onClick: undefined,
  onMouseDown: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  tabIndex: -1,
};

export const ListOption = forwardRef<HTMLLIElement, ListOptionProps>((props, ref) => {
  const {
    active = false,
    className,
    children,
    disabled = false,
    icon,
    info,
    intent,
    multiline = false,
    popoverProps = {},
    selected,
    shouldDismissPopover = true,
    submenuProps,
    text,
    textClassName,
    tagName = "a",
    ...htmlProps
  } = props;

  const { label, id } = useAutomation({ ...props, label: props["aria-label"] ?? text }, "option");

  const isSelectable = selected !== undefined;
  const isSelected = isSelectable && selected;
  const hasIcon = icon != null;

  const intentClass = Classes.intentClass(intent);
  const anchorClasses = classnames(
    Classes.MENU_ITEM,
    intentClass,
    {
      [Classes.ACTIVE]: active,
      [Classes.DISABLED]: disabled,
      // Prevent popover from closing when clicking on disabled item
      [Classes.POPOVER_DISMISS]: shouldDismissPopover && !disabled,
      [Classes.MENU_ITEM_IS_SELECTABLE]: isSelectable,
      [Classes.SELECTED]: isSelected,
    },
    className
  );

  const maybeLabel = info == null ? null : (
    <span className={Classes.MENU_ITEM_LABEL}>
      {info}
    </span>
  );

  const target = createElement(
    tagName,
    {
      onKeyDown: clickElementOnKeyPress(["Enter", " "]),
      tabIndex: 0,
      ...removeNonHtmlProps(htmlProps),
      ...disabled ? DISABLED_PROPS : {},
      role: "none",
      className: anchorClasses,
    },
    isSelected ? <SmallTick className={Classes.MENU_ITEM_SELECTED_ICON} /> : undefined,
    hasIcon ? (
    // Wrap icon in a <span> in case `icon` is a custom element rather than a built-in icon identifier,
    // so that we always render this class
      <span className={Classes.MENU_ITEM_ICON}>
        <Icon aria-hidden icon={icon} />
      </span>
    ) : undefined,
    <Text className={classnames(Classes.FILL, textClassName)} ellipsize={!multiline}>
      {children ?? text}
    </Text>,
    maybeLabel
  );

  return (
    <li ref={ref} aria-label={label} aria-selected={selected} id={id} role="option">
      {target}
    </li>
  );
});

function clickElementOnKeyPress(keys: string[]) {
  return (e: React.KeyboardEvent) => keys.includes(e.key) && e.target.dispatchEvent(new MouseEvent("click", { ...e, view: undefined }));
}
