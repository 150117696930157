import { type Ref, forwardRef, useLayoutEffect, useState } from "react";
import classnames from "classnames";
import { Classes } from "@blueprintjs/core";
import { intentClass, randomAlpha } from "./misc";
import { BaseIconProps, IconDisplayName, IconPaths, IconSize } from "./shared";

type PathModule = { default: IconPaths };
type DynamicPathModule = () => Promise<PathModule>;

export interface PathIconProps extends BaseIconProps {
}

interface DynamicPathIconProps extends BaseIconProps {
  small: DynamicPathModule;
  large: DynamicPathModule;
}

export const PathIcon = forwardRef((props: DynamicPathIconProps, ref: Ref<HTMLElement>) => {
  const { large, small, className, size = IconSize.STANDARD, color, intent = "none", ...restProps } = props;

  const pixelGridSize = size >= IconSize.LARGE ? IconSize.LARGE : IconSize.STANDARD;
  const fill = color && typeof color === "string" ? color : undefined;

  const [paths, setPaths] = useState<IconPaths>();

  useLayoutEffect(() => {
    loadPaths();
  }, [large, small, size]);

  return (
    <span ref={ref} aria-hidden className={classnames(Classes.ICON, className, intentClass(intent), { colored: !!color || intent !== "none" })} {...restProps}>
      <svg fill={fill} height={size} role="img" viewBox={`0 0 ${pixelGridSize} ${pixelGridSize}`} width={size}>
        {renderPaths()}
      </svg>
    </span>
  );

  async function loadPaths() {
    const { default: paths } = size >= IconSize.LARGE ? await large() : await small();
    setPaths(paths);
  }

  function renderPaths(): JSX.Element | null {
    const gradId = randomAlpha(10);
    const gradient = color && typeof color !== "string" ? color : undefined;

    return (
      <>
        {gradient && (
          <defs>
            <linearGradient gradientUnits="userSpaceOnUse" id={gradId} x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={gradient[0]} />
              <stop offset="100%" stopColor={gradient[1]} />
            </linearGradient>
          </defs>
        )}
        <g fill={gradient ? `url(#${gradId})` : undefined}>
          {paths?.map((path, i) => <path key={i} d={path} fill={gradient ? `url(#${gradId})` : undefined} fillRule="evenodd" />)}
        </g>
      </>
    );
  }
});

export function createPathIcon(large: DynamicPathModule, small: DynamicPathModule) {
  const icon = forwardRef((props: PathIconProps, ref: Ref<HTMLElement>) => {
    return <PathIcon ref={ref} large={large} small={small} {...props} />;
  });

  icon.displayName = IconDisplayName;

  return icon;
}
