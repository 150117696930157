import styled, { css } from "styled-components";
import { FormArea } from "@remhealth/ui";

export const Container = styled(FormArea)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${props => props.theme.background.asideMain};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 20px 20px 10px 20px;

  h3 {
    margin: 0;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  .search-input {
    margin-bottom: 10px;
  }
`;

export const RightSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100% - 20px);
  margin: 0 20px 20px 5px;
`;

export interface ContentProps {
  $showRightPane: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  .search-input,
  .date-bar {
    margin-left: 20px;
    margin-right: 15px;
  }

  ${props => props.$showRightPane && css`
    ${LeftSection} {
      width: calc(100% - 380px);
    }
  `};
`;
