import { useEffect, useMemo, useRef } from "react";
import { IFeedIterator, IItemView, RequestOptions } from "@remhealth/apollo";
import { IFeed, PagingController, createSubscription, usePagingController, useStateIfMounted, useSubscriptionRef, useUpdateEffect } from "@remhealth/ui";
import { useErrorHandler } from "../app";

export interface Feed<T> extends IFeed<T> {
  replaceItem(item: T): void;
  removeItem(item: T): void;
}

/**
 * Returns a feed based on an item view.
 * @example
 * const view = apollo.patients.view({ filters: ... });
 * const feed = useFeed(view);
 */
export function useFeed<T>(view: IItemView<T>, options?: UseViewFeedOptions<T>): IFeed<T>;

/**
 * Returns a feed based on an item view.
 * @example
 * const feed = useFeed(apollo.patients, { filters: ... });
 */
export function useFeed<TClient extends FeedClient>(client: TClient, options: UseFeedOptions<TClient>): Feed<ClientResource<TClient>>;

export function useFeed(...args: Parameters<typeof useClientFeed> | Parameters<typeof useViewFeed>): ReturnType<typeof useClientFeed> | ReturnType<typeof useViewFeed> {
  if (isView(args[0])) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useViewFeed(...(args as Parameters<typeof useViewFeed>));
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClientFeed(...(args as Parameters<typeof useClientFeed>));
}

function useClientFeed<TClient extends FeedClient>(client: TClient, options: UseFeedOptions<TClient>): Feed<ClientResource<TClient>> {
  const pagingController = usePagingController<ClientResource<TClient>>();
  const controller = options.controller ?? pagingController;

  const feed = useMemo(() => client.feed(options), [controller.session, JSON.stringify(options)]);

  const iteratorFeed = useIteratorFeed<ClientResource<TClient>>(feed, {
    ...options,
    controller,
  });

  return {
    ...iteratorFeed,
    get count() {
      return iteratorFeed.count;
    },
    get items() {
      return iteratorFeed.items;
    },
    get page() {
      return iteratorFeed.page;
    },
    get session() {
      return iteratorFeed.session;
    },
    get canLoadMore() {
      return iteratorFeed.canLoadMore;
    },
    get loading() {
      return iteratorFeed.loading;
    },
    replaceItem,
    removeItem,
  };

  function replaceItem(item: ClientResource<TClient>) {
    const index = controller.items.findIndex(m => m.id === item.id);
    if (index === -1) {
      return;
    }
    const items = [...controller.items];
    items.splice(index, 1, item);
    controller.setItems(items);
  }

  function removeItem(item: ClientResource<TClient>) {
    const index = controller.items.findIndex(m => m.id === item.id);
    if (index === -1) {
      return;
    }
    const items = [...controller.items];
    items.splice(index, 1);
    controller.setItems(items);
  }
}

interface LoadingViewsState {
  loading: Map<string, Promise<void>>;
}

const { context: LoadingViewsContext, Provider: LoadingViewsProvider } = createSubscription<LoadingViewsState>({
  loading: new Map<string, Promise<void>>(),
});

export { LoadingViewsProvider };

function useViewFeed<T>(view: IItemView<T>, options?: UseViewFeedOptions<T>): IFeed<T> {
  const { reload = true, onLoad } = options ?? {};
  const controller = usePagingController<T>();
  const handleError = useErrorHandler();
  const knownKeys = useRef(new Set<string>());
  const loadingViews = useSubscriptionRef(LoadingViewsContext);

  useEffect(() => {
    return view.onViewChange(handleViewChange);
  }, [view.key]);

  useEffect(() => {
    // Reload when the view's key changes or on initial render if reload=true
    if (reload || knownKeys.current.has(view.key)) {
      view.reset();
    }

    const alreadyLoading = loadingViews.current.loading.get(view.key);

    if (view.canLoadMore) {
      if (!alreadyLoading) {
        controller.reset(true);
      }
    } else {
      controller.setItems(view.items());
    }

    // Register to loading promise if there is one
    if (alreadyLoading) {
      alreadyLoading.then(handleViewLoaded);
    }

    knownKeys.current.add(view.key);
  }, [view.key]);

  useEffect(() => {
    if (options?.controller) {
      options.controller.copyState(controller);
    }
  }, [options?.controller, controller.items, controller.count, controller.page, controller.session]);

  return {
    ...controller,
    get count() {
      return controller.count;
    },
    get session() {
      return controller.session;
    },
    get page() {
      return controller.page;
    },
    get items() {
      return controller.items;
    },
    get canLoadMore() {
      return view.canLoadMore;
    },
    reset,
    get loading() {
      return loadingViews.current.loading.has(view.key);
    },
    loadMore,
  };

  function handleViewChange(items: T[]) {
    controller.setItems(items);
  }

  function handleViewLoaded() {
    controller.setItems(view.items());
  }

  function reset(keepItems?: boolean) {
    if (!loadingViews.current.loading.has(view.key)) {
      view.reset();
    }
    controller.reset(keepItems);
  }

  async function loadMore(limit: number, abort?: AbortSignal): Promise<void> {
    const prevSize = view.items().length;
    const alreadyLoading = loadingViews.current.loading.get(view.key);
    if (alreadyLoading) {
      await alreadyLoading;

      if (onLoad) {
        const loadedItems = view.items().slice(prevSize);
        await onLoad(loadedItems);
      }

      if (!abort?.aborted) {
        handleViewLoaded();
      }
    } else if (view.canLoadMore && !abort?.aborted) {
      // Purposefully ignore abort here, because there may be other future requests to load
      // and we don't want to cancel their promises
      const promise = view.loadMore(limit);

      loadingViews.set(state => ({
        loading: state.loading.set(view.key, promise),
      }));

      try {
        const itemsCount = view.items().length;

        await promise;

        // Handle case when no new items came back unexpectedly
        if (view.items().length === itemsCount) {
          controller.previousPage();
        }

        if (!abort?.aborted) {
          controller.setItems(view.items());
        }

        if (onLoad) {
          const loadedItems = view.items().slice(prevSize);
          await onLoad(loadedItems);
        }
      } catch (error) {
        handleError(error);
        throw error;
      } finally {
        loadingViews.set(state => {
          state.loading.delete(view.key);
          return { loading: state.loading };
        });
      }
    }
  }
}

export function useIteratorFeed<T>(iterator: FeedIterator<T>, options?: UseIteratorFeedOptions<T>): IFeed<T> {
  const handleError = useErrorHandler();
  const [loading, setLoading] = useStateIfMounted(false);
  const pagingController = usePagingController<T>();
  const controller = options?.controller ?? pagingController;

  const lastSession = useRef(controller.session);
  const canLoadMore = useRef(true);

  // Reset canLoadMore on new session
  useUpdateEffect(() => {
    if (!canLoadMore.current) {
      canLoadMore.current = true;
    }
  }, [controller.session]);

  return {
    ...controller,
    get canLoadMore() {
      return canLoadMore.current;
    },
    loading,
    loadMore,
    reset,
    reload,
  };

  function reset(keepItems = false) {
    canLoadMore.current = true;
    controller.reset(keepItems);
  }

  function reload(keepItems = false) {
    canLoadMore.current = true;
    controller.reload(keepItems);
  }

  async function loadMore(limit: number, abort?: AbortSignal): Promise<void> {
    // Reset if necessary
    if (controller.session !== lastSession.current) {
      lastSession.current = controller.session;
      canLoadMore.current = false;
    }

    setLoading(true);
    try {
      const results = await iterator.next(limit, { abort });

      // In rare cases, no new items come back unexpectedly
      canLoadMore.current = iterator.hasMoreResults && results.length > 0;

      // Handle case when no new items came back unexpectedly
      if (results.length === 0) {
        controller.previousPage();
      }

      controller.setItems([...controller.items, ...results]);

      if (options?.onLoad) {
        await options.onLoad(results);
      }
    } catch (error) {
      handleError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }
}

// Helper types
type FeedIteratorType<T> = T extends IFeedIterator<infer R> ? R : never;
type ClientResource<TClient extends FeedClient> = FeedIteratorType<ReturnType<TClient["feed"]>>;
type FeedRequestParam<TClient extends FeedClient> = TClient["feed"] extends ((request: infer R) => any) ? R : {};

type UseFeedOptions<TClient extends FeedClient> = FeedRequestParam<TClient> & {
  /**
   * Optional controller if in controlled mode.
   */
  controller?: PagingController<ClientResource<TClient>>;
};

type UseViewFeedOptions<T> = {
  /**
   * Optional controller if in controlled mode.
   */
  controller?: PagingController<T>;

  /**
   * Will reload view on initialization.
   * @default true
   */
  reload?: boolean;

  onLoad?: (loadedItems: T[]) => void | Promise<void>;
};

export type UseIteratorFeedOptions<T> = {
  /**
   * Optional controller if in controlled mode.
   */
  controller?: PagingController<T>;
  onLoad?: (loadedItems: T[]) => void | Promise<void>;
};

export interface FeedIterator<T> {
  readonly hasMoreResults: boolean;
  next(maxItemCount: number, options?: RequestOptions): Promise<T[]>;
}

export type ContinuationResult<T> = { items: T[]; continuationToken?: string };
export type ContinuationLoader<T> = (maxItemCount: number, continuationToken: string | undefined, options?: RequestOptions) => Promise<ContinuationResult<T>>;

export class ContinuationIterator<T> implements FeedIterator<T> {
  private readonly loader: ContinuationLoader<T>;
  // Undefined means we haven't loaded first page yet, null means no more pages
  private continuationToken: string | null | undefined;

  constructor(loader: ContinuationLoader<T>) {
    this.loader = loader;
  }

  public get hasMoreResults(): boolean {
    // Undefined means possibly more pages, only null means confirmed no more pages
    return this.continuationToken !== null;
  }

  public async next(maxItemCount: number, options?: RequestOptions | undefined): Promise<T[]> {
    try {
      const continuationToken = this.continuationToken || undefined;
      const results = await this.loader(maxItemCount, continuationToken, options);
      this.continuationToken = results.continuationToken || null;
      return results.items;
    } catch (error) {
      this.continuationToken = null;
      throw error;
    }
  }
}

interface FeedClient {
  feed(request: any): FeedIterator<any>;
}

function isView(arg: any): arg is IItemView<any> {
  return typeof arg.canLoadMore === "boolean";
}
