import { memo } from "react";
import styled, { css } from "styled-components";
import { Practitioner } from "@remhealth/apollo";
import { PractitionerPopover } from "./practitionerPopover";
import { PersonName } from "./personName";

export interface PractitionerNameProps {
  practitioner: Practitioner;
  interactive?: boolean;
}

interface ContainerProps {
  $interactive: boolean;
}

const Container = styled.span<ContainerProps>`
  ${props => props.$interactive && css`
    cursor: pointer;

    &:hover {
      color: ${props.theme.font.active};
    }
  `};
`;

export const PractitionerName = memo((props: PractitionerNameProps) => {
  const { practitioner, interactive = false } = props;

  const content = (
    <Container $interactive={interactive}>
      <PersonName name={practitioner.name} />
    </Container>
  );

  if (interactive) {
    return (
      <PractitionerPopover inline practitioner={practitioner}>
        {_ => content}
      </PractitionerPopover>
    );
  }

  return content;
}, arePropsEqual);

function arePropsEqual(prevProps: PractitionerNameProps, nextProps: PractitionerNameProps): boolean {
  return prevProps.practitioner.name.display === nextProps.practitioner.name.display
    && prevProps.interactive === nextProps.interactive;
}
