import React, { PropsWithChildren, useRef, useState } from "react";
import { ResizeEntry, ResizeSensor } from "@remhealth/ui";

import { ActionText, TextWrapper } from "./showMoreText.styles";

export interface ShowMoreTextProps {
  /**
   * Number of lines
   * @default 3
   */
  lines?: number;
}

export const ShowMoreText = (props: PropsWithChildren<ShowMoreTextProps>) => {
  const { lines = 3, children } = props;

  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);

  const lastKnownHeight = useRef<number>();

  return (
    <>
      <ResizeSensor onResize={handleResize}>
        <TextWrapper $isExpanded={isTextExpanded} $lines={lines}>
          {children}
        </TextWrapper>
      </ResizeSensor>
      {(showMoreButton || isTextExpanded) && (
        <ActionText onClick={handleToggleMessageClick}>
          {isTextExpanded ? "show less" : "show more"}
        </ActionText>
      )}
    </>
  );

  function handleResize(entries: ResizeEntry[]) {
    for (const e of entries) {
      if (e.borderBoxSize.length === 0) {
        return;
      }

      const height = e.borderBoxSize[0].blockSize;

      // Its possible for flickering to occur, so don't react to the same height as just reported
      if (lastKnownHeight.current !== undefined && height === lastKnownHeight.current) {
        if (!isTextExpanded && height !== e.target.scrollHeight) {
          setShowMoreButton(true);
        }

        return;
      }

      lastKnownHeight.current = height;

      if (e.target.scrollHeight > (lines * 24)) {
        return setShowMoreButton(true);
      }

      setShowMoreButton(false);
    }
  }

  function handleToggleMessageClick(event: React.MouseEvent) {
    event.stopPropagation();
    setIsTextExpanded(prev => !prev);
  }
};
