import styled from "styled-components";

export const NoteStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteTypeTitle = styled.div`
  font-size: var(--font-size-header4);
  color: ${props => props.theme.font.muted};
  margin-right: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const NoteInfoContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  padding: 10px 0;
  gap: 10px;
  justify-content: space-between;

  &.group-flow {
    padding: 10px;
  }
`;

export const NoteInfo = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 5px;
`;

export const NoteInfoTitle = styled.div`
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-small);
  white-space: nowrap;
`;

export const NoteInfoDescription = styled.div`
  font-size: var(--font-size-normal);
  overflow: hidden;
`;

export const SessionDateTime = styled(NoteInfoDescription)``;

export const Time = styled.span`
  font-size: var(--font-size-small);
`;

export const SessionTimeHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteTypeInfo = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const SessionInfo = styled.div`
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
`;
