import { useEffect } from "react";
import { DateTime } from "luxon";
import { Appointment, Instant, LocalDate, Patient, ReferenceFilter } from "@remhealth/apollo";
import { useApollo, useUserSession } from "@remhealth/host";
import { ActivePullContext, useAppointmentsView, useFeed, useStore } from "@remhealth/core";
import {
  useAbort,
  useDebouncedSubscriptionState,
  useSubscriptionDispatch,
  useSubscriptionState,
  useUpdateEffect
} from "@remhealth/ui";
import { Text } from "~/text";
import { AgendaContext, AgendaSubscriptionProvider, initialAgendaState } from "~/contexts";
import { PatientSearch } from "~/forms/patientSearch";
import { AgendaList } from "./agendaList";
import { DateBar } from "./dateBar";
import { AgendaRightPane } from "./agendaRightPane";
import { AppointmentPullButton } from "./appointmentPullButton";
import { Container, Content, Header, LeftSection, RightSection } from "./agenda.styles";

export interface AgendaProps {
  onEditGroupNoteType: (selectedGroupId: string) => void;
}

const start = DateTime.now().minus({ days: 14 }).startOf("day"); // Fetch appointments from past 2 weeks
const end = DateTime.now().plus({ days: 10 }).endOf("day"); // Fetch appointments till next 10 days from today

export function Agenda() {
  const activePull = useSubscriptionState(ActivePullContext);
  const { selectedDate, selectedAppointment, showRightPane, filterByPatient, filterByAttendeeIds } = useDebouncedSubscriptionState(AgendaContext, 100);
  const setAgenda = useSubscriptionDispatch(AgendaContext);
  const apollo = useApollo();
  const abort = useAbort();
  const session = useUserSession();
  const store = useStore();

  const view = useAppointmentsView({
    field: "Start",
    direction: "Ascending",
  }, {
    attendees: [getAttendeeFilters()],
    before: Instant.fromDateTime(end),
    onOrAfter: Instant.fromDateTime(start),
  });

  const feed = useFeed(view);

  useEffect(() => {
    setAgenda(initialAgendaState);
  }, []);

  useEffect(() => selectedAppointment ? store.appointments.onItemSet(selectedAppointment.partition, selectedAppointment.id, handleSelectedAppointmentChange) : undefined, [selectedAppointment]);

  useUpdateEffect(() => {
    // When pulling appointments finishes
    if (!activePull.appointments) {
      feed.reset(true);
    }
  }, [activePull.appointments]);

  useUpdateEffect(() => {
    feed.reset();
  }, [filterByPatient, filterByAttendeeIds]);

  return (
    <AgendaSubscriptionProvider>
      <Container form="Agenda">
        <Header>
          <h3>{Text.Agenda}</h3>
          <AppointmentPullButton />
        </Header>
        <Content $showRightPane={showRightPane}>
          <LeftSection>
            <div className="search-input">
              <PatientSearch fill soft selectedItem={filterByPatient} onSelect={handlePatientSelect} />
            </div>
            <DateBar selectedDate={selectedDate.date} />
            <AgendaList end={end} feed={feed} start={start} />
          </LeftSection>
          {showRightPane && (
            <RightSection>
              <AgendaRightPane appointment={selectedAppointment} noEvents={feed.count === 0} onClose={handleClose} />
            </RightSection>
          )}
        </Content>
      </Container>
    </AgendaSubscriptionProvider>
  );

  function handleClose() {
    setAgenda(prev => ({
      ...prev,
      showRightPane: false,
    }));
  }

  async function getPatientGroups(patientId: string) {
    const feed = apollo.groups.feed({
      filters: [{
        type: "Patient",
        effective: {
          wraps: LocalDate.today(),
        },
        members: {
          in: [patientId],
          effective: {
            wraps: LocalDate.today(),
          },
        },
      }],
    });

    return await feed.all({ abort: abort.signal });
  }

  function setFilterByPatient(patient: Patient | null) {
    setAgenda(prev => ({
      ...prev,
      filterByPatient: patient,
    }));
  }

  function setFilterByAttendeeIds(ids: string[] | null) {
    setAgenda(prev => ({
      ...prev,
      filterByAttendeeIds: ids,
    }));
  }

  async function handlePatientSelect(patient: Patient | null) {
    setFilterByPatient(patient);
    if (patient) {
      setFilterByPatient(patient);
      setFilterByAttendeeIds((await getPatientGroups(patient.id)).map(g => g.id));
    } else {
      setFilterByAttendeeIds(null);
    }
  }

  function getAttendeeFilters(): ReferenceFilter {
    const staffId = session.person.id;
    let patientAttendeeIds: string[] = [];

    if (filterByPatient) {
      patientAttendeeIds = [...patientAttendeeIds, filterByPatient.id];
    }

    if (filterByAttendeeIds) {
      patientAttendeeIds = [...patientAttendeeIds, ...filterByAttendeeIds];
    }

    return {
      all: [staffId],
      in: patientAttendeeIds.length > 0 ? patientAttendeeIds : undefined,
    };
  }

  function handleSelectedAppointmentChange(item: Appointment) {
    setAgenda(prev => ({
      ...prev,
      selectedAppointment: item,
    }));
  }
}
