import { Color, ColorRange, colorBetween } from "./colors";

type ColorPalette = Readonly<Color[]>;
export type ColorRamp = Readonly<ColorPalette[]>;
export type ColorSpectrum = ColorRamp | ColorRange;

const goodToBad: ColorRange = ["#538dc6", "#cc334d"];

export const colorSpectrums = {
  /** Best used for categories. */
  spectral: [
    ["#538dc6", "#fc8d59"],
    ["#538dc6", "#fc8d59", "#99d594"],
    ["#6f42e7", "#fdae61", "#abdda4", "#2b83ba"],
    ["#6f42e7", "#fdae61", "#e6f598", "#abdda4", "#2b83ba"],
    ["#6f42e7", "#fc8d59", "#fee08b", "#e6f598", "#99d594", "#3288bd"],
    ["#6f42e7", "#f46d43", "#fdae61", "#fee08b", "#e6f598", "#99d594", "#3288bd"],
    ["#6f42e7", "#f46d43", "#fdae61", "#fee08b", "#e6f598", "#abdda4", "#66c2a5", "#3288bd"],
    ["#6f42e7", "#f46d43", "#fdae61", "#fee08b", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#a24fa2"],
  ],
  /** Best used for scaling values (e.g. percentages). */
  warm: ["#d53e4f", "#fee08b"],
  /** Best used for scaling values (e.g. percentages). */
  cool: ["#045e93", "#b6b3bc"],
  /** Best used for scaling values (e.g. percentages). */
  blue: ["#28adcb", "#4452b9"],
  primary: ["#486de5", "#514ef7"],
  success: ["#48e5cf", "#0ccf75"],
  warning: ["#fdae61", "#d7771a"],
  danger: ["#e55e48", "#cf0c4b"],
  /** Best used for scaling values (e.g. percentages). */
  goodToBad,
  /** Best used for scaling values (e.g. percentages). */
  badToGood: [goodToBad[1], goodToBad[0]],
} as const satisfies Record<string, ColorSpectrum>;

/**
 * Generates a range of colors.
 * @param numberOfColors The number of colors to generate.
 * @param spectrum The color spectrum to use when generating colors.
 * @param alternate If false, colors will scale in order.  If true, colors will alternate for diversity.
 * */
export function getColorRange(numberOfColors: number, spectrum: Readonly<ColorSpectrum> = colorSpectrums.spectral): Color[] {
  const palette: ColorPalette = isRamp(spectrum) ? getPalette(numberOfColors, spectrum) : spectrum;

  if (numberOfColors <= palette.length) {
    return [...palette];
  }

  const colors: Color[] = [];
  const interval = (palette.length - 1) / (numberOfColors - 1);

  for (let i = 0; i < numberOfColors; i++) {
    const index1 = Math.floor(interval * i);
    const index2 = Math.ceil(interval * i);
    const ratio = (interval * i) - index1;
    colors.push(colorBetween([palette[index1], palette[index2]], ratio, "hex"));
  }

  return colors;
}

function isRamp(scheme: Readonly<ColorSpectrum>): scheme is Readonly<ColorRamp> {
  return typeof scheme[0] !== "string";
}

function getPalette(maxColors: number, scheme: Readonly<ColorRamp>): ColorPalette {
  let colors = scheme[0];
  for (let i = 0; i < scheme.length; ++i) {
    if (scheme[i].length <= maxColors) {
      colors = scheme[i];
    }
  }
  return colors;
}
