import { Color, ColorRange, HslOptions, isDarkColor, stringToHsl } from "@remhealth/ui";

export const AvatarHsl = {
  hue: [148, 42],
  saturation: [35, 80],
  lightness: [25, 65],
} as const satisfies HslOptions;

export interface AvatarColors {
  backgroundColor: Color;
  isDarkColor: boolean;
  outlineGradient: ColorRange;
}

export function getAvatarColors(id: string): AvatarColors {
  const { h, s, l } = stringToHsl(id, AvatarHsl, "values");

  const backgroundColor: Color = `hsl(${h}, ${s}%, ${l}%)`;
  const outlineLight: Color = `hsl(${(h + 20) % 360}, 85%, ${Math.min(l + 20, AvatarHsl.lightness[1])}%)`;
  const outlineDark: Color = `hsl(${(h - 20) % 360}, 85%, ${Math.min(l + 20, AvatarHsl.lightness[1])}%)`;

  return {
    backgroundColor,
    isDarkColor: isDarkColor({ h, s, l }),
    outlineGradient: [outlineLight, outlineDark],
  };
}
