import { QuestionnaireAnswer, QuestionnaireElement } from "@remhealth/apollo";
import { QuestionnaireOutput } from "~/questionnaire/narrative/questionnaireOutput";
import { Text } from "~/text";
import { Container, SectionHeader } from "./common.styles";

export interface QuestionnairePreviewOutputProps {
  answers: QuestionnaireAnswer[];
  elements: QuestionnaireElement[];
  sectionName: string;
  isPatientView?: boolean;
  includeSectionHeader: boolean;
}

export const QuestionnairePreviewOutput = (props: QuestionnairePreviewOutputProps) => {
  const { sectionName, isPatientView, includeSectionHeader } = props;

  return (
    <Container>
      {includeSectionHeader && <SectionHeader>{sectionName}</SectionHeader>}
      <QuestionnaireOutput
        {...props}
        isPatientView={isPatientView}
        noDataMessage={!isPatientView && includeSectionHeader ? Text.NoData : undefined}
      />
    </Container>
  );
};
