import { createContext, useContext } from "react";
import { MastodonClient } from "@remhealth/mastodon";
import { GlobalLanguageService, LanguageService } from "./languageService";

export interface GlobalLanguageContext {
  readonly languageService: GlobalLanguageService;
}

export const GlobalLanguageContext = createContext<GlobalLanguageContext>({
  get languageService(): GlobalLanguageService {
    throw new Error("LanguageContext is not initialized");
  },
});

export const useGlobalLanguageService = () => {
  return useContext(GlobalLanguageContext).languageService;
};

export interface LanguageContext {
  readonly languageService: LanguageService;
}

export const LanguageContext = createContext<LanguageContext>({
  get languageService(): LanguageService {
    throw new Error("LanguageContext is not initialized");
  },
});

export const useLanguageService = () => {
  return useContext(LanguageContext).languageService;
};

export interface EhrContext {
  readonly ehrService: MastodonClient;
}

export const EhrContext = createContext<EhrContext>({
  get ehrService(): MastodonClient {
    throw new Error("EhrContext is not initialized");
  },
});

export const useEhr = () => {
  return useContext(EhrContext).ehrService;
};
