import { useMemo } from "react";
import { startsWithAllWords } from "@remhealth/ui";
import {
  Coding,
  HealthcareService,
  HealthcareServiceFilterSet,
  HealthcareServiceSortField,
  LocalDate,
  LocationRole,
  ParameterPresence,
  SortField
} from "@remhealth/apollo";
import { EmptyView, areCodingsSame, isDateRangeEffective } from "@remhealth/host";
import { useStore } from "./useStore";

export interface HealthcareServiceFilterOptions {
  query?: string;
  effectiveDate?: LocalDate;
  allowedLocations?: LocationRole[];
  noteForm?: Coding;
  billable?: boolean;
  group?: boolean;
  missedVisitsOnly?: boolean;
  requireUnits?: boolean;
  ids?: string[];
  notIds?: string[];
  showOverlappingServiceExceptions?: boolean;
}

export function useHealthcareServicesView(sort: SortField<HealthcareServiceSortField>, options: HealthcareServiceFilterOptions) {
  const store = useStore();

  return useMemo(() => {
    if (options.ids && options.ids.length === 0) {
      return new EmptyView<HealthcareService>();
    }

    return store.healthcareServices.view({
      filters: {
        online: createHealthcareServicesFilters(options),
        offline: s => offlinefilter(s, options),
      },
      orderBy: {
        online: sort,
        offline: sortOffline(sort),
      },
    });
  }, [JSON.stringify([options, sort])]);
}

export function createHealthcareServicesFilters(options: HealthcareServiceFilterOptions): HealthcareServiceFilterSet[] {
  const queryFilter: HealthcareServiceFilterSet = {
    text: {
      startsWithAllWords: options.query,
    },
  };

  const effectiveFilter: HealthcareServiceFilterSet = !options.effectiveDate ? {} : {
    effective: {
      wraps: options.effectiveDate,
    },
  };

  const locationFilter: HealthcareServiceFilterSet = !options.allowedLocations ? {} : {
    allowedLocations: {
      in: options.allowedLocations,
      presence: ParameterPresence.MaybePresent,
    },
  };

  const allowedNoteFormsFilter: HealthcareServiceFilterSet = !options.noteForm ? {} : {
    allowedNoteForms: {
      system: options.noteForm.system,
      code: { equalTo: options.noteForm.code },
      presence: ParameterPresence.MaybePresent,
    },
  };

  const billableFilter: HealthcareServiceFilterSet = options.billable === undefined ? {} : {
    billable: {
      equalTo: options.billable,
      presence: ParameterPresence.MustBePresent,
    },
  };

  const groupFilter: HealthcareServiceFilterSet = options.group === undefined ? {} : {
    group: {
      equalTo: options.group,
      presence: ParameterPresence.MustBePresent,
    },
  };

  const unitsFilter: HealthcareServiceFilterSet = options.requireUnits === undefined ? {} : {
    requireUnits: {
      equalTo: options.requireUnits,
      presence: ParameterPresence.MustBePresent,
    },
  };

  const idFilter: HealthcareServiceFilterSet = !options.ids && !options.notIds ? {} : {
    id: {
      in: options.ids,
      notIn: options.notIds,
    },
  };

  const overlappingServiceExceptionsFilter: HealthcareServiceFilterSet = options.showOverlappingServiceExceptions === undefined ? {} : {
    overlappingServiceExceptions: {
      presence: options.showOverlappingServiceExceptions ? ParameterPresence.MustBePresent : ParameterPresence.NotPresent,
    },
  };

  const missedVisitsFilter: HealthcareServiceFilterSet = !options.missedVisitsOnly ? {} : {
    characteristics: {
      in: ["MissedVisit"],
      presence: "MustBePresent",
    },
  };

  return [
    {
      ...queryFilter,
      ...effectiveFilter,
      ...locationFilter,
      ...allowedNoteFormsFilter,
      ...billableFilter,
      ...groupFilter,
      ...unitsFilter,
      ...idFilter,
      ...overlappingServiceExceptionsFilter,
      ...missedVisitsFilter,
    },
  ];
}

function offlinefilter(item: HealthcareService, options: HealthcareServiceFilterOptions) {
  const query = options.query;

  if (query) {
    if (!startsWithAllWords(item.display, query)
      && (item.aliases.length > 0 && !item.aliases.some(alias => startsWithAllWords(alias, query)))) {
      return false;
    }
  }

  if (options.billable !== undefined) {
    if (item.characteristics.includes("Billable") !== options.billable) {
      return false;
    }
  }

  const allowedLocations = options.allowedLocations;
  if (allowedLocations && item.allowedLocations.length > 0) {
    if (!item.allowedLocations.some(v => allowedLocations.includes(v))) {
      return false;
    }
  }

  const noteForm = options.noteForm;
  if (noteForm && item.allowedNoteForms.length > 0) {
    if (!item.allowedNoteForms.some(coding => areCodingsSame(coding, noteForm))) {
      return false;
    }
  }

  if (options.group !== undefined) {
    if (item.characteristics.includes("SupportsGroups") !== options.group) {
      return false;
    }
  }

  if (options.requireUnits !== undefined) {
    if (item.characteristics.includes("RequireUnits") !== options.requireUnits) {
      return false;
    }
  }

  if (options.ids && options.ids.length > 0) {
    if (!options.ids.includes(item.id)) {
      return false;
    }
  }

  if (options.notIds && options.notIds.length > 0) {
    if (options.notIds.includes(item.id)) {
      return false;
    }
  }

  if (options.effectiveDate) {
    if (!isDateRangeEffective(item.effective, LocalDate.toDate(options.effectiveDate))) {
      return false;
    }
  }

  if (options.showOverlappingServiceExceptions !== undefined) {
    if ((options.showOverlappingServiceExceptions && item.overlappingServiceExceptions.length === 0)
      || (!options.showOverlappingServiceExceptions && item.overlappingServiceExceptions.length !== 0)) {
      return false;
    }
  }

  if (options.missedVisitsOnly && !item.characteristics.includes("MissedVisit")) {
    return false;
  }

  return true;
}

function sortOffline(sort: SortField<HealthcareServiceSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: HealthcareService, b: HealthcareService) => {
    switch (sort.field) {
      case "Display":
        return dir * a.display.localeCompare(b.display);
      case "Alias":
        return dir * (a.aliases[0] ?? "!").localeCompare(b.aliases[0] ?? "!");
      case "TypeText": // Temporarily not supported
      default:
        return dir * a.display.localeCompare(b.display);
    }
  };
}
