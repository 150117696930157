import { memo, useEffect, useRef, useState } from "react";
import { isEqual, omit } from "lodash-es";
import { IItemView, Note, PatientNote } from "@remhealth/apollo";
import { useApollo, useUserProfile } from "@remhealth/host";
import { NoteFilterOptions, createNoteFilters, useStore } from "@remhealth/core";
import { LoadingMask, useAbort, useDebouncer } from "@remhealth/ui";
import { ReadOnlyNoteDialog } from "./readOnlyNoteDialog";

export interface NoteViewerProps {
  activeNoteIndex: number;
  view: IItemView<PatientNote>;
  /**
   * Filters used to fetch the total count of notes shown
   * If not passed or undefined, count in viewer is not shown. This can be used as a control to show/hide the count
   */
  filters?: NoteFilterOptions;
  syncIssuesView?: boolean;
  allowEditSwitch?: boolean;
  allowOpenChartSwitch?: boolean;
  allowDeleteNote?: boolean;
  onClose?: () => void;
}

type MoveDirection = "Next" | "Prev";

function NoteViewerComponent(props: NoteViewerProps) {
  const {
    activeNoteIndex,
    view,
    filters = {},
    onClose,
    allowEditSwitch = false,
    allowOpenChartSwitch = true,
    allowDeleteNote = true,
    syncIssuesView = false,
  } = props;

  const [currentNoteIndex, setCurrentNoteIndex] = useState(activeNoteIndex);
  const [items, setItems] = useState(() => view.items());
  const [totalItems, setTotalItems] = useState(0);
  const moveDirection = useRef<MoveDirection>("Next");

  const user = useUserProfile();
  const apollo = useApollo();
  const abort = useAbort();
  const store = useStore();
  const debouncer = useDebouncer(100);

  const note = items[currentNoteIndex];

  useEffect(() => {
    updateNotesCount();
  }, [user.id, JSON.stringify(filters)]);

  useEffect(() => view.onViewChange(handleViewChange), [view.key]);
  useEffect(() => store.notes.onDeleted(updateNotesCount));

  // For the first render load all notes till activeNote index, to fetch note at activeNoteIndex
  useEffect(() => {
    loadView(currentNoteIndex + 1);
  }, []);

  // Preload next item
  useEffect(() => {
    loadView();
  }, [currentNoteIndex, items.length, view.key]);

  if (items.length === 0 || !note) {
    return <LoadingMask usePortal />;
  }

  return (
    <ReadOnlyNoteDialog
      isOpen
      allowDeleteNote={allowDeleteNote}
      allowEditSwitch={allowEditSwitch}
      allowOpenChartSwitch={allowOpenChartSwitch}
      note={note}
      showPagination={{
        canMoveNext: canMoveNext(),
        canMovePrevious: canMovePrevious(),
        count: renderCountMessage(),
        onNext: moveNext,
        onPrev: movePrevious,
      }}
      syncIssuesView={syncIssuesView}
      onClose={onClose}
    />
  );

  function canMoveNext() {
    return currentNoteIndex + 1 < items.length;
  }

  function canMovePrevious() {
    return currentNoteIndex - 1 >= 0;
  }

  async function loadView(maxItemCount = 1) {
    if (view.canLoadMore && currentNoteIndex + 1 >= items.length) {
      await view.loadMore(Math.min(maxItemCount, 100), abort.signal);
      setItems(() => view.items());
    }
  }

  function handleViewChange(items: Note[]) {
    setItems(items as PatientNote[]);

    // Force index if somehow the view loses items
    // We do this unless it the initial render i.e. feed is trying to load all items till it gets to note at activeNoteIndex props
    if (items.length !== 0 && activeNoteIndex !== currentNoteIndex && currentNoteIndex > items.length - 1) {
      setCurrentNoteIndex(items.length - 1);
    } else if (items.length === 1) {
      setCurrentNoteIndex(0);
    }
  }

  function updateNotesCount() {
    debouncer.delay(() => {
      if (filters) {
        fetchNotesCount(user.id, filters);
      }
    });
  }

  async function fetchNotesCount(userId: string, filters: NoteFilterOptions) {
    let count = 0;

    if (filters.patientIds && filters.patientIds.length === 1) {
      count = await apollo.notes.queryCount({
        filters: createNoteFilters(userId, false, filters),
        partition: filters.patientIds[0],
        abort: abort.signal,
      });
    } else {
      count = await apollo.notes.queryCount({
        filters: createNoteFilters(userId, false, filters),
        crossPartition: true,
        abort: abort.signal,
      });
    }

    if (count === 0) {
      onClose?.();
    } else {
      setTotalItems(count);
    }
  }

  function moveNext() {
    moveDirection.current = "Next";
    const nextIndex = currentNoteIndex + 1;
    if (nextIndex < items.length) {
      setCurrentNoteIndex(nextIndex);
    }
  }
  function movePrevious() {
    moveDirection.current = "Prev";
    const previousIndex = currentNoteIndex - 1;
    if (previousIndex >= 0) {
      setCurrentNoteIndex(previousIndex);
    }
  }

  function renderCountMessage() {
    if (totalItems === 0) {
      return null;
    }
    return <>{currentNoteIndex + 1} of {totalItems}</>;
  }
}

function arePropsEqual(prevProps: NoteViewerProps, nextProps: NoteViewerProps) {
  const omitProps: (keyof NoteViewerProps)[] = ["activeNoteIndex", "onClose"];
  return isEqual(omit(prevProps, omitProps), omit(nextProps, omitProps));
}

export const NoteViewer = memo(NoteViewerComponent, arePropsEqual);
