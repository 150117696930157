import React from "react";
import { Media, Reference } from "@remhealth/apollo";
import { Spinner } from "@remhealth/ui";
import { MediaContent } from "./useMediaContent";
import { MediaLoader, SizedMediaContent } from "./mediaLoader";
import { Image, MediaContainer } from "./mediaImage.styles";

type ImageAttributes = Omit<React.ImgHTMLAttributes<HTMLImageElement>, "src" | "width" | "height">;

export interface MediaImageProps {
  media: Media | Reference<Media>;

  /** By default, the media's width will be used.  Null means no height will be specified on the image tag. */
  width?: number | null;

  /** By default, the media's height will be used.  Null means no height will be specified on the image tag. */
  height?: number | null;

  maxWidth?: number;
  maxHeight?: number;

  /** Displays this element while media content is loading. */
  fallback?: JSX.Element;

  onContentLoaded?: (content: MediaContent) => void;
}

export const MediaImage = (props: MediaImageProps & ImageAttributes) => {
  const {
    media,
    width,
    height,
    maxWidth,
    maxHeight,
    fallback,
    onContentLoaded,
    alt,
    ...imgProps
  } = props;

  return (
    <MediaLoader height={height} maxHeight={maxHeight} maxWidth={maxWidth} media={media} width={width}>
      {renderContent}
    </MediaLoader>
  );

  function renderContent(mediaContent: SizedMediaContent | undefined) {
    if (!mediaContent && fallback) {
      return fallback;
    }

    if (!mediaContent?.content) {
      return <MediaContainer><Spinner intent="primary" size={15} /></MediaContainer>;
    }

    const { style = {} } = imgProps;

    style.backgroundImage = `url("${mediaContent.content}")`;
    style.width = mediaContent.width;
    style.height = mediaContent.height;

    return (
      <Image
        {...imgProps}
        $cover={mediaContent.width !== undefined || mediaContent.height !== undefined}
        alt={alt}
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        style={style}
      />
    );
  }
};
