import { createRoot } from "react-dom/client";
import { BrowserNotSupported } from "@remhealth/core";
import { isBrowser, isBrowserNotSupported, isMobileOnly } from "@remhealth/ai";
import { RestrictedMobileView } from "./restrictedMobileView";
import { App } from "./app";

import "./theme.scss";

let rootElement:JSX.Element;
if (isMobileOnly) {
  rootElement = <RestrictedMobileView />;
} else if (isBrowser && isBrowserNotSupported()) {
  rootElement = <BrowserNotSupported />;
} else {
  rootElement = <App />;
}
const root = createRoot(document.getElementById("root")!);
root.render(rootElement);
