import { createContext } from "react";
import type { HealthcareService, NoteDefinition, Reference } from "@remhealth/apollo";
import type { NamedPerson } from "@remhealth/metis";

export interface SpeechPatient extends NamedPerson {
  id?: string;
}

export interface SpeechContext {
  patient: SpeechPatient | undefined;
  definition: Reference<NoteDefinition> | undefined;
  serviceType: Reference<HealthcareService> | undefined;
  sectionName: string | undefined;
}

export const SpeechContext = createContext<SpeechContext>({
  patient: undefined,
  definition: undefined,
  serviceType: undefined,
  sectionName: undefined,
});
