import { Fragment } from "react";
import { CarePlanActivity, GoalElementActivity, groupBy } from "@remhealth/apollo";
import { useProductFlag } from "@remhealth/host";
import { Markup } from "@remhealth/core";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";
import { Comment, Goals, GoalsList } from "./goalsObjectivesSectionContent.styles";

export interface GoalsObjectivesSectionContentProps {
  name: string;
  activities: CarePlanActivity[];
}

export function GoalsObjectivesSectionContent(props: GoalsObjectivesSectionContentProps) {
  const { name, activities } = props;
  const numberedGoals = useProductFlag("NumberedGoals");

  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {activities.length === 0
        ? <NoData>{Text.NoData}</NoData>
        : (
          <GoalsList as={!numberedGoals ? "ul" : "ol"}>
            {activities.map(activity => {
              const groups = Array.from(groupBy(activity.activity, g => g.addresses?.display ?? ""));
              if (groups.some(g => g[0])) {
                return groups.map((g, i) => renderGroupedGoals(g[0], g[1], i));
              }

              return activity.activity.map(renderGoal);
            })}
          </GoalsList>
        )}
    </Container>
  );

  function renderGroupedGoals(title: string, goals: GoalElementActivity[], index: number) {
    if (!title) {
      return <Fragment key={index}>{goals.map(renderGoal)}</Fragment>;
    }

    return (
      <li key={index}>
        {title}
        <ul>{goals.map(renderGoal)}</ul>
      </li>
    );
  }

  function renderGoal(goal: GoalElementActivity, goalIndex: number) {
    return (
      <Goals key={goalIndex}>
        {goal.detail ?? goal.code?.text}
        {goal.comments && <Comment><Markup source={goal.comments.value ?? goal.comments.plainText} /></Comment>}
        {renderGoalElement(goal)}
      </Goals>
    );
  }

  function renderGoalElement(element: GoalElementActivity) {
    if (element.children.length > 0) {
      return (
        <ul>
          {element.children.map((child, index) => {
            return (
              <li key={index}>
                {child.detail ?? child.code?.text}
                {child.comments && <Comment><Markup source={child.comments.value ?? child.comments.plainText} /></Comment>}
                {renderGoalElement(child)}
              </li>
            );
          })}
        </ul>
      );
    }
    return undefined;
  }
}
