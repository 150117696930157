import { isApolloResponseError } from "@remhealth/apollo";
import { Error } from "@remhealth/icons";
import { Classes } from "@remhealth/ui";
import { DetailsDialog, ResponseBody } from "./errorDetailsDialog.styles";

export interface ErrorDetailsDialogProps {
  error?: Error;
  onClose: () => void;
}

export function ErrorDetailsDialog(props: ErrorDetailsDialogProps) {
  const { error, onClose } = props;

  if (isApolloResponseError(error)) {
    const requestId = error.response.headers.requestid;
    const title = error.response.data.title ?? error.message;
    const content = isTextPlain(error.response.headers)
      ? <p>{error.response.data ?? error.message}</p>
      : <ResponseBody>{JSON.stringify(error.response.data, undefined, 2)}</ResponseBody>;

    return (
      <DetailsDialog icon={<Error />} isOpen={!!error} title={title ?? "Error Details"} onClose={onClose}>
        <div className={Classes.DIALOG_BODY}>
          <dl>
            <dt>Request ID:</dt>
            <dd>{requestId}</dd>
            <dt>Status:</dt>
            <dd>{error.response.status}</dd>
          </dl>
          {content}
        </div>
      </DetailsDialog>
    );
  }

  return (
    <DetailsDialog isOpen={!!error} title="Error Details" onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        {error ? error.message : "Unknown error occurred"}
        {error?.stack && <pre>{error.stack}</pre>}
      </div>
    </DetailsDialog>
  );
}

function isTextPlain(headers: any) {
  if (!headers?.["content-type"]) {
    return false;
  }

  const contentType: string = headers["content-type"];
  return contentType.toLowerCase().includes("text/plain");
}
