import { css } from "styled-components";
import { BaseEmbedBlot } from "./baseEmbedBlot";

export interface MentionBlotData {
  value: string;
  display: string;
  denotationChar?: string;
}

export class MentionBlot extends BaseEmbedBlot {
  public static readonly blotName = "mention";
  public static readonly tagName = "rh-mention";

  public static create(data: MentionBlotData) {
    const denotation = data.denotationChar ?? "@";
    const node = document.createElement(this.tagName);
    node.setAttribute("value", data.value);
    node.setAttribute("display", data.display);
    node.setAttribute("denotationChar", denotation);
    node.textContent = data.display.startsWith(denotation) ? data.display : denotation + data.display;
    return node;
  }

  public static value(node: HTMLElement): MentionBlotData {
    return {
      value: node.getAttribute("value")!,
      display: node.getAttribute("display")!,
      denotationChar: node.getAttribute("denotationChar") ?? "@",
    };
  }
}

export const MentionCss = css`
  .mention,
  rh-mention {
    width: unset;
    height: unset;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    padding: 0 3px 1px 3px;
    border-radius: 3px;
    color: ${props => props.theme.dark ? "var(--themecolor5)" : "var(--themecolor2)"};
    background-color: rgba(20, 155, 209, 0.1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;

    > span {
      margin: 0;
    }
  }
`;
