import { useEffect, useRef, useState } from "react";
import { useAbort, useCallbackRef } from "@remhealth/ui";
import type { Authenticator } from "./authenticator";
import type { AccessToken, AccessTokenIdentity } from "./accessToken";

export interface TokenRefreshOptions {
  authenticator: Authenticator;
  token: Readonly<AccessToken> | null;
  onTokenChanged: (token: AccessTokenIdentity | null) => void;
}

const tokenExpirationThreshold = 0.8;

export function useTokenRefresh(options: TokenRefreshOptions) {
  const { authenticator, token, onTokenChanged } = options;

  const refreshTimer = useRef<ReturnType<typeof setTimeout>>();
  const [paused, setPaused] = useState(false);
  const abort = useAbort();

  const onTokenRefresh = useCallbackRef(performTokenRefresh);

  useEffect(() => {
    if (token) {
      scheduleTokenRefresh(token.identity.expirationTime);
    } else {
      stopRefreshTimer();
    }
    return () => {
      stopRefreshTimer();
    };
  }, [token]);

  return { pause, unpause };

  function pause() {
    setPaused(true);
  }

  function unpause() {
    setPaused(false);
  }

  function stopRefreshTimer() {
    if (refreshTimer.current) {
      clearTimeout(refreshTimer.current);
    }
  }

  function scheduleTokenRefresh(expirationTime: number | null) {
    if (refreshTimer.current) {
      clearTimeout(refreshTimer.current);
    }

    const expiresIn = expirationTime ? expirationTime - Date.now() : null;
    const timeout = expiresIn && expiresIn > 0 ? expiresIn * tokenExpirationThreshold : null;

    if (timeout) {
      refreshTimer.current = setTimeout(onTokenRefresh, timeout);
    }
  }

  async function performTokenRefresh(): Promise<void> {
    if (refreshTimer.current) {
      clearTimeout(refreshTimer.current);
    }

    const refreshToken = token?.refreshToken;
    if (!refreshToken) {
      return;
    }

    // If we reached expiry while still paused, then we'll have to lose our token
    if (paused) {
      onTokenChanged(null);
      return;
    }

    try {
      const token = await authenticator.refreshToken(refreshToken, abort.signal);

      if (token) {
        scheduleTokenRefresh(token.expirationTime);
        onTokenChanged(token);
      } else {
        onTokenChanged(null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      // Try again in 30 seconds
      refreshTimer.current = setTimeout(onTokenRefresh, 30000);
    }
  }
}
