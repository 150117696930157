import { DateTime } from "luxon";
import { Button } from "@remhealth/ui";
import { Calendar } from "@remhealth/icons";
import { Text } from "~/text";

export interface TodayButtonProps {
  onClick: (date: DateTime) => void;
}

const currentDate = DateTime.now();
export function TodayButton(props: TodayButtonProps) {
  return (
    <Button
      minimal
      className="today-btn"
      icon={<Calendar />}
      intent="primary"
      label={Text.Today}
      onClick={handleClick}
    />
  );

  function handleClick() {
    props.onClick(currentDate);
  }
}
