import { forwardRef } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { DateFormats, useAutomation } from "@remhealth/ui";
import { DateDayItem, DateLabel, DayLabel } from "./dateBarItem.styles";

interface DateBarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  date: DateTime;
  selectedDate: DateTime;
}

export const DateBarItem = forwardRef((props: DateBarItemProps, ref: React.Ref<HTMLDivElement>) => {
  const { date, selectedDate, ...buttonProps } = props;

  const isDateSelected = date.startOf("day").equals(selectedDate.startOf("day"));
  const isToday = date.startOf("day").equals(DateTime.now().startOf("day"));

  const { id } = useAutomation({ name: date.toFormat("M'/'d") }, "date");

  return (
    <DateDayItem
      ref={ref}
      {...buttonProps}
      aria-label={DateFormats.verbose(date)}
      className={classNames({ "selected-date": isDateSelected, "today": isToday })}
      id={id}
      role="button"
    >
      <DayLabel>{date.toFormat("d")}</DayLabel>
      <DateLabel>{isToday ? "Today" : date.toFormat("EEE")}</DateLabel>
    </DateDayItem>
  );
});
