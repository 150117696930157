import React, { useMemo, useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import { PopoverProps } from "./popover";

const RowItem = styled.tr`
  .hover-only {
    visibility: hidden;
  }
  &:focus-within .hover-only,
  &:hover .hover-only,
  &.hovered .hover-only {
    visibility: visible;
  }
`;

type RowPopoverProps = Pick<PopoverProps, "autoFocus" | "openOnTargetFocus" | "disabled" | "placement" | "minimal">;

export interface InteractiveRowState {
  hovered: boolean;
  focused: boolean;
  /** Common popover props for row menus. */
  popoverProps: RowPopoverProps;
}

export interface InteractiveRowProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>, "children" | "ref"> {
  children: (state: InteractiveRowState) => JSX.Element | string | null | undefined;
}

export const InteractiveRow = (props: InteractiveRowProps) => {
  const { children, className, onFocus, onBlur, onMouseEnter, onMouseLeave, ...restProps } = props;

  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const popoverProps = useMemo<RowPopoverProps>(() => ({
    openOnTargetFocus: true,
    autoFocus: false,
    placement: "bottom-end",
    className: "hover-only",
    disabled: !hovered && !focused,
  }), [hovered, focused]);

  return (
    <RowItem
      className={classnames(className, { hovered })}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...restProps}
    >
      {children({ hovered, focused, popoverProps })}
    </RowItem>
  );

  function handleMouseEnter(e: React.MouseEvent<HTMLTableRowElement>) {
    setHovered(true);
    onMouseEnter?.(e);
  }

  function handleMouseLeave(e: React.MouseEvent<HTMLTableRowElement>) {
    setHovered(false);
    onMouseLeave?.(e);
  }

  function handleFocus(e: React.FocusEvent<HTMLTableRowElement>) {
    setFocused(true);
    onFocus?.(e);
  }

  function handleBlur(e: React.FocusEvent<HTMLTableRowElement>) {
    setFocused(false);
    onBlur?.(e);
  }
};
