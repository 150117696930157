import { useMemo } from "react";
import { useCallbackRef } from "./useCallbackRef";
import { useStateRef } from "./useStateRef";

export interface PagingController<T> {
  /** Indicates the items currently loaded. */
  readonly items: T[];

  /**
   * Indicates the number of items currently loaded.
   * May not be the same as items.length if we are showing a ghost items.
   */
  readonly count: number;

  /** Indicates the desired page. */
  readonly page: number | "last";

  /** A session identifier.  Pagination is reset by via a new session. */
  readonly session: number;

  setItems(items: T[]): void;

  firstPage(): void;
  previousPage(): void;
  setPage(page: number): void;
  nextPage(): void;
  lastPage(): void;

  reload(keepItems?: boolean): void;
  reset(keepItems?: boolean): void;

  copyState(other: PagingController<T>): void;
}

export interface PagingControllerProps<T> {
  onItemsChange?: (items: T[]) => void;
  onPageChange?: (page: number | "last") => void;

  /** Controlled mode.  Not recommended. */
  items?: T[];
}

interface PagingControllerState<T> {
  items: T[];
  page: number | "last";
  count: number;
  session: number;
}

export function usePagingController<T = any>(options?: PagingControllerProps<T>): PagingController<T> {
  const optionItems = useCallbackRef(() => options?.items);
  const onItemsChange = useCallbackRef(options?.onItemsChange);
  const onPageChange = useCallbackRef(options?.onPageChange);

  const state = useStateRef<PagingControllerState<T>>(() => ({
    items: optionItems() ?? [],
    page: 1,
    count: (optionItems() ?? []).length,
    session: 0,
  }));

  return useMemo(() => ({
    get items() {
      return optionItems() ?? state.current.items;
    },
    get count() {
      return state.current.count;
    },
    get page() {
      return state.current.page;
    },
    get session() {
      return state.current.session;
    },
    setItems: updateItems,
    setPage: updatePage,
    firstPage,
    previousPage,
    nextPage,
    lastPage,
    reload,
    reset,
    copyState,
  }), []);

  function updateItems(items: T[]) {
    state.set(state => ({ ...state, items, count: items.length }));
    onItemsChange?.(items);
  }

  function firstPage(): void {
    updatePage(1);
  }

  function previousPage(): void {
    const { page } = state.current;
    if (page !== "last" && page > 1) {
      updatePage(page - 1);
    }
  }

  function nextPage(): void {
    const { page } = state.current;
    if (page !== "last") {
      updatePage(page + 1);
    }
  }

  function lastPage(): void {
    updatePage("last");
  }

  function reset(keepItems = false): void {
    updatePage(1);
    reload(keepItems);
  }

  function reload(keepItems = false): void {
    if (!keepItems) {
      state.set(state => ({ ...state, count: 0, items: [], session: state.session + 1 }));
    } else {
      state.set(state => ({ ...state, count: 0, session: state.session + 1 }));
    }
  }

  function updatePage(page: number | "last") {
    state.set(state => ({ ...state, page }));
    onPageChange?.(page);
  }

  function copyState(other: PagingController<T>) {
    state.set(other);
  }
}
