import { useStore, useStoreItem } from "@remhealth/core";
import { Tag } from "@remhealth/ui";
import { AppointmentActions, AppointmentActionsProps } from "./appointmentActions";
import { useAppointmentStatus } from "./utils";
import { Footer, Muted } from "./appointmentDetailFooter.styles";

export interface AppointmentDetailFooterProps extends AppointmentActionsProps {

}

export const AppointmentDetailFooter = (props: AppointmentDetailFooterProps) => {
  const { associatedEncounter } = props;

  const { getStatus } = useAppointmentStatus();
  const store = useStore();
  const appointment = useStoreItem(store.appointments, props.appointment);

  const status = getStatus(appointment, associatedEncounter);

  return (
    <Footer>
      <Tag minimal intent={status.intent}>{status.status}</Tag>
      {status.isCancelled && <Muted>{appointment.cancelReason?.text}</Muted>}
      <AppointmentActions {...props} />
    </Footer>
  );
};
