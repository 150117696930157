import { useMemo } from "react";
import { CodeableConcept, Coding, systems } from "@remhealth/apollo";
import { Ellipsize } from "@remhealth/ui";
import { CodeableText, CodingList } from "./codeable.styles";

export type CodeableType = Exclude<keyof typeof preferredSystems, "">;

const preferredSystems = {
  "": [systems.icd10, systems.oids.icd10, systems.icd9, systems.oids.icd9, systems.snomed, systems.oids.snomed, systems.loinc, systems.oids.loinc],
  "diagnosis": [systems.icd10, systems.oids.icd10, systems.icd9, systems.oids.icd9, systems.dsm5, systems.snomed, systems.oids.snomed],
  "allergy": [systems.loinc],
} as const;

export type CodeableProps = Omit<CodeableComponentProps, "codesOnly">;

export const Codeable = (props: CodeableProps) => {
  return <CodeableComponent {...props} />;
};

export interface CodingsProps extends Omit<CodeableProps, "concept" | "codesOnly"> {
  codings: Coding[];
}

export const Codings = (props: CodingsProps) => {
  return <CodeableComponent codesOnly concept={{ codings: props.codings }} {...props} />;
};

interface CodeableComponentProps {
  concept: CodeableConcept;
  block?: boolean;
  type?: CodeableType;
  codesOnly?: boolean;
  hoverOpenDelay?: number;
  /** @default 1 */
  lines?: number;
}

interface CodedDescription {
  userSelected: boolean;
  description: string;
}

function CodeableComponent(props: CodeableComponentProps) {
  const {
    block = false,
    concept,
    codesOnly = false,
    type,
    hoverOpenDelay,
    lines = 1,
  } = props;

  const codings = useMemo(getCodings, [concept.codings, type]);
  const text = useMemo(getText, [concept, codings]);

  const tooltipContent = !codesOnly ? renderTooltipContent() : null;
  const content = <>{text}</>;

  return !tooltipContent
    ? content
    : (
      <Ellipsize
        block={block}
        hoverOpenDelay={hoverOpenDelay}
        lines={lines}
        tooltipContent={tooltipContent}
      >
        {content}
      </Ellipsize>
    );

  function renderTooltipContent() {
    return (
      <>
        <CodeableText>{text}</CodeableText>
        {renderCodingList()}
      </>
    );
  }

  function renderCodingList() {
    const codedDescriptions: CodedDescription[] = [];

    codings.forEach(coding => {
      const description = getCodingDescription(coding);
      const existing = codedDescriptions.find(c => c.description === description);
      if (!existing) {
        codedDescriptions.push({ description, userSelected: !!coding.userSelected });
      } else if (coding.userSelected) {
        existing.userSelected = true;
      }
    });

    if (codedDescriptions.length === 0) {
      return null;
    }

    // Special case when tooltip ends up being exactly the same as rendered text
    if (codedDescriptions.length === 1 && text === codedDescriptions[0].description) {
      return null;
    }

    return (
      <CodingList>
        {codedDescriptions.map((codedDescription, index) => (
          <li key={index} className={codedDescription.userSelected ? "selected" : ""}>
            {codedDescription.description}
          </li>
        ))}
      </CodingList>
    );
  }

  function getCodings() {
    return getOrderedCodings(concept.codings ?? [], type);
  }

  function getCodingDescription(coding: Coding): string {
    const code = coding.displayCode ?? coding.code;
    return coding.display ? `${coding.display} (${code})` : code;
  }

  function getText(): string {
    return getConceptText(concept, type, codesOnly);
  }
}

export function getConceptText(concept: CodeableConcept, type?: CodeableType, codesOnly = false): string {
  if (concept.text && !codesOnly) {
    return concept.text;
  }

  if (concept.codings && concept.codings.length !== 0) {
    return getCodingText(concept.codings, type, codesOnly);
  }

  return "";
}

export function getCodingText(codings: Coding[], type?: CodeableType, codesOnly = false): string {
  const orderedCodings = getOrderedCodings(codings, type);

  if (orderedCodings.length === 0) {
    return "";
  }

  const coding = orderedCodings[0];

  if (!codesOnly && coding.display) {
    return coding.display;
  }

  return coding.displayCode ?? coding.code;
}

function getOrderedCodings(codings: Coding[], type?: CodeableType) {
  const preferredOrder = new Set<string>([...preferredSystems[type ?? ""], ...preferredSystems[""]]);
  return orderSystems(Array.from(preferredOrder), codings);
}

function orderSystems(preferredSystems: string[], codings: Coding[]): Coding[] {
  return [...codings].sort(codingComparer(preferredSystems));
}

function codingComparer(preferredSystems: string[]) {
  return (left: Coding, right: Coding) => {
    if (left.userSelected && !right.userSelected) {
      return -1;
    }

    if (!left.userSelected && right.userSelected) {
      return 1;
    }

    const leftIndex = preferredSystems.indexOf(left.system);
    const rightIndex = preferredSystems.indexOf(right.system);

    if (leftIndex !== -1 && rightIndex === -1) {
      return -1;
    }

    if (leftIndex === -1 && rightIndex !== -1) {
      return 1;
    }

    return leftIndex - rightIndex;
  };
}
