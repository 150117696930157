import { memo, useMemo } from "react";
import { DateTime } from "luxon";
import { Minor } from "@remhealth/icons";
import { HumanName, LocalDate, Media, Reference } from "@remhealth/apollo";
import { Icon, Spinner, useAutomation, useDebouncedSubscriptionState } from "@remhealth/ui";
import { ActivePullContext } from "~/app";
import { MediaImage } from "~/media/mediaImage";
import { getAvatarColors } from "./avatarColors";
import { AvatarContainer, Content, ImageContainer } from "./avatar.styles";

export interface Person {
  id: string;
  name: HumanName;
  resourceType: "Patient" | "Practitioner" | "RelatedPerson";
  display: string;
  birthDate?: LocalDate;
  photo?: Media | Reference<Media>;
  avatar?: Media | Reference<Media>;
}

export interface AvatarProps {
  person: Person;
  /** @default 36 */
  size?: number | "small" | "large";
  /**
   * Gives avatar appearance of active (mousedown)
   * @default false
   */
  active?: boolean;
  /**
   * Gives avatar appearance on hover
   * @default false
   */
  interactive?: boolean;
  /**
   * Highlights avatar (notice of activity)
   * @default false
   */
  highlight?: boolean;
  className?: string;
}

export const AvatarSizes = {
  small: 24,
  normal: 36,
  large: 60,
};

export const Avatar = memo((props: AvatarProps) => {
  const { person, active, highlight, className, interactive } = props;

  const size = !props.size ? AvatarSizes.normal
    : typeof props.size === "number" ? props.size : AvatarSizes[props.size];

  const activePullState = useDebouncedSubscriptionState(ActivePullContext, 100);
  const loading = activePullState.personIds.has(person.id);

  const isMinor = !!person.birthDate
    && person.resourceType === "Patient"
    && DateTime.now().diff(LocalDate.toDateTime(person.birthDate), "years").years < 18;

  const name = person.name;
  const firstName = (name.given.length > 0 ? name.given[0] : null) ?? "";
  const lastName = name.family ?? "";

  const minorSize = Math.min(size / 2.25, 30);

  const media = person.avatar ?? person.photo;
  const minorIcon = isMinor ? <Icon className="minor-icon" icon={<Minor />} size={minorSize} /> : undefined;

  const avatarColors = useMemo(() => getAvatarColors(person.id), [person.id]);

  const spinner = highlight || loading ? <Spinner intent="primary" size={size - 8} /> : null;

  const { label, id } = useAutomation({ label: `Avatar for ${firstName} ${lastName}` });

  return (
    <AvatarContainer
      $active={!!active}
      $colors={avatarColors}
      $interactive={!!interactive}
      $loading={loading}
      $size={size}
      aria-label={label}
      className={className}
      id={id}
      role={interactive ? "button" : "img"}
    >
      <Content $colors={avatarColors}>
        {renderContent()}
      </Content>
      {spinner}
      {minorIcon}
    </AvatarContainer>
  );

  function renderContent() {
    if (!media) {
      return renderText();
    }

    const imgSize = size - 8;

    return (
      <ImageContainer>
        <MediaImage fallback={renderText()} height={imgSize} media={media} width={imgSize} />
      </ImageContainer>
    );
  }

  function renderText() {
    return (
      <span className="initials">
        {firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}
      </span>
    );
  }
}, arePropsEqual);

function arePropsEqual(prevProps: AvatarProps, nextProps: AvatarProps) {
  return prevProps.className === nextProps.className
    && prevProps.size === nextProps.size
    && prevProps.active === nextProps.active
    && prevProps.highlight === nextProps.highlight
    && prevProps.person.id === nextProps.person.id
    && prevProps.person.photo?.id === nextProps.person.photo?.id
    && prevProps.person.avatar?.id === nextProps.person.avatar?.id;
}
