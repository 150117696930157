import { useContext } from "react";
import { FormScopeContext, cleanDomId, getAutomationId } from "../components/formScope";

export interface AutomatableProps {
  id?: string;
  field?: { name: string | undefined };
  name?: string;
  "aria-label"?: string;
  "aria-errormessage"?: string;
  label?: string;
}

export function useAutomation(props: AutomatableProps, componentType = "field") {
  const formScope = useContext(FormScopeContext);
  return getAutomationProps(formScope, props, componentType);
}

export function getAutomationProps(formScope: FormScopeContext, props: AutomatableProps, componentType = "field") {
  const {
    id,
    field,
    label,
    name = field?.name,
    "aria-label": ariaLabel = label ?? formScope.controlLabel,
  } = props;

  const controlId = name
    ? `${componentType}[${name}]`
    : ariaLabel ? `${componentType}[${ariaLabel}]` : undefined;

  return {
    label: ariaLabel,
    id: id ?? getAutomationId(formScope, controlId),
    controlId: cleanDomId(id ?? controlId),
    errorId: formScope.errorId ?? props["aria-errormessage"],
  };
}
