import {
  AppAuthError,
  AuthorizationError,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestJson,
  AuthorizationRequestResponse,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  DefaultCrypto,
  StringMap
} from "@openid/appauth";

export interface AuthorizationCode {
  code: string;
  verifier: string;
  state?: AuthorizationState;
}

export interface LoginRequest {
  redirectUri: string;
  scope: string;
  forceAuth?: boolean;
  fhirSmart?: FHIRSmartLogin;
  returnUri?: string;
}

export interface FHIRSmartLogin {
  issuer: string;
  launch: string;
}

export interface AuthorizationState {
  returnUri?: string;
}

export interface LoginRequestHandler {
  setAuthorizationNotifier(notifier: AuthorizationNotifier): void;
  performAuthorizationRequest(configuration: AuthorizationServiceConfiguration, request: AuthorizationRequest): void;
  completeAuthorizationRequest(): Promise<AuthorizationRequestResponse | null>;
}

export interface ResponseTypeCodeConfiguration {
  clientId: string;
  scope: string;

  additionalParameters?: {
    [key: string]: string;
  };
}

export class LoginManager {
  private readonly configuration: ResponseTypeCodeConfiguration;
  private readonly metadata: AuthorizationServiceConfiguration;
  private readonly handler: LoginRequestHandler;

  constructor(configuration: ResponseTypeCodeConfiguration, metadata: AuthorizationServiceConfiguration, handler: LoginRequestHandler) {
    this.configuration = configuration;
    this.metadata = metadata;
    this.handler = handler;
  }

  /*
   * Start the login redirect and listen for the response
   */
  /* eslint-disable no-async-promise-executor */
  public async login(request: LoginRequest): Promise<AuthorizationCode> {
    return new Promise<AuthorizationCode>(async (resolve, reject) => {
      try {
        // Try to start a login
        await this.startLogin(request, resolve, reject);
      } catch (error) {
        // Handle any error conditions
        reject(error);
      }
    });
  }

  /*
   * Do the work of the login redirect
   */
  private async startLogin(request: LoginRequest, onSuccess: (value: AuthorizationCode) => void, onError: (e: any) => void): Promise<void> {
    const state: AuthorizationState = {
      returnUri: request.returnUri,
    };

    // Create the authorization request as a JSON object
    const requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.configuration.clientId,
      redirect_uri: request.redirectUri,
      scope: this.configuration.scope,
      state: JSON.stringify(state),
    };

    // Support redirecting to a particular identity provider
    const extras: StringMap = {
      ...this.configuration.additionalParameters,
    };

    if (request.forceAuth) {
      extras["rh.reauth"] = "login";
    }

    if (request.fhirSmart) {
      extras.iss = request.fhirSmart.issuer;
      extras.launch = request.fhirSmart.launch;
    }

    // Create the authorization request message
    const authorizationRequest = new AuthorizationRequest(requestJson, new DefaultCrypto(), true);
    authorizationRequest.extras = extras;

    // Set up PKCE for the redirect, which avoids native app vulnerabilities
    await authorizationRequest.setupCodeVerifier();

    // Use the AppAuth mechanism of a notifier to receive the login result
    const notifier = new AuthorizationNotifier();
    notifier.setAuthorizationListener(async (request: AuthorizationRequest, response: AuthorizationResponse | null, error: AuthorizationError | null) => {
      try {
        // When we receive the result, handle it and complete the callback
        onSuccess(await handleLoginResponse(request, response, error));
      } catch (error) {
        // Handle any error conditions
        onError(error);
      }
    });

    this.handler.setAuthorizationNotifier(notifier);
    this.handler.performAuthorizationRequest(this.metadata, authorizationRequest);
  }
}

export class LoginResponseManager {
  private readonly handler: LoginRequestHandler;

  constructor(handler: LoginRequestHandler) {
    this.handler = handler;
  }

  public async completeAuthorizationRequest(): Promise<AuthorizationCode | null> {
    const response = await this.handler.completeAuthorizationRequest();

    if (response === null) {
      return null;
    }

    return await handleLoginResponse(response.request, response.response, response.error);
  }
}

async function handleLoginResponse(request: AuthorizationRequest, response: AuthorizationResponse | null, error: AuthorizationError | null): Promise<AuthorizationCode> {
  // The first phase of login has completed
  if (error) {
    throw new AppAuthError("A technical problem occurred during login processing", { code: "loginResponseFailed", innerError: error });
  }

  try {
    // Get the PKCE verifier
    const codeVerifierKey = "code_verifier";
    const codeVerifier = request.internal![codeVerifierKey];
    const state = request.state ? JSON.parse(request.state) as AuthorizationState : undefined;

    // Swap the authorization code for tokens
    return { code: response!.code, verifier: codeVerifier, state };
  } catch (error) {
    // Handle any error conditions
    throw new AppAuthError("A technical problem occurred during token processing", { code: "loginResponseFailed", innerError: error });
  }
}
