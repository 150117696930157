import styled from "styled-components";
import { SubformTableStyles } from "../summary/noteSummaryPreview.styles";

export const NoteContentBody = styled.div`
  padding: 0 30px;

  h3, h4, h5 {
    font-size: var(--font-size-header4);
  }

  ${SubformTableStyles};
`;

export const NoteHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridSize * 1.5}px;
`;

export const PatientBannerContainer = styled.div`
  margin-right: auto;
  padding: 10px 0;
`;

export const SignatureSection = styled.div`
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-normal);
  margin-top: 20px;
`;

export const Signature = styled.div`
  margin-top: 10px;
`;

export const SignatureWrapper = styled.div`
  width: fit-content;
`;

export const GroupSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridSize * 1.5}px;
`;

export const GroupAdmin = styled.div`
  margin-bottom: 10px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const SignaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;
