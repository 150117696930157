import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { PatientChart } from "@remhealth/icons";
import { LocalDate, Patient, Reference, isReference } from "@remhealth/apollo";
import { Button, DateFormats, Ellipsize, Spinner, Tooltip } from "@remhealth/ui";
import { Dot, getAvatarColors, getPatientGender, useStore } from "@remhealth/core";
import { PatientDemographics } from "~/patient/patientDemographics";
import { usePatientAccessor } from "~/contexts";
import { clinicalRoutes } from "~/routes";
import { PatientName } from "./patientName";
import { PatientAvatar } from "./patientAvatar";
import { Container, Details, DobInfo, Footer, Header, Name } from "./patientCard.styles";

export interface PatientCardProps {
  patient: Patient | Reference<Patient>;
  onClick?: (e: React.MouseEvent) => void;
}

export const PatientCard = (props: PropsWithChildren<PatientCardProps>) => {
  const { children, onClick } = props;

  const navigate = useNavigate();
  const store = useStore();
  const patientAccessor = usePatientAccessor();

  const colors = useMemo(() => getAvatarColors(props.patient.id), [props.patient.id]);
  const [patient, setPatient] = useState<Patient | undefined>(isReference(props.patient) ? props.patient.resource : props.patient);

  const openPatient = patient ? patientAccessor.test(patient) : false;

  useEffect(() => {
    load();
  }, [props.patient.id]);

  return (
    <Container $gradient={colors.outlineGradient} onClick={onClick}>
      <Header>
        {patient ? renderInfo(patient) : <Spinner size={60} />}
      </Header>
      <Details>
        {openPatient && <PatientDemographics vertical patient={openPatient} />}
      </Details>
      {children}
      <Footer>
        <Button minimal icon={<PatientChart />} intent="primary" label="Open Chart" onClick={handleOpenChart} />
      </Footer>
    </Container>
  );

  function renderInfo(patient: Patient) {
    const { code: genderCode, display: genderDisplay } = getPatientGender(patient);

    return (
      <>
        <PatientAvatar className="avatar" patient={patient} size={100} />
        <Name>
          <Ellipsize lines={3}><PatientName patient={patient} /></Ellipsize>
        </Name>
        <DobInfo>
          <Tooltip content={genderDisplay}>
            {genderCode}
          </Tooltip>
          <Dot />
          {DateFormats.age(LocalDate.toDate(patient.birthDate))}
          <Dot />
          {DateFormats.date(LocalDate.toDate(patient.birthDate))}
        </DobInfo>
      </>
    );
  }

  async function handleOpenChart() {
    if (await patientAccessor.assert(patient ?? props.patient)) {
      navigate(generatePath(clinicalRoutes.patient, { patientId: props.patient.id }));
    }
  }

  async function load() {
    if (!patient && isReference(props.patient)) {
      const expanded = await store.patients.expand(props.patient);
      if (expanded) {
        setPatient(expanded);
      }
    }
  }
};
