import React, { type Ref, type RefCallback } from "react";
import * as Core from "@blueprintjs/core";

export function mergeRefs<T>(...refs: (Ref<T> | undefined)[]): RefCallback<T> {
  return (value: T | null) => {
    for (const ref of refs) {
      if (ref) {
        Core.setRef(ref, value);
      }
    }
  };
}

export function isElementOfType<P = {}>(
  element: any,
  ComponentType: React.ComponentType<P>
): element is React.ReactElement<P> {
  return (
    element?.type?.displayName != null
    && element.type.displayName === ComponentType.displayName
  );
}

export function isIntrinsicElement<T extends keyof JSX.IntrinsicElements>(element: any, tagName: T): element is React.ReactElement<React.HTMLAttributes<JSX.IntrinsicElements[T]>> {
  if (!element) {
    return false;
  }

  if (element.type === tagName) {
    return true;
  }

  // Styled-components
  if (element.target === tagName) {
    return true;
  }

  return false;
}

export function mapChildrenDeep<P, C>(
  element: React.ReactElement<P>,
  expectedAncestry: [...React.ComponentType<any>[], React.ComponentType<C>],
  map: (child: React.ReactElement<C>) => any
): React.ReactElement<P> {
  if (
    expectedAncestry.length > 0
    && typeof element.props === "object"
    && !!element.props
    && "children" in element.props
  ) {
    return React.cloneElement(element, undefined, React.Children.map(element.props.children, child => {
      if (isElementOfType(child, expectedAncestry[0])) {
        if (expectedAncestry.length === 1) {
          return map(child);
        }
        return mapChildrenDeep<P, any>(child, expectedAncestry.slice(1) as [...React.ComponentType<any>[], React.ComponentType<C>], map);
      }
      return child;
    }));
  }

  return element;
}

/**
 * Returns all data-* and aria-* attributes from props.
 */
export function getHtmlAttributes(props: { [key: string]: any }): { [key: string]: string } {
  const attr: { [key: string]: string } = {};

  for (const key in props) {
    if (key.includes("-")) {
      attr[key] = props[key];
    }
  }

  return attr;
}

const acceptsBooleans = new Set<string>([
  "allowFullScreen",
  "async",
  "autoFocus",
  "autoPlay",
  "controls",
  "default",
  "defer",
  "disabled",
  "disablePictureInPicture",
  "disableRemotePlayback",
  "formNoValidate",
  "hidden",
  "itemScope",
  "loop",
  "noModule",
  "noValidate",
  "open",
  "playsInline",
  "readOnly",
  "required",
  "reversed",
  "scoped",
  "seamless",
]);

export function removeNonHtmlProps(props: { [key: string]: any }) {
  const props2 = Core.removeNonHTMLProps(props);
  for (const key in props2) {
    if (typeof props2[key] === "boolean" && !acceptsBooleans.has(key)) {
      delete props2[key];
    }
  }
  return props2;
}
