import styled from "styled-components";
import { Label } from "@remhealth/ui";
import { FullCenterScreen } from "../styles";

export const Main = styled(FullCenterScreen)`
  text-align: center;
  margin: auto;
`;

export const BrowserNotFoundTitle = styled.span`
  font-size: 2rem;
`;

export const BrowserVersionLabel = styled(Label)`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.375rem;
`;
