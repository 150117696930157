import { useEffect, useRef } from "react";

export function useUnmountEffect(effect: () => void) {
  const willBeRemounted = useRef(false);

  useEffect(() => {
    // This code will run when the component is mounted.

    // Set the willBeRemounted flag to true if the component is about to be remounted.
    if (!willBeRemounted.current) {
      willBeRemounted.current = true;
    }

    return () => {
      // This code will run when the component is unmounted.
      if (!willBeRemounted.current) {
        effect();
      }
    };
  }, []);

  useEffect(() => {
    // This code will run every time the component re-renders.

    if (willBeRemounted.current) {
      // The component is remounted.
      // Reset the flag to false when the component is remounted.
      willBeRemounted.current = false;
    }
  });
}
