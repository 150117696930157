import styled, { css } from "styled-components";
import { Card, Icon, Popover } from "@remhealth/ui";

export const Wrapper = styled(Popover)`
  margin: 16px 4px;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyColumn = styled(Column)``;

export const TagColumn = styled(Column)`
  justify-content: flex-end;
  align-items: flex-end;
`;

export interface GridProps {
  $condensed: boolean;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${props => props.$condensed ? "auto auto" : "2fr 3fr 3fr 100px"};
  grid-column-gap: 8px;

  ${props => props.$condensed && css`
    > *:nth-child(2n) {
      text-align: right;
    }
  `};

  ${props => !props.$condensed && css`
    > *:not(:first-child, ${TagColumn}, ${EmptyColumn}) {
      padding-left: 24px;
      border-left: 2px solid ${props => props.theme.border.muted};
    }
  `};
`;

export const AppointmentDuration = styled.span`
  font-size: var(--font-size-small);

  &:not(:first-child) {
    margin-left: 5px;
  }
`;

export const ServiceType = styled.div`
  color: ${props => props.theme.font.active};
`;

export const AppointmentLocation = styled.div`
  font-size: var(--font-size-small);
`;

export const RecurrenceIcon = styled(Icon)`
  margin: 4px;
`;

export const CancelledIcon = styled(Icon)`
  margin-left: 4px;
`;

export const Muted = styled.div`
  color: ${props => props.theme.font.muted};
`;

export const Recurrence = styled.span`
  color: ${props => props.theme.font.muted};
`;

export const Next = styled.div`
  background-color: rgba(var(--pt-intent-primary-rgb), 0.2);
  color: var(--pt-intent-primary);
  font-size: var(--font-size-small);
  padding: 2px 8px;
  width: fit-content;
  margin-left: 5px;
`;

interface AppointmentCardWrapperProps {
  $serviceColor?: string;
  $active: boolean;
  $condensed: boolean;
}

export const AppointmentCardWrapper = styled(Card)<AppointmentCardWrapperProps>`
  display: flex;
  flex: 1;
  padding: ${props => props.$condensed ? "10px 16px" : "14px 16px"};
  border-left: 4px solid ${props => props.$serviceColor ?? "var(--gray5)"};

  flex-direction: column;
  gap: ${props => props.$condensed ? "4px" : "10px"};

  ${props => props.$active && css`
    outline: 1px solid var(--themecolor5);
  `};
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
  }
`;
