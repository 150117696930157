import { Recurrence, RecurrenceType } from "@remhealth/apollo";

const numberSuffixes = ["st", "nd", "rd", "th"];

export function generateRecurrenceText(recurrence: Recurrence) {
  const text = ["every"];

  const interval = recurrence.intervalSeparation + 1;
  if (interval > 1) {
    text.push(interval.toString(), getFreq(recurrence.type) + "s");
  } else {
    text.push(getFreq(recurrence.type));
  }

  const monthOfYear = recurrence.monthOfYear ? getMonth(recurrence.monthOfYear) : undefined;
  if (monthOfYear !== undefined) {
    text.push("on", monthOfYear);
  }

  if (recurrence.daysOfWeek.length > 0) {
    text.push("on", recurrence.daysOfWeek.join(" or "));
  }

  if (recurrence.dayOfMonth !== undefined) {
    const suffix = numberSuffixes[recurrence.dayOfMonth - 1] ?? "th";
    text.push("on", "the", recurrence.dayOfMonth + suffix);
  }

  if (recurrence.maximumOccurrences !== undefined) {
    text.push(`for ${recurrence.maximumOccurrences} times`);
  }

  return text.join(" ");
}

function getFreq(type: RecurrenceType) {
  switch (type) {
    default:
    case RecurrenceType.Daily:
      return "day";
    case RecurrenceType.Weekly:
      return "week";
    case RecurrenceType.Monthly:
      return "month";
    case RecurrenceType.Annually:
      return "year";
  }
}

function getMonth(monthOfYear: number): string | undefined {
  switch (monthOfYear) {
    case 1: return "January";
    case 2: return "February";
    case 3: return "March";
    case 4: return "April";
    case 5: return "May";
    case 6: return "June";
    case 7: return "July";
    case 8: return "August";
    case 9: return "September";
    case 10: return "October";
    case 11: return "November";
    case 12: return "December";
    default: return undefined;
  }
}
