import { useContext, useEffect } from "react";
import { createSubscription } from "@remhealth/ui";
import type { ComposeEditor } from "~/editor/composeEditor";

const { context: Context } = createSubscription<Map<number, ComposeEditor>>(new Map());

export interface ComposeRegistry {
  getAll(): ComposeEditor[];
}

export function useComposeRegistry(): ComposeRegistry {
  const context = useContext(Context);
  return {
    getAll: () => Array.from(context.subscription.state.values()),
  };
}

export function useComposeRegistration(editor: ComposeEditor): void {
  const context = useContext(Context);
  const subscription = context.subscription;

  useEffect(() => {
    const key = Math.random();
    subscription.update(editors => {
      editors.set(key, editor);
      return new Map(editors);
    });

    return () => {
      subscription.update(editors => {
        editors.delete(key);
        return new Map(editors);
      });
    };
  }, [editor]);
}
