import styled from "styled-components";
import { Icon } from "@remhealth/ui";

interface ContainerProps {
  $serviceColor?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 19rem;
  border-top: solid 4px ${props => props.$serviceColor ?? "var(--gray5)"};
  border-radius: 3px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  flex-grow: 1;
  padding-top: 0px;
`;

export const ServiceTypeName = styled.h4`
  color: ${props => props.theme.font.active};
  font-weight: normal;
  margin: 0 30px 8px 0;
`;

export const AppointmentDate = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const AppointmentTime = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Date = styled.div`
  margin-right: 8px;
`;

export const AppointmentDuration = styled.span`
  color: ${props => props.theme.font.muted};
  margin-left: 4px;
`;

export const Recurrence = styled.div`
  color: ${props => props.theme.font.muted};
  font-size: var(--font-size-small);
`;

export const RecurrenceIcon = styled(Icon)`
  margin-right: 6px;
  margin-top: 4px;
`;

export const AppointmentLocation = styled.div`
  color: ${props => props.theme.font.muted};
  margin-bottom: 8px;
`;

export const Participant = styled.div`
  margin: 8px 0;
`;

export const Header = styled.div`
  position: absolute;
  right: 0;
  margin: 2px 2px 0 0;
`;

export const Footer = styled.div`
  padding: 12px 0;
  border-top: 1px solid ${props => props.theme.border.muted};
`;
