import { useEffect, useMemo, useRef, useState } from "react";

export interface LoadableMap<T> {
  getAll(): T[];
  get(key: string): T | undefined;
  entries(): Map<string, T>;
  loading(key: string): boolean;
  status(key: string): "loaded" | "loading" | "error" | "not-found";
  set(key: string, value: T): void;
  set(values: Iterable<[string, T]>): void;
  unload(key: string): void;
  unloadAll(): void;
  remove(key: string): void;
}

export type LoadablePromise<T> = (keys: string[], abort: AbortSignal) => Promise<Map<string, T>>;

type Loadable<T> = T | "loading" | "error" | "not-loaded";

/**
 * Used to load bulk items and cache the results.
 * @param keys Keys for items to be loaded.  May have keys of items that may have previously loaded already.
 * @param loader A bulk loader promise.  Will be called only when there are keys not loaded.
 * @returns A cache map object for retrieving loaded results.
 */
export function useLoadables<T>(keys: string[], loader: LoadablePromise<T>): LoadableMap<T> {
  const abort = useAbort();
  // eslint-disable-next-line react/hook-use-state
  const [, setVersion] = useState(0);
  const items = useRef(new Map<string, Loadable<T>>());

  useEffect(() => {
    addNewKeys();
  }, [JSON.stringify(keys)]);

  useEffect(() => {
    const timeoutId = setTimeout(load, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [JSON.stringify(getNotLoaded())]);

  return useMemo(() => ({ getAll, entries, get, set, loading, status, unload, unloadAll, remove }), []);

  function getAll() {
    return Array.from(items.current.values()).filter(isLoaded);
  }

  function entries(): Map<string, T> {
    const map = new Map<string, T>();
    items.current.forEach((value, key) => {
      if (isLoaded(value)) {
        map.set(key, value);
      }
    });
    return map;
  }

  function get(key: string) {
    const loadable = items.current.get(key);
    return loadable === undefined || !isLoaded(loadable) ? undefined : loadable;
  }

  function isLoaded(loadable: Loadable<T>): loadable is T {
    return loadable !== "loading" && loadable !== "not-loaded" && loadable !== "error";
  }

  function set(key: string, value: T): void;
  function set(values: Iterable<[string, T]>): void;
  function set(keyOrValues: string | Iterable<[string, T]>, value?: T) {
    if (typeof keyOrValues === "string" && value) {
      items.current.set(keyOrValues, value);
    } else {
      Array.from(keyOrValues as Iterable<[string, T]>).forEach(([key, value]) => {
        items.current.set(key, value);
      });
    }

    setVersion(v => v + 1);
  }

  function loading(key: string) {
    const status = items.current.get(key);
    return status === "loading" || status === "not-loaded";
  }

  function unload(key: string) {
    if (items.current.get(key)) {
      items.current.set(key, "not-loaded");
      setVersion(v => v + 1);
    }
  }

  function unloadAll() {
    for (const key of items.current.keys()) {
      items.current.set(key, "not-loaded");
    }
    setVersion(v => v + 1);
  }

  function remove(key: string) {
    if (items.current.get(key)) {
      items.current.delete(key);
      setVersion(v => v + 1);
    }
  }

  function status(key: string): "loaded" | "loading" | "error" | "not-found" {
    const loadable = items.current.get(key);

    switch (loadable) {
      case undefined: return "not-found";
      case "loading": return "loading";
      case "not-loaded": return "loading";
      case "error": return "error";
      default: return "loaded";
    }
  }

  function addNewKeys() {
    keys.forEach(key => {
      if (!items.current.get(key)) {
        items.current.set(key, "not-loaded");
      }
    });
    setVersion(v => v + 1);
  }

  function getNotLoaded() {
    const notLoaded: string[] = [];

    items.current.forEach((loadable, encounterId) => {
      if (loadable === "not-loaded") {
        notLoaded.push(encounterId);
      }
    });

    return notLoaded;
  }

  async function load() {
    const notLoaded = getNotLoaded();

    if (notLoaded.length > 0) {
      // Mark items as loading
      notLoaded.forEach(key => {
        if (!items.current.get(key)) {
          items.current.set(key, "loading");
        }
      });

      setVersion(v => v + 1);

      const results = await loader(notLoaded, abort);

      results.forEach((value, key) => {
        items.current.set(key, value);
      });

      setVersion(v => v + 1);
    }
  }
}

function useAbort() {
  const controller = useRef(new AbortController());

  useEffect(() => {
    return () => controller.current.abort();
  }, []);

  return controller.current.signal;
}
