/**
 * All the utility functions which are common among the Resource should be listed here.
 * For example, itemsEquals which is common between Group and Facility.
 */

import {
  AllZones,
  CodeableConcept,
  Coding,
  Element,
  IItemView,
  Identifier,
  Instant,
  Patient,
  PracticeClients,
  PracticeContext,
  Practitioner,
  Reference,
  RegistryClients,
  RequestHandler,
  ResourceTypes,
  isReference,
  knownCodings
} from "@remhealth/apollo";
import { DateFormats, sanitizeBaseUrl } from "@remhealth/ui";
import { AccessToken } from "~/auth/accessToken";
import { isInstantRangeEffective } from "~/utils";

export const zones = ["development", "int", "qa", "stg", "train", "prod"] satisfies AllZones[];
export type Zone = (typeof zones)[number];

export function isValidZone(zone: string | AllZones): zone is Zone {
  return (zones as string[]).includes(zone);
}

export interface ResourceComparable {
  id: string;
}

/**
 * Check the equality of two resurces based on the value of `id` property. Two resources are considered to be equals iff
 * they have same value for the `id` otherwise they are distince resource.
 * @param item1 The Resource to compare
 * @param item2 The Resource to compare
 */
export function resourceEquals(item1: ResourceComparable, item2: ResourceComparable) {
  return item1.id === item2.id;
}

export function createApolloJwtHandler(token: AccessToken, innerHandler?: RequestHandler): RequestHandler {
  return {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;

      headers.setAuthorization(`Bearer ${token.accessToken}`);

      let newConfig = {
        headers,
        ...rest,
      };

      if (innerHandler?.onBeforeRequest) {
        newConfig = await innerHandler.onBeforeRequest(newConfig);
      }

      return newConfig;
    },
    onRequestError: innerHandler ? innerHandler.onRequestError : undefined,
    onSuccessfulResponse: innerHandler ? innerHandler.onSuccessfulResponse : undefined,
    onFailedResponse: innerHandler ? innerHandler.onFailedResponse : undefined,
  };
}

export type ApolloClients = RegistryClients & PracticeClients;

export function registryClients(apolloUrl: string, token: AccessToken, innerHandler?: RequestHandler): RegistryClients {
  return new RegistryClients({
    baseURL: sanitizeBaseUrl(apolloUrl),
    requestHandler: createApolloJwtHandler(token, innerHandler),
  });
}

export function practiceClients(apolloUrl: string, token: AccessToken, practice: PracticeContext, innerHandler?: RequestHandler): PracticeClients {
  return new PracticeClients({
    baseURL: sanitizeBaseUrl(apolloUrl),
    practice,
    requestHandler: createApolloJwtHandler(token, innerHandler),
  });
}

export function apolloClients(apolloUrl: string, token: AccessToken, practice: PracticeContext, innerHandler?: RequestHandler): ApolloClients {
  return {
    ...registryClients(apolloUrl, token, innerHandler),
    ...practiceClients(apolloUrl, token, practice, innerHandler),
  };
}

export const UserAccessStatus = {
  Active: "Active" as const,
  Pending: "Pending" as const,
  Expired: "Expired" as const,
};
export type UserAccessStatus = typeof UserAccessStatus[keyof typeof UserAccessStatus];

interface User {
  suspended: boolean;
  accessPeriod?: {
    start?: Readonly<Instant> | undefined;
    end?: Readonly<Instant> | undefined;
  };
}

/**
 * Gets a user's UserAccessStatus.  This value indicates the user's accessPeriod.  This
 * does not account for the suspended flag.
 */
export function getAccessStatus(user: User, relativeTo?: Date): UserAccessStatus {
  const currentTime = relativeTo ?? new Date();

  const start = Instant.toDate(user.accessPeriod?.start);
  if (start && start > currentTime) {
    return UserAccessStatus.Pending;
  }

  const end = Instant.toDate(user.accessPeriod?.end);
  if (end && end <= currentTime) {
    return UserAccessStatus.Expired;
  }

  return UserAccessStatus.Active;
}

export function getAccessStatusMessage(user: User, relativeTo?: Date): string | null {
  if (user.suspended) {
    return "User access is suspended";
  }

  switch (getAccessStatus(user, relativeTo)) {
    case UserAccessStatus.Active: return null;
    case UserAccessStatus.Pending: {
      const activationDate = Instant.toDate(user.accessPeriod?.start);
      return "Pending hire" + (activationDate ? ` on ${DateFormats.date(activationDate)}` : "");
    }
    case UserAccessStatus.Expired: {
      const expirationDate = Instant.toDate(user.accessPeriod?.end);
      return "Terminated" + (expirationDate ? ` on ${DateFormats.date(expirationDate)}` : "");
    }
  }
}

export function isUserActive(user: User, relativeTo?: Date): boolean {
  if (user.suspended) {
    return false;
  }

  return getAccessStatus(user, relativeTo) === UserAccessStatus.Active;
}

export function isBlacklisted(user: Practitioner, patient: Patient | Reference<Patient>): boolean {
  return user.exclusions.some(e => {
    return e.patient.id === patient.id && isInstantRangeEffective(e.exclusionPeriod);
  });
}

export function containsCoding(concept: CodeableConcept, coding: Coding): boolean {
  return concept.codings.some(c => areCodingsSame(coding, c));
}

export function areCodingsSame(left: Coding, right: Coding): boolean {
  return left.code === right.code && left.system === right.system;
}

export function containsIdentifier(element: Element, identifier: Identifier): boolean {
  if (element.identifiers && element.identifiers.length > 0) {
    const identifierValue = identifier.value.toLowerCase();
    return element.identifiers.some(i => i.system === identifier.system && i.value.toLowerCase() === identifierValue);
  }
  return false;
}

export function areIdentifiersSame(left: Identifier, right: Identifier): boolean {
  return left.system === right.system && left.value.toLowerCase() === right.value.toLowerCase();
}

export function partitionReferences<T extends ResourceTypes>(maybeReferences: (Reference<T> | T)[]): [resources: T[], references: Reference<T>[]] {
  const resources: T[] = [];
  const references: Reference<T>[] = [];
  maybeReferences.forEach(r => {
    if (isReference(r)) {
      references.push(r);
    } else {
      resources.push(r as T);
    }
  });
  return [resources, references];
}

export function createReference<T extends ResourceTypes>(maybeReference: Reference<T> | T): Reference<T> {
  return {
    resourceType: maybeReference.resourceType,
    partition: "partition" in maybeReference ? maybeReference.partition : undefined,
    id: maybeReference.id,
    display: maybeReference.display,
  } as any as Reference<T>;
}

export function createVersionedReference<T extends ResourceTypes>(resource: T): Reference<T> {
  return {
    resourceType: resource.resourceType,
    partition: resource.partition,
    versionId: resource.meta?.versionId,
    id: resource.id,
    display: resource.display,
  } as any as Reference<T>;
}

export function profileStoragePartitionKey(networkId: string, profile: string) {
  return `${networkId}-${profile}`;
}

/**
 * Can be used to return an empty view.
 */
export class EmptyView<T> implements IItemView<T> {
  public canLoadMore = false;
  public key = "_void";
  public created: Date | null = null;

  public items(): T[] {
    return [];
  }

  public reset(): void {
    return;
  }

  public loadMore(): Promise<void> {
    return Promise.resolve();
  }

  public onViewChange(): () => void {
    return () => { };
  }
}

export type ReportType = keyof typeof knownCodings.reportTypes;
