import React, { useEffect, useState } from "react";
import { PagingController, PagingResult, useLocalStorage, usePageLoader, usePagingController, useUpdateEffect, useWasMounted } from "../hooks";
import { GridRowProps, GridView, GridViewProps } from "./gridView";

export type PagingGridRowProps = GridRowProps;

export interface PagingGridProps<T> extends Omit<GridViewProps<T> & React.HTMLAttributes<HTMLDivElement>, "controller" | "loader" | "loading" | "canLoadMore" | "pageSize" | "onPageSizeChanged" | "onScroll" | "onLoadMore"> {
  /** Unique key for the grid */
  pageKey: string;
  onLoadMore: (startIndex: number, limit: number, abort: AbortSignal) => PagingResult<T> | Promise<PagingResult<T>>;

  controller?: PagingController<T>;

  /** @default 15 */
  defaultPageSize?: number;

  /**
   * Amount of pages to preload.
   * @default 0
   */
  preload?: number;

  /** If controlled mode, you can provide the loaded items.  Must also register to onLoadedItemsChange. */
  loadedItems?: T[];

  onLoadedItemsChange?: (loadedItems: T[]) => void;
  onPageChange?: (page: number, pageSize: number) => void;
  onPageSizeChanged?: (pageSize: number) => void;
}

export const defaultPagingGridPageSize = 15;

export function PagingGrid<T>(props: PagingGridProps<T>) {
  const pagingController = usePagingController<T>({
    items: props.loadedItems,
    onItemsChange: props.onLoadedItemsChange,
  });

  const {
    controller = pagingController,
    pageKey,
    defaultPageSize = defaultPagingGridPageSize,
    loadedItems,
    preload = 0,
    id = pageKey,
    onLoadMore,
    onLoadedItemsChange,
    onPageChange,
    onPageSizeChanged,
    ...viewProps
  } = props;

  const localStorage = useLocalStorage();
  const wasMounted = useWasMounted();
  const storedPageSize = localStorage.getItem(`${pageKey}-pageSize`);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [pageSize, setPageSize] = useState(storedPageSize ? Number(storedPageSize) : defaultPageSize);

  const loader = usePageLoader({
    ...viewProps,
    canLoadMore,
    onPageChange: handlePageChange,
    onLoadMore: handleLoadMore,
    pageSize,
    preload,
    controller,
  });

  useEffect(() => {
    if (wasMounted || pageSize !== props.defaultPageSize) {
      onPageSizeChanged?.(pageSize);
    }
  }, [pageSize]);

  // Reset canLoadMore on new session
  useUpdateEffect(() => {
    if (!canLoadMore) {
      setCanLoadMore(true);
    }
  }, [controller.session]);

  return (
    <GridView<T>
      {...viewProps}
      canLoadMore={canLoadMore}
      controller={controller}
      id={id}
      loader={loader}
      pageSize={pageSize}
      onLoadMore={handleGridLoadMore}
      onPageSizeChanged={handlePageSizeChanged}
    />
  );

  function handleGridLoadMore(limit: number) {
    if (!loader.loading && canLoadMore) {
      handleLoadMore(limit, new AbortController().signal);
    }
  }

  async function handleLoadMore(limit: number, abort: AbortSignal): Promise<void> {
    const results = await onLoadMore(controller.items.length, limit, abort);
    controller.setItems([...controller.items, ...results.items]);

    // Handle case when no new items came back unexpectedly
    setCanLoadMore(results.hasMore && results.items.length > 0);
  }

  function handlePageChange(page: number) {
    onPageChange?.(page, pageSize);
  }

  function handlePageSizeChanged(pageSize: number) {
    localStorage.setItem(`${pageKey}-pageSize`, pageSize.toString());
    setPageSize(pageSize);
  }
}
