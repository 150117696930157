import React from "react";
import classnames from "classnames";
import SVGInject from "svg-injector";
import { injectGradient } from "@remhealth/icons";
import { Color, ColorRange } from "~/utils";

const dataSvgPrefix = "data:image/svg+xml,";
const dataBase64Prefix = "data:image/svg+xml;base64,";

export interface SvgProps {
  src: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  fill?: string | Color | ColorRange;
  style?: React.CSSProperties;
  afterInject?: (svg: SVGElement) => void;
}

export class Svg extends React.PureComponent<SvgProps> {
  private readonly img = React.createRef<HTMLImageElement>();
  private readonly reactSvg = React.createRef<SVGSVGElement>();
  private svg: SVGElement | null = null;
  private gradientGroup: SVGGElement | null = null;
  private attributes = new Map<string, string>();

  public componentDidMount() {
    this.convertImageToSvg();
    this.appendAttributesToSvg();
  }

  /**
   * If the svg src changed, we will reset,
   * otherwise we only need to change a few attributes on the already rendered svg
   */
  public componentDidUpdate(prevProps: SvgProps) {
    if (!isSvgData(this.props.src) && isSvgData(prevProps.src)) {
      this.convertImageToSvg();
    } else {
      const changed = prevProps.src !== this.props.src || !areFillEqual(prevProps.fill, this.props.fill);
      if (changed) {
        this.resetSvg();
      } else {
        this.updateSvg();
      }
    }
  }

  public componentWillUnmount() {
    // Need to restore dom back to original state so react can do proper cleanup
    this.restoreImg();
  }

  public render() {
    const { src, fill, className, afterInject, ...svgProps } = this.props;

    const svgXml = this.getSrcXml();
    if (svgXml) {
      this.restoreImg();

      const el = document.createElement("div");
      el.innerHTML = svgXml;

      const svg = el.firstElementChild;

      if (!svg) {
        return "";
      }

      const attributes = svg.attributes;
      this.attributes.clear();
      const props: { [index: string]: string | undefined } = {};

      for (let i = 0; i < attributes.length; ++i) {
        const attr = attributes[i];

        // HACK: Can't apply string style or class attr to react svg element, so we do it manually later using setAttribute
        if (attr.name === "style") {
          this.attributes.set(attr.name, attr.value);
        } else if (attr.name === "class" || attr.name === "className") {
          props.className = attr.value;
        } else if (!attr.name.includes(":")) {
          props[attr.name] = attr.value;
        }
      }

      return (
        <svg
          dangerouslySetInnerHTML={{ __html: svg.innerHTML }}
          ref={this.reactSvg}
          {...props}
          {...svgProps}
          className={classnames(props.className, className)}
        />
      );
    }

    return (
      <img ref={this.img} data-src={src} {...svgProps} />
    );
  }

  private getSrcXml = () => {
    const { src } = this.props;

    // Source is "data:image/svg+xml,%3c?xml%20version..."
    if (src.startsWith(dataSvgPrefix)) {
      return decodeURIComponent(src.slice(dataSvgPrefix.length));
    }

    // Source is "data:image/svg+xml;base64,XYZZZZ..."
    if (src.startsWith(dataBase64Prefix)) {
      return decodeBase64(src.slice(dataBase64Prefix.length));
    }

    return null;
  };

  /** Updates the svg props */
  private updateSvg = () => {
    if (!this.svg) {
      this.appendAttributesToSvg();
      return;
    }

    const { width, height, fill, className } = this.props;

    if (className) {
      this.svg.classList.add(...className.split(" "));
    }

    if (width) {
      this.svg.setAttribute("width", String(width));
    }

    if (height) {
      this.svg.setAttribute("height", String(height));
    }

    if (fill && typeof fill === "string") {
      this.svg.setAttribute("fill", String(fill));
    }

    this.injectGradient(this.svg);
  };

  /** Replaces the img with an svg */
  private convertImageToSvg = () => {
    if (!this.img.current?.parentElement) {
      return;
    }

    const { src, afterInject } = this.props;

    SVGInject(this.img.current, {
      each: (svg) => {
        if (typeof svg !== "string") {
          this.svg = svg;

          // Source changed during loading
          if (this.props.src !== src) {
            this.resetSvg();
          } else {
            this.updateSvg();
          }

          // Callback after SVG is injected
          afterInject?.(svg);
        }
      },
    });
  };

  /** Appends pending attributes to svg rendered by react */
  private appendAttributesToSvg = () => {
    const reactSvg = this.reactSvg.current;
    if (reactSvg) {
      this.attributes.forEach((value, key) => {
        reactSvg.setAttribute(key, value);
      });

      this.injectGradient(reactSvg);

      if (this.props.afterInject) {
        this.props.afterInject(reactSvg);
      }
    }
  };

  /** Replaces svg with original image and re-renders the svg */
  private resetSvg = () => {
    if (!this.svg?.parentElement || !this.img.current) {
      return;
    }

    this.restoreImg();
    this.convertImageToSvg();
  };

  private restoreImg = () => {
    if (!this.svg?.parentElement || !this.img.current) {
      return;
    }

    this.svg.parentElement.insertBefore(this.img.current, this.svg);
    this.svg.remove();
    this.svg = null;
  };

  private injectGradient = (svg: SVGElement | undefined) => {
    const { fill } = this.props;
    if (!svg || this.gradientGroup || !fill || typeof fill === "string") {
      return;
    }

    this.gradientGroup = injectGradient(svg, fill);
  };
}

function areFillEqual(a: string | Color | ColorRange | undefined, b: string | Color | ColorRange | undefined): boolean {
  if (a === b) {
    return true;
  }

  if (isColorRange(a) && isColorRange(b) && a[0] === b[0] && a[1] === b[1]) {
    return true;
  }

  return false;
}

function isColorRange(value: string | Color | ColorRange | undefined): value is ColorRange {
  return !!value && typeof value !== "string";
}

function decodeBase64(data: string): string {
  const base64 = data.replace(/-/g, "+").replace(/_/g, "/");
  return decodeURIComponent(window.atob(base64).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));
}

function isSvgData(src: string) {
  return src.startsWith(dataSvgPrefix) || src.startsWith(dataBase64Prefix);
}
