import styled from "styled-components";
import { Dialog } from "./dialog";

export const GridDialog = styled(Dialog)`
  margin: 0;
  padding-bottom: 0;

  .bp5-dialog-header {
    flex: 0 0 auto;
    box-shadow: inset 0 -1px 0 ${props => props.theme.border.muted};
  }

  > .bp5-dialog-body {
    flex: 1 1 auto;
    padding: 0px 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    > .paging-grid {
      flex: 1 1 auto;
      width: calc(100% + 60px);
      height: auto;
      margin: 0 -30px;

      &:not(:first-child) {
        border-top: 1px solid ${props => props.theme.border.muted};
      }

      .bp5-html-table tr th {
        background-color: ${props => props.theme.background.muted};
      }

      .bp5-html-table tr td:first-child,
      .bp5-html-table tr th:first-child {
        padding-left: 20px;
      }

      .pagination-controls {
        box-shadow: inset 0 1px 0 ${props => props.theme.border.muted};
        background-color: ${props => props.theme.background.muted};
      }
    }
  }

  > .bp5-dialog-footer {
    box-shadow: inset 0 1px 0 ${props => props.theme.border.muted};
    padding: 10px 15px;
    margin: 0;
  }
`;
