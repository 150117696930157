import { useEffect } from "react";
import type { NoteRule } from "@remhealth/apollo";
import { type NoteRuleFilterOptions, useFeed, useNoteRulesView } from "@remhealth/core";
import { useAbort, useDebouncer } from "@remhealth/ui";

export function useNoteRulesFeed(options: NoteRuleFilterOptions): () => Promise<NoteRule[]>;
export function useNoteRulesFeed(options: NoteRuleFilterOptions, autoLoad: true): NoteRule[];
export function useNoteRulesFeed(options: NoteRuleFilterOptions, autoLoad = false): (NoteRule[] | (() => Promise<NoteRule[]>)) {
  const abort = useAbort();
  const debouncer = useDebouncer(100);

  const view = useNoteRulesView(options);

  const feed = useFeed(view, { reload: false });

  useEffect(() => {
    if (autoLoad && !options.disabled) {
      debouncer.delay(loadRules);
    }
  }, [autoLoad, options.disabled, feed.session]);

  if (autoLoad) {
    return feed.items;
  }

  return loadRules;

  async function loadRules() {
    const signal = abort.signal;
    while (feed.canLoadMore && !signal.aborted) {
      await feed.loadMore(100, signal);
    }
    return feed.items;
  }
}
