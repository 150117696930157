import * as rdd from "react-device-detect";
import { supportedBrowsers } from "~/supportedBrowsers";

function isVersionSupported(): boolean {
  return supportedBrowsers.test(navigator.userAgent);
}

export function isBrowserVersionSupported(): boolean {
  if (rdd.isTablet) {
    return false;
  }

  if (
    rdd.getUA.includes("CT|One") // Allow CT|One regardless of the version
    || isVersionSupported()
  ) {
    return true;
  }
  return false;
}

export function isBrowserNotSupported(): boolean {
  return rdd.isIE;
}

export { isMobileOnly, isBrowser, isAndroid, isIOS, isWindows } from "react-device-detect";
