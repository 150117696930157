import { createContext, useContext } from "react";
import { AuditService } from "./auditService";

export interface AuditContext {
  readonly audit: Readonly<AuditService>;
}

export const AuditContext = createContext<AuditContext>({
  get audit(): AuditService {
    throw new Error("AuditContext is not initialized");
  },
});

export const useAudit = () => useContext(AuditContext).audit;
