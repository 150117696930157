import { Media, Reference } from "@remhealth/apollo";
import { MediaContent, useMediaContent } from "./useMediaContent";

export interface MediaLoaderProps {
  media: Media | Reference<Media>;

  /** By default, the media's width will be used.  Null means no height will be specified on the image tag. */
  width?: number | null;

  /** By default, the media's height will be used.  Null means no height will be specified on the image tag. */
  height?: number | null;

  maxWidth?: number;
  maxHeight?: number;

  children?: (content: SizedMediaContent | undefined) => React.ReactNode;
  onContentLoaded?: (content: MediaContent) => void;
}

export interface SizedMediaContent extends MediaContent {
  width: number | undefined;
  height: number | undefined;
}

export const MediaLoader = (props: MediaLoaderProps) => {
  const {
    media: controlledMedia,
    width: controlledWidth,
    height: controlledHeight,
    maxWidth,
    maxHeight,
    children,
    onContentLoaded,
  } = props;

  const mediaContent = useMediaContent({
    media: controlledMedia,
    maxHeight: maxHeight ?? controlledHeight ?? undefined,
    maxWidth: maxWidth ?? controlledWidth ?? undefined,
    onContentLoaded,
  });

  let width = controlledWidth !== undefined
    ? controlledWidth !== null ? controlledWidth : undefined
    : mediaContent ? mediaContent.media.width : undefined;

  let height = controlledHeight !== undefined
    ? controlledHeight !== null ? controlledHeight : undefined
    : mediaContent ? mediaContent.media.height : undefined;

  if (width !== undefined && height !== undefined) {
    const aspectRatio = width / height;

    if (maxWidth !== undefined && width > maxWidth) {
      width = maxWidth;
      height = width / aspectRatio;
    }

    if (maxHeight !== undefined && height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }
  } else if (maxWidth !== undefined && width !== undefined && width > maxWidth) {
    width = maxWidth;
  } else if (maxHeight !== undefined && height !== undefined && height > maxHeight) {
    height = maxHeight;
  }

  if (!mediaContent) {
    return <>{children?.(undefined)}</>;
  }

  return <>{children?.({ ...mediaContent, width, height })}</>;
};
