import React, { useContext } from "react";
import {
  Formik,
  type FormikHelpers as FormikActions,
  type FormikComputedProps,
  type FormikConfig,
  type FormikErrors,
  type FormikHelpers,
  type FormikProps,
  type FormikState,
  type FormikTouched,
  type FormikValues,
  getIn,
  isInteger,
  isObject,
  setNestedObjectValues,
  validateYupSchema,
  yupToFormErrors
} from "formik";
import { clone, toPath } from "lodash-es";
import isEqual from "react-fast-compare";
import { Form as UiForm, createForm } from "@remhealth/ui";
import { ValidationError, prepareForValidation } from "~/validation";

export interface FormValues extends FormikValues {}
export type FormErrors<Values> = FormikErrors<Values>;
export type FormTouched<Values> = FormikTouched<Values>;

type OverriddenActions = "resetForm" | "setFieldValue" | "setFieldError" | "setFieldTouched" | "validateField" | "submitForm";
export type FormActions<Values> = Omit<FormikActions<Values>, OverriddenActions> & {
  setFieldValue: <TKey extends string & keyof Values>(field: TKey, value: Values[TKey], shouldValidate?: boolean) => void;
  updateFieldValue: <TKey extends string & keyof Values>(field: TKey, updateFn: (currentValue: Values[TKey]) => Values[TKey], shouldValidate?: boolean) => void;
  setFieldError: <TKey extends string & keyof Values>(field: TKey, message: string | undefined) => void;
  setFieldTouched: <TKey extends string & keyof Values>(field: TKey, isTouched?: boolean, shouldValidate?: boolean) => void;
  validateField: <TKey extends string & keyof Values>(field: TKey) => void;
  submitForm: () => Promise<void>;
  resetForm: (nextValues?: Values | undefined) => void;
  setAllTouched: () => void;
};

export interface FormProps<Values extends FormValues> extends Omit<FormikConfig<Values>, "children" | "component" | "innerRef" | "render" | "onReset" | "onSubmit" | "validationSchema"> {
  /** If true, all fields linked to this form will be readonly. */
  readOnly?: boolean;
  /** If true, all fields linked to this form will be disabled. */
  disabled?: boolean;
  /**
   * If true, all fields linked to this form will be disabled during submit.
   * @default true
   */
  disableIfSubmitting?: boolean;
  /** Tells Formik to validate whenever the initial values change */
  validateOnInitialize?: boolean;
  validationSchema?: ValidationSchemaFactory<any>;
  onReset?: (values: Values, formActions: FormActions<Values>) => void;
  onSubmit: (values: Values, formActions: FormActions<Values>) => void | Promise<void>;
  children?: (content: FormContent<Values>) => React.ReactNode;
}

type ValidationSchemaFactory<T> = ValidationSchema<T> | ((field?: string) => ValidationSchema<T>);

interface ValidateOptions {
  abortEarly?: boolean;
  context?: object;
}

interface ValidationSchema<T> {
  validate(value: any, options?: ValidateOptions): Promise<T>;
  validateSync(value: any, options?: ValidateOptions): T;
  validateAt(path: string, value: T, options?: ValidateOptions): Promise<T>;
}

type FormikContent<Values extends FormValues> = FormikState<Values> & FormikHelpers<Values> & FormikComputedProps<Values>;
export interface FormContent<Values extends FormValues> extends Omit<FormikContent<Values>, OverriddenActions>, FormActions<Values> {
  readonly key: number;
    /** If true, all fields linked to this form will be readonly. */
  readonly readOnly: boolean;
    /** If true, all fields linked to this form will be disabled. */
  readonly disabled: boolean;
  readonly fields: UiForm<Values>;
  onReset(handler: ResetEventHandler): Unsubscriber;
}

export interface FormContext<Values extends FormValues> {
  readonly form: FormContent<Values>;
}

export const FormContext = React.createContext<FormContext<any>>({
  get form(): FormContent<any> {
    throw new Error("FormContext is not initialized");
  },
});

export const useFormContent = function<Values extends FormValues>() {
  return useContext<FormContext<Values>>(FormContext).form;
};

export type ResetEventHandler = () => void;
export type Unsubscriber = () => void;

interface FormState<Values extends FormValues> {
  reinitializing: boolean;
  key: number;
  initialValues: Values;
  isSubmittingInternal: boolean;
}

export class Form<Values extends FormValues> extends React.Component<FormProps<Values>, FormState<Values>> {
  private readonly formik = React.createRef<FormikProps<Values>>();
  private readonly resetHandlers = new Map<number, ResetEventHandler>();
  private subscriptionIndex = 0;

  constructor(props: FormProps<Values>) {
    super(props);

    this.state = {
      reinitializing: false,
      key: Math.random(),
      initialValues: prepareForValidation(props.initialValues),
      isSubmittingInternal: false,
    };
  }

  public static getDerivedStateFromProps<Values extends FormValues>(nextProps: FormProps<Values>, prevState: FormState<Values>): FormState<Values> | null {
    if (nextProps.enableReinitialize) {
      if (nextProps.initialValues !== prevState.initialValues) {
        const nextInitialValues = prepareForValidation(nextProps.initialValues);

        if (!isEqual(nextInitialValues, prevState.initialValues)) {
          return {
            // Force remount using key due to issue with stale initialValues
            // being used for a render cycle.
            // https://github.com/jaredpalmer/formik/issues/729
            initialValues: nextInitialValues,
            key: Math.random(),
            reinitializing: true,
            isSubmittingInternal: false,
          };
        }
      }
    }

    // Continue using same initialValues as before
    return null;
  }

  public componentDidMount() {
    if (this.formik.current && this.props.validateOnInitialize) {
      validateForm(this.formik.current, this.formik.current.values);
    }
  }

  public componentDidUpdate() {
    if (this.state.reinitializing) {
      this.setState({ reinitializing: false });

      if (this.formik.current && this.props.validateOnInitialize) {
        validateForm(this.formik.current, this.state.initialValues);
      }
    }
  }

  public render() {
    const { children, ...formikProps } = this.props;
    const { reinitializing, initialValues } = this.state;

    return (
      <Formik<Values>
        innerRef={this.formik}
        {...formikProps}
        initialValues={initialValues}
        validate={this.validate}
        validationSchema={undefined}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
      >
        {formik => reinitializing
          ? this.renderChildren({ ...formik, initialValues, values: initialValues, initialErrors: {}, errors: {}, dirty: false })
          : this.renderChildren(formik)}
      </Formik>
    );
  }

  public setValues = (values: React.SetStateAction<Values>, shouldValidate?: boolean): Promise<void | FormikErrors<Values>> => this.formik.current?.setValues(values, shouldValidate) ?? Promise.resolve();
  public setErrors = (errors: FormErrors<Values>): void => this.formik.current?.setErrors(errors);
  public setStatus = (status?: any): void => this.formik.current?.setStatus(status);
  public setSubmitting = (isSubmitting: boolean): void => this.formik.current?.setSubmitting(isSubmitting);
  public validateField = (field: string): Promise<void> | Promise<string | undefined> => this.formik.current?.validateField(field) ?? Promise.resolve();
  public validateForm = async (values: Values): Promise<FormikErrors<Values>> => await this.formik.current?.validateForm(values) ?? {};
  public setFormikState = (f: FormikState<Values> | ((prevState: FormikState<Values>) => FormikState<Values>), cb?: () => void) => this.formik.current?.setFormikState(f, cb);

  public resetForm = (nextValues?: Values | undefined): void => {
    if (this.formik.current) {
      resetForm(this.formik.current, this.resetHandlers, nextValues);
    }
  };

  public setTouched = (touched: FormikTouched<Values>, shouldValidate?: boolean): void => {
    if (this.formik.current) {
      setTouched(this.formik.current, touched, shouldValidate);
    }
  };

  public setFieldTouched = (field: string, isTouched?: boolean, shouldValidate?: boolean): void => {
    if (this.formik.current) {
      setFieldTouched(this.formik.current, field, isTouched, shouldValidate);
    }
  };

  public setFieldValue = (field: string, value: any, shouldValidate?: boolean): void => {
    if (this.formik.current) {
      setFieldValue(this.formik.current, field, value, shouldValidate);
    }
  };

  public setFieldError = (field: string, message: string | undefined): void => {
    if (this.formik.current) {
      setFieldError(this.formik.current, field, message);
    }
  };

  public setAllTouched() {
    if (this.formik.current) {
      setAllTouched(this.formik.current, this.formik.current.values);
    }
  }

  public submitForm = async () => {
    if (this.formik.current) {
      await submitForm(this.formik.current, this.setIsSubmittingInternal);
    }
  };

  public onReset = (handler: ResetEventHandler): Unsubscriber => {
    const index = this.subscriptionIndex++;
    this.resetHandlers.set(index, handler);
    return () => this.resetHandlers.delete(index);
  };

  private setIsSubmittingInternal = (isSubmittingInternal: boolean) => {
    this.setState({ isSubmittingInternal });
  };

  private validate = (values: Values, field?: string): Promise<FormikErrors<Values>> => {
    const validationSchema = this.props.validationSchema;
    const schema = typeof validationSchema === "function" ? validationSchema(field) : validationSchema;

    if (!schema) {
      return Promise.resolve({});
    }

    // Fix any circular references to avoid errors
    const validationValues = prepareForValidation(values);

    const promise = field && schema.validateAt
      ? schema.validateAt(field, validationValues)
      : validateYupSchema(validationValues, schema);

    return new Promise((resolve, reject) => {
      promise.then(
        () => {
          resolve({});
        },
        (error: any) => {
          if (error.name === "ValidationError") {
            resolve(yupToFormErrors(error));
          } else {
            reject(error);
          }
        }
      );
    });
  };

  private handleReset = (values: Values, formikActions: FormikActions<Values>) => {
    // Reset occurs during Reinitialize, but we should treat that as a different behavior than a real reset
    if (this.state.reinitializing) {
      return;
    }

    this.props.onReset?.(values, createFormActions(formikActions, { values }, this.resetHandlers, this.setIsSubmittingInternal));
    this.resetHandlers.forEach(handler => handler());
  };

  private handleSubmit = async (values: Values, formikActions: FormikActions<Values>) => {
    await this.props.onSubmit(values, createFormActions(formikActions, { values }, this.resetHandlers, this.setIsSubmittingInternal));
  };

  private renderChildren = (formik: FormikProps<Values>) => {
    const { readOnly = false, disabled, children, disableIfSubmitting = true } = this.props;
    const forceDisabled = formik.isSubmitting && disableIfSubmitting;
    const isSubmittingInternal = () => this.state.isSubmittingInternal;

    const computedFields: FormikComputedProps<Values> = {
      get isValid() {
        return formik.isValid;
      },
      get dirty() {
        return formik.dirty;
      },
      get initialValues() {
        return formik.initialValues;
      },
      get initialErrors() {
        return formik.initialErrors;
      },
      get initialTouched() {
        return formik.initialTouched;
      },
    };

    const stateFields: FormikState<Values> = {
      get values() {
        return formik.values;
      },
      get errors() {
        return formik.errors;
      },
      get touched() {
        return formik.touched;
      },
      get isSubmitting() {
        return formik.isSubmitting || isSubmittingInternal();
      },
      get isValidating() {
        return formik.isValidating;
      },
      get submitCount() {
        return formik.submitCount;
      },
    };

    const formActions: FormActions<Values> = createFormActions(formik, stateFields, this.resetHandlers, this.setIsSubmittingInternal);

    const content: FormContent<Values> = {
      key: this.state.key,
      readOnly,
      disabled: disabled || forceDisabled,
      ...formik,
      ...formActions,
      ...computedFields,
      ...stateFields,
      onReset: this.onReset,
      fields: createForm({
        ...formik,
        ...formActions,
        ...computedFields,
        ...stateFields,
        readOnly,
        disabled: disabled || forceDisabled,
      }, []),
    };

    return (
      <FormContext.Provider value={{ form: content }}>
        {children?.(content)}
      </FormContext.Provider>
    );
  };
}

function createFormActions<Values extends FormValues>(
  formik: FormikActions<Values>,
  state: { readonly values: Values },
  resetHandlers: Map<number, ResetEventHandler>,
  setIsSubmittingInternal: (value: boolean) => void
): FormActions<Values> {
  return {
    ...formik,
    setAllTouched: () => setAllTouched(formik, state.values),
    setTouched: (...args) => setTouched(formik, ...args),
    setFieldValue: (...args) => setFieldValue(formik, ...args),
    updateFieldValue: (...args) => updateFieldValue(formik, ...args),
    setFieldTouched: (...args) => setFieldTouched(formik, ...args),
    setFieldError: (...args) => setFieldError(formik, ...args),
    submitForm: () => submitForm(formik, setIsSubmittingInternal),
    resetForm: (...args) => resetForm(formik, resetHandlers, ...args),
  };
}

type ExtendedFormikActions<Values> = FormikActions<Values> & {
  // Required for when our custom setIn function is called multiple times
  // between renders, to avoid stomping on previous changes that haven't rendered yet
  pendingValues?: Values;
};

function setFieldValue<Values>(formik: ExtendedFormikActions<Values>, field: string, value: any, shouldValidate?: boolean): void {
  if (value === undefined) {
    formik.setValues(values => {
      values = setIn(formik.pendingValues ?? values, field, value);
      formik.pendingValues = values;
      return values;
    }, shouldValidate);
  } else {
    formik.setFieldValue(field, value, shouldValidate);

    if (formik.pendingValues) {
      formik.pendingValues = setIn(formik.pendingValues, field, value);
    } else if (hasFormikValues(formik)) {
      formik.pendingValues = setIn(formik.values, field, value);
    }
  }
}

function updateFieldValue<Values>(formik: ExtendedFormikActions<Values>, field: string, updateFn: (value: any) => any, shouldValidate?: boolean): void {
  formik.setValues(values => {
    const prevValue = getIn(formik.pendingValues ?? values, field);
    const value = updateFn(prevValue);
    values = setIn(formik.pendingValues ?? values, field, value);
    formik.pendingValues = values;
    return values;
  }, shouldValidate);
}

function hasFormikValues<Values>(formik: ExtendedFormikActions<Values>): formik is ExtendedFormikActions<Values> & { values: Values } {
  return "values" in formik;
}

// https://github.com/formium/formik/issues/2083
function setFieldError<Values>(formik: ExtendedFormikActions<Values>, field: string, message: string | undefined): void {
  setTimeout(() => {
    formik.setFieldError(field, message);
  }, 0);
}

// https://github.com/formium/formik/issues/2083
function setTouched<Values>(formik: FormikActions<Values>, touched: FormikTouched<Values>, shouldValidate?: boolean): Promise<void | FormikErrors<Values>> {
  return new Promise<void | FormikErrors<Values>>(resolve => {
    setTimeout(async () => {
      resolve(await formik.setTouched(touched, shouldValidate));
    }, 0);
  });
}

// https://github.com/formium/formik/issues/2083
function setFieldTouched<Values>(formik: FormikActions<Values>, field: string, isTouched?: boolean, shouldValidate?: boolean): Promise<void | FormikErrors<Values>> {
  return new Promise<void | FormikErrors<Values>>(resolve => {
    setTimeout(async () => {
      resolve(await formik.setFieldTouched(field, isTouched, shouldValidate));
    }, 0);
  });
}

// https://github.com/formium/formik/issues/2083
async function setAllTouched<Values extends FormValues>(formik: FormikActions<Values>, values: Values, shouldValidate?: boolean) {
  return new Promise<void | FormikErrors<Values>>(resolve => {
    setTimeout(async () => {
      resolve(formik.setTouched(setNestedObjectValues<Values>(values, true), shouldValidate));
    }, 0);
  });
}

async function validateForm<Values extends FormValues>(formik: FormikActions<Values>, values: Values): Promise<FormikErrors<Values>> {
  const errors = await formik.validateForm(values);
  await setAllTouched(formik, values, false);
  return errors;
}

function submitForm<Values extends FormValues>(formik: FormikActions<Values>, setIsSubmittingInternal: (value: boolean) => void): Promise<void> {
  setIsSubmittingInternal(true);

  // Wait for new values to resolve to current state before submitting
  // https://github.com/formium/formik/issues/2083
  return new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      try {
        // SubmitForm does not throw for Yup errors,
        // but we want it to so we can use the promise to know if it halted submission
        const errors = await formik.validateForm();

        // Still issue submit either way, because it ensures all values are marked as touched
        await formik.submitForm();

        if (Object.keys(errors).length !== 0) {
          reject(new ValidationError(Object.keys(errors), errors, ""));
        } else {
          resolve();
        }
      } catch (error) {
        reject(error);
      } finally {
        setIsSubmittingInternal(false);
      }
    }, 0);
  });
}

function resetForm<Values>(formik: FormikActions<Values>, resetHandlers: Map<number, ResetEventHandler>, nextValues?: Values | undefined): void {
  if (nextValues) {
    formik.resetForm({ values: nextValues });
  } else {
    formik.resetForm();
  }

  resetHandlers.forEach(handler => handler());
}

/**
 * A copy of Formik's setIn function, except it assigns undefined instead of deleting the property if the value
 * being set is undefined.
 * https://github.com/jaredpalmer/formik/issues/2332
 */
function setIn(obj: any, path: string, value: any): any {
  const res: any = clone(obj); // This keeps inheritance when obj is a class
  let resVal: any = res;
  let i = 0;
  const pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    const currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath: string = pathArray[i + 1];
      resVal = resVal[currentPath]
        = isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    resVal[pathArray[i]] = undefined;
  } else {
    resVal[pathArray[i]] = value;
  }

  // If the path array has a single element, the loop did not run.
  // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
  if (i === 0 && value === undefined) {
    res[pathArray[i]] = undefined;
  }

  return res;
}
