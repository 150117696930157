import { Node } from "unist";
import { visit } from "unist-util-visit";
import { Processor, Transformer } from "unified";
import { type RootNode, isElementNode, isRootNode, isTextNode } from "@remhealth/compose";

// Trim any trailing breaks
export function trimEmptyBreaks(this: Processor): Transformer {
  return function transformer(tree: Node | RootNode): Node {
    visit(tree, isRootNode, (root) => {
      if (root.children && root.children.length >= 1) {
        for (let i = root.children.length - 1; i >= 0; i--) {
          if (!isEmptyBreak(root.children[i])) {
            break;
          }

          root.children.pop();
        }
      }
    });

    return tree;
  };
}

function isEmptyBreak(node: Node | RootNode): boolean {
  if (isTextNode(node) && node.value === "\n") {
    return true;
  }

  if (isElementNode(node) && node.tagName.toLowerCase() === "p" && node.children?.length === 1) {
    const br = node.children[0];
    if (isElementNode(br) && br.tagName.toLowerCase() === "br") {
      return true;
    }
  }
  return false;
}
