import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Note, isPatientNote } from "@remhealth/apollo";
import { Overlay } from "@remhealth/ui";
import { Container, StyledNotePrint } from "./noteSummaryPrint.styles";

export interface NoteSummaryPrintProps {
  isOpen: boolean;
  note: Note;
  onAfterPrint?: () => void;
  isPatientView?: boolean;
}

export function NoteSummaryPrint(props: NoteSummaryPrintProps) {
  const { isOpen, note, isPatientView, onAfterPrint } = props;

  const preview = useRef<HTMLElement>(null);

  const handlePrint = useReactToPrint({
    contentRef: preview,
    onAfterPrint,
    bodyClass: "bells-app",
    pageStyle: "@page { size: auto; margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
  });

  if (!isOpen || !note || !isPatientNote(note)) {
    return <></>;
  }

  return (
    <Overlay isOpen hasBackdrop={false}>
      <Container>
        <StyledNotePrint ref={preview} isPrintView isPatientView={isPatientView} note={note} onReady={handlePrint} />
      </Container>
    </Overlay>
  );
}
