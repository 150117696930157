import styled from "styled-components";
import { Refresh } from "@remhealth/icons";
import { Button, Spinner, useAbort, useSubscription } from "@remhealth/ui";
import { useUserSession } from "@remhealth/host";
import { ActivePullContext, useEhr, useLabeling } from "@remhealth/core";

const PullButton = styled(Button)`
  font-size: var(--font-size-small);
`;

export const AppointmentPullButton = () => {
  const session = useUserSession();
  const ehr = useEhr();
  const abort = useAbort();
  const labels = useLabeling();
  const [activePull, setActivePull] = useSubscription(ActivePullContext);

  return (
    <PullButton
      minimal
      alignText="right"
      className="pull-button"
      disabled={activePull.appointments}
      icon={activePull.appointments ? <Spinner size={14} /> : <Refresh size={12} />}
      label={getButtonText()}
      onClick={handlePull}
    />
  );

  function getButtonText(): string {
    if (activePull.appointments) {
      return `Syncing with ${labels.Product}...`;
    }
    return `Sync appointments for ${session.person.display} with ${labels.Product}`;
  }

  async function handlePull() {
    setActivePull(state => ({ ...state, appointments: true }));
    try {
      await ehr.pullAppointments(session.person.id, abort.signal);
    } finally {
      setActivePull(state => ({ ...state, appointments: false }));
    }
  }
};
