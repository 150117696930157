import { createContext, useContext } from "react";
import { Features, Practice, SecurityTokenHandler } from "@remhealth/apollo";
import { ReleaseFlags } from "~/releases";
import { AccessToken } from "./accessToken";
import { UserProfile, UserSession } from "./userSession";

export interface AccessTokenContext {
  readonly token: Readonly<AccessToken>;
}

export const unmountedAccessTokenContext: AccessTokenContext = {
  get token(): Readonly<AccessToken> {
    throw new Error("AccessTokenContext is not initialized");
  },
};

export const AccessTokenContext = createContext<AccessTokenContext>(unmountedAccessTokenContext);

export const useAccessToken = () => useContext(AccessTokenContext).token;

export interface UserContext {
  readonly user: Readonly<UserProfile>;
  readonly session: Readonly<UserSession>;
}

export const unmountedUserContext: UserContext = {
  get user(): UserProfile {
    throw new Error("UserContext is not initialized");
  },
  get session(): UserSession {
    throw new Error("UserContext is not initialized");
  },
};

export const UserContext = createContext<UserContext>(unmountedUserContext);

export const useUserProfile = () => useContext(UserContext).user;
export const useUserSession = () => useContext(UserContext).session;
export const useUserPermissions = () => useUserSession().permissions;
export const useFeatureCheck = (feature: Features) => useUserSession().hasFeature(feature);
export const useReleaseCheck = (flag: ReleaseFlags) => useUserSession().isReleased(flag);

export function hasFeature(practice: Practice, feature: Features): boolean {
  return practice.features.includes(feature);
}

export function isReleased(practice: Practice, flag: ReleaseFlags): boolean {
  if (practice.allReleaseFlags) {
    return !practice.exceptReleaseFlags.includes(flag);
  }
  return practice.releaseFlags.includes(flag);
}

export interface SecurityTokenContext {
  readonly securityTokens: Readonly<SecurityTokenHandler>;
}

export const SecurityTokenContext = createContext<SecurityTokenContext>({
  get securityTokens(): SecurityTokenHandler {
    throw new Error("SecurityTokenContext is not initialized");
  },
});

export const useSecurityTokens = () => useContext(SecurityTokenContext).securityTokens;
