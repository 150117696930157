import type { SuggestionRule } from "@remhealth/metis";

export function refFactory(source: string, rule: SuggestionRule | undefined): string {
  if (source === "lang") {
    return rule ? `lang:${rule.id}` : "lang";
  }
  if (rule?.id) {
    return `${rule.type}:SpeechRule/${rule.id}`;
  }
  return rule?.type ?? "nlp";
}

export function parseRuleTypeRef(ref: string): string | null {
  if (ref.startsWith("lang:")) {
    return null;
  }

  const typeIndex = ref.indexOf(":");
  if (typeIndex !== -1) {
    ref = ref.slice(0, typeIndex);
  }

  return ref;
}

export function parseRuleIdRef(ref?: string): string | undefined {
  if (!ref) {
    return undefined;
  }

  const typeIndex = ref.indexOf(":");
  if (typeIndex !== -1) {
    ref = ref.slice(typeIndex + 1);
  }

  if (!ref?.startsWith("SpeechRule/")) {
    return undefined;
  }

  return ref.slice("SpeechRule/".length);
}
