import { partition, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import { Appointment, Coding, DocumentationStatus, Encounter, EncounterFilterSet, EncountersClient, type ExpandableReference, Patient, Practitioner, ZonedDateTime, knownCodings } from "@remhealth/apollo";
import { UserSession, areCodingsSame, usePracticePreferences, useUserSession } from "@remhealth/host";
import { DateFormats, Intent } from "@remhealth/ui";
import { Text } from "~/text";
import { PatientAccessor } from "~/contexts";
import { getGroupMembers } from "~/utils";
import { myEvolvCancelledStatuses } from "./constants";

export interface AppointmentEncounterLoadOptions {
  client: EncountersClient;
  accessor: PatientAccessor;
  user: Readonly<UserSession>;
  appointments: Appointment[];
  abort?: AbortSignal;
}

export async function loadAppointmentEncounters(options: AppointmentEncounterLoadOptions) {
  const { client, accessor, user, appointments, abort } = options;
  let patientAndGroupAttendees = appointments.flatMap(i => i.attendees).filter(a => a.subject.resourceType === "Patient" || a.subject.resourceType === "Group");
  patientAndGroupAttendees = uniqBy(patientAndGroupAttendees, a => a.subject.id);

  const [accessiblePatients, otherSubjects] = partition(
    patientAndGroupAttendees.map(p => p.subject),
    p => p.resource?.resourceType === "Patient" && accessor.test(p.resource) !== false
  );

  const accessiblePatientsIds = accessiblePatients.map(p => p.id);
  const otherSubjectIds = otherSubjects.map(p => p.id);

  const filters: EncounterFilterSet[] = [];

  if (accessiblePatientsIds.length > 0) {
    filters.push({
      appointment: { in: appointments.map(i => i.id) },
      subject: { in: accessiblePatientsIds },
    });
  }

  if (otherSubjectIds.length > 0) {
    filters.push({
      appointment: { in: appointments.map(i => i.id) },
      subject: { in: otherSubjectIds },
      participant: { in: [user.person.id, ...user.supervisees.map(s => s.id)] },
    });
  }

  if (filters.length > 0) {
    const feed = client.feed({
      filters,
      crossPartition: true,
    });
    return await feed.all({ abort });
  }

  return [];
}

export function isGroupAppointment(appointment: Appointment, mustHaveGroup = false) {
  if (mustHaveGroup) {
    return appointment.attendees.some(a => a.subject.resourceType === "Group");
  }
  // Many patients or one group
  return appointment.attendees.filter(a => a.subject.resourceType === "Patient").length > 1
    || appointment.attendees.some(a => a.subject.resourceType === "Group");
}

export function isExternalAppointment(appointment: Appointment, encounter?: Encounter | null) {
  if (encounter) {
    return false;
  }

  return !!appointment.extensions?.some(e => e.name === "linkedToNote" && e.value === "true");
}

export function getAppointmentTime(appointment: Appointment, showMilitaryTime?: boolean) {
  const start = ZonedDateTime.toDateTime(appointment.start);
  const end = ZonedDateTime.toDateTime(appointment.end);
  return DateFormats.friendlyTimeRange(start, end, showMilitaryTime);
}

export function getUpcomingAppointment(appointments: Appointment[]) {
  const todaysAppointments = appointments.filter(appointment => {
    const start = ZonedDateTime.toDateTime(appointment.start);
    const startOfToday = DateTime.now().startOf("day");
    const endOfToday = DateTime.now().endOf("day");
    return start >= startOfToday && start <= endOfToday;
  });

  let upcomingAppointment: Appointment | undefined;
  const now = DateTime.now();

  todaysAppointments.forEach(appointment => {
    const start = ZonedDateTime.toDateTime(appointment.start);
    if (start >= now) {
      if (!upcomingAppointment || start <= ZonedDateTime.toDateTime(upcomingAppointment.start)) {
        upcomingAppointment = appointment;
      }
    }
  });

  return upcomingAppointment;
}

export function getAppointmentStatus(coding: Coding, patientLabel: string) {
  if (areCodingsSame(myEvolvCancelledStatuses.cancelledByPatient, coding)) {
    return `Appointment cancelled by ${patientLabel}`;
  }
  if (areCodingsSame(myEvolvCancelledStatuses.cancelledByProvider, coding)) {
    return "Appointment cancelled by Provider";
  }
  return coding.display;
}

export function useAppointmentCancelStatuses(): Coding[] {
  const practicePreferences = usePracticePreferences();
  const session = useUserSession();

  if (session.practice.product === "myEvolv") {
    return Object.values<Coding>(myEvolvCancelledStatuses);
  }

  return practicePreferences.appointmentVisitStatus?.cancelled ?? [];
}

export function useAppointmentStatus() {
  const practicePreferfences = usePracticePreferences();
  const cancelledStatuses = Object.values<Coding>(myEvolvCancelledStatuses)
    .concat(practicePreferfences.appointmentVisitStatus?.cancelled ?? [])
    .concat(knownCodings.appointmentStatuses.cancelled);
  const noShowStatuses = Object.values<Coding>([knownCodings.appointmentStatuses.noShow])
    .concat(practicePreferfences.appointmentVisitStatus?.noShow ?? []);

  return { isCancelledStatus, isNoShowStatus, getStatus };

  function isCancelledStatus(status: Coding) {
    return cancelledStatuses.some(c => areCodingsSame(status, c));
  }

  function isNoShowStatus(status: Coding) {
    return noShowStatuses.some(c => areCodingsSame(status, c));
  }

  function getStatus(appointment: Appointment, associatedEncounter?: Encounter): { status: string; intent: Intent; isCancelled: boolean } {
    if (isCancelledStatus(appointment.status)) {
      return { status: "Cancelled", intent: Intent.DANGER, isCancelled: true };
    }

    switch (associatedEncounter?.documentationStatus) {
      case DocumentationStatus.Complete:
        return { status: "Signed", intent: Intent.SUCCESS, isCancelled: false };
      case DocumentationStatus.InProgress:
        return { status: Text.Draft, intent: Intent.PRIMARY, isCancelled: false };
    }

    if (isExternalAppointment(appointment, associatedEncounter)) {
      return { status: "External", intent: Intent.SUCCESS, isCancelled: false };
    }

    if (areCodingsSame(appointment.status, knownCodings.appointmentStatuses.cancelled)) {
      return { status: Text.Cancelled, intent: Intent.DANGER, isCancelled: true };
    }

    if (isNoShowStatus(appointment.status)) {
      return { status: Text.NoShow, intent: Intent.NONE, isCancelled: false };
    }

    return { status: Text.NotStarted, intent: Intent.NONE, isCancelled: false };
  }
}

export function getAppointmentAttendees(appointment: Appointment, invitedOnly: boolean): [staff: ExpandableReference<Practitioner>[], patients: ExpandableReference<Patient>[]] {
  const groupMembers = !invitedOnly ? appointment.attendees.flatMap(a => a.subject.resource?.resourceType === "Group" ? getGroupMembers(a.subject.resource) : []) : [];

  const patientGroupMembers = groupMembers.flatMap(a => a.resourceType === "Patient" ? a : []);
  const patientReferences = appointment.attendees.flatMap(a => a.subject.resourceType === "Patient" ? a.subject as ExpandableReference<Patient> : []);

  const patients = patientReferences.concat(patientGroupMembers);

  const staffGroupMembers = groupMembers.flatMap(a => a.resourceType === "Practitioner" ? a : []);
  const staffReferences = appointment.attendees.flatMap(a => a.subject.resourceType === "Practitioner" ? a.subject as ExpandableReference<Practitioner> : []);

  const staff = staffReferences.concat(staffGroupMembers);

  return [staff, patients];
}
