import { useMemo } from "react";
import { first, sortBy } from "lodash-es";
import { DateTime } from "luxon";
import { startsWithAllWords, startsWithAnyWords } from "@remhealth/ui";
import {
  CompositionStatus,
  IItemView,
  Instant,
  InstantRange,
  Note,
  NoteExpandables,
  NoteFilterSet,
  NoteSortField,
  ParameterPresence,
  ParticipantRole,
  PatientNote,
  ResourceSyncStatus,
  SortField,
  TextFilter,
  isMultiNote
} from "@remhealth/apollo";
import { isInstantRangeEffective, useUserProfile } from "@remhealth/host";
import { useStore } from "./useStore";
import { lastModifiedAscending } from "./filters";

export interface NoteFilterOptions {
  selfAuthoredOnly?: boolean;
  patientIds?: string[];
  status?: CompositionStatus[];
  searchText?: TextFilterCriteria;
  ids?: string[];
  primaryPerformers?: string[];
  signers?: string[];
  syncIssuesOnly?: boolean;
  partOfIds?: string[];
  patientGroupNotes?: boolean;
  signed?: InstantRange;
  additionalExpansions?: NoteExpandables[];
}

export interface TextFilterCriteria {
  text: string;
  allWords: boolean;
}

/** Amount of hours that a note can be in Pending sync status before its considered an issue */
export const stalePendingSyncStatusInHours = 3;

export function useNotesView(sort: SortField<NoteSortField>, allowMultiNotes: false, options: NoteFilterOptions): IItemView<PatientNote>;
export function useNotesView(sort: SortField<NoteSortField>, allowMultiNotes: true, options: NoteFilterOptions): IItemView<Note>;
export function useNotesView(sort: SortField<NoteSortField>, allowMultiNotes: boolean, options: NoteFilterOptions) {
  const store = useStore();
  const user = useUserProfile();

  return useMemo(() => store.notes.view({
    filters: {
      online: createNoteFilters(user.id, allowMultiNotes, options),
      offline: s => offlinefilter(s, user.id, options, allowMultiNotes),
    },
    responseOptions: {
      expansions: options.additionalExpansions,
    },
    orderBy: {
      online: sort,
      offline: sortOffline(sort),
    },
    feedOptions: !options.patientIds || options.patientIds.length !== 1
      ? { crossPartition: true }
      : { partition: options.patientIds[0] },
  }), [JSON.stringify([user.id, options, sort])]);
}

export function createNoteFilters(userId: string, allowMultiNotes: boolean, options: NoteFilterOptions): NoteFilterSet[] {
  const userFilter: NoteFilterSet = options.selfAuthoredOnly
    ? {
      participants: {
        in: [userId],
        role: { equalTo: ParticipantRole.PrimaryPerformer },
      },
    }
    : options.primaryPerformers && options.primaryPerformers.length > 0
      ? {
        participants: {
          in: options.primaryPerformers,
          role: { equalTo: ParticipantRole.PrimaryPerformer },
        },
      }
      : {};

  const subjectFilter: NoteFilterSet = options.patientIds && options.patientIds.length > 0 ? {
    subject: {
      in: options.patientIds,
      resourceType: ["Patient"],
    },
  } : {};

  const statusFilter: NoteFilterSet = !options.status || options.status.length === 0 ? {} : {
    status: {
      in: options.status,
    },
  };

  const idFilter: NoteFilterSet = !options.ids || options.ids.length === 0 ? {} : {
    ids: options.ids,
  };

  const signerFilter: NoteFilterSet = !options.signers || options.signers.length === 0 ? {} : {
    signers: {
      in: options.signers,
    },
  };

  const signedFilter: NoteFilterSet = !options.signed ? {} : {
    signedDate: {
      onOrAfter: options.signed.start,
      onOrBefore: options.signed.end,
    },
  };

  const partOfFilter: NoteFilterSet = !options.partOfIds || options.partOfIds.length === 0
    // Only show patient notes that are within a group when viewing a specific group note, or when specifically requested
    ? options.patientGroupNotes
      ? {}
      : {
        partOf: { presence: ParameterPresence.NotPresent },
      }
    : {
      partOf: { in: options.partOfIds },
    };

  let allFilters: NoteFilterSet[] = [
    {
      ...userFilter,
      ...subjectFilter,
      ...statusFilter,
      ...idFilter,
      ...signerFilter,
      ...signedFilter,
      ...partOfFilter,
    },
  ];

  if (options.searchText?.text) {
    const searchText: TextFilter = options.searchText.allWords
      ? { startsWithAllWords: options.searchText.text }
      : { startsWithAnyWord: options.searchText.text };

    const textFilters: NoteFilterSet[] = [
      {
        sectionText: searchText,
      },
      {
        syncIssueMessage: searchText,
      },
      {
        subject: {
          display: searchText,
        },
      },
      {
        definition: {
          display: searchText,
        },
      },
      {
        participants: {
          role: { equalTo: ParticipantRole.PrimaryPerformer },
          display: searchText,
        },
      },
    ];

    allFilters = textFilters.flatMap(textFilter => {
      return allFilters.map(f => ({
        ...textFilter,
        ...f,
        subject: textFilter.subject ? { ...textFilter.subject, ...f.subject } : undefined,
        definition: textFilter.definition ? { ...textFilter.definition, ...f.definition } : undefined,
      }));
    });
  }

  if (allowMultiNotes) {
    allFilters = [
      ...allFilters.map(f => ({
        ...f,
        composition: { notIn: ["Group"] },
      } satisfies NoteFilterSet)),
      ...allFilters.map(f => ({
        ...f,
        // For group notes, we need to search for those whom current user may only be secondary practitioner
        participants: { ...f.participants, role: undefined },
        composition: { in: ["Group"] },
      } satisfies NoteFilterSet)),
    ];
  } else {
    allFilters = allFilters.map(f => ({
      ...f,
      composition: { in: ["Individual"] },
    } satisfies NoteFilterSet));
  }

  if (options.syncIssuesOnly) {
    const syncStatusFilter: NoteFilterSet[] = [
      { syncStatus: { in: [ResourceSyncStatus.Failed, ResourceSyncStatus.Warning] } },
      { syncStatus: { in: [ResourceSyncStatus.Pending] }, syncRecorded: { before: Instant.fromDateTime(DateTime.now().minus({ hours: stalePendingSyncStatusInHours })) } },
    ];

    return syncStatusFilter.flatMap(filter => {
      return allFilters.map(f => ({
        ...filter,
        ...f,
      }));
    });
  }

  return allFilters;
}

export function getNoteSyncStatus(note: Note) {
  const syncStatus = note.meta?.syncState?.status;

  if (syncStatus === ResourceSyncStatus.Pending && note.meta?.syncState?.recorded) {
    const recordedHours = DateTime.now().diff(Instant.toDateTime(note.meta.syncState.recorded)).as("hours");
    if (recordedHours > stalePendingSyncStatusInHours) {
      return ResourceSyncStatus.Failed;
    }
  }

  return syncStatus;
}

function offlinefilter(note: Note, userId: string, options: NoteFilterOptions, allowMultiNotes: boolean) {
  if (options.selfAuthoredOnly && !note.participants.some(p => p.role === ParticipantRole.PrimaryPerformer && p.individual.id === userId)) {
    return false;
  }

  const primaryPerformersSet = new Set(options.primaryPerformers);
  if (primaryPerformersSet.size > 0 && !note.participants.some(p => p.role === ParticipantRole.PrimaryPerformer && primaryPerformersSet.has(p.individual.id))) {
    return false;
  }

  if (options.patientIds && options.patientIds.length > 0 && !options.patientIds.includes(note.subject.id)) {
    return false;
  }

  if (options.status && options.status.length > 0) {
    if (!options.status.includes(note.status)) {
      return false;
    }
  }

  if (options.ids && options.ids.length > 0) {
    if (!options.ids.includes(note.id)) {
      return false;
    }
  }

  if (!allowMultiNotes && isMultiNote(note)) {
    return false;
  }

  if (options.partOfIds && options.partOfIds.length > 0) {
    if (!note.partOf || !options.partOfIds.includes(note.partOf.id)) {
      return false;
    }
  } else if (note.partOf && !options.patientGroupNotes) {
    return false;
  }

  const signerSet = new Set(options.signers);
  if (signerSet.size > 0 && !note.signatures.some(s => s.signer?.id && signerSet.has(s.signer.id))) {
    return false;
  }

  if (options.signed && !note.signatures.some(s => isInstantRangeEffective(options.signed, Instant.toDate(s.signed)))) {
    return false;
  }

  if (options.syncIssuesOnly) {
    const syncStatus = getNoteSyncStatus(note);
    if (syncStatus !== ResourceSyncStatus.Failed && syncStatus !== ResourceSyncStatus.Warning) {
      return false;
    }
  }

  const searchText = options.searchText;
  if (searchText?.text) {
    const query = searchText.text;
    const searchType = searchText.allWords ? startsWithAllWords : startsWithAnyWords;
    if (!searchType(note.subject.display, query)
      && !searchType(note.definition?.display, query)
      && !searchType(note.syncIssueMessage, query)
      && !note.participants.some(p => p.role === ParticipantRole.PrimaryPerformer && searchType(p.individual.display, query))
      && !note.sections.some(section => searchType(section.text?.plainText, query))) {
      return false;
    }
  }

  return true;
}

function sortOffline(sort: SortField<NoteSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Note, b: Note) => {
    switch (sort.field) {
      case "Definition":
        return dir * (a.definition?.display ?? "").localeCompare(b.definition?.display ?? "");
      case "Subject":
        return dir * (a.subject.display ?? "").localeCompare(b.subject.display ?? "");
      case "Created":
        return dir * a.created.localeCompare(b.created);
      case "Recorded":
        return dir * a.recorded.localeCompare(b.recorded);
      case "Signed": {
        const signatureA = first(sortBy(a.signatures, n => n.signed));
        const signatureB = first(sortBy(b.signatures, n => n.signed));
        return dir * (signatureA?.signed ?? "").localeCompare(signatureB?.signed ?? "");
      }
      case "LastModified":
      default:
        return dir * lastModifiedAscending(a, b);
    }
  };
}
