import { generatePath, matchPath, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeft } from "@remhealth/icons";
import { BreadcrumbProps, Breadcrumbs, Button } from "@remhealth/ui";
import { Practitioner, Reference } from "@remhealth/apollo";
import { userRoutes } from "./userRoutes";

interface ProfileNavStackItem {
  url: string;
  display: string;
}

interface ProfileNavLocationState {
  profileNavStack?: ProfileNavStackItem[];
}

const breadcrumbLength = 2;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 0;
`;

export function useProfileNav() {
  const navigate = useNavigate();
  const location = useLocation();

  return { openProfile, renderNav };

  function openProfile(practitioner: Reference<Practitioner>) {
    const profileNavStack: ProfileNavStackItem[] = [...location.state?.profileNavStack ?? []];
    let path: string;

    if (matchPath(userRoutes.myProfile, location.pathname)) {
      // From My Profile
      profileNavStack.length = 0;
      profileNavStack.unshift(getMyProfileStackItem());
      profileNavStack.unshift(getProfileStackItem(practitioner));
      path = generatePath(userRoutes.profile, { user_id: practitioner.id });
    } else if (matchPath(userRoutes.profile, location.pathname)) {
      // Supervisee from My Profile
      profileNavStack.unshift(getProfileStackItem(practitioner));
      path = generatePath(userRoutes.profile, { user_id: practitioner.id });
    } else if (isUserManagementPath(location.pathname) && !profileNavStack.some(i => isUserManagementPath(i.url))) {
      // From user management
      profileNavStack.unshift(getUserManagementStackItem());
      profileNavStack.unshift(getUserStackItem(practitioner));
      path = generatePath(userRoutes.user, { user_id: practitioner.id });
    } else {
      // From anywhere else
      profileNavStack.unshift(getUserStackItem(practitioner));
      path = generatePath(userRoutes.profile, { user_id: practitioner.id });
    }

    navigate(path, { state: { profileNavStack } satisfies ProfileNavLocationState });
  }

  function renderNav(): JSX.Element {
    const profileStack = [...location.state?.profileNavStack ?? []];
    const breadcrumbs: ProfileNavStackItem[] = [...profileStack];

    // Don't show current profile in nav
    if (breadcrumbs.length > 0 && !isUserManagementPath(breadcrumbs[0].url)) {
      breadcrumbs.shift();
    }

    if (breadcrumbs.length === 0) {
      return <></>;
    }

    breadcrumbs.reverse();

    const items: BreadcrumbProps[] = breadcrumbs
      .slice(0, breadcrumbLength)
      .map<BreadcrumbProps>(renderBreadcrumb);

    return (
      <StyledBreadcrumbs collapseFrom="end" items={items} />
    );
  }

  function renderBreadcrumb(entry: ProfileNavStackItem, index: number) {
    const profileStack = [...location.state?.profileNavStack ?? []];
    return {
      text: (
        <Button
          minimal
          icon={index === 0 ? <ArrowLeft /> : undefined}
          intent="primary"
          label={entry.display}
          onClick={() => navigate(entry.url, {
            state: {
              profileNavStack: [...profileStack].reverse().slice(0, index + 1).reverse(),
            } satisfies ProfileNavLocationState,
          })}
        />
      ),
    };
  }

  function getUserManagementStackItem(): ProfileNavStackItem {
    return {
      url: userRoutes.manageUsers,
      display: "User Management",
    };
  }

  function getUserStackItem(practitioner: Reference<Practitioner>): ProfileNavStackItem {
    return {
      url: generatePath(userRoutes.user, { user_id: practitioner.id }),
      display: practitioner.display ?? "",
    };
  }

  function getMyProfileStackItem(): ProfileNavStackItem {
    return {
      url: userRoutes.myProfile,
      display: "My Profile",
    };
  }

  function getProfileStackItem(practitioner: Reference<Practitioner>): ProfileNavStackItem {
    return {
      url: generatePath(userRoutes.profile, { user_id: practitioner.id }),
      display: practitioner.display ?? "",
    };
  }

  function isUserManagementPath(path: string) {
    return !!matchPath(`${userRoutes.manageUsers}/*`, path);
  }
}
