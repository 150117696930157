import { useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import { inRange, isEqual } from "lodash-es";
import { Appointment, Encounter, ZonedDateTime } from "@remhealth/apollo";
import { useApollo, useUserSession } from "@remhealth/host";
import { useStore } from "@remhealth/core";
import { IFeed, Spinner, useAbort, useDebouncedSubscriptionState, useStopwatch, useSubscriptionDispatch } from "@remhealth/ui";
import { AgendaContext, usePatientAccessor } from "~/contexts";
import { getUpcomingAppointment, loadAppointmentEncounters } from "~/appointments/utils";
import { AgendaDay } from "./agendaDay";

import { dateHeaderVerticalMargin } from "./agendaDay.styles";
import { Container, ScrollArea, SpinnerContainer } from "./agendaList.styles";

type AgendaItemRefs = { [date: string]: HTMLDivElement };
type AppointmentsMap = Map<string, Appointment[]>;

export interface AgendaListProps {
  feed: IFeed<Appointment>;
  start: DateTime;
  end: DateTime;
}

export function AgendaList(props: AgendaListProps) {
  const { feed, start, end } = props;
  const { selectedDate } = useDebouncedSubscriptionState(AgendaContext, 100);
  const setAgenda = useSubscriptionDispatch(AgendaContext);

  const apollo = useApollo();
  const abort = useAbort();
  const store = useStore();
  const user = useUserSession();
  const accessor = usePatientAccessor();
  const agendaItems = useRef<AgendaItemRefs>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver>();

  const [isLoading, setIsLoading] = useState(true);
  const [appointmentsMap, setAppointmentsMap] = useState<AppointmentsMap>(getInitialMap);
  const [encounters, setEncounters] = useState<Encounter[]>([]);

  const autoScrolled = useRef(false);
  const autoScrollTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (selectedDate.scrollToDate && !isLoading) {
      scheduleAutoScroll();
    }
  }, [selectedDate.scrollToDate, isLoading]);

  useEffect(() => {
    loadResources();
  }, [feed.session]);

  useEffect(() => store.encounters.onSet(handleEncounterSet), [encounters]);
  useEffect(() => store.encounters.onDeleted(handleEncounterDelete), [encounters]);

  useEffect(() => setAppointmentsData(feed.items), [feed.items.length]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      observeStickyElements(container);
      return () => {
        unobserveStickyElements(container);
      };
    }
    return undefined;
  }, [appointmentsMap]);

  useEffect(() => store.appointments.onUpserted(handleAppointmentUpserted));

  const minuteTimer = useStopwatch();
  const upcomingAppointment = useMemo(() => {
    const currentDateKey = getKey(DateTime.now());
    return getUpcomingAppointment(appointmentsMap.get(currentDateKey) ?? []);
  }, [appointmentsMap, minuteTimer]);

  return (
    <Container ref={containerRef}>
      {isLoading && (
        <SpinnerContainer>
          <Spinner intent="primary" size={60} />
          <div className="loading-message">Please wait while we load your appointments...</div>
        </SpinnerContainer>
      )}
      <ScrollArea>
        {Array.from(appointmentsMap.keys()).map(key => {
          const date = parseKey(key);
          const appointments = appointmentsMap.get(key) || [];

          return (
            <AgendaDay
              key={key}
              ref={el => el && setAgendaItem(key, el)}
              appointments={appointments}
              className="agenda-day"
              date={date}
              encounters={encounters}
              upcomingAppointment={upcomingAppointment}
            />
          );
        })}
      </ScrollArea>
    </Container>
  );

  function handleAppointmentUpserted(appointment: Appointment) {
    const start = ZonedDateTime.toDateTime(appointment.start);
    const key = getKey(start);
    if (appointmentsMap.has(key)) {
      const values = appointmentsMap.get(key) || [];
      const targetAppointmentIndex = values.findIndex(v => v.id === appointment.id);
      if (targetAppointmentIndex !== -1) {
        values[targetAppointmentIndex] = appointment;
      }
    }

    setAppointmentsMap(new Map(appointmentsMap));
    setAgenda(prev => ({
      ...prev,
      selectedAppointment: appointment,
      showRightPane: true,
    }));
  }

  function setAgendaItem(date: string, el: HTMLDivElement) {
    agendaItems.current[date] = el;
  }

  function scheduleAutoScroll() {
    cancelAutoScroll();
    autoScrollTimeout.current = setTimeout(() => {
      const el = agendaItems.current[getKey(selectedDate.date)];
      if (el) {
        el.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });

        setAgenda(a => ({
          ...a,
          selectedDate: { ...a.selectedDate, scrollToDate: false },
        }));
      }
    }, 500);
  }

  function cancelAutoScroll() {
    if (autoScrollTimeout.current) {
      clearTimeout(autoScrollTimeout.current);
      autoScrollTimeout.current = undefined;
    }
  }

  async function loadResources() {
    // Load the appointments we have already
    await loadAppointments();
  }

  async function loadAppointments() {
    if (abort.signal.aborted) {
      return;
    }

    while (feed.canLoadMore && !abort.signal.aborted) {
      await feed.loadMore(100, abort.signal);
    }

    if (!abort.signal.aborted) {
      const appointments = feed.items;
      setAppointmentsData(appointments);
      if (appointments.length > 0) {
        await loadEncounters(appointments);
      }
      setIsLoading(false);

      if (!autoScrolled.current) {
        autoScrolled.current = true;
        scheduleAutoScroll();
      }
    }
  }

  function setAppointmentsData(items: Appointment[]) {
    const appointmentsMapStore = getInitialMap();

    items.forEach(appointment => {
      const start = ZonedDateTime.toDateTime(appointment.start);
      const key = getKey(start);
      if (appointmentsMapStore.has(key)) {
        const values = appointmentsMapStore.get(key) || [];
        values.push(appointment);
        appointmentsMapStore.set(key, values);
      }
    });

    setAppointmentsMap(new Map(appointmentsMapStore));
    setAgenda(prev => ({
      ...prev,
      selectedAppointment: getUpcomingAppointment(items) ?? null,
      showRightPane: true,
    }));
  }

  async function loadEncounters(items: Appointment[]) {
    const encounters = await loadAppointmentEncounters({
      client: apollo.encounters,
      user,
      accessor,
      appointments: items,
      abort: abort.signal,
    });
    setEncounters(encounters);
  }

  function getInitialMap() {
    const map: AppointmentsMap = new Map<string, Appointment[]>();
    let targetDate = start;

    while (targetDate <= end) {
      map.set(getKey(targetDate), []);
      targetDate = targetDate.plus({ day: 1 });
    }

    return map;
  }

  function handleEncounterSet(item: Encounter) {
    const appointment = item.appointment;
    const original = [...encounters];
    if (appointment) {
      const matchIndex = encounters.findIndex(e => e.appointment?.id === appointment.id && e.id === item.id);
      if (matchIndex !== -1) {
        encounters.splice(matchIndex, 1, item);
      } else {
        encounters.push(item);
      }
      if (!isEqual(original, encounters)) {
        setEncounters([...encounters]);
      }
    }
  }

  function handleEncounterDelete(resourceId: string) {
    const original = [...encounters];
    const filteredEncounters = encounters.filter(e => e.id !== resourceId);
    if (!isEqual(original, filteredEncounters)) {
      setEncounters(filteredEncounters);
    }
  }

  function observeStickyElements(container: HTMLDivElement) {
    observeHeaders(container);
  }

  function unobserveStickyElements(container: HTMLDivElement) {
    const headers = container.querySelectorAll(".sticky");

    if (observerRef.current) {
      headers.forEach(el => observerRef.current?.unobserve(el));
    }
  }

  function observeHeaders(container: HTMLDivElement) {
    const containerArea = containerRef.current?.getBoundingClientRect();
    const observer = new IntersectionObserver((records) => {
      for (const record of records) {
        const targetArea = record.target.getBoundingClientRect();
        const dateString = record.target.parentElement?.getAttribute("data-date");
        const parsedDate = parseKey(dateString);
        const headerHeight = targetArea.height + dateHeaderVerticalMargin;

        if (containerArea
          && parsedDate
          && record.intersectionRatio < 1
          && (inRange(targetArea.top, containerArea.top, containerArea.top + headerHeight) || inRange(targetArea.bottom, containerArea.top, containerArea.top + headerHeight))) {
          record.target.classList.add("active");
          setAgenda(prev => ({
            ...prev,
            selectedDate: {
              date: parsedDate,
              scrollToDate: false,
            },
          }));
        } else {
          record.target.classList.remove("active");
        }
      }
    }, {
      threshold: [1, 0],
      root: container,
      rootMargin: "-1px 0px 0px 0px",
    });

    observerRef.current = observer;

    // Add the bottom sentinels to each section and attach an observer.
    const headers = container.querySelectorAll(".sticky");
    headers.forEach(el => observer.observe(el));
  }
}

const dateFormat = "MM'/'dd'/'yyyy";

function parseKey(dateStr: string): DateTime;
function parseKey(dateStr: string | null | undefined): DateTime | null | undefined;
function parseKey(dateStr: string | null | undefined): DateTime | null | undefined {
  if (dateStr === undefined || dateStr === null) {
    return dateStr;
  }
  return DateTime.fromFormat(dateStr, dateFormat);
}

function getKey(date: DateTime) {
  return date.toFormat(dateFormat);
}
