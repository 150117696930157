import { createContext, useContext, useEffect, useMemo } from "react";
import { useUnmountEffect } from "@remhealth/ui";

export const bellsThemes = [
  { theme: "default", dark: false, label: "Light Theme" },
  { theme: "default", dark: true, label: "Dark Theme" },
  { theme: "high-contrast", dark: false, label: "Light High Contrast" },
  { theme: "high-contrast", dark: true, label: "Dark High Contrast" },
  { theme: "myAvatar", dark: false, label: "myAvatar" },
  { theme: "custom1", dark: false, label: "Theme 1" },
  { theme: "custom2", dark: false, label: "Theme 2" },
  { theme: "custom3", dark: false, label: "Theme 3" },
] as const satisfies ReadonlyArray<{ theme: string; dark: boolean; label: string }>;

export type BellsTheme = typeof bellsThemes[number];
export type BellsThemeName = typeof bellsThemes[number]["theme"];

export interface ThemeContext {
  readonly theme: BellsThemeName;
  setTheme(theme: BellsThemeName): void;
}

let currentTheme: BellsThemeName = "default";
export const ThemeContext = createContext<ThemeContext>({
  get theme(): BellsThemeName {
    return currentTheme;
  },
  setTheme(theme: BellsThemeName) {
    currentTheme = theme;
  },
});

export const useTheme = () => useContext(ThemeContext);

export function useThemeOverride(themeOverride: BellsThemeName) {
  const { theme, setTheme } = useTheme();

  const defaultTheme = useMemo(() => theme, []);

  useEffect(() => {
    overrideTheme();
  }, []);

  useUnmountEffect(() => {
    restoreTheme();
  });

  function restoreTheme() {
    setTheme(defaultTheme);
  }

  function overrideTheme() {
    setTheme(themeOverride);
  }
}
