import { useEffect, useMemo, useState } from "react";
import { sortBy } from "lodash-es";
import { Refresh } from "@remhealth/icons";
import {
  Appointment,
  Duration,
  Encounter,
  Expandable,
  Note,
  NoteExpandables,
  Patient,
  Practitioner,
  Reference,
  ZonedDateTime
} from "@remhealth/apollo";
import { FormGroup, Intent, Spinner, useAbort } from "@remhealth/ui";
import {
  isDateRangeEffective,
  useApollo,
  useDateFormatter,
  useProductFlag,
  useUserSession
} from "@remhealth/host";
import {
  PractitionerBanner,
  generateRecurrenceText,
  useAudit,
  useLabeling,
  useStore
} from "@remhealth/core";
import { PatientBanner } from "~/avatars/patientBanner";
import { Text } from "~/text";
import { usePatientAccessor } from "~/contexts";
import { AppointmentGroupBanner } from "~/appointments/appointmentGroupBanner";
import { AppointmentDetailFooter } from "~/appointments/appointmentDetailFooter";
import { getAppointmentStatus, isGroupAppointment, loadAppointmentEncounters } from "~/appointments/utils";
import {
  AppointmentDuration,
  AppointmentInfo,
  AppointmentTime,
  AttendeeContainer,
  AttendeeWrapper,
  Attendees,
  CommentSection,
  Content,
  Details,
  Recurrence,
  RecurrenceIcon,
  ScrollArea,
  Section,
  SectionLabel,
  Sections,
  ServiceTypeName,
  Time
} from "./appointmentDetails.styles";

export interface AppointmentDetailsProps {
  appointment: Appointment;
}

type PatientAttendee = Patient | Reference<Patient>;

export const AppointmentDetails = (props: AppointmentDetailsProps) => {
  const { appointment } = props;

  const apollo = useApollo();
  const abort = useAbort();
  const labels = useLabeling();
  const dates = useDateFormatter();
  const store = useStore();
  const audit = useAudit();
  const user = useUserSession();
  const accessor = usePatientAccessor();

  const invitedAttendeesOnly = useProductFlag("AppointmentInvitedAttendeesOnly");

  const [associatedEncounter, setAssociatedEncounter] = useState<Encounter>();
  const [currentNote, setCurrentNote] = useState<Note>();
  const [patients, setPatients] = useState<PatientAttendee[]>([]);
  const [staffs, setStaffs] = useState<Practitioner[]>([]);
  const [loading, setLoading] = useState(false);

  const schedule = appointment.schedule?.resource;

  useEffect(() => {
    loadAppointmentDetails();
  }, [appointment.id, associatedEncounter?.id, appointment.attendees.length]);

  useEffect(() => {
    loadNote();
  }, [associatedEncounter?.id]);

  useEffect(() => currentNote
    ? store.notes.onItemSet(currentNote.partition, currentNote.id, setCurrentNote)
    : undefined, [currentNote]);

  useEffect(() => associatedEncounter
    ? store.encounters.onItemSet(associatedEncounter.partition, associatedEncounter.id, handleEncounterSet)
    : undefined, [associatedEncounter]);

  useEffect(() => appointment
    ? store.encounters.onSet(handleEncounterSet)
    : undefined, [appointment.id]);

  useEffect(() => associatedEncounter
    ? store.encounters.onDeleted(handleEncounterDelete)
    : undefined, [associatedEncounter]);

  const recurrenceText = useMemo(() => {
    return schedule?.recurrence ? generateRecurrenceText(schedule.recurrence) : undefined;
  }, [schedule?.id]);

  if (loading) {
    return (
      <Spinner intent={Intent.PRIMARY} size={60} />
    );
  }

  let location = appointment.location.display;

  if (appointment.serviceLocation?.display) {
    location += ` (${appointment.serviceLocation?.display})`;
  }
  const status = getAppointmentStatus(appointment.status, labels.Patient);

  return (
    <>
      <Details>
        {appointment.serviceType && <ServiceTypeName>{appointment.serviceType.display}</ServiceTypeName>}
        <AppointmentTime>
          <Time>{renderAppointmentTime()}</Time>
          <AppointmentDuration>{Duration.humanize(appointment.duration)}</AppointmentDuration>
        </AppointmentTime>
        {recurrenceText && (
          <Recurrence>
            <RecurrenceIcon icon={<Refresh />} size={10} />
            <span>{recurrenceText}</span>
          </Recurrence>
        )}
        <Sections>
          <Section>
            <AppointmentInfo label={labels.Location}>
              {location}
            </AppointmentInfo>
            {appointment.program && (
              <AppointmentInfo label={Text.Program}>
                {renderProgramAndSubProgram()}
              </AppointmentInfo>
            )}
          </Section>
          <Section>
            {appointment.serviceType && (
              <AppointmentInfo label={labels.ServiceType}>
                {appointment.serviceType.display}
              </AppointmentInfo>
            )}
            <AppointmentInfo label={Text.Status}>{status}</AppointmentInfo>
          </Section>
          {!!appointment.cancelReason && (
            <Section>
              <AppointmentInfo label={Text.CancelledStatus}>{appointment.cancelReason?.text}</AppointmentInfo>
            </Section>
          )}
        </Sections>
        {appointment.comments && (
          <CommentSection>
            <FormGroup label={Text.Comment}>{appointment.comments}</FormGroup>
          </CommentSection>
        )}
      </Details>
      <AttendeeWrapper>
        <ScrollArea autoHide={false}>
          <Content>
            {isGroupAppointment(appointment, true) && renderGroup()}
            {renderStaff()}
            {renderAttendees()}
          </Content>
        </ScrollArea>
      </AttendeeWrapper>
      <AppointmentDetailFooter appointment={appointment} associatedEncounter={associatedEncounter} location="Agenda" />
    </>
  );

  function renderAppointmentTime() {
    const start = ZonedDateTime.toDate(appointment.start);
    const end = ZonedDateTime.toDate(appointment.end);
    return `${dates.verboseWithTime(start)} - ${dates.time(end)}`;
  }

  async function loadAppointmentDetails() {
    setLoading(true);
    const [patients, staffs] = await fetchPatientsAndStaffs();

    setPatients(sortBy(patients, "display"));
    setStaffs(sortBy(staffs, "display"));

    const encounters = await loadAppointmentEncounters({
      client: apollo.encounters,
      user,
      accessor,
      appointments: [appointment],
      abort: abort.signal,
    });
    const selectedEncounter = encounters[0];
    setAssociatedEncounter(selectedEncounter);

    setLoading(false);
  }

  async function loadNote() {
    setLoading(true);
    if (associatedEncounter) {
      const note = await getNote(associatedEncounter);
      setCurrentNote(note);
    } else {
      setCurrentNote(undefined);
    }
    setLoading(false);
  }

  async function fetchPatientsAndStaffs(): Promise<[PatientAttendee[], Practitioner[]]> {
    const groupMembers = !invitedAttendeesOnly
      ? appointment.attendees
        .flatMap(a => {
          return a.subject.resource?.resourceType !== "Group" ? [] : a.subject.resource.members
            .filter(m => isDateRangeEffective(m.effective))
            .map(m => m.member);
        })
      : [];

    const patientGroupMembers = groupMembers.flatMap(a => a.resourceType === "Patient" ? a : []);
    const patientAttendees = appointment.attendees.flatMap(a => a.subject.resourceType === "Patient" ? a.subject : []);
    const patientReferences = patientAttendees.concat(patientGroupMembers);

    // Audit that the appointment was selected and the associated patients
    if (patientReferences.length > 0) {
      audit.log("AppointmentSelected", { entities: patientReferences });
    }

    const staffGroupMembers = groupMembers.flatMap(a => a.resourceType === "Practitioner" ? a : []);
    const staffReferences = appointment.attendees.flatMap(a => a.subject.resourceType === "Practitioner" ? a.subject : []);
    const staffExpandables = staffReferences.concat(staffGroupMembers);

    const patients: PatientAttendee[] = await store.patients.expand(patientReferences as Expandable<Patient>[], { abort: abort.signal });
    const restrictedPatients = patientReferences.filter(e => !patients.some(p => p.id === e.id)) as Reference<Patient>[];
    patients.push(...restrictedPatients);

    const staffs = await store.practitioners.expand(staffExpandables as Expandable<Practitioner>[], { abort: abort.signal });

    return [patients, staffs];
  }

  function renderAttendees() {
    if (appointment.attendees.length === 1 && appointment.attendees[0].subject.id === user.person.id) {
      return;
    }

    if (patients.length > 0) {
      return (
        <>
          <SectionLabel>{Text.Attendees}</SectionLabel>
          <Attendees>
            {patients
              .map(patient => (
                <AttendeeContainer key={patient.id}>
                  <PatientBanner interactive patient={patient} />
                </AttendeeContainer>
              ))}
          </Attendees>
        </>
      );
    }
    return;
  }

  function renderGroup() {
    return (
      <Section>
        <AppointmentInfo label={`${Text.Group} name`}>
          <AppointmentGroupBanner appointment={appointment} />
        </AppointmentInfo>
      </Section>
    );
  }

  function renderStaff() {
    if (staffs.length > 0) {
      return (
        <>
          <SectionLabel>{labels.Practitioner}</SectionLabel>
          <Attendees>
            {staffs.map(staff => (
              <AttendeeContainer key={staff.id}>
                <PractitionerBanner interactive practitioner={staff} />
              </AttendeeContainer>
            ))}
          </Attendees>
        </>
      );
    }
    return;
  }

  async function getNote(encounter: Encounter) {
    const superviseeIds = user.supervisees.map(s => s.id);
    const response = await apollo.notes.query({
      filters: [{
        subject: { in: [encounter.subject.id] },
        encounter: { in: [encounter.id] },
        participants: {
          in: [user.person.id, ...superviseeIds],
        },
      }],
      feedOptions: {
        partition: encounter.subject.id,
        maxItemCount: 1,
      },
      responseOptions: {
        expansions: [
          NoteExpandables.Encounter,
          NoteExpandables.Subject,
        ],
      },
      abort: abort.signal,
    });
    return response.results[0];
  }

  function renderProgramAndSubProgram() {
    if (appointment.program?.resource?.parent) {
      return `${appointment.program.resource.parent.display} - ${appointment.program.resource.display}`;
    }
    return appointment.program?.display;
  }

  function handleEncounterSet(item: Encounter) {
    if (item.appointment?.id === appointment.id && !item.partOf) {
      setAssociatedEncounter(item);
    }
  }

  function handleEncounterDelete(resourceId: string) {
    if (associatedEncounter?.id === resourceId) {
      setAssociatedEncounter(undefined);
    }
  }
};
