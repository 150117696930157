import { DependencyList, useEffect } from "react";
import { useDebouncer } from "./useDebouncer";
import { useCallbackRef } from "./useCallbackRef";

export function useDebouncedEffect(effect: () => void, debounceMs: number, dep?: DependencyList) {
  const debouncer = useDebouncer(debounceMs);
  const effectCallback = useCallbackRef(effect);

  useEffect(() => {
    debouncer.delay(handleFireEffect);
  }, dep);

  function handleFireEffect() {
    effectCallback();
  }
}
