import { useEffect, useRef } from "react";
import { useAbort, useStateIfMounted } from "@remhealth/ui";
import { PersonalPreferences } from "@remhealth/apollo";
import { usePreferences } from "./usePreferences";

export function usePersonalPreferencesRef() {
  const preferences = usePreferences();
  const abort = useAbort();
  const [personalPreferences, setPersonalPreferences] = useStateIfMounted<PersonalPreferences | undefined>(preferences.loadedPersonal ?? undefined);
  const personalPreferencesRef = useRef(personalPreferences);

  useEffect(() => {
    if (!preferences.isPersonalLoaded) {
      loadPersonalPreferences();
    }
  }, []);

  useEffect(() => preferences.registerPersonal(handleUpdate), [preferences]);

  async function loadPersonalPreferences() {
    const loaded = await preferences.getPersonal(abort.signal);
    personalPreferencesRef.current = loaded;
    setPersonalPreferences(loaded);
  }

  function handleUpdate(preferences: PersonalPreferences) {
    personalPreferencesRef.current = preferences;
    setPersonalPreferences(preferences);
  }

  return personalPreferencesRef;
}

export function usePersonalPreferences() {
  return usePersonalPreferencesRef().current;
}
