import styled from "styled-components";
import { Icon } from "@remhealth/ui";

export const RuleDisplay = styled.div`
  color: ${props => props.theme.font.muted};
  font-size: 14px;
  margin-left: 4px;
`;

export const DotIcon = styled(Icon)`
  margin-right: 0px;
`;

export const RuleContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;
