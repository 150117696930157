// eslint-disable-next-line no-restricted-imports
import * as Yup from "yup";

declare module "yup" {
  export interface Schema<T> {
    unless(condition: boolean, transmute: (schema: this) => this): this;
  }
}

function unless<T>(this: Yup.Schema<T> & T, condition: boolean, transmute: (schema: T) => T): T {
  if (!condition) {
    return transmute(this);
  }
  return this;
}

Yup.addMethod(Yup.mixed, "unless", unless);
Yup.addMethod(Yup.string, "unless", unless);
Yup.addMethod(Yup.number, "unless", unless);
Yup.addMethod(Yup.boolean, "unless", unless);
Yup.addMethod(Yup.date, "unless", unless);
Yup.addMethod(Yup.array, "unless", unless);
Yup.addMethod(Yup.object, "unless", unless);
