import { BrowserLoginHandler, type LoginRequestHandler, type LogoutRequestHandler } from "@remhealth/host";
import type { AuthenticationEvents } from "./useAuthentication";

export class BrowserLoginAuthenticationEvents implements AuthenticationEvents {
  public createLoginHandler(): LoginRequestHandler {
    return new BrowserLoginHandler();
  }

  public createLogoutHandler(): LogoutRequestHandler {
    return (_onSuccess: () => void, logoutUrl: string) => {
      window.location.href = logoutUrl;
    };
  }
}
