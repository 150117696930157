import { BlockEmbed } from "quill/blots/block";
import { css } from "styled-components";

export interface ReadonlyTableBlotData {
  rows: TableRow[];
}

export interface TableRow {
  head: boolean;
  cells: TableCell[];
}

export interface TableCell {
  content: string;
  head: boolean;
}

export class ReadonlyTableBlot extends BlockEmbed {
  public static blotName = "readonly-table";
  public static tagName = "rh-readonly-table";
  public declare domNode: HTMLElement;

  public static create(data: ReadonlyTableBlotData) {
    const node = document.createElement(this.tagName);
    node.setAttribute("contenteditable", "false");
    ReadonlyTableBlot.init(node, data);
    return node;
  }

  public static value(node: HTMLElement): ReadonlyTableBlotData {
    const table = node.tagName.toLowerCase() === "rh-readonly-table" ? node.querySelector("table") : node;

    const rows: TableRow[] = [];

    if (table) {
      for (let i = 0; i < table.children.length; i++) {
        const child = table.children.item(i);

        if (child instanceof HTMLTableSectionElement) {
          for (let j = 0; j < child.children.length; j++) {
            const head = child.tagName === "THEAD";
            const sectionChild = child.children.item(j);

            if (sectionChild instanceof HTMLTableRowElement) {
              rows.push({ head, cells: getCells(sectionChild) });
            }
          }
        } else if (child instanceof HTMLTableRowElement) {
          rows.push({ head: false, cells: getCells(child) });
        }
      }
    }

    return { rows };
  }

  public static init(node: HTMLElement, data: ReadonlyTableBlotData): void {
    const table = node.ownerDocument.createElement("table");

    let section: HTMLTableSectionElement | undefined;

    for (const row of data.rows) {
      if (!section || (section.tagName !== "THEAD" && row.head) || (section.tagName === "THEAD" && !row.head)) {
        section = row.head ? node.ownerDocument.createElement("thead") : node.ownerDocument.createElement("tbody");
        table.append(section);
      }

      const rowEl = node.ownerDocument.createElement("tr");
      section.append(rowEl);

      for (const cell of row.cells) {
        const cellEl = cell.head ? node.ownerDocument.createElement("th") : node.ownerDocument.createElement("td");
        cellEl.textContent = cell.content;
        rowEl.append(cellEl);
      }
    }

    node.append(table);
  }
}

function getCells(row: HTMLTableRowElement): TableCell[] {
  const cells: TableCell[] = [];

  for (let i = 0; i < row.children.length; i++) {
    const child = row.children.item(i);

    if (child instanceof HTMLTableCellElement) {
      cells.push({ content: child.textContent ?? "", head: child.tagName === "TH" });
    }
  }

  return cells;
}

export function isReadonlyTableBlot(blot: any): blot is ReadonlyTableBlot {
  return blot && blot instanceof ReadonlyTableBlot;
}

export const ReadonlyTableCss = css`
  rh-readonly-table {
    background-color: ${props => props.theme.background.default};
    box-shadow: ${props => props.theme.elevations.one};
    border-radius: var(--pt-border-radius);
    margin: 10px 5px;
    padding: 0px;
    align-self: flex-start;
    cursor: default;

    table {
      width: auto;
      border-spacing: 3px;
      border-collapse: separate;
    }

    th {
      background-color: ${props => props.theme.table.header};
    }

    thead:first-child th:first-child,
    table > tr:first-child th:first-child {
      border-top-left-radius: 3px;
    }

    thead:first-child th:last-child,
    table > tr:first-child th:last-child {
      border-top-right-radius: 3px;
    }

    td, th {
      border: none;
      padding: 4px 6px;

      &:before {
        content: '';
        display: block;
        width: 5em;
      }
    }
  }
`;
