import * as Core from "@blueprintjs/core";
import classnames from "classnames";

export interface ButtonGroupProps extends Core.ButtonGroupProps {
  /** If true, buttons will be styled as toggles */
  toggles?: boolean;

  small?: boolean;
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    toggles = false,
    small = false,
    minimal = false,
    className,
    ...buttonGroupProps
  } = props;

  return (
    <Core.ButtonGroup
      {...buttonGroupProps}
      className={classnames(className, {
        [Core.Classes.SMALL]: small,
        [Core.Classes.MINIMAL]: minimal,
        toggles,
      })}
      minimal={minimal}
    />
  );
};
