import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .bp5-popover-target {
    display: inline-flex;
  }

  .bp5-popover-target + .bp5-popover-target {
    border-radius: 50%;
    outline: 1px solid ${props => props.theme.background.default};
    position: relative;
    margin-left: -5px;
  }
`;

export const StackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RemainingCount = styled.div`
  position: relative;
  display: inline-flex;
  height: 28px;
  width: 28px;
  margin-left: -5px;
  border-radius: 50%;
  border: 2px solid var(--pt-intent-primary);
  background-color: ${props => props.theme.background.default};
  outline: 1px solid ${props => props.theme.background.default};
  z-index: 1;

  .count {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border-radius: 50%;
    user-select: none;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-small);
    color: ${props => props.theme.font.active};
  }
`;
