import { Note, NoteSectionFormat } from "@remhealth/apollo";
import { Text } from "~/text";
import { Container, NoData, SectionHeader } from "./common.styles";

export interface ClinicalQualityCodesSectionContentProps {
  note: Note;
}

export function ClinicalQualityCodesSectionContent(props: ClinicalQualityCodesSectionContentProps) {
  const { note } = props;
  const name = note.sections.find(section => section.format === NoteSectionFormat.ClinicalQualityIndicator)?.name;
  return (
    <Container>
      <SectionHeader>{name}</SectionHeader>
      {note.clinicalQualityCodes.length === 0 ? <NoData>{Text.NoData}</NoData> : (
        <ul>{note.clinicalQualityCodes.map(c => <li key={c.code}>{c.code} - {c.description}</li>)}</ul>
      )}
    </Container>
  );
}
