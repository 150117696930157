import { randomAlpha } from "./misc";

export function injectGradient(svg: SVGElement, gradient: Readonly<[startColor: string, endColor: string]>): SVGGElement {
  const elements = svg.querySelectorAll(":scope > *:not(defs)");

  const gradId = randomAlpha(10);

  const xmlns = "http://www.w3.org/2000/svg";
  const gradientEl = document.createElementNS(xmlns, "linearGradient");
  gradientEl.id = gradId;
  gradientEl.setAttributeNS(null, "gradientUnits", "userSpaceOnUse");
  gradientEl.setAttributeNS(null, "x1", "0%");
  gradientEl.setAttributeNS(null, "x2", "0%");
  gradientEl.setAttributeNS(null, "y1", "0%");
  gradientEl.setAttributeNS(null, "y2", "100%");

  const stop1 = document.createElementNS(xmlns, "stop");
  stop1.setAttributeNS(null, "offset", "0%");
  stop1.setAttributeNS(null, "stop-color", gradient[0]);

  const stop2 = document.createElementNS(xmlns, "stop");
  stop2.setAttributeNS(null, "offset", "100%");
  stop2.setAttributeNS(null, "stop-color", gradient[1]);

  gradientEl.append(stop1);
  gradientEl.append(stop2);

  const defs = document.createElementNS(xmlns, "defs");
  defs.append(gradientEl);

  const group = document.createElementNS(xmlns, "g");

  svg.append(defs);
  svg.append(group);

  elements.forEach(element => {
    group.append(element);
  });

  // Special case where icon is stroke-only
  const isStrokeOnly = elements.length === 1 && elements.item(0).getAttributeNS(null, "stroke") === "currentColor";
  group.setAttributeNS(null, isStrokeOnly ? "stroke" : "fill", `url(#${gradId})`);

  if (isStrokeOnly) {
    elements.item(0).removeAttributeNS(null, "stroke");
  }

  return group;
}
