import { Tooltip } from "@remhealth/ui";
import { Practitioner, Reference, isReference } from "@remhealth/apollo";
import { RestrictedAvatar } from "./avatarPlaceholder";
import { PractitionerPopover } from "./practitionerPopover";
import { PersonName } from "./personName";
import { Avatar } from "./avatar";

export interface PractitionerAvatarProps {
  practitioner: Practitioner | Reference<Practitioner>;
  size?: number;
  className?: string;
  /**
   * Gives avatar appearance of active (mousedown)
   * @default false
   */
  active?: boolean;
  /**
   * Gives avatar appearance on hover
   * @default false
   */
  interactive?: boolean;
  /**
   * Highlights avatar (notice of activity)
   * @default false
   */
  highlight?: boolean;
  /** @default false */
  tooltip?: boolean;
}

export const PractitionerAvatar = (props: PractitionerAvatarProps) => {
  const { size, className, active, interactive = false, highlight, tooltip = false } = props;

  const practitioner = isReference(props.practitioner) ? props.practitioner.resource : props.practitioner;

  if (!practitioner) {
    return (
      <RestrictedAvatar className={className} size={size} />
    );
  }

  if (interactive) {
    return (
      <PractitionerPopover practitioner={practitioner}>
        {isOpen => (
          <Tooltip content={<PersonName name={practitioner.name} />} disabled={!tooltip || isOpen}>
            <Avatar interactive active={active || isOpen} className={className} highlight={highlight} person={practitioner} size={size} />
          </Tooltip>
        )}
      </PractitionerPopover>
    );
  }

  if (tooltip) {
    return (
      <Tooltip content={<PersonName name={practitioner.name} />}>
        <Avatar active={active} className={className} highlight={highlight} person={practitioner} size={size} />
      </Tooltip>
    );
  }

  return <Avatar active={active} className={className} highlight={highlight} person={practitioner} size={size} />;
};
