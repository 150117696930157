import styled from "styled-components";
import { Icon, IconProps } from "@remhealth/ui";
import { Group } from "@remhealth/icons";

interface StyledIconProps extends GroupAvatarProps {
  $diameter: number;
}

const StyledIcon = styled(Icon)<StyledIconProps>`
  width: ${props => props.$diameter}px;
  height: ${props => props.$diameter}px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.icon.default};

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 2px solid currentColor;
    opacity: 0.5;
  }
`;

export type GroupAvatarProps = Omit<IconProps, "icon" | "iconSize">;

export function GroupAvatar(props: GroupAvatarProps) {
  const { size = 36, color, ...restProps } = props;
  const iconSize = Math.floor(size / 1.8);
  return <StyledIcon $diameter={size} color={color} icon={<Group />} size={iconSize} {...restProps} />;
}
