import { approximateRangeOverlaps, useApollo, useUserProfile } from "@remhealth/host";
import { useStore } from "@remhealth/core";
import { useAbort } from "@remhealth/ui";
import {
  Approximate,
  ApproximateRangeFilter,
  CompositionStatus,
  EncounterDocumentationType,
  EncounterStatus,
  NoteComposition,
  NoteExpandables,
  NoteFilterSet,
  NoteRule,
  OffsetDateTime,
  ParameterPresence,
  Participant,
  PatientNote
} from "@remhealth/apollo";
import { useNoteRulesFeed } from "../rules/useNoteRulesFeed";

export interface NoteOverlapCheckResult {
  overlappingNotes: PatientNote[];
  overlapRule: NoteRule | undefined;
}

export function useNoteOverlapCheck() {
  const apollo = useApollo();
  const abort = useAbort();
  const store = useStore();
  const user = useUserProfile();

  // Only one overlap rule can exist, so we just pull them all
  const loadOverlapRules = useNoteRulesFeed({
    criteriaTypes: ["SessionOverlap"],
  });

  return { noteOverlapCheck, allowNoteOverlapCheck: allowOverlapCheck };

  async function noteOverlapCheck(note: PatientNote): Promise<NoteOverlapCheckResult> {
    if (!allowOverlapCheck(note)) {
      return { overlappingNotes: [], overlapRule: undefined };
    }

    const sessionTimes = note.participants
      .filter(p => p.role === "PrimaryPerformer"
        && !!p.period?.start
        && !!p.period?.end
        && Approximate.getPrecision(p.period.start) === "Full"
        && Approximate.getPrecision(p.period.end) === "Full"
        // Ignore session times that aren't over 2 minute
        && getDuration(p.period.start, p.period.end) > 2);

    const filters: NoteFilterSet[] = sessionTimes.flatMap(session => !session.period?.start || !session.period.end ? [] : {
      id: { notIn: [note.id] },
      composition: { in: ["Individual"] },
      status: { in: [CompositionStatus.Final] },
      partOfComposition: { notIn: [NoteComposition.Group], presence: ParameterPresence.MaybePresent },
      participantPeriod: getOverlapsFilter(session.period.start, session.period.end),
    } satisfies NoteFilterSet);

    if (filters.length === 0) {
      return { overlappingNotes: [], overlapRule: undefined };
    }

    const overlappingNoteFeed = apollo.notes.feed({
      filters: filters.flatMap(filter => [
        {
          ...filter,
          participants: { in: [user.id] },
        },
        {
          ...filter,
          subject: { in: [note.subject.id] },
        },
      ]),
      crossPartition: true,
      responseOptions: {
        expansions: [NoteExpandables.Encounter],
      },
    });

    let overlappingNotes = await overlappingNoteFeed.all({ abort: abort.signal }) as PatientNote[];

    if (overlappingNotes.length === 0) {
      return { overlappingNotes: [], overlapRule: undefined };
    }

    await store.healthcareServices.expand(overlappingNotes.flatMap(n => n.encounter.resource?.serviceType ?? []));
    await store.noteDefinitions.expand(overlappingNotes.flatMap(n => n.definition ?? []));

    const encounter = await store.encounters.expand(note.encounter);
    const serviceType = encounter.serviceType ? await store.healthcareServices.expand(encounter.serviceType) : undefined;

    const overlapRules = await loadOverlapRules();
    const overlapRule = overlapRules.find(r => r.limitToServices.some(s => s.id === encounter.serviceType?.id));

    overlappingNotes = overlappingNotes.filter(overlappingNote => {
      if (!allowOverlapCheck(overlappingNote)) {
        return false;
      }

      // Double-check that the overlapping session was from the same participant
      // because participantPeriod filter doesn't support role
      if (!hasOverlappingSessionTime(sessionTimes, overlappingNote)) {
        return false;
      }

      // Overlap validation rule takes precedence over the exceptions list
      if (!overlapRule) {
        // Check exceptions list
        const overlappingNoteServiceType = overlappingNote.encounter.resource?.serviceType?.resource;

        if (serviceType && overlappingNoteServiceType) {
          const hasException = serviceType.overlappingServiceExceptions.some(o => o.id === overlappingNoteServiceType.id)
            && overlappingNoteServiceType.overlappingServiceExceptions.some(o => o.id === serviceType.id);

          if (hasException) {
            return false;
          }
        }
      }

      return true;
    });

    return { overlappingNotes, overlapRule };
  }
}

function allowOverlapCheck(note: PatientNote): boolean {
  // Only check against Show notes
  if (!isShowStatus(note)) {
    return false;
  }

  // Must be normal note type
  if (note.encounter.resource?.documentationType !== EncounterDocumentationType.Note) {
    return false;
  }

  // Ignore independent notes
  if (!note.definition.resource?.billable) {
    return false;
  }

  return true;
}

function hasOverlappingSessionTime(sessionTimes: Participant[], otherNote: PatientNote): boolean {
  return otherNote.participants.some(otherSessionTime => {
    return sessionTimes.some(sessionTime => {
      if (sessionTime.role !== "PrimaryPerformer" || otherSessionTime.role !== "PrimaryPerformer") {
        return false;
      }

      return !!sessionTime.period
        && !!otherSessionTime.period
        && approximateRangeOverlaps(sessionTime.period, otherSessionTime.period, true);
    });
  });
}

function getOverlapsFilter(start: Approximate, end: Approximate): ApproximateRangeFilter {
  // Force exclusive range search
  const exclusiveStart = Approximate.fromFull(OffsetDateTime.fromDateTime(Approximate.toDateTime(start).plus({ minute: 1 })));
  const exclusiveEnd = Approximate.fromFull(OffsetDateTime.fromDateTime(Approximate.toDateTime(end).minus({ minute: 1 })));

  return {
    start: { presence: ParameterPresence.MustBePresent },
    end: { presence: ParameterPresence.MustBePresent },
    overlaps: {
      start: exclusiveStart,
      end: exclusiveEnd,
    },
  };
}

function getDuration(start: Approximate, end: Approximate): number {
  return Approximate.toDateTime(end).diff(Approximate.toDateTime(start)).as("minutes");
}

function isShowStatus(note: PatientNote) {
  if (note.encounter.resource?.status === EncounterStatus.NoShow) {
    return false;
  }

  if (note.encounter.resource?.status === EncounterStatus.Cancelled) {
    return false;
  }

  return true;
}
