import { useEffect, useRef } from "react";

/**
 * Returns a ref that indicates if the component is currently mounted.
 */
export function useMounted() {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
