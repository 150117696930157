import { PropsWithChildren, ReactElement, forwardRef, useImperativeHandle, useRef } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { useColorScheme } from "./theme";
import { Icon, IconName, IconProps } from "./icon";

export interface ToastProps extends Omit<Core.ToastProps, "icon"> {
  icon?: IconName | ReactElement<IconProps>;
}

export type ToastOptions = ToastProps & {
  key: string;
};

export interface Toaster extends Omit<Core.Toaster, "show"> {
  show(props: ToastProps, key?: string): string;
}

export const Toast = (props: ToastProps) => {
  const { icon, ...toastProps } = props;

  const nativeIcon = icon ? <Icon icon={icon} /> : undefined;

  return <Core.Toast2 {...toastProps} icon={nativeIcon} />;
};

export const OverlayToaster = forwardRef<Core.Toaster, PropsWithChildren<Core.OverlayToasterProps>>((props, ref) => {
  const { colorScheme } = useColorScheme();
  const toaster = useRef<Core.OverlayToaster>(null);
  const toastId = useRef(1);

  useImperativeHandle(ref, () => ({
    show,
    dismiss,
    clear,
    getToasts,
  }), [colorScheme.dark]);

  return (
    <Core.OverlayToaster ref={toaster} {...props} />
  );

  function show(props: ToastProps, key?: string): string {
    if (key === undefined) {
      key = `notification-${toastId.current++}`;
    }

    if (colorScheme.dark) {
      props.className = classnames(props.className, "bp5-dark");
    }

    if (props.timeout === undefined) {
      const currentToasts = toaster.current?.getToasts() ?? [];
      props.timeout = getTimeout(currentToasts.length);
    }

    if (typeof props.icon === "string") {
      props.icon = <Icon icon={props.icon} />;
    }

    toaster.current?.show(props, key);

    return key;
  }

  function dismiss(key: string): void {
    toaster.current?.dismiss(key);
  }

  function clear(): void {
    toaster.current?.clear();
  }

  function getToasts(): Core.ToastOptions[] {
    return toaster.current?.getToasts() ?? [];
  }
});

class VoidNotifier implements Core.Toaster {
  public show() {
    return "";
  }
  public dismiss() {}
  public clear() {}
  public getToasts() {
    return [];
  }
}

export const voidNotifier = new VoidNotifier();

export class ForwardingNotifier implements Core.Toaster {
  private readonly toasterRef: { current: Core.Toaster | null };

  constructor(toasterRef: { current: Core.Toaster | null }) {
    this.toasterRef = toasterRef;
  }

  public show(props: Core.ToastProps, key?: string) {
    return this.toasterRef.current ? this.toasterRef.current.show(props, key) : "";
  }
  public dismiss(key: string) {
    if (this.toasterRef.current) {
      this.toasterRef.current.dismiss(key);
    }
  }
  public clear() {
    if (this.toasterRef.current) {
      this.toasterRef.current.clear();
    }
  }
  public getToasts() {
    return this.toasterRef.current ? this.toasterRef.current.getToasts() : [];
  }
}

function getTimeout(showCount: number): number {
  return Math.max(2000, 5000 - (showCount * 1000));
}
