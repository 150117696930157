import { useEffect } from "react";
import { findLastIndex } from "lodash-es";
import { Flavor, Media, MediaThumbnail, Reference } from "@remhealth/apollo";
import { useStateRef, useUpdateEffect } from "@remhealth/ui";
import { useApollo } from "@remhealth/host";
import { useMedia } from "./useMedia";

type CachedMediaContentKey = Flavor<string, "CachedMediaContent">;
type CachedMediaContent = { loader: Promise<string>; loaded: string | undefined };
const loaderCache = new Map<CachedMediaContentKey, CachedMediaContent>();

export interface MediaContentHookOptions {
  media: Media | Reference<Media>;
  maxWidth?: number;
  maxHeight?: number;
  onContentLoaded?: (content: MediaContent) => void;
}

export interface MediaContent {
  media: Media;
  content?: string;
}

export function useMediaContent(options: MediaContentHookOptions): MediaContent | undefined {
  const { media: mediaOrRef, maxWidth, maxHeight, onContentLoaded } = options;

  const apollo = useApollo();
  const media = useMedia(mediaOrRef);

  const cacheKey = media ? getMediaContentCacheKey(media, maxWidth, maxHeight) : undefined;

  const loaded = useStateRef(() => (cacheKey ? loaderCache.get(cacheKey) : undefined)?.loaded);

  useEffect(() => {
    if (media && cacheKey) {
      loadContent(media, cacheKey);
    }
  }, [cacheKey]);

  useUpdateEffect(() => {
    if (media && cacheKey) {
      loaded.set(undefined);
      loadContent(media, cacheKey);
    }
  }, [cacheKey]);

  return media && loaded.current ? { media, content: loaded.current } : undefined;

  async function loadContent(media: Media, cacheKey: CachedMediaContentKey) {
    // Prefer locally cached base64 data, in case of pending uploads while offline
    if (media.content?.url?.startsWith("data:")) {
      if (!loaded.current || loaded.current !== media.content.url) {
        loaded.set(media.content.url);
        return;
      }
    }

    // Already loaded
    if (loaded.current) {
      return;
    }

    let cacheItem = loaderCache.get(cacheKey);
    if (!cacheItem) {
      const loader = getContent(media, cacheKey);
      if (!loader) {
        return;
      }

      cacheItem = { loader, loaded: undefined };
      loaderCache.set(cacheKey, cacheItem);
    }

    const content = cacheItem.loaded ?? await cacheItem.loader;
    loaded.set(content);
    onContentLoaded?.({ media, content });
  }

  async function getContent(media: Media, cacheKey: CachedMediaContentKey) {
    const content = await downloadContent(media);
    const cacheItem = loaderCache.get(cacheKey);
    if (cacheItem) {
      cacheItem.loaded = content;
    }
    return content;
  }

  async function downloadContent(media: Media): Promise<string> {
    if (maxWidth || maxHeight) {
      const [thumbnail, thumbIndex] = getThumbnail(media, maxWidth, maxHeight);

      if (thumbnail) {
        if (media.distribution === "Public" && thumbnail.url?.startsWith("http")) {
          return thumbnail.url;
        }

        return await apollo.media.downloadContent(media, "base64", thumbIndex);
      }
    }

    if (media.distribution === "Public" && media.content?.url?.startsWith("http")) {
      return media.content.url;
    }

    return await apollo.media.downloadContent(media, "base64");
  }
}

function getMediaContentCacheKey(media: Media, maxWidth?: number, maxHeight?: number): CachedMediaContentKey {
  // Cache versioned media if it is versioned
  const id = media.meta?.versionId ? media.meta.versionId : media.id;
  const url = media.content?.url;
  const hash = media.content?.hash;
  const [thumbnail] = getThumbnail(media, maxWidth, maxHeight);
  const thumbnailUrl = thumbnail?.url;
  return `${id}_${url}_${hash}_${thumbnailUrl}` as CachedMediaContentKey;
}

function getThumbnail(media: Media, maxWidth?: number, maxHeight?: number): [thumbnail: MediaThumbnail | undefined, thumbnailIndex: number] {
  const thumbIndex = findLastIndex(media.thumbnails, t =>
    (maxWidth === undefined || t.width >= maxWidth)
    && (maxHeight === undefined || t.height >= maxHeight));
  if (thumbIndex !== -1) {
    return [media.thumbnails[thumbIndex], thumbIndex];
  }
  return [undefined, -1];
}
