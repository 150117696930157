import { createContext } from "react";
import { Address, AdministrativeGender, BirthSex, CodeableConcept, ContactPoint, HumanName, LocalDate, Location, LocationRole, OtherName, Practitioner, Reference } from "@remhealth/apollo";
import { PracticePreferencesData, defaultPracticePreferences } from "@remhealth/host";

export interface PlaceholderContext {
  patient: {
    name: HumanName;
    otherNames?: OtherName[];
    birthDate: LocalDate;
    gender: AdministrativeGender;
    genderIdentity?: CodeableConcept;
    birthSex?: BirthSex;
    addresses: Address[];
    telecoms: ContactPoint[];
  } | undefined;
  guardian: {
    display: string;
  } | undefined;
  practitioner: Practitioner | undefined;
  practicePreferences: PracticePreferencesData;
  location: Reference<Location> | undefined;
  placeOfService: LocationRole | Reference<Location> | undefined;
}

export const PlaceholderContext = createContext<PlaceholderContext>({
  get patient(): PlaceholderContext["patient"] {
    return undefined;
  },
  get guardian(): PlaceholderContext["guardian"] {
    return undefined;
  },
  get practitioner(): PlaceholderContext["practitioner"] {
    return undefined;
  },
  get practicePreferences(): PlaceholderContext["practicePreferences"] {
    return defaultPracticePreferences;
  },
  get location(): PlaceholderContext["location"] {
    return undefined;
  },
  get placeOfService(): PlaceholderContext["placeOfService"] {
    return undefined;
  },
});
