import { useMemo } from "react";
import styled from "styled-components";
import { Classes } from "@blueprintjs/core";
import { CaretDown, CaretUp, DoubleCaretVertical } from "@remhealth/icons";
import { Button, ButtonProps } from "./button";
import { Tooltip } from "./tooltip";

const StyledButton = styled(Button)`
  background: none !important;
  box-shadow: none !important;
  padding: 0;
  color: inherit !important;

  > .bp5-button-text {
    margin-right: 3px;
  }

  > .bp5-button-text + .bp5-icon {
    padding: 3px;
    border-radius: 3px;
    margin-right: 0;
  }

  &:hover {
    background: none;

    > .bp5-button-text + .bp5-icon {
      background: var(--minimal-button-background-color-hover);
    }
  }

  &:active {
    background: none;

    > .bp5-button-text + .bp5-icon {
      background: var(--minimal-button-background-color-active);
    }
  }
`;

type SortDirection = "Ascending" | "Descending" | "Unspecified";

export interface SortButtonProps extends Omit<ButtonProps, "intent" | "minimal" | "rightIcon" | "children"> {
  sort: SortDirection;
}

export const SortButton = (props: SortButtonProps) => {
  const { sort, label, ...buttonProps } = props;

  const tooltip = useMemo(() => sort === "Unspecified" ? <><span className={Classes.TEXT_MUTED}>Sort by</span> {label}</> : <>{label} <span className={Classes.TEXT_MUTED}>{sort}</span></>, [sort, label]);

  return (
    <Tooltip content={tooltip}>
      <StyledButton
        {...buttonProps}
        aria-label={`${label} Sort`}
        aria-sort={sort === "Ascending" ? "ascending" : sort === "Descending" ? "descending" : "none"}
        data-sort-button={label}
        data-sort-direction={sort}
        label={label}
        rightIcon={sort === "Unspecified"
          ? <DoubleCaretVertical intent="primary" />
          : sort === "Ascending" ? <CaretUp intent="primary" /> : <CaretDown intent="primary" />}
      />
    </Tooltip>
  );
};
