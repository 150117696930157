const versionProperty = "__APP_VERSION";

export async function getConfigVersion(appName: string, lastKnownVersion: string | undefined): Promise<string | undefined> {
  const config = await fetch("/config.js", {
    headers: {
      "X-RH-ApplicationID": appName,
      "X-RH-ApplicationVersion": lastKnownVersion ?? "",
    },
  });

  const contentType = config.headers.get("Content-Type");
  if (!contentType?.startsWith("application/javascript") && !contentType?.startsWith("text/javascript")) {
    return undefined;
  }

  // Config.js returns javascript, so we can't treat it exactly like json.
  // So we'll just try to parse the app version manually
  const configContents = await config.text();

  const appVersionPropertyStart = configContents.indexOf(versionProperty);
  if (appVersionPropertyStart === -1) {
    return undefined;
  }

  let appVersionStart = appVersionPropertyStart + versionProperty.length;

  // If proprety is quoted
  if (configContents[appVersionStart] === '"') {
    appVersionStart++;
  }

  // Skip colon
  appVersionStart++;

  // If space after colon
  if (configContents[appVersionStart] === " ") {
    appVersionStart++;
  }

  // Expect version begining quote
  if (configContents[appVersionStart] !== '"') {
    return undefined;
  }

  // Skip version beginning quote
  appVersionStart++;

  const appVersionEnd = configContents.indexOf('"', appVersionStart);
  if (appVersionEnd === -1) {
    return undefined;
  }

  const version = configContents.slice(appVersionStart, appVersionEnd).trim();
  return version ? version : undefined;
}
