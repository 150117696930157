import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useCallbackRef } from "./useCallbackRef";
import { useMounted } from "./useMounted";

/**
 * Can be used like useState, except with a value that may or may not be in controlled mode.
 * If in controlled mode, the controlled value will be used.  Otherwise, the value will be tracked internally.
 * @param value The value if in controlled mode.
 * @param onChange The onChange dispatch if in controlled mode.
 * @param initialValue The initial value if not in controlled mode.
 * @param shouldUpdate An optional value comparer to determine if a change has occurred.
 * @returns Returns [state, setState]
 */
export function useControllableState<T>(
  value: T | undefined,
  onChange: Dispatch<T> | Dispatch<SetStateAction<T>> | undefined,
  initialValue: T | (() => T),
  shouldUpdate: (prev: T, next: T) => boolean = (prev, next) => prev !== next
): [T, Dispatch<SetStateAction<T>>] {
  const isMounted = useMounted();
  const changeCallback = useCallbackRef(onChange);
  const shouldUpdateCallback = useCallbackRef(shouldUpdate);

  const [valueState, setValueState] = useState(initialValue);

  const isControlled = value !== undefined;
  const effectiveValue = value !== undefined ? value : valueState;

  const updateValue = useCallback((next: React.SetStateAction<T>) => {
    const nextValue = isStateCallback(next) ? next(effectiveValue) : next;

    if (!isMounted.current || !shouldUpdateCallback(effectiveValue, nextValue)) {
      return;
    }

    if (!isControlled) {
      setValueState(nextValue);
    }

    changeCallback(nextValue);
  }, [isControlled, changeCallback, effectiveValue, shouldUpdateCallback]);

  return [effectiveValue, updateValue];
}

function isStateCallback<S>(a: SetStateAction<S>): a is ((prevState: S) => S) {
  return typeof a === "function";
}
