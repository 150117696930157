import styled from "styled-components";
import { Svg } from "@remhealth/ui";

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  padding-bottom: 10%;
  font-size: var(--font-size-header3);
`;

export const Illustration = styled(Svg)`
  margin-bottom: 10px;
`;
