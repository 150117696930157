import { useEffect } from "react";
import { RelatedPeopleFilterOptions, useFeed, useRelatedPeopleView } from "@remhealth/core";
import { IFeed, useAbort } from "@remhealth/ui";
import { RelatedPerson } from "@remhealth/apollo";
import { OpenPatient } from "~/contexts";

export interface RelatedPeopleHookOptions extends Omit<RelatedPeopleFilterOptions, "patientId"> {

}

export function useRelatedPeople(patient: OpenPatient, options: RelatedPeopleHookOptions): IFeed<RelatedPerson> {
  const abort = useAbort();

  const view = useRelatedPeopleView({ direction: "Ascending", field: "Name" }, {
    patientId: patient.patient.id,
    ...options,
  });

  const feed = useFeed(view, {
    reload: false,
  });

  useEffect(() => {
    loadAll();
    return patient.onPullComplete(view.key, handlePullComplete);
  }, [view.key]);

  return feed;

  async function handlePullComplete() {
    feed.reset(true);
    loadAll();
  }

  async function loadAll() {
    while (feed.canLoadMore && !abort.signal.aborted) {
      await feed.loadMore(100, abort.signal);
    }
  }
}
