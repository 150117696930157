import type { LiteralString } from "./types";
import { DurationString, durationToMs } from "./utils";

interface StoredValue {
  value: string;
  expiry?: number;
  slide?: DurationString;
}

type StoredMap = ReadonlyArray<[string, StoredValue]>;

interface AbsoluteExpirationOptions {
  /**
   * Will set the expiration date.
   */
  absoluteExpiration: Date;
}

interface TimeToLiveOptions {
  /**
   * Will set an expiration in milliseconds, relative from now.
   */
  timeToLiveMs: DurationString;
}

interface SlidingExpirationOptions {
  /**
   * Will set an expiration in milliseconds, relative from now.
   * Each retrieval of the value will renew the expiration.
   */
  slidingExpirationMs: DurationString;
}

export type SetOptions = AbsoluteExpirationOptions | TimeToLiveOptions | SlidingExpirationOptions;

const partitionPrefix = "partition-v2-";

export class StoragePartition {
  private readonly storage: Storage;
  private readonly partitionKey: string;
  private readonly data: Map<string, StoredValue>;
  private readonly handleError: (error: unknown) => void;

  constructor(storage: Storage, partition: string, handleError?: (error: unknown) => void) {
    const partitionKey = `${partitionPrefix}${partition}`;

    removeLegacyPartitions(storage);

    this.data = read(storage, partitionKey);
    this.partitionKey = partitionKey;
    this.storage = storage;
    // eslint-disable-next-line no-console
    this.handleError = handleError ?? console.error;

    this.clearExpired();
  }

  public get length(): number {
    return Array.from(this.data.keys()).length;
  }

  public clearExpired(): void {
    const now = Date.now();
    let needsUpdating = false;

    for (const [key, val] of this.data) {
      if (val.expiry !== undefined && val.expiry < now) {
        this.data.delete(key);
        needsUpdating = true;
      }
    }

    if (needsUpdating) {
      this.updateStorage();
    }
  }

  public clear(): void {
    this.data.clear();
    this.updateStorage();
  }

  public getItem(key: string): string | null {
    const entry = this.data.get(key) ?? null;
    if (!entry) {
      return null;
    }

    if (entry.slide) {
      this.setItem(key, entry.value, { slidingExpirationMs: entry.slide });
    }

    return entry.value;
  }

  public key(index: number): string | null {
    return Array.from(this.data.keys())[index] || null;
  }

  public removeItem(key: string): void {
    this.data.delete(key);
    this.updateStorage();
  }

  public setItem<T extends string>(key: LiteralString<T>, value: string): void;
  public setItem(key: string, value: string, options: SetOptions): void;
  public setItem(key: string, value: string, options?: SetOptions): void {
    let absoluteExpiration: Date | undefined;
    let slidingExpirationMs: DurationString | undefined;

    if (options && "timeToLiveMs" in options) {
      absoluteExpiration = new Date(Date.now() + durationToMs(options.timeToLiveMs));
    } else if (options && "slidingExpirationMs" in options) {
      slidingExpirationMs = options.slidingExpirationMs;
      absoluteExpiration = new Date(Date.now() + durationToMs(options.slidingExpirationMs));
    }

    const expiry = absoluteExpiration?.getTime();
    const storedValue: StoredValue = { value, expiry, slide: slidingExpirationMs };

    try {
      this.saveItem(key, storedValue);
    } catch (error) {
      if (isQuotaExceededError(error)) {
        try {
          this.cleanUp();
          this.saveItem(key, storedValue);
        } catch (error) {
          this.handleError(error);
        }
      } else {
        // Unknown error
        this.handleError(error);
      }
    }
  }

  private saveItem(key: string, value: StoredValue) {
    this.data.set(key, value);
    this.updateStorage();
  }

  private updateStorage() {
    write(this.storage, this.partitionKey, this.data);
  }

  private cleanUp() {
    try {
      cleanUp(this.storage);
      readInto(this.storage, this.partitionKey, this.data);
    } catch (error) {
      if (!isQuotaExceededError(error)) {
        throw error;
      }

      removeOtherPartitions(this.storage, this.partitionKey);
    }
  }
}

function readInto(storage: Storage, partitionKey: string, data: Map<string, StoredValue>) {
  data.clear();
  read(storage, partitionKey).forEach((value, key) => {
    data.set(key, value);
  });
}

function read(storage: Storage, partitionKey: string): Map<string, StoredValue> {
  const json = storage.getItem(partitionKey);
  const data = json ? JSON.parse(json) as StoredMap : [];
  return new Map<string, StoredValue>(data);
}

function write(storage: Storage, partitionKey: string, data: Map<string, StoredValue>): void {
  const storedMap: StoredMap = Array.from(data.entries());
  const json = JSON.stringify(storedMap);
  storage.setItem(partitionKey, json);
}

// Used in case we need to make room for storage
function cleanUp(storage: Storage): void {
  // Roll through all partitions
  for (let i = 0; i < storage.length; i++) {
    const storageKey = storage.key(i);
    if (storageKey?.startsWith(partitionPrefix)) {
      const data = read(storage, storageKey);
      for (const [itemKey, val] of data) {
        if (
          // Delete any values that have expiry, since these are clearly temporary
          val.expiry !== undefined
          // Delete any values that are overtly large
          || val.value.length > 1000
        ) {
          data.delete(itemKey);
        }
      }
      write(storage, storageKey, data);
    }
  }
}

// Removes any old legacy partition entries
function removeLegacyPartitions(storage: Storage) {
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (key?.startsWith("partition-") && !key.startsWith(partitionPrefix)) {
      storage.removeItem(key);
    }
  }
}

// Removes all partitions other than the one specified
function removeOtherPartitions(storage: Storage, retainPartitionPrefix: string) {
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (key?.startsWith("partition-") && !key.startsWith(retainPartitionPrefix)) {
      storage.removeItem(key);
    }
  }
}

function isQuotaExceededError(error: unknown): error is DOMException {
  return error instanceof DOMException && error.name === "QuotaExceededError";
}
