import styled from "styled-components";
import { Ellipsize } from "@remhealth/ui";

export interface ContainerProps {
  $vertical: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.$vertical ? "column" : "row"};
  flex: 1 0 auto;
  gap: ${props => props.$vertical ? 12 : 20}px;
  justify-content: flex-start;
`;

export const PreferedContactData = styled(Ellipsize)`
  color: ${props => props.theme.font.active};
  max-width: 155px;
`;

export const SectionHeader = styled.div`
  font-size: var(--font-size-small);
  color: ${props => props.theme.font.label};
  white-space: nowrap;
`;
