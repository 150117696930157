import { useEffect, useState } from "react";
import { uniqBy } from "lodash-es";
import { Patient, Practitioner, Reference, RelatedPerson, isReference } from "@remhealth/apollo";
import { Tooltip, useAbort, useColorScheme } from "@remhealth/ui";
import { Avatar, AvatarPlaceholder, PractitionerAvatar, useStore } from "@remhealth/core";
import { PatientAvatar } from "./patientAvatar";
import { Container, RemainingCount } from "./stackedAvatar.styles";

type Person = Practitioner | Patient | RelatedPerson;
type MaybePerson = Person | Reference<Practitioner> | Reference<Patient> | Reference<RelatedPerson>;
const remainingTooltipLimit = 10;

export interface StackedAvatarProps {
  people: MaybePerson[];
  /** @default "small" */
  scale?: "normal" | "small" | "large";
  /** @default 5 */
  limit?: number;
  highlightPeople?: string[];
}

export function StackedAvatar(props: StackedAvatarProps) {
  const { people, scale = "small", limit = 5, highlightPeople } = props;

  const { gridSize } = useColorScheme();

  const avatarSize = gridSize * (scale === "large" ? 4 : scale === "small" ? 2.8 : 3.6);

  const abort = useAbort();
  const store = useStore();

  const [items, setItems] = useState<MaybePerson[]>([]);

  useEffect(() => {
    loadPeople();
  }, [JSON.stringify(people.map(p => p.id))]);

  return (
    <Container>
      {renderContent()}
    </Container>
  );

  function renderContent() {
    if (items.length === 0) {
      return null;
    }

    const visibleItems = items.slice(0, limit - 1).map(renderItem);
    const remainingItems = items.length - visibleItems.length;

    if (remainingItems > 0) {
      visibleItems.push(
        <RemainingCount key="count">
          <Tooltip className="count" content={getTooltipForPeople(items.slice(limit - 1))}>
            <span>+{remainingItems}</span>
          </Tooltip>
        </RemainingCount>
      );
    }

    return visibleItems;
  }

  function getTooltipForPeople(items: MaybePerson[]) {
    const listItems = items.length <= remainingTooltipLimit + 1 ? items : items.slice(0, remainingTooltipLimit);
    const hiddenItemsCount = items.length - listItems.length;
    return (
      <ul className="bp5-list-unstyled">
        {listItems.map(i => <li key={i.id}>{i.display}</li>)}
        {hiddenItemsCount > 0 && <>{hiddenItemsCount} more...</>}
      </ul>
    );
  }

  function renderItem(item: MaybePerson) {
    const highlight = !!highlightPeople?.includes(item.id);

    if (isPatient(item)) {
      return (
        <PatientAvatar key={item.id} interactive tooltip highlight={highlight} patient={item} size={avatarSize} />
      );
    }

    if (isPractitioner(item)) {
      return (
        <PractitionerAvatar key={item.id} interactive tooltip highlight={highlight} practitioner={item} size={avatarSize} />
      );
    }

    if (isReference(item)) {
      return (
        <Tooltip key={item.id} content={item.display ?? ""} targetTagName="div">
          <AvatarPlaceholder size={avatarSize} />
        </Tooltip>
      );
    }

    return (
      <Tooltip key={item.id} content={item.display ?? ""} targetTagName="div">
        <Avatar key={item.id} highlight={highlight} person={item} size={avatarSize} />
      </Tooltip>
    );
  }

  async function loadPeople() {
    const persons: Person[] = [];
    const patientReferences: Reference<Patient>[] = [];
    const relatedPersonReferences: Reference<RelatedPerson>[] = [];
    const practitionerReferences: Reference<Practitioner>[] = [];

    for (let i = 0; i < people.length; i++) {
      const person = people[i];
      if (isReference(person)) {
        if (isPatient(person)) {
          patientReferences.push(person);
        } else if (isPractitioner(person)) {
          practitionerReferences.push(person);
        } else if (isRelatedPerson(person)) {
          relatedPersonReferences.push(person);
        }
      } else {
        persons.push(person);
      }
    }
    const expandedPatients = await expandPatients(patientReferences);
    const expandedPractitioners = await expandPractitioners(practitionerReferences);
    const expandedRelatedPeople = await expandRelatedPeople(relatedPersonReferences);
    const expandedPersons = uniqBy([...expandedPatients, ...expandedPractitioners, ...expandedRelatedPeople], "id");
    const unexpanded = expandedPersons.length ? people.filter(p => !expandedPersons.some(e => e.id === p.id)) : [];
    setItems([...persons, ...expandedPatients, ...expandedPractitioners, ...expandedRelatedPeople, ...unexpanded]);
  }

  function expandPatients(patients: Reference<Patient>[]) {
    return store.patients.expand(patients, { abort: abort.signal });
  }

  function expandPractitioners(practitioners: Reference<Practitioner>[]) {
    return store.practitioners.expand(practitioners, { abort: abort.signal });
  }

  function expandRelatedPeople(relatedPeople: Reference<RelatedPerson>[]) {
    return store.relatedPeople.expand(relatedPeople, { abort: abort.signal });
  }
}

function isPractitioner(person: MaybePerson): person is Practitioner | Reference<Practitioner> {
  return person.resourceType === "Practitioner";
}

function isPatient(person: MaybePerson): person is Patient | Reference<Patient> {
  return person.resourceType === "Patient";
}

function isRelatedPerson(person: MaybePerson): person is RelatedPerson | Reference<RelatedPerson> {
  return person.resourceType === "RelatedPerson";
}
