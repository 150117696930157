import { useNotesView } from "@remhealth/core";
import type { NoteSortField } from "@remhealth/apollo";
import { NoteViewer, NoteViewerProps } from "./noteViewer";

export type GroupNoteViewerProps = Omit<NoteViewerProps, "view"> & {
  sort: NoteSortField;
};

export const GroupNoteViewer = (props: GroupNoteViewerProps) => {
  const { filters = {}, sort } = props;
  const view = useNotesView({ field: sort, direction: "Ascending" }, false, filters);

  return (
    <NoteViewer {...props} view={view} />
  );
};
