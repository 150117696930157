import { useMemo } from "react";
import { Coverage, CoverageFilterSet, CoverageSortField, CoveragesClient, IItemView, IStore, LocalDate, SortField } from "@remhealth/apollo";
import { isDateRangeEffective } from "@remhealth/host";
import { useStore } from "./useStore";

export interface CoverageFilterOptions {
  beneficiaryId: string;
  effectiveOn?: LocalDate;
}

export function useCoveragesView(sort: SortField<CoverageSortField>, options: CoverageFilterOptions): IItemView<Coverage> {
  const store = useStore();
  return useMemo(() => getCoveragesView(store.coverages, sort, options), [JSON.stringify([options, sort])]);
}

export function getCoveragesView(store: IStore<CoveragesClient>, sort: SortField<CoverageSortField>, options: CoverageFilterOptions) {
  return store.view({
    filters: {
      online: createCoverageFilters(options),
      offline: s => offlinefilter(s, options),
    },
    orderBy: {
      online: sort,
      offline: sortOffline(sort),
    },
    feedOptions: { partition: options.beneficiaryId },
  });
}

export function createCoverageFilters(options: CoverageFilterOptions): CoverageFilterSet[] {
  const patientFilter: CoverageFilterSet = {
    beneficiary: {
      in: [options.beneficiaryId],
    },
  };

  const periodFilter: CoverageFilterSet = !options.effectiveOn ? {} : {
    effective: {
      wraps: options.effectiveOn,
    },
  };

  return [
    {
      ...patientFilter,
      ...periodFilter,
    },
  ];
}

function offlinefilter(coverage: Coverage, options: CoverageFilterOptions) {
  if (options.beneficiaryId !== coverage.beneficiary.id) {
    return false;
  }

  if (options.effectiveOn && !isDateRangeEffective(coverage.effective, LocalDate.toDate(options.effectiveOn))) {
    return false;
  }

  return true;
}

function sortOffline(sort: SortField<CoverageSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Coverage, b: Coverage) => {
    switch (sort.field) {
      case "Order":
      default:
        return dir * (a.order - b.order);
    }
  };
}
