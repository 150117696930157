import { ReactElement } from "react";
import { Appointment, Location, Note, NoteReviewAssignment, PatientNote, Reference } from "@remhealth/apollo";
import { IconProps, createSubscription } from "@remhealth/ui";

export interface NoteEditPromptsState {
  signing: boolean;
  routing: NoteDialogPrompt | undefined;
  overlappingNotesAlert: OverlappingNotesAlert | undefined;
  presignAlert: PresignAlert | undefined;
  showDeleteConfirmDialog: boolean;
  signedOutcome: NoteSignPromptValues | undefined;
}

export interface NoteSignPromptValues {
  createAppointment: boolean;
  locationKind: Reference<Location> | undefined;
  linkAppointment: Appointment | undefined;
  assignedReviewers: boolean;
  reviewers: NoteReviewAssignment[];
}

export interface NoteDialogPrompt {
  note: Reference<Note>;
  onComplete?: () => void;
  onCancel?: () => void;
}

export interface OverlappingNotesAlert extends NoteDialogPrompt {
  overlappingNotes: PatientNote[];
}

export interface PresignAlert {
  onOkay?: () => void;
  onCancel?: () => void;
  icon?: ReactElement<IconProps>;
  confirmText?: string;
  cancelText?: string;
  content: JSX.Element;
}

const { context: NoteEditPromptsContext, Provider: NoteEditPromptsProvider } = createSubscription<NoteEditPromptsState>({
  signing: false,
  showDeleteConfirmDialog: false,
  signedOutcome: undefined,
  routing: undefined,
  presignAlert: undefined,
  overlappingNotesAlert: undefined,
});

export { NoteEditPromptsContext, NoteEditPromptsProvider };
